import React from "react";
import { Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { BiRevision } from "react-icons/bi";
import { FiRotateCw, FiChevronRight } from "react-icons/fi";
import BankDropDown from "../../components/BankDropDown";
import { Dialog } from "primereact/dialog";

const RelationshipAdd = ({ handelAdd, close, isLoading, selectedBankId }) => {
  const RelationshipAddSchema = Yup.object().shape({
    bankId: Yup.string().required("Bank can't be empty"),
    relationshipNo: Yup.string().required("Relationship Number can't be empty"),
  });

  // const relationshipAdd = (values) => {
  // 	console.log("values==>", values);
  // 	setLoading(true);
  // 	relastionshipService
  // 		.addRelation(values)
  // 		.then((res) => {
  // 			// sessionStorage.setItem("token", res.jwttoken);
  // 			// setLoading(false);
  // 			// setAuthToken(res.jwttoken);
  // 		})
  // 		.catch((e) => {
  // 			setError(true);
  // 			setLoading(false);
  // 		});
  // };

  return (
    <Dialog
      draggable={false}
      style={{ width: "50%" }}
      header={<h1>Add New Relationship</h1>}
      visible={true}
      onHide={() => close()}
    >
      <section className="c-content-in">
        <Formik
          initialValues={{
            bankId: "",
            relationshipNo: "",
            ...(selectedBankId && { bankId: selectedBankId }),
          }}
          validationSchema={RelationshipAddSchema}
          onSubmit={(values, { resetForm }) => {
            handelAdd(values);
            resetForm();
          }}
        >
          {({ handleChange, onSubmit, values, errors, touched }) => (
            <Form autoComplete="off">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <Field
                      name="bankId"
                      className="form-control"
                      component={BankDropDown}
                      placeholder={"Bank"}
                    />
                    {errors.bankId && touched.bankId ? (
                      <div className="text-danger">{errors.bankId}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <Field
                      name="relationshipNo"
                      type="text"
                      className="form-control"
                      placeholder="Relationship Number"
                    />

                    {errors.relationshipNo && touched.relationshipNo ? (
                      <div className="text-danger">{errors.relationshipNo}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="d-flex c-btn-group">
                    <button
                      type="submit"
                      className="c-btn-1"
                      disabled={isLoading}
                    >
                      <strong>Submit</strong>
                      {isLoading ? (
                        <span className="rotate-infinite">
                          <FiRotateCw />
                        </span>
                      ) : (
                        <span>
                          <FiChevronRight />
                        </span>
                      )}
                    </button>

                    <button
                      type="button"
                      className="c-btn-1 c-del-btn"
                      onClick={() => close()}
                    >
                      <strong>Cancel</strong>
                      <span>
                        <BiRevision />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </Dialog>
    // <Modal size="lg" backdrop="static">
    //   <Modal.Header closeButton>

    //   </Modal.Header>

    // </Modal>
  );
};

export default RelationshipAdd;
