import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import { Collapse, FormLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { BiEdit, BiRevision, BiSearchAlt } from "react-icons/bi";
import {
  BsArrowDown,
  BsArrowUp,
  BsArrowUpRightCircle,
  BsTrash,
} from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AccountService from "../../services/AccountService";
import LedgerService from "../../services/LedgerService";
import AccountGroupDropDown from "../components/AccountGroupDropDown";
import LedgerView from "../components/LedgerView";
import PageLoader from "../components/PageLoader";
import Paginator from "../components/Paginator";
import EditLedgers from "./EditLedgers";
import { Dialog } from "primereact/dialog";
import BankingServices from "../../services/BankingServices";

const ListLedgers = () => {
  const { pathname } = useLocation();

  const ledgerService = new LedgerService(); //-----------For Service Call-------//
  const accountSevice = new AccountService();
  const bankService = new BankingServices();

  const [allChildGroup, setAllChildGroup] = useState([]);
  const [ledgerListData, setLedgerListData] = useState([]); //-----------For Listing--------//
  const [edit, SetEdit] = useState(false); //--------For Edit Modal---------//
  const [editID, setEditID] = useState([]); //--------For edit id---------//
  const [pageNo, setpageNo] = useState(1); //--------for pagenation-------//
  const [pageSize, setpageSize] = useState(10); //-----------for pagenation------//
  const [searchBar, setSearchBar] = useState(false); //--------For SearchBar---------//
  const [loading, setLoading] = useState(false);
  const [access, setAccess] = useState(null); //--------
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState(false);
  const [fieldNmae, setFieldName] = useState(undefined);
  const [selectedLedger, setSelectedLedger] = useState(undefined);
  const [allLedgerList, setAllLedgerList] = useState([]);
  // const [isLedgerView, setIsLedgerView] = useState(false);
  const [isOpenLedgerView, setIsOpenLedgerVIew] = useState(false);

  useEffect(() => {
    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isRead, isWrite, isDelete }) => ({
        isRead,
        isWrite,
        isDelete,
      }))(menu);

      setAccess(access);
    }
  }, []);

  useEffect(() => {
    if (menuList !== null) {
      let payload = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (typeof fieldNmae !== "undefined") {
        payload.sorts = [`${fieldNmae},${order ? "asc" : "desc"}`];
      }
      ledgerList(payload);
    }
  }, [pageNo, pageSize, order]);

  /////////For Listing///////////
  const ledgerList = (payload) => {
    setLoader(true);
    ledgerService
      .getSearchLedgerAccount(payload)
      .then((res) => {
        setLedgerListData(res);
      })

      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .finally(() => setLoader(false));
  };

  /////////for pagenation////////////
  const increment = () => {
    setpageNo(pageNo + 1);
  };

  const decrement = () => {
    setpageNo(pageNo - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * pageNo) / e.target.value);
    setpageSize(e.target.value);
    setpageNo(temp);
  };

  ///////////Delete /////////
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const payload = {
              pageNo: pageNo,
              pageSize: pageSize,
            };
            ledgerService
              .deleteLedgerAccount(id)
              .then((res) => {
                toast.success(res, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              })
              .finally(() => ledgerList(payload));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  //////////For Edit /////////

  const getIdforUpdate = (id) => {
    setLoader(true);
    Promise.all([
      ledgerService.getUpdateId(id),
      accountSevice.getAllChildGroup(),
    ])
      .then((response) => {
        const allGroup = response[1];
        setAllChildGroup(allGroup);
        let filterID = ledgerListData.data.filter((item) => item.id === id);
        setEditID(filterID[0]);
        setLoader(false);
        SetEdit(true);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoader(false);
      });

    // console.log("filterId", filterID[0].id);
  };

  const handleCloseEdit = () => {
    SetEdit(false);
  };

  //////////For Search//////////

  const handleSearch = (data) => {
    console.log(data);
    let request = {
      pageNo: 1,
      pageSize: pageSize,
      sorts: [],
    };

    if (data.description) {
      request.description = data.description;
    }

    if (data.alias) {
      request.alias = data.alias;
    }
    if (data.openingBalance) {
      request.openingBalance = data.openingBalance;
    }
    if (data.accountGroupId) {
      request.accountGroupId = data.accountGroupId;
    }

    if (data.openingDate) {
      request.openingDate = moment(data.openingDate).format("YYYY-MM-MM");
    }

    ledgerService.getSearchLedgerAccount(request).then((res) => {
      setLedgerListData(res);
    });
  };

  ///Ledger Account Update////
  const getUpdateData = (values) => {
    setLoading(true);
    ledgerService
      .updateLedgerAccount(values)
      .then((res) => {
        toast.success("Ledger Updated Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        handleCloseEdit();
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      })
      .finally(() => {
        let payload = {
          pageNo: pageNo,
          pageSize: pageSize,
        };
        ledgerList(payload);
        setLoading(false);
      });
  };

  const reLoad = () => {
    window.location.reload();
  };

  const onSort = (fieldName, value) => {
    setFieldName(fieldName);
    setOrder(value);
  };

  const onShowLedger = (item) => {
    setLoader(true);
    bankService
      .getBalanceSheetLedgerView(item.id)
      .then((res) => {
        setAllLedgerList(res);
        setSelectedLedger(item);
        setIsOpenLedgerVIew(true);
        setLoader(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsOpenLedgerVIew(false);
        setLoader(false);
      });

    setSelectedLedger(item);
    setLoader(true);
  };

  const onCloseLedgerView = () => {
    // setSelectedLedger(undefined);
    // setIsLedgerView(false);
  };

  return access ? (
    <div className="table-responsive">
      <ToastContainer />
      {/* {!ledgerListData.data && <PageLoader />} */}
      {loader && <PageLoader />}

      <div className="d-flex align-items-center justify-content-between mb-2">
        <h1>Account ledger</h1>

        <div className="d-flex align-items-center add-searchBtn">
          <Link to="/ledger-add">
            {(access.isWrite || access.isDelete) && (
              <button type="button" className="c-btn-1 me-1">
                <span>
                  <FiPlus />
                </span>
                <strong>Ledger</strong>
              </button>
            )}
          </Link>
          <button
            type="button"
            className="c-btn-1 ms-2"
            onClick={() => setSearchBar(!searchBar)}
          >
            <span>
              <BiSearchAlt />
            </span>
            <strong>Search</strong>
          </button>
        </div>
      </div>
      <div className="cn-bank-search-w">
        <Collapse in={searchBar}>
          <div className="cn-bank1-search-form">
            <h1>Search</h1>
            <Formik
              initialValues={{
                description: "",
                alias: "",
                openingBalance: "",
                accountGroupId: "",
                openingDate: "",
              }}
              onSubmit={(values) => {
                handleSearch(values);
              }}
            >
              {({ handleChange, values, errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Description</FormLabel>
                        <div className="d-flex">
                          <Field
                            name="description"
                            className="form-control"
                            type="textarea"
                            placeholder="Description"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Alis</FormLabel>
                        <div className="d-flex">
                          <Field
                            name="alias"
                            className="form-control"
                            type="text"
                            placeholder="Alis"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                      <div className="form-group c-MutualFund-text">
                        <FormLabel>Accountant Name</FormLabel>
                        <div className="d-flex">
                          <Field
                            name="accountGroupId"
                            className="form-control"
                            component={AccountGroupDropDown}
                            placeholder="Accountant Group"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Opening Balance</FormLabel>
                        <div className="d-flex">
                          <Field
                            name="openingBalance"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Opening Balance"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Opening Date </FormLabel>
                        <div className="d-flex">
                          {/* <Field
                            name="openingDate"
                            className="form-control"
                            type="date"
                            placeholder="Opening Date"
                          /> */}

                          <Calendar
                            name="openingDate"
                            className="w-100 mb-2 form-group c-calender-w"
                            value={values.openingDate}
                            onChange={handleChange}
                            showIcon
                            dateFormat="dd/mm/yy"
                            placeholder="dd/mm/yyyy"
                            readOnlyInput
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group d-flex justify-content-end">
                          <button type="submit" className="c-btn-1">
                            <strong>SEARCH</strong>{" "}
                          </button>
                          <button
                            type="reset"
                            className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                          >
                            <strong>Reset</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>

                          <button
                            // type="reset"
                            onClick={() => reLoad()}
                            className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                          >
                            <strong>Reload</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Collapse>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th onClick={() => onSort("description", !order)}>
              Description <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("accountGroupName", !order)}>
              Account Group Name
              <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("alias", !order)}>
              Alias <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>

            <th>Opening Balance</th>
            <th onClick={() => onSort("openingDate", !order)}>
              Date <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>

            {(access.isWrite || access.isDelete) && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {ledgerListData.data && ledgerListData.data.length > 0
            ? ledgerListData.data.map((item, index) => {
                return (
                  <tr key={`ledgerListData-${index}`}>
                    <td>{pageSize * pageNo - pageSize + 1 + index}</td>
                    <td>{item.description}</td>
                    <td>{item.accountGroupName}</td>
                    <td>{item.alias}</td>
                    <td>{item.openingBalance}</td>
                    <td>{moment(item.openingDate).format("Do MMM, YYYY")}</td>

                    {(access.isWrite || access.isDelete) && (
                      <td>
                        {access.isWrite ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                            }
                          >
                            <button
                              // onClick={() => getIdforUpdate(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              {/* handleClickEdit */}
                              <BiEdit
                                onClick={() => getIdforUpdate(item.id)}
                              />{" "}
                            </button>
                          </OverlayTrigger>
                        ) : null}

                        {access.isDelete ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                            }
                          >
                            <button
                              onClick={() => deleteItem(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              <BsTrash />
                            </button>
                          </OverlayTrigger>
                        ) : null}
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>View Ledger</Tooltip>
                          }
                        >
                          <button
                            onClick={() => onShowLedger(item)}
                            type="button"
                            className="c-edit-btn"
                          >
                            <BsArrowUpRightCircle />
                          </button>
                        </OverlayTrigger>
                      </td>
                    )}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      <Paginator
        pageSize={ledgerListData.pageSize}
        firstPage={ledgerListData.firstPage}
        lastPage={ledgerListData.lastPage}
        decrement={decrement}
        increment={increment}
        pagesizechange={pagesizechange}
        pageNo={ledgerListData.pageNo}
        totalPages={ledgerListData.totalPages}
      />

      {edit ? (
        <EditLedgers
          LedgerAccountEditFilterData={editID}
          ledgerUpdate={getUpdateData}
          close={handleCloseEdit}
          loading={loading}
          allChildGroup={allChildGroup}
        />
      ) : null}

      {/* {isLedgerView && (
        <LedgerView
          onClose={onCloseLedgerView}
          selectedLedger={selectedLedger}
        />
      )} */}

      <Dialog
        draggable={false}
        visible={isOpenLedgerView}
        onHide={() => {
          setSelectedLedger(undefined);
          setIsOpenLedgerVIew(false);
        }}
        header={<h1>{selectedLedger?.description} Ledger</h1>}
        style={{ width: "60%" }}
      >
        <div className="p-3">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Transaction Date</th>
                <th>Narration</th>
                <th>Type</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {allLedgerList?.map((item, index) => {
                return (
                  <tr key={index.toString()}>
                    <td>{index + 1}</td>
                    <td>{moment(item.txnDate).format("Do MMM, YYYY")}</td>
                    <td>{item.narration} </td>
                    <td>{item.type} </td>
                    <td>{item.amount} </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialog>
    </div>
  ) : null;
};

export default ListLedgers;
