import React, { Component } from "react";
import { Field, Form, Formik } from "formik";
import { FiChevronRight, FiLock } from "react-icons/fi";
import * as Yup from "yup";
import Password_icon from "../../src/assets/images/Password.png";
import Logoimg from "../../src/assets/images/login-bg-1.jpg";
import AuthService from "../services/AuthService";
import { Navigate } from "react-router-dom";
import { Toast } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      authToken: null,
    };
    this.resetSchema = Yup.object().shape({
      // newPassword: Yup.string().required("New Password can't be empty."),
      newPassword: Yup.string()
        .required("No password provided.")
        .min(
          8,
          "Password should be minimum 8 chars with at least 1 upper, 1 lower, 1 number and 1 special character(@$!%*?)"
        )
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
          "Password can only contain Latin letters."
        ),

      confirmPassword: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        "Passwords must match"
      ),
    });
    this.authService = new AuthService();
  }

  handelRestPassword = (values) => {
    this.setState({ loading: true });
    this.authService
      .resetPassword(values)
      .then((res) => {
        toast.success(res.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        sessionStorage.setItem("token", res.jwttoken);

        this.setState({ authToken: res.jwttoken });
        this.setState({ loading: false });
      })
      .catch((e) => {
        // this.toast.show({
        //   severity: "error",
        //   summary: e.name,
        //   detail: e.message,
        //   style: { color: "#000000" },
        //   sticky: true,
        // });

        this.setState({ loading: false });
      });
  };

  render = () => {
    return (
      <>
        {this.state.authToken && <Navigate to="/login" replace={true} />}
        <ToastContainer />
        <Toast ref={(el) => (this.toast = el)} />
        <section className="c-login-w">
          <div className="c-leftBox-w">
            <img className="img-fluid" src={Logoimg} alt="Logoimg" />
          </div>
          <div className="c-rightBox-w">
            <div className="c-rightBox-in">
              <div className="c-rightBox-con">
                <h2>PIFS</h2>
                <p>Personal Investment & Financial System</p>
              </div>
              <div className="c-rightBox-form">
                <Formik
                  initialValues={{
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  validationSchema={this.resetSchema}
                  onSubmit={(values) => {
                    this.handelRestPassword(values);
                  }}>
                  {({ handelRestPassword, errors, touched }) => (
                    <Form>
                      <div className="form-group">
                        <span>
                          <img src={Password_icon} alt="" />
                        </span>
                        <Field
                          name="newPassword"
                          type="password"
                          className="form-control"
                          placeholder="New Password"
                        />
                        {errors.newPassword && touched.newPassword ? (
                          <div className="text-danger">
                            {errors.newPassword}
                          </div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <span>
                          <img src={Password_icon} alt="" />
                        </span>
                        <Field
                          name="confirmPassword"
                          type="password"
                          className="form-control"
                          placeholder="Confirm Password"
                        />
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <div className="text-danger">
                            {errors.confirmPassword}
                          </div>
                        ) : null}
                      </div>

                      <button
                        type="submit"
                        className="c-submit-btn"
                        // loading={this.state.loading}
                      >
                        <strong>Submit</strong>
                        <span>
                          <FiChevronRight />
                        </span>
                      </button>
                    </Form>
                  )}
                </Formik>

                <div className="c-forget-btn">
                  <a href={123}> </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
}

export default ResetPassword;
