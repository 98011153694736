import { Field, Form, Formik } from "formik";
import moment from "moment/moment";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { FormLabel } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { BiEdit, BiRevision } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import { FiChevronsRight, FiRotateCw } from "react-icons/fi";
import { toast } from "react-toastify";
import * as Yup from "yup";
import InsuranceServices from "../../../services/InsuranceService";
import PolicyNumberDropDown from "./PolicyNumberDropDown";

const ValueUpdateTableModal = ({ close, valueUpdateData }) => {
  const [editUpdateModal, setEditUpdateModal] = useState(false);
  const [updateID, setupdateID] = useState([]); //--------For Fatching One Data--------//
  const [editID, setEditID] = useState([]); //--------For edit id---------//
  const insuranceService = new InsuranceServices(); //-----------For Service Call-------//

  const [initialValues, setInitialValues] = useState(null); //---------For edit--------//
  const [showForm, setShowForm] = useState(false); //---------For Edit Form----------//
  const [updateValue, SetUpdateValue] = useState([]); //---------For Update Value--------//
  const [loading, setLoading] = useState(false); //---------For Loading------//

  const valueEditSchema = Yup.object().shape({
    insuranceId: Yup.string().required(
      "Insurance Police Number can't be empty"
    ),
    // nav: Yup.string().required("Nav can't be empty"),
    // totalUnit: Yup.string().required("Total Unit can't be empty"),
    // totalValue: Yup.string().required("Total Value can't be empty"),
    // valueDate: Yup.string().required("Value Date can't be empty"),
  });

  ////////////Value Edit//////////

  const getIdforUpdate = async (id) => {
    const res = await insuranceService.getupliUpdateId(id);
    res.valueDate = res.valueDate
      ? moment(res.valueDate).format("YYYY-MM-DD")
      : null;
    setInitialValues(res);
    setShowForm(true);
  };

  //For useing Update Endpoint/////

  const upliUpdate = (values) => {
    setLoading(true);
    insuranceService
      .upDateUpli(values)
      .then((res) => {
        toast.success("Value Updated Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        close();
        setLoading(false);
      })

      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      });
  };

  //////////Delete Insurance Value////////

  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            insuranceService.deleteValue(id).then((res) => {
              toast.success(res, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            });
            close();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div className="c-listbank-w p-3">
      <div className="d-flex justify-content-end">
        <Dialog
          draggable={false}
          visible={true}
          onHide={() => close()}
          header={
            showForm ? (
              <h1>Edit ULIP Value Update</h1>
            ) : (
              <h1> ULIP Value Update</h1>
            )
          }
          style={{ width: "80%" }}
        >
          <div className="c-content-in">
            {showForm ? (
              <Formik
                initialValues={{
                  ...initialValues,
                }}
                validationSchema={valueEditSchema}
                onSubmit={(values, { resetForm }) => {
                  let reqData = { ...values };
                  reqData.valueDate = values.valueDate
                    ? moment(values.valueDate).format("YYYY-MM-DD")
                    : null;
                  upliUpdate(reqData);
                  resetForm();
                }}
                enableReinitialize
              >
                {({ handleChange, onSubmit, values, errors, touched }) => (
                  <Form>
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Insurance Police Number</FormLabel>

                          <Field
                            name="insuranceId"
                            className="form-control"
                            component={PolicyNumberDropDown}
                            placeholder="Insurance policy number"
                          />
                          {errors.insuranceId && touched.insuranceId ? (
                            <div className="text-danger">
                              {errors.insuranceId}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Nav</FormLabel>
                          <Field
                            name="nav"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Nav"
                          />
                          {errors.nav && touched.nav ? (
                            <div className="text-danger">{errors.nav}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Total Unit</FormLabel>

                          <Field
                            name="totalUnit"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Total Unit"
                          />
                          {errors.totalUnit && touched.totalUnit ? (
                            <div className="text-danger">
                              {errors.totalUnit}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Total Value</FormLabel>
                          <Field
                            name="totalValue"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Total Value"
                          />
                          {errors.totalValue && touched.totalValue ? (
                            <div className="text-danger">
                              {errors.totalValue}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Value Date</FormLabel>
                          {/* <Field
                            name="valueDate"
                            className="form-control"
                            type="date"
                            placeholder="Date"
                          /> */}
                          <Calendar
                            name="valueDate"
                            className="w-100 mb-2 form-group c-calender-w"
                            value={values.valueDate}
                            onChange={handleChange}
                            showIcon
                            dateFormat="dd/mm/yy"
                            placeholder="dd/mm/yyyy"
                            readOnlyInput
                          />
                          {errors.valueDate && touched.valueDate ? (
                            <div className="text-danger">
                              {errors.valueDate}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="d-flex c-btn-group">
                          <button
                            type="submit"
                            className="c-btn-1"
                            disabled={loading}
                          >
                            <strong>Update</strong>
                            {loading ? (
                              <span className="rotate-infinite">
                                <FiRotateCw />
                              </span>
                            ) : (
                              <span>
                                <FiChevronsRight />
                              </span>
                            )}
                          </button>

                          <button
                            onClick={() => close()}
                            type="reset"
                            className="c-btn-1 c-del-btn"
                          >
                            <strong>Cancel</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : null}

            <div className="table-responsive p-3">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Insurance Policy Number</th>
                    <th>Nav</th>
                    <th>Total Unit</th>
                    <th>Total Value</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {valueUpdateData.length > 0
                    ? valueUpdateData.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.insuranceId}</td>
                            <td>{item.nav}</td>
                            <td>{item.totalUnit}</td>
                            <td>{item.totalValue}</td>
                            <td>{moment(item.valueDate).format("YY-MM-DD")}</td>

                            <td>
                              <button
                                onClick={() => getIdforUpdate(item.id)}
                                type="button"
                                className="c-edit-btn"
                              >
                                {" "}
                                <BiEdit />{" "}
                              </button>

                              <button
                                type="button"
                                className="c-edit-btn"
                                onClick={() => deleteItem(item.id)}
                              >
                                <BsTrash />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>

          {/* {editUpdateModal ? (
            <UpliValueEdit
              upliValueUpdateFilterData={editID}
              close={handleClickClose}
            />
          ) : null} */}
        </Dialog>
      </div>
    </div>
  );
};

export default ValueUpdateTableModal;
