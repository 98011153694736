import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InsuranceServices from "../../services/InsuranceService";
import { FormLabel } from "react-bootstrap";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { BiChevronsRight, BiEdit, BiRevision } from "react-icons/bi";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import PolicyNumberDropDown from "./component/PolicyNumberDropDown";
import { toast, ToastContainer } from "react-toastify";
import { Calendar } from "primereact/calendar";
import moment from "moment";

const UlipValueUpdate = () => {
  const [loading, setLoading] = useState(false); //---------For Loading------//

  const navigate = useNavigate();

  const insuranceService = new InsuranceServices(); //--------For Services Call--------//

  const valueUpdateSchema = Yup.object().shape({
    insuranceId: Yup.number().required(
      "Insurance Police Number can't be empty"
    ),
    // nav: Yup.number().required("Nav can't be empty"),
    // totalUnit: Yup.number().required("Total Unit can't be empty"),
    // totalValue: Yup.number().required("Total Value can't be empty"),
    // valueDate: Yup.string().required("Value Date can't be empty"),
  });

  /////// Create ULIP Insurance value Update///////////

  const updateValueSubmit = (values) => {
    const number = Number(values.insuranceId);
    values.insuranceId = number;
    setLoading(true);
    insuranceService
      .addValueUpdate(values)
      .then((res) => {
        toast.success("Value Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/insurance");
        }, 1000);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <ToastContainer />
      <div className="d-flex align-items-center justify-content-between w-100">
        <h1>ULIP Value Update</h1>
        <Link to="/insurance">
          <button type="button" className="c-btn-1">
            <span>
              <FiChevronRight />
            </span>
            <strong>Back</strong>
          </button>
        </Link>
      </div>
      <div>
        <section className="c-content-in">
          <Formik
            initialValues={{
              insuranceId: "",
              nav: "",
              totalUnit: "",
              totalValue: "",
              valueDate: "",
            }}
            validationSchema={valueUpdateSchema}
            onSubmit={(values, { resetForm }) => {
              let reqData = { ...values };

              reqData.valueDate = values.valueDate
                ? moment(values.valueDate).format("YYYY-MM-DD")
                : null;
              updateValueSubmit(reqData);
              resetForm();
            }}
          >
            {({ handleChange, values, errors, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Insurance Police Number</FormLabel>

                      <Field
                        name="insuranceId"
                        className="form-control"
                        component={PolicyNumberDropDown}
                        placeholder="Insurance policy number"
                      />
                      {errors.insuranceId && touched.insuranceId ? (
                        <div className="text-danger">{errors.insuranceId}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Nav</FormLabel>
                      <Field
                        name="nav"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Nav"
                      />
                      {errors.nav && touched.nav ? (
                        <div className="text-danger">{errors.nav}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Total Unit</FormLabel>

                      <Field
                        name="totalUnit"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Total Unit"
                      />
                      {errors.totalUnit && touched.totalUnit ? (
                        <div className="text-danger">{errors.totalUnit}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Total Value</FormLabel>
                      <Field
                        name="totalValue"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Total Value"
                      />
                      {errors.totalValue && touched.totalValue ? (
                        <div className="text-danger">{errors.totalValue}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Value Date</FormLabel>
                      {/* <Field
                        name="valueDate"
                        className="form-control"
                        type="date"
                        placeholder="Date"
                      /> */}
                      <Calendar
                        name="valueDate"
                        className="w-100 mb-2 form-group c-calender-w"
                        value={values.valueDate}
                        onChange={handleChange}
                        showIcon
                        dateFormat="dd/mm/yy"
                        placeholder="dd/mm/yyyy"
                        readOnlyInput
                      />
                      {errors.valueDate && touched.valueDate ? (
                        <div className="text-danger">{errors.valueDate}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="d-flex c-btn-group">
                      <button
                        type="submit"
                        className="c-btn-1"
                        disabled={loading}
                      >
                        <strong>Submit</strong>
                        {loading ? (
                          <span className="rotate-infinite">
                            <FiRotateCw />
                          </span>
                        ) : (
                          <span>
                            <FiChevronRight />
                          </span>
                        )}
                      </button>
                      <button type="reset" className="c-btn-1 c-del-btn">
                        <strong>Reset</strong>
                        <span>
                          <BiRevision />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </div>
    </div>
  );
};

export default UlipValueUpdate;
