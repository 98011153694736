import React from "react";

const Footer = () => {
	return (
		<footer className="c-footer-w">
			<div className="container">
				<p>© PIFS Technologies Pvt. Ltd. All Rights Reserved.</p>
			</div>
		</footer>
	);
};

export default Footer;
