import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FormLabel } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import { FiChevronRight, FiChevronsRight, FiRotateCw } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import AccountService from "../../services/AccountService";
import AccountParentIDDropDown from "../components/AccountParentIDDropDown";
import DropdownComponent from "../components/primereactComponent/DropdownComponent";

const AccountGroupAdd = () => {
  const accountSevice = new AccountService(); //-----------For Service Call-------//
  const [accountGroup, setAccountGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    accountSevice.getAccountGroupListAll().then((res) => {
      setAccountGroup(res);
    });
  }, []);

  const accountGroupchema = Yup.object().shape({
    name: Yup.string().required("Name can't be empty"),
    // position: Yup.string().required("Position can't be empty"),
    // parentId: Yup.string().required("User Id can't be empty"),
    accounting: Yup.string().required("Accounting Id can't be empty"),
    // ledgerName: Yup.string().required("LedgerName Id can't be empty"),
  });

  const createLedgerSubmit = (values) => {
    let parentId = Number(values.parentId);

    let reqData = {
      ...values,
      parentId: parentId > 0 ? parentId : null,
    };

    setLoading(true);
    accountSevice
      .createLedger(reqData)
      .then((res) => {
        toast.success("Account Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/account-group");
        }, 1000);

        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  return (
    <div className="row">
      <ToastContainer />
      <div className="d-flex align-items-center justify-content-between w-100">
        <h1>Add Account Group</h1>
        <Link to="/account-group">
          <button type="button" className="c-btn-1">
            <span>
              <FiChevronRight />
            </span>
            <strong>Back</strong>
          </button>
        </Link>
      </div>
      <Formik
        initialValues={{
          name: "",
          position: "",
          parentId: "",
          accounting: "",
          // ledgerName: "",
        }}
        validationSchema={accountGroupchema}
        onSubmit={(values, { resetForm }) => {
          createLedgerSubmit(values);
          resetForm();
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Name</FormLabel>

                  <Field
                    name="name"
                    className="form-control"
                    type="text"
                    placeholder="Name"
                    value={values.name}
                    onChange={(e) =>
                      setFieldValue("name", e.target.value?.toUpperCase())
                    }
                  />
                  {errors.name && touched.name ? (
                    <div className="text-danger">{errors.name}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Accounting Type</FormLabel>
                  <select
                    className="form-control"
                    name="accounting"
                    value={values.accounting}
                    onChange={handleChange}
                    aria-label="Default select example"
                  >
                    <option>Select Accounting Type</option>
                    <option value="ASSET">ASSET</option>
                    <option value="LIABILITY">LIABILITY</option>
                    <option value="INCOME">INCOME</option>
                    <option value="EXPENSES">EXPENSE</option>
                  </select>
                  {errors.accounting && touched.accounting ? (
                    <div className="text-danger">{errors.accounting}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group c-MutualFund-text">
                  {/* <FormLabel>Parent Group</FormLabel> */}

                  {/* <Field
                    name="parentId"
                    className="form-control"
                    type="text"
                    component={AccountParentIDDropDown}
                    placeholder="Parent Group"
                  /> */}
                  <DropdownComponent
                    filter
                    options={accountGroup}
                    placeholder="Select Account Group"
                    optionLabel="name"
                    header={"Select Account Group"}
                    optionValue="id"
                    name="parentId"
                    className="cr-autoSearch"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Position</FormLabel>

                  <Field
                    name="position"
                    className="form-control"
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    placeholder="Position"
                  />
                  {errors.position && touched.position ? (
                    <div className="text-danger">{errors.position}</div>
                  ) : null}
                </div>
              </div>
              {/* 
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Ledger Name</FormLabel>

                  <Field
                    name="ledgerName"
                    className="form-control"
                    type="text"
                    placeholder="Ledger Name"
                  />
                  {errors.ledgerName && touched.ledgerName ? (
                    <div className="text-danger">{errors.ledgerName}</div>
                  ) : null}
                </div>
              </div> */}

              <div className="col-lg-12 col-md-12">
                <div className="d-flex c-btn-group">
                  <button type="submit" className="c-btn-1" disabled={loading}>
                    <strong>Submit</strong>
                    {loading ? (
                      <span className="rotate-infinite">
                        <FiRotateCw />
                      </span>
                    ) : (
                      <span>
                        <FiChevronsRight />
                      </span>
                    )}
                  </button>

                  <button type="reset" className="c-btn-1 c-del-btn">
                    <strong>Reset</strong>
                    <span>
                      <BiRevision />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AccountGroupAdd;
