import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import React from "react";
import PageLoader from "./PageLoader";

const UploaCsv = ({ isUpload, onHideUpload, onUploading, isLoading }) => {
  const templateDownload = () => {};

  const onUpload = (e) => {
    const file = e.files[0];
    onUploading(file);
  };

  return (
    <Dialog
      draggable={false}
      visible={isUpload}
      onHide={onHideUpload}
      header={<h3 className="mb-0">Statement Upload</h3>}
      style={{ width: "60%" }}
    >
      {isLoading && <PageLoader />}
      <div className="cr-innerBody">
        <div className="c-download-pw">
          <h4 className="mb-3">Steps:</h4>
          <ol>
            <li>
              Download the template shown below and save it to your computer
            </li>
            <li>
              Enter the data in the template (do not change any column heading)
            </li>
            <li>Upload the file from your computer and save</li>
          </ol>
          <div className="c-download-btn">
            <span className="c-download-text">Download Lead Template</span>{" "}
            <div className="c-excel-icon">
              <i className="pi pi-file-excel"></i>
            </div>
            <Button
              icon="pi pi-download"
              className="p-button-rounded p-button-info p-button-text"
              onClick={() => templateDownload()}
            />
          </div>
        </div>

        <div>
          <FileUpload
            name="demo[]"
            customUpload
            accept=".csv, .xlsx"
            maxFileSize={1000000}
            emptyTemplate={
              <p className="m-0">Drag and drop files to here to upload.</p>
            }
            uploadHandler={(e) => onUpload(e)}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default UploaCsv;
