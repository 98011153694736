import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { BiRevision } from "react-icons/bi";
import { FiRotateCw, FiChevronsRight } from "react-icons/fi";
import { Modal, FormLabel } from "react-bootstrap";
import Select, { StylesConfig } from "react-select";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

const accountGroupchema = Yup.object().shape({
  name: Yup.string().required("Name can't be empty"),
  position: Yup.string().required("Position can't be empty"),
  // parentId: Yup.string().required("User Id can't be empty"),
  // accounting: Yup.string().required("Account Header can't be empty"),
  // ledgerName: Yup.string().required("LedgerName Id can't be empty"),
});

const AccountHeader = [
  { name: "ASSET", id: "ASSET" },
  { name: "LIABILITY", id: "LIABILITY" },
  { name: "INCOME", id: "INCOME" },
  { name: "EXPENSE", id: "EXPENSE" },
];

const AddAccountGroup = ({
  onClose,
  handelAdd,
  isLoading,
  selectedGroup,
  allParentGroup,
}) => {
  return (
    <Dialog
      draggable={false}
      header={<h1>Add Account Group</h1>}
      visible={true}
      onHide={onClose}
      style={{ width: "60%" }}
    >
      {/* // <Modal size="lg" show={true} onHide={onClose}>
    //   <Modal.Header closeButton>
    //     <h1>Add Account Group</h1>
    //   </Modal.Header> */}
      <section className="c-content-in">
        <Formik
          initialValues={{
            name: "",
            position: "",
            accounting: selectedGroup?.accounting,
            parentId: selectedGroup?.id,
          }}
          validationSchema={accountGroupchema}
          onSubmit={(values, { resetForm }) => {
            handelAdd(values);
            resetForm();
          }}
        >
          {({ handleChange, onSubmit, values, errors, touched }) => (
            <Form>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Name</FormLabel>

                    <Field
                      name="name"
                      className="form-control"
                      type="text"
                      placeholder="Name"
                    />
                    {errors.name && touched.name ? (
                      <div className="text-danger">{errors.name}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group c-MutualFund-text">
                    <FormLabel>Account Header</FormLabel>
                    <Dropdown
                      filter
                      disabled
                      name="accounting"
                      value={values.accounting}
                      options={AccountHeader}
                      optionLabel="name"
                      optionValue="id"
                      placeholder="Select Account Header"
                      className="w-100 mb-2"
                      // onChange={(e) => onChangeProduct(e.value, setFieldValue)}
                    />
                    {errors.accounting && touched.accounting ? (
                      <div className="text-danger">{errors.accounting}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Position</FormLabel>

                    <Field
                      name="position"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Position"
                    />
                    {errors.position && touched.position ? (
                      <div className="text-danger">{errors.position}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Parent Group</FormLabel>
                    <Select
                      isDisabled
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      placeholder="Select Parent Group"
                      options={allParentGroup}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.name}
                      className="c-select"
                      value={allParentGroup.filter(function (option) {
                        return option.id === values.parentId;
                      })}
                      // onChange={(e) => onChangeGroup(e, setFieldValue)}
                    />
                  </div>
                </div>

                {/* 
            <div className="col-lg-6 col-md-12">
              <div className="form-group">
                <FormLabel>Ledger Name</FormLabel>

                <Field
                  name="ledgerName"
                  className="form-control"
                  type="text"
                  placeholder="Ledger Name"
                />
                {errors.ledgerName && touched.ledgerName ? (
                  <div className="text-danger">{errors.ledgerName}</div>
                ) : null}
              </div>
            </div> */}

                <div className="col-lg-12 col-md-12">
                  <div className="d-flex c-btn-group">
                    <button
                      type="submit"
                      className="c-btn-1"
                      disabled={isLoading}
                    >
                      <strong>Submit</strong>
                      {isLoading ? (
                        <span className="rotate-infinite">
                          <FiRotateCw />
                        </span>
                      ) : (
                        <span>
                          <FiChevronsRight />
                        </span>
                      )}
                    </button>

                    <button type="reset" className="c-btn-1 c-del-btn">
                      <strong>Reset</strong>
                      <span>
                        <BiRevision />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
      {/* </Modal> */}
    </Dialog>
  );
};
export default AddAccountGroup;
