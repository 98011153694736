import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class LedgerService {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  ////////////Create Ledger Account///////////

  createLedgerAccount = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/ledger/create`, payload, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////For Search  and listing //////
  getSearchLedgerAccount = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/ledger/search`,
        payload,

        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////Ledger For Single ID///////////

  getUpdateId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/ledger/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////For Update Ledger Account ////////

  updateLedgerAccount = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/ledger/update`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////For all Ledger Account///////

  allLedgerAccount = async () => {
    try {
      const response = await axios.get(`${baseUrl}/ledger/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Deleete Ledger Account //////

  deleteLedgerAccount = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/ledger/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getBalanceSheet = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bank-statement-voucher/balanceSheet-all/search`,
        payload,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getProfitLoss = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bank-statement-voucher/profit-loss-all/search`,
        payload,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getLedgerDetails = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bank-statement-voucher/ledger-details`,
        payload,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getIncomeLedger = async () => {
    try {
      const response = await axios.get(`${baseUrl}/ledger/all-income-ledger`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getExpensesLedger = async () => {
    try {
      const response = await axios.get(`${baseUrl}/ledger/all-expense-ledger`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getCustomerProduct = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/customer-product/product-all`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
