import React, { useEffect, useState } from "react";
import StockService from "../../services/StockService";
import { Form } from "react-bootstrap";

const StockName = ({ field, form: { touched, errors }, ...props }) => {
  const stockService = new StockService(); //---------For service------------//
  const [stockName, setStockName] = useState([]);

  useEffect(() => {
    stockNameList();
  }, []);

  const stockNameList = () => {
    stockService.getAllStock().then((res) => {
      setStockName(res);
    });
  };

  return (
    <div>
      <Form.Select className="form-control" {...field} {...props}>
        <option value="null">Select Stock</option>
        {stockName.map((item) => (
          <option key={item.id} value={item.id}>
            {item.stockName}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export default StockName;
