import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ProductService from "../../services/ProductService";

const ProductCodeDropDown = ({
	field,
	form: { touched, errors },
	...props
}) => {
	const [product, setProduct] = useState([]);
	const productService = new ProductService();

	useEffect(() => {
		getProduct();
	}, []);

	const getProduct = () => {
		productService.getAllProductCode().then((res) => {
			setProduct(res);
		});
	};

	return (
		<div>
			<Form.Select className="form-control" {...field} {...props}>
				<option value="null">Select Product Code</option>
				{product.map((item) => (
					<option key={item.id} value={item.id}>
						{`${item.code}(${item.productName})`}
					</option>
				))}
			</Form.Select>
		</div>
	);
};

export default ProductCodeDropDown;
