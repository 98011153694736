import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import Logoimg from "../assets/images/login-bg-1.jpg";
import UserIcon from "../assets/images/User.png";
import AuthService from "../services/AuthService";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const VerifyOtp = () => {
	const authService = new AuthService();
	const [loading, setLoading] = useState(false);
	const [authToken, setAuthToken] = useState(null);

	const searchParams = new URLSearchParams(window.location.search);
	const mobile = searchParams.get("mobile");

	const verifyOtpSchema = Yup.object().shape({
		otp: Yup.string().required("OTP IS REQUIRED"),
	});

	const otpSubmit = (values) => {
		const payload = {
			...values,
			requestType: "UPDATE",
			mobileNumber: mobile,
		};
		setLoading(true);
		authService
			.verifyOtp(payload)
			.then((res) => {
				sessionStorage.setItem("token", res.jwtToken);
				setAuthToken(res.jwtToken);
				setLoading(false);
			})
			.catch((e) => {
				toast.error(e.message, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				setLoading(false);
			});
	};

	return (
		<>
			{authToken && <Navigate to="/resetpassword" replace={true} />}
			<ToastContainer />
			<section className="c-login-w">
				<div className="c-leftBox-w">
					<img className="img-fluid" src={Logoimg} alt="Logoimg" />
				</div>
				<div className="c-rightBox-w">
					<div className="c-rightBox-in">
						<div className="c-rightBox-con">
							<h2>PIFS</h2>
							<p>Personal Investment & Financial System</p>
						</div>

						<div className="c-rightBox-form">
							<Formik
								initialValues={{
									otp: "",
								}}
								validationSchema={verifyOtpSchema}
								onSubmit={(values) => {
									otpSubmit(values);
								}}
							>
								{({ onSubmit, errors, touched }) => (
									<Form autoComplete="off">
										<div className="form-group">
											<span>
												<img src={UserIcon} alt="" />
											</span>

											<Field
												name="otp"
												type="text"
												className="form-control"
												placeholder="ENTER OTP"
											/>
											{errors.userId && touched.userId ? (
												<div className="text-danger">
													{errors.userId}
												</div>
											) : null}
										</div>

										<button
											type="submit"
											className="c-submit-btn"
											disabled={loading}
										>
											<strong>VERIFY</strong>
											{loading ? (
												<span className="rotate-infinite">
													<FiRotateCw />
												</span>
											) : (
												<span>
													<FiChevronRight />
												</span>
											)}
										</button>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default VerifyOtp;
