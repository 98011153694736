import React, { useEffect, useState } from "react";
import { FormLabel, Modal } from "react-bootstrap";
import * as Yup from "yup";
import InsuranceServices from "../../../services/InsuranceService";
import { Field, Form, Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { BiEdit, BiRevision } from "react-icons/bi";
import { FiRotateCw, FiChevronRight } from "react-icons/fi";
import { confirmAlert } from "react-confirm-alert";
import { BsTrash } from "react-icons/bs";
import PageLoader from "../../components/PageLoader";
import { Dialog } from "primereact/dialog";

const AddInsuranceType = ({
  close,
  onAddInsurance,
  onUpdateInsurance,
  isLoading,
}) => {
  const insuranceService = new InsuranceServices();

  const [insuranceTypeList, setInsuranceTypeList] = useState([]);
  const [initialValue, setinitialValue] = useState({ name: "" });
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const insuranceTypeSchema = Yup.object().shape({
    name: Yup.string().required("Product Type Name can't be empty"),
  });

  ///////Insurance Type listing /////////

  useEffect(() => {
    listInsuranceType();
  }, []);

  ///////Create & Edit Insurance Type/////////

  const handelForm = (value) => {
    if (value.hasOwnProperty("id")) {
      onUpdateInsurance(value);
    } else {
      onAddInsurance(value);
    }
  };

  const getInsuranceType = (item) => {
    setinitialValue({
      id: item.id,
      name: item.name,
    });
  };

  const onChangeName = (value, setFieldValue) => {
    setFieldValue("name", value);
    if (value.length === 0) {
      setinitialValue({
        name: value,
      });
    }
  };

  ///////Insurance Type listing /////////

  const listInsuranceType = () => {
    setIsPageLoading(true);
    insuranceService
      .getInsuranceTypeList()
      .then((res) => {
        setInsuranceTypeList(res);
        setIsPageLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsPageLoading(false);
      });
  };

  //////////Delete Insurance////////

  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setIsModalLoading(true);
            insuranceService
              .deleteType(id)
              .then((res) => {
                const filterData = insuranceTypeList.filter(
                  (it) => it.id !== id
                );
                setInsuranceTypeList(filterData);
                setIsModalLoading(false);
                toast.success(res, {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              })
              .catch((e) => {
                toast.error(e.message, {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                setIsModalLoading(false);
              });

            // listInsuranceType();
            // close();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div>
      {isPageLoading && <PageLoader />}
      {!isPageLoading ? (
        <section className="c-inner-page-2 ">
          <ToastContainer />
          <Dialog
            draggable={false}
            visible={true}
            onHide={() => close()}
            header={<h1>Insurance Type</h1>}
            style={{ width: "80%" }}
          >
            {isModalLoading && <PageLoader />}

            <div>
              <section className="c-content-in py-2">
                <Formik
                  initialValues={{
                    ...initialValue,
                  }}
                  validationSchema={insuranceTypeSchema}
                  onSubmit={(values, formmikHandeler) => {
                    handelForm(values);
                    formmikHandeler.resetForm();
                  }}
                  enableReinitialize
                >
                  {({
                    handleChange,
                    values,
                    errors,
                    touched,
                    setFieldValue,
                  }) => (
                    <Form>
                      <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                          <div className="form-group">
                            <FormLabel>Insurance Type</FormLabel>
                            <Field
                              name="name"
                              className="form-control"
                              type="text"
                              placeholder="Type Insurance Name"
                              onChange={(e) =>
                                onChangeName(e.target.value, setFieldValue)
                              }
                            />
                            {errors.name && touched.name ? (
                              <div className="text-danger">{errors.name}</div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                          <div className="d-flex c-btn-group">
                            <button
                              type="submit"
                              className="c-btn-1"
                              disabled={isLoading}
                            >
                              {values.id ? (
                                <strong>Update</strong>
                              ) : (
                                <strong>Submit</strong>
                              )}
                              {isLoading ? (
                                <span className="rotate-infinite">
                                  <FiRotateCw />
                                </span>
                              ) : (
                                <span>
                                  <FiChevronRight />
                                </span>
                              )}
                            </button>

                            <button
                              onClick={() => close()}
                              type="button"
                              className="c-btn-1 c-del-btn"
                            >
                              <strong>Cancel</strong>
                              <span>
                                <BiRevision />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </section>
            </div>
            <div className="c-listbank-w p-3">
              <div className="table-responsive">
                <h1>Insurance Type List</h1>

                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Insurance Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {insuranceTypeList.length > 0
                      ? insuranceTypeList.map((item, index) => {
                          return (
                            <tr key={index.toString()}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>

                              <td>
                                <button
                                  onClick={() => getInsuranceType(item)}
                                  type="button"
                                  className="c-edit-btn"
                                >
                                  {" "}
                                  <BiEdit />{" "}
                                </button>

                                <button
                                  type="button"
                                  className="c-edit-btn"
                                  onClick={() => deleteItem(item.id)}
                                >
                                  <BsTrash />
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </Dialog>
        </section>
      ) : null}
    </div>
  );
};

export default AddInsuranceType;
