import React from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { FiChevronsRight, FiRotateCw } from "react-icons/fi";
import { BiRevision } from "react-icons/bi";
import { inputField } from "../../Global Component/FormField";
import { Dialog } from "primereact/dialog";

const EditAccountant = ({
  close,
  AccountantEditFilterData,
  accountentUpdate,
  loading,
}) => {
  const accountantSchema = Yup.object().shape({
    fullName: Yup.string()
      .required("Name is required")
      .matches(/^[a-zA-Z ]*$/, "Use only alphabetical characters"),
    mobileNo: Yup.string()
      .required("Mobile number is required")
      .matches(/^[0]?[6789]\d{9}$/, "Invalid Mobile No."),
    email: Yup.string().required("Email can't be empty"),
    userName: Yup.string().required("User Name can't be empty"),
  });

  return (
    <div>
      <Dialog
        draggable={false}
        visible={true}
        onHide={() => close()}
        header={<h1>Edit Accountent</h1>}
        style={{ width: "80%" }}
      >
        <section className="c-content-in">
          <Formik
            initialValues={{ ...AccountantEditFilterData }}
            validationSchema={accountantSchema}
            onSubmit={(values) => {
              accountentUpdate(values);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <Field
                      name="fullName"
                      type="text"
                      placeholder="FullName"
                      label="Full Name"
                      component={inputField}
                    />
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <Field
                      name="mobileNo"
                       type="number"onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Mobile No"
                      label="Mobile No"
                      component={inputField}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <Field
                      name="email"
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      label="Email"
                      component={inputField}
                    />
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <Field
                      name="userName"
                      className="form-control"
                      type="text"
                      placeholder="User Name"
                      label="User Name"
                      component={inputField}
                      disabled
                    />
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="d-flex c-btn-group">
                      <button
                        type="submit"
                        className="c-btn-1"
                        disabled={loading}
                      >
                        <strong>Update</strong>
                        {loading ? (
                          <span className="rotate-infinite">
                            <FiRotateCw />
                          </span>
                        ) : (
                          <span>
                            <FiChevronsRight />
                          </span>
                        )}
                      </button>

                      <button
                        onClick={() => close()}
                        type="reset"
                        className="c-btn-1 c-del-btn"
                      >
                        <strong>Cancel</strong>
                        <span>
                          <BiRevision />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </Dialog>
    </div>
  );
};

export default EditAccountant;
