import React, { useEffect, useState } from "react";
import BankingServices from "../../services/BankingServices";
import DropdownComponent from "./primereactComponent/DropdownComponent";

const CountryDropDown = ({ field, form: { touched, errors }, ...props }) => {
  const [country, setCountry] = useState([]);
  const bankingService = new BankingServices();

  useEffect(() => {
    countryList();
  }, []);

  const countryList = () => {
    bankingService.getCountryList().then((res) => {
      setCountry(res);
    });
  };

  return (
    <div>
      {/* <Form.Select className="form-control" {...field} {...props}>
				<option value="null">Select Country</option>
				{country.map((item) => (
					<option key={item.id} value={item.id}>
						{item.name}
					</option>
				))}
			</Form.Select> */}
      <DropdownComponent
        options={country}
        name={field.name}
        {...props}
        className={"p-0 "}
        filter
        optionLabel={"name"}
        optionValue={"id"}
      />
    </div>
  );
};

export default CountryDropDown;
