import { produce } from "immer";
import moment from "moment/moment";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useReducer, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { BiEdit } from "react-icons/bi";
import { BsFillTrashFill, BsUpload } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { successToaster } from "../../Global Component/FormField";
import BondService from "../../services/BondService";
import StatementService from "../../services/StatementService";
import DocumentUpload from "../components/DocumentUpload";
import PageLoader from "../components/PageLoader";
import Paginator from "../components/Paginator";

const ListUploadStatment = () => {
  const statementService = new StatementService();
  const bondServices = new BondService();

  const navigate = useNavigate();
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));

  const reducer = produce((state, action) => {
    switch (action.type) {
      case "dialog":
        state.dialog = action.payload;
        break;
      case "origin":
        state.origin.originId = action.payload.originId;
        state.origin.originItemId = action.payload.originItemId;
        break;
      default:
        return state;
    }
  });
  const [state, dispatch] = useReducer(reducer, {
    dialog: false,
    origin: { originId: null, originItemId: null },
  });

  useEffect(() => {
    if (menuList !== null) {
      setIsLoading(true);

      getData();
    }
  }, [page, pageSize]);

  const getData = () => {
    let reqData = {
      pageNo: page,
      pageSize: pageSize,
    };

    statementService
      .getStatement(reqData)
      .then((res) => {
        setAllData(res);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
      });
  };

  const increment = () => {
    setPage(page + 1);
  };

  const decrement = () => {
    setPage(page - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * page) / e.target.value);
    setpageSize(e.target.value);
    setPage(temp);
  };

  const onEdit = (id) => {
    navigate(`/addupload`, { state: id });
  };

  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setIsLoading(true);
            bondServices
              .deleteStatement(id)
              .then((res) => {
                successToaster({ label: res });
              })

              .finally(() => getData());
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <div className="c-listbank-w">
        <div className="d-flex justify-content-end">
          <Link to="/addupload">
            <button type="button" className="c-btn-1">
              <span>
                <FiPlus />
              </span>
              <strong>Statement</strong>
            </button>
          </Link>
        </div>
        <div className="table-responsive">
          <h1>Statment List</h1>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Type Of Bank Account</th>
                <th>Account Number</th>
                <th>Date Of Upoload</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allData?.data?.map((item, index) => {
                return (
                  <tr key={index.toString()}>
                    <td>{index + 1}</td>
                    <td>{item.statement}</td>
                    <td>{item.accountType.replaceAll("_", " ")}</td>
                    <td>{item.accountNo}</td>
                    <td>{moment(item.createdOn).format("Do MMM YYYY")}</td>
                    <td>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id={`tooltip-bottom`}>Edit</Tooltip>}
                      >
                        <button
                          onClick={() => onEdit(item.id)}
                          type="button"
                          className="c-edit-btn"
                        >
                          <BiEdit />
                        </button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>Document</Tooltip>
                        }
                      >
                        <button
                          type="button"
                          className="c-edit-btn"
                          onClick={() => {
                            dispatch({ type: "dialog", payload: true });
                            dispatch({
                              type: "origin",
                              payload: {
                                originId: item?.productId,
                                originItemId: item?.id,
                              },
                            });
                          }}
                        >
                          <BsUpload />
                        </button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                        }
                      >
                        <button
                          type="button"
                          className="c-edit-btn"
                          onClick={() => {
                            deleteItem(item?.id);
                          }}
                        >
                          <BsFillTrashFill />
                        </button>
                      </OverlayTrigger>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <Paginator
            pageSize={allData.pageSize}
            firstPage={allData.firstPage}
            lastPage={allData.lastPage}
            decrement={decrement}
            increment={increment}
            pagesizechange={pagesizechange}
            pageNo={allData.pageNo}
            totalPages={allData.totalPages}
          />
        </div>
      </div>

      <Dialog
        visible={state.dialog}
        style={{ width: "50%" }}
        onHide={() => {
          dispatch({ type: "dialog", payload: false });
        }}
        header={<h1>Document Upload</h1>}
      >
        <DocumentUpload name={"Mutual_Fund"} origin={state.origin} />
      </Dialog>
    </>
  );
};

export default ListUploadStatment;
