import axios from "axios";
import { baseUrl } from "../shared/Config";

export class RelationshipService {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }
  ////////////Relationship List/////////
  getRelationshipList = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/relationship/search`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  ////////////Search Relation//////////
  searchRelationship = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/relationship/search`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  ///////////////All Relationship/////////
  getAllRelationShip = async () => {
    try {
      const response = await axios.get(`${baseUrl}/relationship/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getIndividualRelationShip = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/relationship/Bank/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  ///////////////One Relationship ID////////
  getRelationshipDetails = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/relationship/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //// Create Relaationship ////
  addRelation = async (payload) => {
    try {
      const response = await axios.post(
        baseUrl + "/relationship/create",
        payload,
        { headers: this.headers }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  ////////////////Update Relaationship ////////////
  updateRelation = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/relationship/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  ///////////Delete Relation/////////////
  deleteRelation = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/relationship/delete/${id}`,
        {},
        { headers: this.headers }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
