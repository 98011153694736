import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { FormLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { BiEdit, BiRevision } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import PropertyService from "../../services/PropertyService";
import { getCurrencyFormat } from "../../shared/UtilsFunction";
import Paginator from "../components/Paginator";
import PropertyDropDown from "../components/PropertyDropDown";

const PropertyExpensesModal = ({ close }) => {
  const propertyService = new PropertyService(); //----------For Suevice---------------//
  const [loading, setLoading] = useState(false);
  const [expensesList, setExpensesList] = useState(false);
  const [expensesEditModal, setExpensesEditModal] = useState(false);
  const [updateID, setupdateID] = useState([]); //--------For Fatching One Data--------//
  const [editID, setEditID] = useState([]); //--------For edit id---------//

  const [pageNo, setpageNo] = useState(1); //--------for pagenation-------//
  const [pageSize, setpageSize] = useState(10); //-----------for pagenation------//
  const [isEdit, setEdit] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [initialValue, setinitialValue] = useState({
    propertyId: "",
    maintenancePayableFreq: "",
    maintenancePayableDate: "",
    maintenanceAmount: "",
    maintenanceAccountDebited: "",
    taxPayableFreq: "",
    taxPayableDate: "",
    taxAmount: "",
    taxAccountDebited: "",
    fees: "",
    rentReceiptFreq: "",
    rentReceiptDate: "",
    rentAmount: "",
    rentAccountCredited: "",
    incomeDescription: "",
    incomeAmount: "",
    feeDescription: "",
    type: "",
  });

  const expensesSchema = Yup.object().shape({
    propertyId: Yup.number().required("Product Name can't be empty"),
    maintenancePayableFreq: Yup.string().required(
      "Payable Frequency can't be empty"
    ),
    // maintenancePayableDate: Yup.string().required("Payble Date can't be empty"),
    // maintenanceAmount: Yup.number().required("Amount can't be empty"),
    maintenanceAccountDebited: Yup.string().required(
      "Account Debited can't be empty"
    ),
    taxPayableFreq: Yup.string().required(
      "Tex Payable Frequency can't be empty"
    ),
    // taxPayableDate: Yup.string().required("Tex Payable Date can't be empty"),

    // taxAmount: Yup.number().required("Tax Amount can't be empty"),
    taxAccountDebited: Yup.string().required(
      "Tax Account Debited can't be empty"
    ),
    // fees: Yup.number().required("Fess can't be empty"),
    // rentReceiptFreq: Yup.number().required(
    //   "Rent Receipt Frequency can't be empty"
    // ),
    // rentReceiptDate: Yup.string().required("Rent Receipt Date can't be empty"),
    // rentAmount: Yup.number().required("Rent Amount can't be empty"),
    rentAccountCredited: Yup.string().required(
      "Rent Account Credited can't be empty"
    ),
    // incomeDescription: Yup.string().required(
    //   "Description Credited can't be empty"
    // ),
    // incomeAmount: Yup.number().required("Income Amount can't be empty"),
  });

  // const expensesCreate = (values) => {
  //   setLoading(true);
  //   propertyService
  //     .createExpenses(values)
  //     .then((res) => {
  //       toast.success(" Property Expenses added", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       toast.error(e.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       setLoading(false);
  //     });
  // };

  /////////For Listing///////

  const handelForm = (value) => {
    let payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    if (isEdit) {
      setEdit(false);
      propertyService
        .updateExpenses(value)
        .then((res) => {
          if (res) {
            setLoading(true);
            toast.success(" Expenses Updated Successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setLoading(false);
            getListExpenses(payload);
            setinitialValue({
              propertyId: "",
              maintenancePayableFreq: "",
              maintenancePayableDate: "",
              maintenanceAmount: "",
              maintenanceAccountDebited: "",
              taxPayableFreq: "",
              taxPayableDate: "",
              taxAmount: "",
              taxAccountDebited: "",
              fees: "",
              rentReceiptFreq: "",
              rentReceiptDate: "",
              rentAmount: "",
              rentAccountCredited: "",
              incomeDescription: "",
              incomeAmount: "",
              feeDescription: "",
              type: "",
            });
            close();
          }
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        });
    } else {
      let payload = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      propertyService
        .createExpenses(value)
        .then((res) => {
          if (res) {
            setLoading(true);
            toast.success("Expenses Added Successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setLoading(false);
            getListExpenses(payload);
            close();
          }
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    let payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    getListExpenses(payload);
  }, [pageNo, pageSize]);

  /////////For Listing///////
  const getListExpenses = (payload) => {
    propertyService.getExpensesList(payload).then((res) => {
      setExpensesList(res);
    });
  };

  /////////for pagenation////////////
  const increment = () => {
    setpageNo(pageNo + 1);
    setpageSize(pageSize + 10);
  };

  const decrement = () => {
    setpageNo(pageNo - 1);
    setpageSize(pageSize - 10);
  };

  const pagesizechange = (e) => {
    setpageSize(e.target.value);
  };

  ///////////Delete Property Expenses/////////
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            propertyService.deleteExpenses(id).then((res) => {
              getListExpenses();

              toast.success(res, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            });
            close();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  //////////For Edit Expenses /////////

  // const getIdforUpdate = (id) => {
  //   propertyService.getUpdateWithIdExpenses(id).then((res) => {
  //     console.log("Id===>", res);

  //     setupdateID(res);
  //     console.log("Id22===>", res);
  //   });

  //   let filterID = expensesList.data.filter((item) => item.id === id);

  //   setEditID(filterID[0]);
  //   console.log("filterId", filterID[0].id);
  // };

  const getIdforUpdate = (id) => {
    setEdit(true);
    propertyService
      .getUpdateWithIdExpenses(id)
      .then((res) => {
        const {
          id,
          propertyId,
          maintenancePayableFreq,
          maintenancePayableDate,
          maintenanceAmount,
          maintenanceAccountDebited,
          taxPayableFreq,
          taxPayableDate,
          taxAmount,
          taxAccountDebited,
          fees,
          rentReceiptFreq,
          rentReceiptDate,
          rentAmount,
          rentAccountCredited,
          incomeDescription,
          incomeAmount,
          feeDescription,
          type,
        } = res;
        setinitialValue({
          id: id,
          propertyId: propertyId,
          maintenancePayableFreq: maintenancePayableFreq,
          maintenancePayableDate: maintenancePayableDate
            ? new Date(maintenancePayableDate)
            : null,
          maintenanceAmount: maintenanceAmount,
          maintenanceAccountDebited: maintenanceAccountDebited,
          taxPayableFreq: taxPayableFreq,
          taxPayableDate: taxPayableDate ? new Date(taxPayableDate) : null,
          taxAmount: taxAmount,
          taxAccountDebited: taxAccountDebited,
          fees: fees,
          rentReceiptFreq: rentReceiptFreq,
          rentReceiptDate: rentReceiptDate ? new Date(rentReceiptDate) : null,
          rentAmount: rentAmount,
          rentAccountCredited: rentAccountCredited,
          incomeDescription: incomeDescription,
          incomeAmount: incomeAmount,
          feeDescription: feeDescription,
          type: type,
        });
        setShowForm(true);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  //////////Expenses Edit Modal///////
  const handleClickOpen = () => {
    setExpensesEditModal(true);
  };

  const handleClickClose = () => {
    setExpensesEditModal(false);
  };

  return (
    <div className="c-listbank-w p-3">
      <div className="d-flex justify-content-end">
        <Dialog
          draggable={false}
          visible={true}
          onHide={() => close()}
          header={showForm ? <h1>Edit Expenses</h1> : <h1>Add Expenses</h1>}
          style={{ width: "80%" }}
        >
          <section className="c-content-in">
            <ToastContainer />
            <div className="">
              <Formik
                initialValues={{
                  ...initialValue,
                }}
                validationSchema={expensesSchema}
                onSubmit={(values, formmikHandeler) => {
                  let reqData = { ...values };
                  reqData.taxPayableDate = values.taxPayableDate
                    ? moment(values.taxPayableDate).format("YYYY-MM-DD")
                    : null;
                  reqData.rentReceiptDate = values.rentReceiptDate
                    ? moment(values.rentReceiptDate).format("YYYY-MM-DD")
                    : null;
                  reqData.maintenancePayableDate = values.maintenancePayableDate
                    ? moment(values.maintenancePayableDate).format("YYYY-MM-DD")
                    : null;
                  handelForm(reqData);
                  formmikHandeler.resetForm();
                }}
                enableReinitialize
              >
                {({ handleChange, values, errors, touched }) => (
                  <Form>
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Select Property</FormLabel>
                          <Field
                            name="propertyId"
                            className="form-control"
                            component={PropertyDropDown}
                          />

                          {errors.propertyId && touched.propertyId ? (
                            <div className="text-danger">
                              {errors.propertyId}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Payment Frequency</FormLabel>
                          <select
                            className="form-control"
                            name="maintenancePayableFreq"
                            value={values.maintenancePayableFreq}
                            onChange={handleChange}
                            aria-label="Default select example"
                          >
                            <option>Select Payment</option>
                            <option value="monthly">Monthly</option>
                            <option value="quarterly">Quarterly</option>
                            <option value="halfyearly">Half Yearly</option>
                            <option value="annually">Annually</option>
                          </select>
                          {errors.maintenancePayableFreq &&
                          touched.maintenancePayableFreq ? (
                            <div className="text-danger">
                              {errors.maintenancePayableFreq}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Maintenance Payable Date</FormLabel>
                          {/* <Field
                            name="maintenancePayableDate"
                            className="form-control"
                            type="date"
                            placeholder="Payable Date"
                          /> */}
                          <Calendar
                            name="maintenancePayableDate"
                            className="w-100 mb-2 form-group c-calender-w"
                            value={values.maintenancePayableDate}
                            onChange={handleChange}
                            showIcon
                            dateFormat="dd/mm/yy"
                            placeholder="dd/mm/yyyy"
                            readOnlyInput
                          />
                          {errors.maintenancePayableDate &&
                          touched.maintenancePayableDate ? (
                            <div className="text-danger">
                              {errors.maintenancePayableDate}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel> Maintenance Amount</FormLabel>
                          <Field
                            name="maintenanceAmount"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Amount"
                          />
                          {errors.maintenanceAmount &&
                          touched.maintenanceAmount ? (
                            <div className="text-danger">
                              {errors.maintenanceAmount}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <FormLabel>Account Debited</FormLabel>
                          <select
                            className="form-control"
                            name="maintenanceAccountDebited"
                            value={values.maintenanceAccountDebited}
                            onChange={handleChange}
                            aria-label="Default select example"
                          >
                            <option>Select</option>
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                          </select>
                          {errors.maintenanceAccountDebited &&
                          touched.maintenanceAccountDebited ? (
                            <div className="text-danger">
                              {errors.maintenanceAccountDebited}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <FormLabel>Text Payble Frequency</FormLabel>
                          <select
                            className="form-control"
                            name="taxPayableFreq"
                            value={values.taxPayableFreq}
                            onChange={handleChange}
                            aria-label="Default select example"
                          >
                            <option>Select Payment</option>
                            <option value="monthly">Monthly</option>
                            <option value="quarterly">Quarterly</option>
                            <option value="halfyearly">Half Yearly</option>
                            <option value="annually">Annually</option>
                          </select>
                          {errors.taxPayableFreq && touched.taxPayableFreq ? (
                            <div className="text-danger">
                              {errors.taxPayableFreq}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <FormLabel>Tex Payble Date</FormLabel>
                          {/* <Field
                            name="taxPayableDate"
                            className="form-control"
                            type="date"
                            placeholder="Payable Date"
                          /> */}
                          <Calendar
                            name="taxPayableDate"
                            className="w-100 mb-2 form-group c-calender-w"
                            value={values.taxPayableDate}
                            onChange={handleChange}
                            showIcon
                            dateFormat="dd/mm/yy"
                            placeholder="dd/mm/yyyy"
                            readOnlyInput
                          />
                          {errors.taxPayableDate && touched.taxPayableDate ? (
                            <div className="text-danger">
                              {errors.taxPayableDate}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Tex Amount</FormLabel>
                          <Field
                            name="taxAmount"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Amount"
                          />
                          {errors.taxAmount && touched.taxAmount ? (
                            <div className="text-danger">
                              {errors.taxAmount}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Tex Account Debited</FormLabel>
                          <select
                            className="form-control"
                            name="taxAccountDebited"
                            value={values.taxAccountDebited}
                            onChange={handleChange}
                            aria-label="Default select example"
                          >
                            <option>Select</option>
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                          </select>
                          {errors.taxAccountDebited &&
                          touched.taxAccountDebited ? (
                            <div className="text-danger">
                              {errors.taxAccountDebited}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Fees</FormLabel>
                          <Field
                            name="fees"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Fees"
                          />
                          {errors.fees && touched.fees ? (
                            <div className="text-danger">{errors.fees}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Receipt Frequency</FormLabel>
                          <Field
                            name="rentReceiptFreq"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Receipt Frequency"
                          />
                          {errors.rentReceiptFreq && touched.rentReceiptFreq ? (
                            <div className="text-danger">
                              {errors.rentReceiptFreq}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Receipt Date</FormLabel>
                          {/* <Field
                            name="rentReceiptDate"
                            className="form-control"
                            type="date"
                            placeholder="Payable Date"
                          /> */}
                          <Calendar
                            name="rentReceiptDate"
                            className="w-100 mb-2 form-group c-calender-w"
                            value={values.rentReceiptDate}
                            onChange={handleChange}
                            showIcon
                            dateFormat="dd/mm/yy"
                            placeholder="dd/mm/yyyy"
                            readOnlyInput
                          />
                          {errors.rentReceiptDate && touched.rentReceiptDate ? (
                            <div className="text-danger">
                              {errors.rentReceiptDate}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Rent Amount</FormLabel>
                          <Field
                            name="rentAmount"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Amount"
                          />
                          {errors.rentAmount && touched.rentAmount ? (
                            <div className="text-danger">
                              {errors.rentAmount}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Rent Account Credited</FormLabel>
                          <select
                            className="form-control"
                            name="rentAccountCredited"
                            value={values.rentAccountCredited}
                            onChange={handleChange}
                            aria-label="Default select example"
                          >
                            <option>Select</option>
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                          </select>
                          {errors.rentAccountCredited &&
                          touched.rentAccountCredited ? (
                            <div className="text-danger">
                              {errors.rentAccountCredited}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Income Description</FormLabel>
                          <Field
                            name="incomeDescription"
                            className="form-control"
                            type="textarea"
                            placeholder="Income Description"
                          />
                          {errors.incomeDescription &&
                          touched.incomeDescription ? (
                            <div className="text-danger">
                              {errors.incomeDescription}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Income Amount</FormLabel>
                          <Field
                            name="incomeAmount"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Income Amount"
                          />
                          {errors.incomeAmount && touched.incomeAmount ? (
                            <div className="text-danger">
                              {errors.incomeAmount}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Fee Description</FormLabel>
                          <Field
                            name="feeDescription"
                            className="form-control"
                            type="textarea"
                            placeholder="Fee Description"
                          />
                          {errors.feeDescription && touched.feeDescription ? (
                            <div className="text-danger">
                              {errors.feeDescription}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="d-flex c-btn-group">
                          <button
                            type="submit"
                            className="c-btn-1"
                            disabled={loading}
                          >
                            {showForm ? (
                              <strong>Update</strong>
                            ) : (
                              <strong>Submit</strong>
                            )}
                            {loading ? (
                              <span className="rotate-infinite">
                                <FiRotateCw />
                              </span>
                            ) : (
                              <span>
                                <FiChevronRight />
                              </span>
                            )}
                          </button>

                          <button
                            type="button"
                            onClick={() => close()}
                            className="c-btn-1 c-del-btn"
                          >
                            <strong>Cancel</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </section>

          <div className="table-responsive">
            <div className=" mb-2 p-4">
              <h2 className="mb-2">ExpensesList</h2>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Payable Frequency</th>
                    <th>Payable Date</th>
                    <th>Amount</th>
                    <th>Account Debited</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {expensesList.data && expensesList.data.length > 0
                    ? expensesList.data.map((item, index) => {
                        return (
                          <tr key={`expensesList-${index}`}>
                            <td>{index + 1}</td>
                            <td>{item.propertyId}</td>
                            <td>{item.maintenancePayableFreq}</td>
                            <td>
                              {moment(item.maintenancePayableDate).format(
                                "Do MMM, YYYY"
                              )}
                            </td>
                            <td>{getCurrencyFormat(item.maintenanceAmount)}</td>
                            <td>{item.maintenanceAccountDebited}</td>

                            <td>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                                }
                              >
                                <button
                                  onClick={() => getIdforUpdate(item.id)}
                                  type="button"
                                  className="c-edit-btn"
                                >
                                  {" "}
                                  <BiEdit onClick={handleClickOpen} />{" "}
                                </button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <button
                                  onClick={() => deleteItem(item.id)}
                                  type="button"
                                  className="c-edit-btn"
                                >
                                  {" "}
                                  <BsTrash />{" "}
                                </button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
              {expensesList.firstPage && expensesList.lastPage ? null : (
                <Paginator
                  pageSize={expensesList.pageSize}
                  firstPage={expensesList.firstPage}
                  lastPage={expensesList.lastPage}
                  decrement={decrement}
                  increment={increment}
                  pagesizechange={pagesizechange}
                  pageNo={expensesList.pageNo}
                  totalPages={expensesList.totalPages}
                />
              )}
            </div>
          </div>
        </Dialog>
      </div>
      {/* {expensesEditModal ? (
        <PropertyExpenseseEdit
          expensesFilterDate={editID}
          close={handleClickClose}
        />
      ) : null} */}
    </div>
  );
};

export default PropertyExpensesModal;
