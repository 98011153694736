import React, { useEffect, useState } from "react";
import BankingServices from "../../services/BankingServices";
import { Form } from "react-bootstrap";
import ProductService from "../../services/ProductService";
import DropdownComponent from "./primereactComponent/DropdownComponent";

const BankDropDown = ({ field, form: { touched, errors }, ...props }) => {
  const [bank, setBank] = useState([]);
  const bankingService = new BankingServices();
  const productService = new ProductService(); //---------For api Service -------//

  useEffect(() => {
    bankList();
  }, []);

  const bankList = () => {
    productService.getBankList().then((res) => {
      setBank(res);
    });
  };

  return (
    <div>
      {/* <Form.Select className="form-control" {...field} {...props}>
        <option value="null">Select bank</option>
        {bank.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Select> */}
      <DropdownComponent
        options={bank}
        placeholder={props.placeholder ?? "Bank"}
        name={field.name}
        className={"p-0 "}
        filter
        optionLabel={"name"}
        optionValue={"id"}
      />
    </div>
  );
};

export default BankDropDown;
