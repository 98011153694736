import React, { Fragment, useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import PageLoader from "./components/PageLoader";
import { TbEdit } from "react-icons/tb";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import AccountService from "../services/AccountService";
import moment from "moment";
import SearchByYear from "./components/SearchByYear";
import { Dialog } from "primereact/dialog";

const NetWorth = () => {
  const accountService = new AccountService();
  const [country, setCountry] = useState([
    { name: "inr", isActive: true, value: 0 },
    { name: "usd", isActive: false, value: 0 },
    { name: "vietnamese", isActive: false, value: 0 },
    { name: "philippine Peso", isActive: false, value: 0 },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [v1IsSearch, setV1IsSearch] = useState(false);
  const [v2IsSearch, setV2IsSearch] = useState(false);
  const [v1SearchData, setV1SearchData] = useState(null);
  const [v2SearchData, setV2SearchData] = useState(null);
  const [data, setData] = useState({});

  const makeReqData = () => {
    let reqData = {};
    let filter = country.filter((it) => it.isActive && it.name !== "inr");

    filter.forEach((item) => {
      let modName = item.name.replace(/\s/g, "").toLowerCase();
      reqData[modName] = Number(item.value);
    });

    return reqData;
  };

  useEffect(() => {
    let reqData = makeReqData();
    if (v1SearchData) reqData.timeRangeV1 = v1SearchData;
    if (v2SearchData) reqData.timeRangeV2 = v2SearchData;
    accountService
      .getNetWorth(reqData)
      .then((res) => {
        setData(res);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
      });
  }, []);

  const getData = () => {
    let reqData = makeReqData();
    if (v1SearchData) reqData.timeRangeV1 = v1SearchData;
    if (v2SearchData) reqData.timeRangeV2 = v2SearchData;
    setIsLoading(true);
    accountService
      .getNetWorth(reqData)
      .then((res) => {
        setData(res);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
      });
  };

  const onChangeCountry = (e, index) => {
    let allCountry = [...country];
    allCountry[index].isActive = !allCountry[index].isActive;
    setCountry(allCountry);
  };

  const onChangeValue = (value, index) => {
    let allCountry = [...country];

    allCountry[index].value = value;

    setCountry(allCountry);
  };

  const onChangeV1 = () => {
    setV1IsSearch(true);
  };

  const onCLoseV1 = () => {
    setV1IsSearch(false);
  };

  const onChangeV2 = () => {
    setV2IsSearch(true);
  };

  const onCLoseV2 = () => {
    setV2IsSearch(false);
  };

  const onV1Search = (value) => {
    const getReqData = makeReqData();

    let reqData = value
      ? {
          date: value.date ? value.date : null,
          startYear: value?.startYear
            ? moment(value?.startYear).format("YYYY-MM-DD")
            : null,
          endYear: value?.endYear
            ? moment(value?.endYear).format("YYYY-MM-DD")
            : null,
        }
      : null;

    getReqData.timeRangeV1 = reqData;
    if (v2SearchData) getReqData.timeRangeV2 = v2SearchData;
    setV1SearchData(reqData);
    setV1IsSearch(false);
    setIsLoading(true);
    accountService
      .getNetWorth(getReqData)
      .then((res) => {
        setData(res);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
      });
  };

  const onV2Search = (value) => {
    const getReqData = makeReqData();

    let reqData = value
      ? {
          date: value.date ? value.date : null,
          startYear: value?.startYear
            ? moment(value?.startYear).format("YYYY-MM-DD")
            : null,
          endYear: value?.endYear
            ? moment(value?.endYear).format("YYYY-MM-DD")
            : null,
        }
      : null;

    getReqData.timeRangeV2 = reqData;
    if (v1SearchData) getReqData.timeRangeV1 = v1SearchData;
    setV2SearchData(reqData);
    setV2IsSearch(false);
    setIsLoading(true);
    accountService
      .getNetWorth(getReqData)
      .then((res) => {
        setData(res);
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <PageLoader />}
      {/* {load && <PageLoader />} */}

      <div className="d-flex flex-wrap ">
        {country.map((item, index) => {
          return (
            <div className="d-flex align-items-center me-3" key={index}>
              <Checkbox
                inputId="ingredient1"
                name={item.name}
                value={item.name}
                checked={item.isActive}
                onChange={
                  item.name !== "inr"
                    ? (e) => onChangeCountry(e, index)
                    : undefined
                }
              />
              <label htmlFor="ingredient1" className="ms-2">
                {item.name.toUpperCase()}
              </label>
            </div>
          );
        })}
      </div>
      <div className="row">
        {country?.map((item, index) => {
          if (item.isActive && item.name !== "inr") {
            return (
              <div className="col-md-2  mt-2 " key={index}>
                <div
                  className="form-group"
                  style={{ marginBottom: "10px", marginTop: "10px" }}
                >
                  <label className="mb-2 text-capitalize">{item.name}</label>
                  <input
                    className="form-control"
                    key={index}
                    type="text"
                    value={item.value}
                    onChange={(e) => {
                      onChangeValue(e.target.value, index);
                    }}
                  />
                </div>
              </div>
            );
          }
        })}
        <div className="col-md-3  mt-4 ">
          <label className="mb-1 text-capitalize w-100">&nbsp;</label>
          <Button label="View" type="button" onClick={() => getData()} />
        </div>
      </div>

      <div style={{ margin: "30px auto" }}>
        <div className="table-responsive">
          <table className="c-table-new-w">
            <tbody>
              <tr>
                <th colSpan={3}>NETWORTH REPORT AS ON</th>
                <th style={{ width: "15%" }} rowSpan={2}>
                  EXCHANGE RATE (INR=&gt;USD)
                </th>
                <th style={{ width: "" }} className="no-padding">
                  <div className="d-flex justify-content-between p-2  align-items-center">
                    {v1SearchData
                      ? v1SearchData?.date === "CUSTOM_DATE_RANGE"
                        ? `${moment(v1SearchData?.startYear).format(
                            "Do MMM, YYYY"
                          )} -
                            ${moment(v1SearchData?.endYear).format(
                              "Do MMM, YYYY"
                            )}`
                        : v1SearchData?.date
                      : "PREVIOUS FINANCIAL YEAR"}
                    <TbEdit
                      onClick={onChangeV1}
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        color: "#253d98",
                      }}
                    />
                  </div>
                </th>
                <th style={{ width: "" }} className="no-padding">
                  <div className="d-flex justify-content-between p-2  align-items-center">
                    {v2SearchData
                      ? v2SearchData?.date === "CUSTOM_DATE_RANGE"
                        ? `${moment(v2SearchData?.startYear).format(
                            "Do MMM, YYYY"
                          )} -
                      ${moment(v2SearchData?.endYear).format("Do MMM, YYYY")}`
                        : v2SearchData?.date
                      : "CURRENT FINANCIAL YEAR"}

                    <TbEdit
                      onClick={onChangeV2}
                      style={{
                        fontSize: "20px",
                        cursor: "pointer",
                        color: "#253d98",
                      }}
                    />
                  </div>
                </th>
              </tr>
              <tr>
                <th style={{ width: "7%" }}>SL.NO.</th>
                <th style={{ width: "23%" }}>PARTICULAR</th>
                <th style={{ width: "15%" }}>
                  DESCRIPTION /<br />
                  PRODUCT NAME
                </th>
                <th style={{ width: "" }} className="no-padding">
                  <table className="c-table-new-w no-padding">
                    <tbody>
                      <tr>
                        <th>
                          AMOUNT <br />
                          (INR)
                        </th>
                        <th>
                          AMOUNT <br />
                          (USD)
                        </th>
                        <th>
                          AMOUNT <br />
                          (VND)
                        </th>
                        <th>
                          AMOUNT <br />
                          (PHP)
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </th>
                <th style={{ width: "" }} className="no-padding">
                  <table className="c-table-new-w no-padding">
                    <tbody>
                      <tr>
                        <th>
                          AMOUNT <br />
                          (INR)
                        </th>
                        <th>
                          AMOUNT <br />
                          (USD)
                        </th>
                        <th>
                          AMOUNT <br />
                          (VND)
                        </th>
                        <th>
                          AMOUNT <br />
                          (PHP)
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </th>
              </tr>
              {/* fixed assets start */}
              <>
                {data?.asset?.groupList.map((item, index) => (
                  <Fragment key={index}>
                    <tr className="c-heading-table">
                      <th style={{ width: "7%" }}>{index + 1}</th>
                      <th style={{ width: "23%" }}>{item?.ledgerGroupName}</th>
                      <th style={{ width: "15%" }} />
                      <th style={{ width: "15%" }}></th>
                      <th style={{ width: "" }} className="no-padding">
                        <table className="c-table-new-w no-padding">
                          <tbody>
                            <tr>
                              <th style={{ width: "25%" }}></th>
                              <th style={{ width: "25%" }}></th>
                              <th style={{ width: "25%" }}></th>
                              <th style={{ width: "25%" }}></th>
                            </tr>
                          </tbody>
                        </table>
                      </th>
                      <th style={{ width: "" }} className="no-padding">
                        <table className="c-table-new-w no-padding">
                          <tbody>
                            <tr>
                              <th style={{ width: "25%" }}></th>
                              <th style={{ width: "25%" }}></th>
                              <th style={{ width: "25%" }}></th>
                              <th style={{ width: "25%" }}></th>
                            </tr>
                          </tbody>
                        </table>
                      </th>
                    </tr>
                    {item?.subGroupDtoList?.map((ele, ind) => (
                      <Fragment key={ind}>
                        <tr>
                          <td style={{ width: "5%" }} />
                          <td style={{ width: "15%" }}>
                            {ind + 1}. {ele?.subGroupName}
                          </td>
                          <td style={{ width: "10%" }} />
                          <td style={{ width: "10%" }}></td>
                          <td style={{ width: "20%" }} className="no-padding">
                            <table className="c-table-new-w no-padding">
                              <tbody>
                                <tr>
                                  <td style={{ width: "25%" }}>
                                    {ele?.inrAmountV1}
                                  </td>
                                  <td style={{ width: "25%" }}>{ele?.usdV1}</td>
                                  <td style={{ width: "25%" }}>
                                    {ele?.vietnameseV1}
                                  </td>
                                  <td style={{ width: "25%" }}>
                                    {ele?.philippinePesoV1}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td style={{ width: "20%" }} className="no-padding">
                            <table className="c-table-new-w no-padding">
                              <tbody>
                                <tr>
                                  <td style={{ width: "25%" }}>
                                    {ele?.inrAmountV2}
                                  </td>
                                  <td style={{ width: "25%" }}>{ele?.usdV2}</td>
                                  <td style={{ width: "25%" }}>
                                    {ele?.vietnameseV2}
                                  </td>
                                  <td style={{ width: "25%" }}>
                                    {ele?.philippinePesoV2}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>

                        {ele?.ledger?.map((element, elementIndex) => {
                          return (
                            <tr key={elementIndex}>
                              <td style={{ width: "7%" }} />
                              <td style={{ width: "23%" }} />
                              <td style={{ width: "15%" }}>
                                {elementIndex + 1}. {element?.ledgerName}
                              </td>
                              <td style={{ width: "15%" }}></td>
                              <td style={{ width: "" }} className="no-padding">
                                <table className="c-table-new-w no-padding">
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td style={{ width: "" }} className="no-padding">
                                <table className="c-table-new-w no-padding">
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          );
                        })}
                      </Fragment>
                    ))}

                    <tr>
                      <td style={{ width: "7%" }} />
                      <td style={{ width: "23%" }} />
                      <td style={{ width: "15%" }}>
                        <strong className="no-space">
                          Sub Total in Assets Group
                        </strong>
                      </td>
                      <td style={{ width: "15%" }}>
                        <strong className="no-space"></strong>
                      </td>
                      <td style={{ width: "" }} className="no-padding">
                        <table className="c-table-new-w no-padding">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.inrAmountV1}
                                </strong>
                              </td>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.usdV1}
                                </strong>
                              </td>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.vietnameseV1}
                                </strong>
                              </td>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.philippinePesoV1}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style={{ width: "" }} className="no-padding">
                        <table className="c-table-new-w no-padding">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.inrAmountV2}
                                </strong>
                              </td>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.usdV2}
                                </strong>
                              </td>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.vietnameseV2}
                                </strong>
                              </td>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.philippinePesoV2}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </Fragment>
                ))}
                <tr className="c-heading-table-2">
                  <td style={{ width: "15%" }} colSpan={3}>
                    <strong className="no-space">
                      TOTAL FIXED AND LIQUIDE ASSETS ( A){" "}
                    </strong>
                  </td>
                  <td style={{ width: "15%" }}>
                    <strong className="no-space"></strong>
                  </td>
                  <td style={{ width: "" }} className="no-padding">
                    <table className="c-table-new-w no-padding">
                      <tbody>
                        <tr>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.asset?.totalInrAmountV1}
                            </strong>
                          </td>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.asset?.totalUsdV1}
                            </strong>
                          </td>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.asset?.totalVietnameseV1}
                            </strong>
                          </td>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.asset?.totalPhilippinePesoV1}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style={{ width: "" }} className="no-padding">
                    <table className="c-table-new-w no-padding">
                      <tbody>
                        <tr>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.asset?.totalInrAmountV2}
                            </strong>
                          </td>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.asset?.totalUsdV2}
                            </strong>
                          </td>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.asset?.totalVietnameseV2}
                            </strong>
                          </td>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.asset?.totalPhilippinePesoV2}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </>

              <>
                {data?.loan?.groupList.map((item, index) => (
                  <Fragment key={index}>
                    <tr className="c-heading-table">
                      <th style={{ width: "7%" }}>{index + 1}</th>
                      <th style={{ width: "23%" }}>{item?.ledgerGroupName}</th>
                      <th style={{ width: "15%" }} />
                      <th style={{ width: "15%" }}></th>
                      <th style={{ width: "" }} className="no-padding">
                        <table className="c-table-new-w no-padding">
                          <tbody>
                            <tr>
                              <th style={{ width: "25%" }}></th>
                              <th style={{ width: "25%" }}></th>
                              <th style={{ width: "25%" }}></th>
                              <th style={{ width: "25%" }}></th>
                            </tr>
                          </tbody>
                        </table>
                      </th>
                      <th style={{ width: "" }} className="no-padding">
                        <table className="c-table-new-w no-padding">
                          <tbody>
                            <tr>
                              <th style={{ width: "25%" }}></th>
                              <th style={{ width: "25%" }}></th>
                              <th style={{ width: "25%" }}></th>
                              <th style={{ width: "25%" }}></th>
                            </tr>
                          </tbody>
                        </table>
                      </th>
                    </tr>
                    {item?.subGroupDtoList?.map((ele, ind) => (
                      <Fragment key={ind}>
                        <tr>
                          <td style={{ width: "5%" }} />
                          <td style={{ width: "15%" }}>
                            {ind + 1}. {ele?.subGroupName}
                          </td>
                          <td style={{ width: "10%" }} />
                          <td style={{ width: "10%" }}></td>
                          <td style={{ width: "20%" }} className="no-padding">
                            <table className="c-table-new-w no-padding">
                              <tbody>
                                <tr>
                                  <td style={{ width: "25%" }}>
                                    {ele?.inrAmountV1}
                                  </td>
                                  <td style={{ width: "25%" }}>{ele?.usdV1}</td>
                                  <td style={{ width: "25%" }}>
                                    {ele?.vietnameseV1}
                                  </td>
                                  <td style={{ width: "25%" }}>
                                    {ele?.philippinePesoV1}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td style={{ width: "20%" }} className="no-padding">
                            <table className="c-table-new-w no-padding">
                              <tbody>
                                <tr>
                                  <td style={{ width: "25%" }}>
                                    {ele?.inrAmountV2}
                                  </td>
                                  <td style={{ width: "25%" }}>{ele?.usdV2}</td>
                                  <td style={{ width: "25%" }}>
                                    {ele?.vietnameseV2}
                                  </td>
                                  <td style={{ width: "25%" }}>
                                    {ele?.philippinePesoV2}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>

                        {ele?.ledger?.map((element, elementIndex) => {
                          return (
                            <tr key={elementIndex}>
                              <td style={{ width: "7%" }} />
                              <td style={{ width: "23%" }} />
                              <td style={{ width: "15%" }}>
                                {elementIndex + 1}. {element?.ledgerName}
                              </td>
                              <td style={{ width: "15%" }}></td>
                              <td style={{ width: "" }} className="no-padding">
                                <table className="c-table-new-w no-padding">
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td style={{ width: "" }} className="no-padding">
                                <table className="c-table-new-w no-padding">
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                      <td
                                        style={{
                                          width: "25%",
                                        }}
                                      ></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          );
                        })}
                      </Fragment>
                    ))}

                    <tr>
                      <td style={{ width: "7%" }} />
                      <td style={{ width: "23%" }} />
                      <td style={{ width: "15%" }}>
                        <strong className="no-space">
                          Sub Total in LIABILITY
                        </strong>
                      </td>
                      <td style={{ width: "15%" }}>
                        <strong className="no-space"></strong>
                      </td>
                      <td style={{ width: "" }} className="no-padding">
                        <table className="c-table-new-w no-padding">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.inrAmountV1}
                                </strong>
                              </td>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.usdV1}
                                </strong>
                              </td>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.vietnameseV1}
                                </strong>
                              </td>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.philippinePesoV1}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style={{ width: "" }} className="no-padding">
                        <table className="c-table-new-w no-padding">
                          <tbody>
                            <tr>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.inrAmountV2}
                                </strong>
                              </td>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.usdV2}
                                </strong>
                              </td>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.vietnameseV2}
                                </strong>
                              </td>
                              <td style={{ width: "25%" }}>
                                <strong className="no-space">
                                  {item?.philippinePesoV2}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </Fragment>
                ))}
                <tr className="c-heading-table-2">
                  <td style={{ width: "15%" }} colSpan={3}>
                    <strong className="no-space">
                      TOTAL LOAN & LIABILITY/ FUTURE EXPENSES (B)
                    </strong>
                  </td>
                  <td style={{ width: "15%" }}>
                    <strong className="no-space"></strong>
                  </td>
                  <td style={{ width: "" }} className="no-padding">
                    <table className="c-table-new-w no-padding">
                      <tbody>
                        <tr>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.loan?.totalInrAmountV1}
                            </strong>
                          </td>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.loan?.totalUsdV1}
                            </strong>
                          </td>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.loan?.totalVietnameseV1}
                            </strong>
                          </td>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.loan?.totalPhilippinePesoV1}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td style={{ width: "" }} className="no-padding">
                    <table className="c-table-new-w no-padding">
                      <tbody>
                        <tr>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.loan?.totalInrAmountV2}
                            </strong>
                          </td>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.loan?.totalUsdV2}
                            </strong>
                          </td>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.loan?.totalVietnameseV2}
                            </strong>
                          </td>
                          <td
                            style={{
                              width: "25%",
                            }}
                          >
                            <strong className="no-space">
                              {data?.loan?.totalPhilippinePesoV2}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr className="c-heading-table-3">
                  <td style={{ width: "15%" }} colSpan="3">
                    <strong className="no-space">
                      REPORT ON NETWORTH (A-B) = C
                    </strong>
                  </td>
                  <td style={{ width: "15%" }}>
                    <strong className="no-space"></strong>
                  </td>
                  <td style={{ width: "" }} className="no-padding">
                    <table className="c-table-new-w no-padding">
                      <tr>
                        <td style={{ width: "25%" }}>
                          <strong className="no-space">
                            {Number(
                              data?.asset?.totalInrAmountV1 -
                                data?.loan?.totalInrAmountV1
                            )}
                          </strong>
                        </td>
                        <td style={{ width: "25%" }}>
                          <strong className="no-space">
                            {Number(
                              data?.asset?.totalUsdV1 - data?.loan?.totalUsdV1
                            )}
                          </strong>
                        </td>
                        <td style={{ width: "25%" }}>
                          <strong className="no-space">
                            {Number(
                              data?.asset?.totalVietnameseV1 -
                                data?.loan?.totalVietnameseV1
                            )}
                          </strong>
                        </td>
                        <td style={{ width: "25%" }}>
                          <strong className="no-space">
                            {Number(
                              data?.asset?.totalPhilippinePesoV1 -
                                data?.loan?.totalPhilippinePesoV1
                            )}
                          </strong>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style={{ width: "" }} className="no-padding">
                    <table className="c-table-new-w no-padding">
                      <tr>
                        <td style={{ width: "25%" }}>
                          <strong className="no-space">
                            {Number(
                              data?.asset?.totalInrAmountV2 -
                                data?.loan?.totalInrAmountV2
                            )}
                          </strong>
                        </td>
                        <td style={{ width: "25%" }}>
                          <strong className="no-space">
                            {Number(
                              data?.asset?.totalUsdV2 - data?.loan?.totalUsdV2
                            )}
                          </strong>
                        </td>
                        <td style={{ width: "25%" }}>
                          <strong className="no-space">
                            {Number(
                              data?.asset?.totalVietnameseV2 -
                                data?.loan?.totalVietnameseV2
                            )}
                          </strong>
                        </td>
                        <td style={{ width: "25%" }}>
                          <strong className="no-space">
                            {Number(
                              data?.asset?.totalPhilippinePesoV2 -
                                data?.loan?.totalPhilippinePesoV2
                            )}
                          </strong>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </>
            </tbody>
          </table>
        </div>
      </div>

      <Dialog
        visible={v1IsSearch}
        style={{ width: "50%" }}
        onHide={() => {
          onCLoseV1();
        }}
        draggable={false}
        header={<h1>Comapare</h1>}
      >
        <SearchByYear
          initialDate={"PREVIOUS_FINANCIAL_YEAR"}
          onSearch={onV1Search}
        />
      </Dialog>

      <Dialog
        visible={v2IsSearch}
        style={{ width: "50%" }}
        onHide={() => {
          onCLoseV2();
        }}
        draggable={false}
        header={<h1>Comapare</h1>}
      >
        <SearchByYear onSearch={onV2Search} />
      </Dialog>
    </>
  );
};

export default NetWorth;
