import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormLabel, Modal } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import { FiChevronRight, FiRotateCw, FiPlus } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import ProductService from "../../services/ProductService";
import BondTypeDropDown from "../components/BondTypeDropDown";
import PaymentFreqDropDown from "../components/PaymentFreqDropDown";
import BondType from "./BondType";
import AddProduct from "../Products/ProductCodeManagement/AddProduct";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";

const EditBonds = ({ bondDetails, handelEdit, close, isLoading }) => {
  const { pathname } = useLocation();
  // const [product, setProduct] = useState(bondDetails.productId);
  const [productCode, setProductCode] = useState([]);
  const [addType, setAddType] = useState(false);
  const [access, setAccess] = useState(null);
  const [add, setAdd] = useState(false);
  const [isOverlayLoading, setIsOverlayLoadingAdd] = useState(false);

  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));

  const productService = new ProductService();
  const bondEditSchema = Yup.object().shape({
    // productId: Yup.string().required("Product Id can't be empty"),
    bondTypeId: Yup.string().required("Bond Type Id can't be empty"),
    // productCodeId: Yup.string().required("Product Code Id can't be empty"),
    // accountNo: Yup.string().required("Account No can't be empty"),
    // issuingCompany: Yup.string().required("Issuing Company can't be empty"),
    purchaseDate: Yup.string().required("Purchase Date can't be empty"),
    // couponAmount: Yup.string().required("Coupon Amount can't be empty"),
    // couponRate: Yup.string().required("Coupon Rate can't be empty"),
    purchaseAmount: Yup.string().required("Purchase Amount can't be empty"),
    // expiryDate: Yup.string().required("Expiry Date can't be empty"),
    // paymentFreq: Yup.string().required("Payment Freq can't be empty"),
    // sellDate: Yup.string().required("Sell Date can't be empty"),
    // sellPrice: Yup.number().required("Sell Price can't be empty"),
    // totalSoldValue: Yup.number().required("Total Value can't be empty"),
  });

  useEffect(() => {
    productService.getProductCodeByAliasID("BONDS").then((res) => {
      setProductCode(res);
    });

    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
    }
  }, [add]);

  const handleCloseAddType = () => {
    setAddType(false);
  };

  const handleCloseAdd = () => {
    setAdd(false);
  };

  const handelAddProduct = (value) => {
    setIsOverlayLoadingAdd(true);
    productService
      .createProduct(value)
      .then((res) => {
        if (res) {
          toast.success("Product code Added Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsOverlayLoadingAdd(false);
          setAdd(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsOverlayLoadingAdd(false);
      });
  };

  return (
    <div>
      <section className="c-inner-page-2 ">
        <Dialog
          draggable={false}
          visible={true}
          onHide={() => close()}
          header={<h1>Edit Bonds</h1>}
          style={{ width: "80%" }}
        >
          <section className="c-content-in">
            <Formik
              initialValues={{
                id: bondDetails.id,
                // productId: bondDetails.productId,
                bondTypeId: bondDetails.bondTypeId,
                productCodeId: bondDetails.productCodeId,
                accountNo: bondDetails.accountNo,
                issuingCompany: bondDetails.issuingCompany,
                purchaseDate: bondDetails.purchaseDate
                  ? new Date(bondDetails.purchaseDate)
                  : null,
                couponAmount: bondDetails.couponAmount,
                couponRate: bondDetails.couponRate,
                purchaseAmount: bondDetails.purchaseAmount,
                expiryDate: bondDetails.expiryDate
                  ? new Date(bondDetails.expiryDate)
                  : null,
                paymentFreq: bondDetails.paymentFreq,
                // sellDate: bondDetails.sellDate
                //   ? new Date(bondDetails.sellDate)
                //   : null,
                // sellPrice: bondDetails.sellPrice ? bondDetails.sellPrice : "",
                // totalSoldValue: bondDetails.totalSoldValue
                //   ? bondDetails.totalSoldValue
                //   : "",
              }}
              validationSchema={bondEditSchema}
              onSubmit={(values, { resetForm }) => {
                let reqData = { ...values };
                // reqData.expiryDate = values.expiryDate
                //   ? moment(values.expiryDate).format("YYYY-MM-DD")
                //   : null;
                // reqData.purchaseDate = values.purchaseDate
                //   ? moment(values.purchaseDate).format("YYYY-MM-DD")
                //   : null;

                handelEdit(reqData);
                resetForm();
              }}
              enableReinitialize
            >
              {({ errors, touched, setFieldValue, values, handleChange }) => (
                <Form>
                  <div className="row">
                    {/* <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Select Product</FormLabel>
                        <Field
                          name="productId"
                          className="form-control"
                          component={ProductDropDown}
                          onChange={(e) => {
                            setProduct(e.target.value);
                            setFieldValue("productId", e.target.value);
                          }}
                        />
                        {errors.productId && touched.productId ? (
                          <div className="text-danger">{errors.productId}</div>
                        ) : null}
                      </div>
                    </div> */}

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <div style={{ width: "85%" }}>
                          <FormLabel>Bond Type</FormLabel>
                          {!addType && (
                            <Field
                              name="bondTypeId"
                              className="form-control"
                              component={BondTypeDropDown}
                            />
                          )}

                          {errors.bondTypeId && touched.bondTypeId ? (
                            <div className="text-danger">
                              {errors.bondTypeId}
                            </div>
                          ) : null}
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary cr-BtnCus"
                          style={{ borderRadius: "50%", marginTop: 20 }}
                          onClick={() => setAddType(true)}
                        >
                          <span>
                            <FiPlus />
                          </span>
                        </button>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <div style={{ width: "85%" }}>
                          <FormLabel>Product Code</FormLabel>
                          {/* <Field
                            as="select"
                            name="productCodeId"
                            className="form-control"
                          >
                            <option value="">Select Product Code</option>
                            {productCode.map((item, index) => (
                              <option
                                value={item.id}
                                key={`product-code-${index}`}
                              >
                                {item.code}
                              </option>
                            ))}
                          </Field> */}
                          <Dropdown
                            options={productCode}
                            name="productCodeId"
                            className={"p-0 w-100"}
                            optionValue={"id"}
                            filter
                            optionLabel={"code"}
                            value={values.productCodeId}
                            onChange={handleChange}
                          />
                          {errors.productCodeId && touched.productCodeId ? (
                            <div className="text-danger">
                              {errors.productCodeId}
                            </div>
                          ) : null}
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary cr-BtnCus"
                          style={{ borderRadius: "50%", marginTop: 20 }}
                          onClick={() => setAdd(true)}
                        >
                          <span>
                            <FiPlus />
                          </span>
                        </button>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Account No</FormLabel>
                        <Field
                          name="accountNo"
                          className="form-control"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Account No"
                          //value={bondFilterData.accountNo}
                        />
                        {errors.accountNo && touched.accountNo ? (
                          <div className="text-danger">{errors.accountNo}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Issuing Company</FormLabel>
                        <Field
                          name="issuingCompany"
                          className="form-control"
                          type="text"
                          placeholder="Issuing Company"
                          //value = {bondFilterData.issuingCompany}
                        />
                        {errors.issuingCompany && touched.issuingCompany ? (
                          <div className="text-danger">
                            {errors.issuingCompany}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Purchase Date</FormLabel>
                        {/* <Field
                          name="purchaseDate"
                          className="form-control"
                          type="date"
                          placeholder="Purchase Date"
                          //value = {bondFilterData.purchaseDate}
                        /> */}
                        <Calendar
                          name="purchaseDate"
                          className="w-100 mb-2 form-group c-calender-w"
                          value={values.purchaseDate}
                          onChange={handleChange}
                          showIcon
                          dateFormat="dd/mm/yy"
                          placeholder="dd/mm/yyyy"
                          readOnlyInput
                        />

                        {errors.purchaseDate && touched.purchaseDate ? (
                          <div className="text-danger">
                            {errors.purchaseDate}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Coupon Amount</FormLabel>
                        <Field
                          name="couponAmount"
                          className="form-control"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Coupon Amount"
                          //value = {bondFilterData.purchaseDate}
                        />
                        {errors.couponAmount && touched.couponAmount ? (
                          <div className="text-danger">
                            {errors.couponAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Coupon Rate</FormLabel>
                        <Field
                          name="couponRate"
                          className="form-control"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Coupon Rate"
                          //value = {bondFilterData.couponRate}
                        />
                        {errors.couponRate && touched.couponRate ? (
                          <div className="text-danger">{errors.couponRate}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Purchase Amount</FormLabel>
                        <Field
                          name="purchaseAmount"
                          className="form-control"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Purchase Amount"
                          //value = {bondFilterData.purchaseAmount}
                        />
                        {errors.purchaseAmount && touched.purchaseAmount ? (
                          <div className="text-danger">
                            {errors.purchaseAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Expiry Date</FormLabel>
                        {/* <Field
                          name="expiryDate"
                          className="form-control"
                          type="date"
                          placeholder="Expiry Date"
                        /> */}
                        <Calendar
                          name="expiryDate"
                          className="w-100 mb-2 form-group c-calender-w"
                          value={values.expiryDate}
                          onChange={handleChange}
                          showIcon
                          dateFormat="dd/mm/yy"
                          placeholder="dd/mm/yyyy"
                          readOnlyInput
                        />
                        {errors.expiryDate && touched.expiryDate ? (
                          <div className="text-danger">{errors.expiryDate}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Payment Frequency</FormLabel>
                        <Field
                          name="paymentFreq"
                          className="form-control"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          component={PaymentFreqDropDown}
                        />
                        {errors.paymentFreq && touched.paymentFreq ? (
                          <div className="text-danger">
                            {errors.paymentFreq}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Sell Date</FormLabel>
                    
                        <Calendar
                          name="sellDate"
                          className="w-100 mb-2 form-group c-calender-w"
                          value={values.sellDate}
                          onChange={handleChange}
                          showIcon
                          dateFormat="dd/mm/yy"
                          placeholder="dd/mm/yyyy"
                          readOnlyInput
                        />
                        {errors.sellDate && touched.sellDate ? (
                          <div className="text-danger">{errors.sellDate}</div>
                        ) : null}
                      </div>
                    </div> */}

                    {/* <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Sell Price</FormLabel>
                        <Field
                          name="sellPrice"
                          className="form-control"
                           type="number"onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Sell Price"
                          //value = {bondFilterData.sellPrice}
                        />
                        {errors.sellPrice && touched.sellPrice ? (
                          <div className="text-danger">{errors.sellPrice}</div>
                        ) : null}
                      </div>
                    </div> */}

                    {/* <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Total Sold Value</FormLabel>
                        <Field
                          name="totalSoldValue"
                          className="form-control"
                           type="number"onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Total Sold Value"
                          //value = {bondFilterData.totalSoldValue}
                        />
                        {errors.totalSoldValue && touched.totalSoldValue ? (
                          <div className="text-danger">
                            {errors.totalSoldValue}
                          </div>
                        ) : null}
                      </div>
                    </div> */}

                    <div className="col-lg-12 col-md-12">
                      <div className="d-flex c-btn-group">
                        <button
                          type="submit"
                          className="c-btn-1"
                          disabled={isLoading}
                        >
                          <strong>Update</strong>
                          {isLoading ? (
                            <span className="rotate-infinite">
                              <FiRotateCw />
                            </span>
                          ) : (
                            <span>
                              <FiChevronRight />
                            </span>
                          )}
                        </button>
                        <button
                          type="button"
                          onClick={() => close()}
                          className="c-btn-1 c-del-btn"
                        >
                          <strong>Cancel</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </section>

          {addType ? (
            <BondType close={handleCloseAddType} access={access} />
          ) : null}

          {add ? (
            <AddProduct
              handelAdd={handelAddProduct}
              close={handleCloseAdd}
              isLoading={isOverlayLoading}
            />
          ) : null}
        </Dialog>
      </section>
    </div>
  );
};

export default EditBonds;
