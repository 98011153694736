import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import MutualFundsService from "../../services/MutualFundsService";
export default function MutualFundDropDown({
  field,
  form: { touched, errors },
  ...props
}) {
  const [mutualFund, setMutualFund] = useState([]);
  const mutualFundService = new MutualFundsService();

  useEffect(() => {
    mutualFundService.getAllFund().then((res) => {
      setMutualFund(res);
    });
  }, []);

  return (
    <div>
      <Form.Select className="form-control" {...field} {...props}>
        <option value="null">Select Mutual Fund</option>
        {mutualFund.map((item) => (
          <option key={item.id} value={item.id}>
            {item.fundName}
          </option>
        ))}
      </Form.Select>
    </div>
  );
}
