import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { S3 } from "aws-sdk";
import {
  FiChevronRight,
  FiChevronsRight,
  FiPlus,
  FiTrash2,
} from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { FileUpload } from "primereact/fileupload";
import StatementService from "../../services/StatementService";
import PageLoader from "../components/PageLoader";
import LedgerService from "../../services/LedgerService";
import MutualFundsService from "../../services/MutualFundsService";
import StockForm from "../components/StatementProductForm/StockForm";
import StockService from "../../services/StockService";
import BondForm from "../components/StatementProductForm/BondForm";
import BondService from "../../services/BondService";
import CreditCardForm from "../components/StatementProductForm/CreditCardForm";
import CreditCardService from "../../services/CreditCardService";
import BankAccountForm from "../components/StatementProductForm/BankAccountForm";
import BankingServices from "../../services/BankingServices";
import PropertyForm from "../components/StatementProductForm/PropertyForm";
import PropertyService from "../../services/PropertyService";
import InsuranceForm from "../components/StatementProductForm/InsuranceForm";
import InsuranceServices from "../../services/InsuranceService";
import AccountService from "../../services/AccountService";
import AddAccountLedger from "../components/AddAccountLeader";
import MFform from "../components/StatementProductForm/MFform";
import Config from "../../shared/Config";
import { convertNullToString } from "../utils/utils";

const Addjournal = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state;

  const statementService = new StatementService();
  const mutualFundService = new MutualFundsService();
  const stockService = new StockService();
  const ledgerService = new LedgerService();
  const bondservice = new BondService();
  const creditcardService = new CreditCardService();
  const bankservice = new BankingServices();
  const propertyService = new PropertyService();
  const insuranceService = new InsuranceServices();
  const accountSevice = new AccountService();

  const [selectedChildGroup, setSelectedChildGroup] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [addingLoader, setAddingLoader] = useState(false);
  const [isShowAccountLedger, setIsShowAccountLedger] = useState(false);

  const [productData, setProductData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(undefined);
  const [selectedAlias, setSelectedAlias] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [amountError, setAmountError] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const editMode = id === null ? false : true;

  const s3Clint = new S3({
    accessKeyId: Config.S3_CONFIG.S3_ACCESSKEYID,
    secretAccessKey: Config.S3_CONFIG.S3_SECRETACCESSKEY,
    region: Config.S3_CONFIG.S3_REGION,
    signatureVersion: "v4",
  });

  const [initialValue, setInitialValue] = useState({
    txnDate: "",
    document: "",
    journalDetailsDtos: [
      {
        allLedger: [],
        product: "",
        ledgerId: "",
        narration: "",
        debit: "",
        credit: "",
      },
      {
        allLedger: [],
        product: "",
        ledgerId: "",
        narration: "",
        debit: "",
        credit: "",
      },
    ],
  });

  const uploadStatmentSchema = Yup.object().shape({
    txnDate: Yup.string().required("Transaction Date is required"),
    document: Yup.string().required("Document is required"),
    journalDetailsDtos: Yup.array().of(
      Yup.object().shape({
        product: Yup.string().required("Product is required"),
        ledgerId: Yup.string().required("Ledger is required"),
        narration: Yup.string().required("Narration is required"),
      })
    ),
  });

  useEffect(() => {
    if (!editMode) {
      Promise.all([statementService.getProductDetails()])
        .then((response) => {
          const productDetailsResponse = response[0];

          productDetailsResponse.push({
            alias: "OTHERS",
            category: null,
            id: 0,
            name: "Others",
          });

          setProductData(productDetailsResponse);
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        });
    } else {
      Promise.all([
        statementService.getProductDetails(),
        accountSevice.getJournal(id),
      ])
        .then((response) => {
          const productDetailsResponse = response[0];
          const JournalDetailsResponse = response[1];

          productDetailsResponse.push({
            alias: "OTHERS",
            category: null,
            id: 0,
            name: "Others",
          });

          let convertedData = convertNullToString(JournalDetailsResponse);
          let mapp = convertedData.journalDetailsDtos.map((item) => {
            return { ...item, ledgerId: item.ledgerName };
          });

          let data = {
            ...convertedData,
            txnDate: new Date(convertedData.txnDate),
            document:
              Config.S3_CONFIG.S3_ACCESS_URL +
              "journal-doc/" +
              convertedData.document,
            journalDetailsDtos: mapp,
          };

          setInitialValue(data);
          setProductData(productDetailsResponse);
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddAccountGroup = (value) => {
    accountSevice
      .createLedger(value)
      .then((res) => {})
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const onCloseAccountLeger = () => {
    setSelectedIndex(undefined);
    setIsShowAccountLedger(false);
  };

  const onAddAccountLedger = (value) => {
    setAddingLoader(true);

    // if (productId === 2007 || productId === 2010) {
    ledgerService
      .createLedgerAccount(value)
      .then((res) => {
        const allLedger = [
          ...formRef.current.values.journalDetailsDtos[selectedIndex].allLedger,
        ];
        allLedger.push(res);
        formRef.current.values.journalDetailsDtos[selectedIndex].allLedger =
          allLedger;
        // setAllLedger(allLedger);
        setAddingLoader(false);
        setIsShowAccountLedger(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setAddingLoader(false);
      });
  };

  const handleFormSubmit = (value) => {
    setIsLoading(true);
    accountSevice
      .addJournal(value)
      .then((res) => {
        toast.success("Journal Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
        navigate("/journal");
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
      });
  };

  const onAddMf = (value, setFieldValue) => {
    // const fundTypeId = Number(values.fundTypeId);
    // const productId = Number(values.productId);
    const productCodeId = Number(value.productCodeId);
    const requestBody = {
      ...value,
      // fundTypeId: fundTypeId,
      // productId: productId,
      productCodeId: productCodeId,
    };

    setIsBtnLoading(true);
    mutualFundService
      .createMutualfund(requestBody)
      .then((res) => {
        toast.success("MutualFund Added", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const allLedger = [
          ...formRef.current.values.journalDetailsDtos[selectedIndex].allLedger,
        ];
        allLedger.push(res);
        setFieldValue(
          `journalDetailsDtos[${selectedIndex}].allLedger`,
          allLedger
        );
        setIsBtnLoading(false);
        setIsShowModal(false);
        setSelectedIndex(undefined);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  const onAddStock = (value, setFieldValue) => {
    const number = Number(value.productCodeId);

    value.productCodeId = number;

    setIsBtnLoading(true);
    stockService
      .createStock(value)
      .then((res) => {
        toast.success("Stock Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const allLedger = [
          ...formRef.current.values.journalDetailsDtos[selectedIndex].allLedger,
        ];
        allLedger.push(res);
        setFieldValue(
          `journalDetailsDtos[${selectedIndex}].allLedger`,
          allLedger
        );
        setIsBtnLoading(false);
        setIsShowModal(false);
        setSelectedIndex(undefined);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  const onAddBond = (data, setFieldValue) => {
    setIsBtnLoading(true);

    bondservice
      .createBond(data)
      .then((res) => {
        toast.success("Bond Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const allLedger = [
          ...formRef.current.values.journalDetailsDtos[selectedIndex].allLedger,
        ];
        allLedger.push(res);
        setFieldValue(
          `journalDetailsDtos[${selectedIndex}].allLedger`,
          allLedger
        );
        setIsBtnLoading(false);
        setIsShowModal(false);
        setSelectedIndex(undefined);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  const onAddCreditCard = (value, setFieldValue) => {
    setIsBtnLoading(true);

    creditcardService
      .createCreditCard(value)
      .then((res) => {
        if (res) {
          toast.success("Credit Card Added Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          const allLedger = [
            ...formRef.current.values.journalDetailsDtos[selectedIndex]
              .allLedger,
          ];
          setFieldValue(`journalDetailsDtos[${selectedIndex}].allProductName`, [
            ...allLedger,
            ...res,
          ]);
          // setFieldValue(
          //   `journalDetailsDtos[${selectedIndex}].allProductName`,
          //   res
          // );
          setIsBtnLoading(false);
          setIsShowModal(false);
          setSelectedIndex(undefined);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  const onAddBankAccount = (value, setFieldValue) => {
    setIsBtnLoading(true);

    bankservice
      .createBankAccount(value)
      .then((res) => {
        if (res) {
          toast.success("Account Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          const allLedger = [
            ...formRef.current.values.journalDetailsDtos[selectedIndex]
              .allLedger,
          ];
          allLedger.push(res);
          setFieldValue(
            `journalDetailsDtos[${selectedIndex}].allLedger`,
            allLedger
          );
          setIsBtnLoading(false);
          setIsShowModal(false);
          setSelectedIndex(undefined);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  const onAddProperty = (value, setFieldValue) => {
    setIsBtnLoading(true);

    const countryId = Number(value.countryId);
    // const productID = Number(value.productId);
    const requestBody = {
      ...value,
      countryId: countryId,
      // productId: productID,
    };
    // console.log(requestBody);
    propertyService
      .createProperty(requestBody)
      .then((res) => {
        toast.success(" Property Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        const allLedger = [
          ...formRef.current.values.journalDetailsDtos[selectedIndex].allLedger,
        ];
        allLedger.push(res);
        setFieldValue(
          `journalDetailsDtos[${selectedIndex}].allLedger`,
          allLedger
        );
        setIsBtnLoading(false);
        setIsShowModal(false);
        setSelectedIndex(undefined);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  const onAddInsurance = (value, setFieldValue) => {
    setIsBtnLoading(true);
    insuranceService
      .addInsurance(value)
      .then((res) => {
        toast.success("Insurance Type Added Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const allLedger = [
          ...formRef.current.values.journalDetailsDtos[selectedIndex].allLedger,
        ];
        allLedger.push(res);
        setFieldValue(
          `journalDetailsDtos[${selectedIndex}].allLedger`,
          allLedger
        );
        setIsBtnLoading(false);
        setIsShowModal(false);
        setSelectedIndex(undefined);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  //******************************* new funtion ************************************//

  const onChnageTxnDate = (e, setFieldValue) => {
    setFieldValue("txnDate", e);
  };

  const onChangeProduct = (index, item, setFieldValue) => {
    setIsLoading(true);
    setIsShowModal(false);
    setFieldValue(`journalDetailsDtos[${index}].product`, item.value);

    if (item.value !== "OTHERS") {
      let reqData = {
        productType: item.value,
      };

      Promise.all([statementService.getByProductId(reqData)])
        .then((response) => {
          const ledgerResponse = response[0];
          setFieldValue(
            `journalDetailsDtos[${index}].allLedger`,
            ledgerResponse
          );
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        });
    } else {
      Promise.all([ledgerService.allLedgerAccount()])
        .then((response) => {
          const allLedgerRes = response[0];
          setFieldValue(`journalDetailsDtos[${index}].allLedger`, allLedgerRes);
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        });
    }
  };

  const onClickProductName = (index, item) => {
    setSelectedAlias(item.product);
    setSelectedIndex(index);
    setIsShowModal(true);
  };

  const onAddLedger = (index) => {
    setSelectedIndex(index);
    setIsShowAccountLedger(true);
  };

  const onChangeDeposit = (index, value, setFieldValue) => {
    setFieldValue(`journalDetailsDtos[${index}].debit`, value);
    if (index === 0) {
      setFieldValue(`journalDetailsDtos[${index + 1}].credit`, value);
    }
  };

  const onChangeWithdrawl = (index, value, setFieldValue) => {
    setFieldValue(`journalDetailsDtos[${index}].credit`, value);
    if (index === 0) {
      setFieldValue(`journalDetailsDtos[${index + 1}].debit`, value);
    }
  };

  const handelFileUpload = (e, setFieldValue) => {
    setIsLoading(true);
    const file = e?.files[0];
    const fileName = file?.name;
    const fileNameArray = fileName.split(".");
    const fileExtension = fileNameArray[fileNameArray.length - 1];
    const newFileName = `${new Date().getTime()}.${fileExtension}`;
    const params = {
      Body: file,
      Bucket: Config.S3_CONFIG.S3_BUCKET + "/journal-doc",
      Key: newFileName,
      ContentType: file.type,
    };

    s3Clint.upload(params, (err, resp) => {
      if (err) {
        toast.error(e?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
      } else {
        setFieldValue(`document`, newFileName);
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <div className="row">
        <div className="d-flex align-items-center justify-content-between w-100">
          <h1>{!editMode ? "Add" : "View"} Journal</h1>

          {!editMode ? (
            <div>
              <Link to="/journal">
                <button type="button" className="c-btn-1 ms-2">
                  <span>
                    <FiChevronRight />
                  </span>
                  <strong>Back</strong>
                </button>
              </Link>
            </div>
          ) : null}
        </div>
        <Formik
          initialValues={initialValue}
          innerRef={formRef}
          validationSchema={uploadStatmentSchema}
          onSubmit={handleFormSubmit}
          enableReinitialize
        >
          {({ handleChange, values, errors, touched, setFieldValue }) => {
            let totalDeposit = 0;
            let totalWithdrawal = 0;

            values?.journalDetailsDtos?.forEach((item) => {
              totalDeposit += item.debit || 0;
              totalWithdrawal += item.credit || 0;
            });
            return (
              <div>
                <Form>
                  <div className="row">
                    {/* <div className="col-md-6"> */}
                    <div className="col">
                      <label className="cr-label mb-2">Txn date</label>
                      <Calendar
                        disabled={editMode}
                        name="txnDate"
                        value={values.txnDate}
                        onChange={(e) =>
                          onChnageTxnDate(e.target.value, setFieldValue)
                        }
                        placeholder="Txn Date"
                        className="w-100 mb-2"
                        readOnlyInput
                      />
                      {errors.txnDate && touched.txnDate ? (
                        <div className="text-danger">{errors.txnDate}</div>
                      ) : null}
                    </div>

                    {!editMode ? (
                      <div className="col">
                        <label className="cr-label mb-2">Document</label>

                        <FileUpload
                          disabled={editMode}
                          accept="*"
                          maxFileSize={1000000}
                          emptyTemplate={
                            <p>Drag and drop files to here to upload.</p>
                          }
                          uploadHandler={(e) =>
                            handelFileUpload(e, setFieldValue)
                          }
                          customUpload
                          chooseLabel="Browse"
                        />
                        {errors.document && touched.document ? (
                          <div className="text-danger">{errors.document}</div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="col">
                        <label className="cr-label mb-2">Document</label>
                        <div>
                          <img src={values.document} />
                        </div>
                      </div>
                    )}
                    {/* </div> */}

                    {/* <div className="col-md-6"> */}
                    <FieldArray
                      name="journalDetailsDtos"
                      render={(arrayHelpers) => {
                        return (
                          <>
                            {!editMode ? (
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <button
                                  type="button"
                                  className="c-btn-1 c-btn-right w-200"
                                  style={{ marginTop: 15 }}
                                  onClick={() =>
                                    arrayHelpers.push({
                                      product: "",
                                      ledgerId: "",
                                      narration: "",
                                      debit: "",
                                      credit: "",
                                    })
                                  }
                                >
                                  <span>
                                    <FiPlus />
                                  </span>
                                  <strong> Add Line</strong>
                                </button>
                              </div>
                            ) : null}

                            <div className="mb-2">
                              <strong className="text-danger">
                                {amountError
                                  ? "*Please enter withdrawl or deposit amount"
                                  : null}
                              </strong>
                            </div>

                            {values.journalDetailsDtos &&
                            values.journalDetailsDtos.length > 0
                              ? values.journalDetailsDtos.map((item, index) => {
                                  return (
                                    <div key={index}>
                                      <div className="cr-newFormBx mb-3">
                                        <div className="row">
                                          {!editMode ? (
                                            <div className="col-md-4">
                                              <label className="cr-label mb-2">
                                                Product
                                              </label>
                                              <Dropdown
                                                filter
                                                name="product"
                                                value={
                                                  values.journalDetailsDtos[
                                                    index
                                                  ].product
                                                }
                                                onChange={(e) =>
                                                  onChangeProduct(
                                                    index,
                                                    e,
                                                    setFieldValue
                                                  )
                                                }
                                                options={productData}
                                                optionLabel="name"
                                                optionValue="alias"
                                                placeholder="Select Product"
                                                className="w-100 mb-2"
                                              />
                                              <ErrorMessage
                                                name={`journalDetailsDtos[${index}].product`}
                                              >
                                                {(msg) => (
                                                  <div style={{ color: "red" }}>
                                                    {msg}
                                                  </div>
                                                )}
                                              </ErrorMessage>
                                            </div>
                                          ) : (
                                            <div className="col-md-4">
                                              <label className="cr-label mb-2">
                                                Product
                                              </label>
                                              <InputText
                                                disabled
                                                className="w-100 mb-2"
                                                name="product"
                                                value={
                                                  values.journalDetailsDtos[
                                                    index
                                                  ].product
                                                }
                                                placeholder="Product"
                                              />
                                            </div>
                                          )}

                                          {!editMode ? (
                                            <div className="col-md-4">
                                              <label className="cr-label mb-2">
                                                Ledger
                                              </label>
                                              <div className="p-inputgroup flex-1">
                                                <Dropdown
                                                  filter
                                                  name="ledger"
                                                  value={
                                                    values.journalDetailsDtos[
                                                      index
                                                    ].ledgerId
                                                  }
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      `journalDetailsDtos[${index}].ledgerId`,
                                                      e.value
                                                    )
                                                  }
                                                  options={
                                                    values.journalDetailsDtos[
                                                      index
                                                    ].allLedger
                                                  }
                                                  optionLabel="ledgerName"
                                                  optionValue="ledgerId"
                                                  placeholder="Select Ledger"
                                                  className="w-100 mb-2"
                                                />
                                                {!editMode ? (
                                                  <span
                                                    className="p-inputgroup-addon cr-addonBtn"
                                                    onClick={() =>
                                                      values
                                                        ?.journalDetailsDtos[
                                                        index
                                                      ]?.product === "OTHERS"
                                                        ? onAddLedger(index)
                                                        : onClickProductName(
                                                            index,
                                                            item
                                                          )
                                                    }
                                                  >
                                                    <FiPlus />
                                                  </span>
                                                ) : null}
                                              </div>
                                              <ErrorMessage
                                                name={`journalDetailsDtos[${index}].ledgerId`}
                                              >
                                                {(msg) => (
                                                  <div style={{ color: "red" }}>
                                                    {msg}
                                                  </div>
                                                )}
                                              </ErrorMessage>
                                            </div>
                                          ) : (
                                            <div className="col-md-4">
                                              <label className="cr-label mb-2">
                                                Ledger
                                              </label>
                                              <InputText
                                                disabled
                                                className="w-100 mb-2"
                                                name="ledgerId"
                                                value={
                                                  values.journalDetailsDtos[
                                                    index
                                                  ].ledgerId
                                                }
                                                placeholder="Ledger"
                                              />
                                            </div>
                                          )}

                                          <div className="col-md-4">
                                            <label className="cr-label mb-2">
                                              Narration
                                            </label>
                                            <InputText
                                              disabled={editMode}
                                              className="w-100 mb-2"
                                              name="Narration"
                                              value={
                                                values.journalDetailsDtos[index]
                                                  .narration
                                              }
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `journalDetailsDtos[${index}].narration`,
                                                  e.target.value
                                                )
                                              }
                                              placeholder="Narration"
                                            />
                                            <ErrorMessage
                                              name={`journalDetailsDtos[${index}].narration`}
                                            >
                                              {(msg) => (
                                                <div style={{ color: "red" }}>
                                                  {msg}
                                                </div>
                                              )}
                                            </ErrorMessage>
                                          </div>

                                          <div className="col-md-6">
                                            <label className="cr-label mb-2">
                                              Debit
                                            </label>
                                            <InputNumber
                                              className="w-100 mb-2"
                                              name="deposit"
                                              inputId="integeronly"
                                              disabled={
                                                values.journalDetailsDtos[index]
                                                  .credit || editMode
                                                  ? true
                                                  : false
                                              }
                                              value={
                                                values.journalDetailsDtos[index]
                                                  .debit
                                              }
                                              onChange={(e) =>
                                                onChangeDeposit(
                                                  index,
                                                  e.value,
                                                  setFieldValue
                                                )
                                              }
                                              placeholder="Deposit"
                                            />
                                          </div>

                                          <div className="col-md-6">
                                            <label className="cr-label mb-2">
                                              Credit
                                            </label>
                                            <InputNumber
                                              className="w-100 mb-2"
                                              name="withdrawl"
                                              inputId="integeronly"
                                              disabled={
                                                values.journalDetailsDtos[index]
                                                  .debit || editMode
                                                  ? true
                                                  : false
                                              }
                                              value={
                                                values.journalDetailsDtos[index]
                                                  .credit
                                              }
                                              onChange={(e) =>
                                                onChangeWithdrawl(
                                                  index,
                                                  e.value,
                                                  setFieldValue
                                                )
                                              }
                                              placeholder="Withdrawl"
                                            />
                                          </div>
                                        </div>

                                        {!editMode &&
                                        index !== 0 &&
                                        index !== 1 &&
                                        values.journalDetailsDtos.length > 2 ? (
                                          <div
                                            className="cr-delBtn"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <FiTrash2 />
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  );
                                })
                              : null}
                          </>
                        );
                      }}
                    />
                    {/* </div> */}

                    {!editMode ? (
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <button
                          type="submit"
                          className="c-btn-1 c-btn-right"
                          disabled={totalDeposit !== totalWithdrawal}
                        >
                          <span>
                            <FiChevronsRight />
                          </span>
                          <strong>Save</strong>
                        </button>
                      </div>
                    ) : null}
                  </div>
                </Form>

                {isShowModal ? (
                  selectedAlias === "MUTUAL_FUNDS" ? (
                    <MFform
                      onClose={() => {
                        setSelectedIndex(undefined);
                        setIsShowModal(false);
                      }}
                      onAdd={(value) => {
                        onAddMf(value, setFieldValue);
                      }}
                      isLoading={isBtnLoading}
                    />
                  ) : selectedAlias === "STOCKS" ? (
                    <StockForm
                      onClose={() => {
                        setSelectedIndex(undefined);
                        setIsShowModal(false);
                      }}
                      onAdd={(value) => {
                        onAddStock(value, setFieldValue);
                      }}
                      isLoading={isBtnLoading}
                    />
                  ) : selectedAlias === "BONDS" ? (
                    <BondForm
                      onClose={() => {
                        setSelectedIndex(undefined);
                        setIsShowModal(false);
                      }}
                      onAdd={(value) => {
                        onAddBond(value, setFieldValue);
                      }}
                      isLoading={isBtnLoading}
                    />
                  ) : selectedAlias === "CREDIT_CARD" ? (
                    <CreditCardForm
                      onClose={() => {
                        setSelectedIndex(undefined);
                        setIsShowModal(false);
                      }}
                      onAdd={(value) => {
                        onAddCreditCard(value, setFieldValue);
                      }}
                      isLoading={isBtnLoading}
                    />
                  ) : selectedAlias === "CHECKING_CURRENT_ACCOUNT" ||
                    selectedAlias === "LOAN" ||
                    selectedAlias === "SAVINGS_ACCOUNT" ||
                    selectedAlias === "DEPOSIT_ACCOUNT" ? (
                    <BankAccountForm
                      onClose={() => {
                        setSelectedIndex(undefined);
                        setIsShowModal(false);
                      }}
                      onAdd={(value) => {
                        onAddBankAccount(value, setFieldValue);
                      }}
                      alias={selectedAlias}
                      isLoading={isBtnLoading}
                    />
                  ) : selectedAlias === "PROPERTY" ? (
                    <PropertyForm
                      onClose={() => {
                        setSelectedIndex(undefined);
                        setIsShowModal(false);
                      }}
                      onAdd={(value) => {
                        onAddProperty(value, setFieldValue);
                      }}
                      isLoading={isBtnLoading}
                    />
                  ) : selectedAlias === "ULIP" ||
                    selectedAlias === "TRADITIONAL" ||
                    selectedAlias === "TERM_LIFE_INSURANCE" ||
                    selectedAlias === "MEDICAL_INSURANCE" ||
                    selectedAlias === "LIFE_INSURANCE" ? (
                    <InsuranceForm
                      onClose={() => {
                        setSelectedIndex(undefined);
                        setIsShowModal(false);
                      }}
                      onAdd={(value) => {
                        onAddInsurance(value, setFieldValue);
                      }}
                      alias={selectedAlias}
                      isLoading={isBtnLoading}
                    />
                  ) : null
                ) : null}
              </div>
            );
          }}
        </Formik>
      </div>

      {isShowAccountLedger && (
        <AddAccountLedger
          // isShowAccountLedger={isShowAccountLedger}
          onClose={onCloseAccountLeger}
          handelAdd={onAddAccountLedger}
          isLoading={addingLoader}
          selectedGroup={selectedGroup}
          // allChildGroup={allChildGroup}
          selectedChildGroup={selectedChildGroup}
          // allParentGroup={allParentGroup}
          // onChangeParentGroup={onChangeParentGroup}
        />
      )}
    </>
  );
};

export default Addjournal;
