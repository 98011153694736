import React, { useEffect, useRef, useState } from "react";
import MenuServices from "../../services/MenuServices";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { confirmAlert } from "react-confirm-alert";
import EditMenu from "./EditMenu";
import AddMenu from "./AddMenu";
import PageLoader from "../components/PageLoader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const ListMenu = () => {
  const menuServices = new MenuServices(); //----------For Suevice---------------//
  const [menuList1, setMenulist] = useState([]); //-----------For Listing------------//
  const toastTL = useRef(null); //-----------For Toster Msg----------------// //-----------for pagenation------//
  const [editModal, setEditModal] = useState(false); //-----------Edit Modal---------//
  const [editID, setEditID] = useState([]); //--------For edit id---------//
  const [isLoading, setisLoading] = useState(false);
  const [add, setAdd] = useState(false); //--------For Add---------//
  const { pathname } = useLocation();
  const [access, setAccess] = useState(null); //--------
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const [expandedRows, setExpandedRows] = useState(null);
  useEffect(() => {
    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
    }
  }, []);

  useEffect(() => {
    if (menuList !== null) {
      getListMenu();
    }
  }, []);

  const getListMenu = () => {
    setisLoading(true);
    menuServices
      .getNewMenuList()
      .then((res) => {
        setMenulist(res);
      })

      .catch((e) => {
        toastTL.current.show({
          severity: "error",
          summary: e.name,
          detail: e.message,
          style: { color: "#000000" },
          sticky: true,
        });
      })
      .finally(() => setisLoading(false));
  };

  ///////////Delete Property/////////
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item .",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            menuServices
              .deleteMenu(id)
              .then((res) => {
                toast.success(res, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              })
              .finally(() => getListMenu());
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleClickOpen = () => {
    setEditModal(true);
  };

  const handleClickClose = () => {
    setEditModal(false);
  };

  const handleCloseAdd = () => {
    setAdd(false);
    setisLoading(false);
  };

  const menuAdd = (values) => {
    setisLoading(true);
    const requestBody = {
      ...values,
      parentId: Boolean(values?.parentId) ? Number(values.parentId) : null,
    };
    menuServices
      .createMenu(requestBody)
      .then((res) => {
        toast.success(" Menu added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getListMenu();
        setisLoading(false);
        setAdd(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      });
  };

  const menuUpdate = (values) => {
    setisLoading(true);
    const requestBody = {
      ...values,
      parentId: Boolean(values?.parentId) ? Number(values.parentId) : null,
    };
    menuServices
      .updateMenu(requestBody)
      .then((res) => {
        toast.success(" Menu Updated Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        getListMenu();
        setisLoading(false);
        setEditModal(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      });
  };
  const allowExpansion = (rowData) => {
    return rowData?.child?.length > 0;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <DataTable
        value={data.child}
        unstyled={true}
        tableClassName="table table-bordered"
      >
        <Column field="name" header="Name" />
        <Column field="lightIcon" header="Light Icon" />
        <Column field="darkIcon" header="Dark Icon" />
        <Column field="url" header="Url" />
        <Column field="position" header="Position" />
        <Column
          header="Action"
          body={(item) => {
            return (
              (access.isWrite || access.isDelete) && (
                <>
                  {access.isWrite ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id={`tooltip-bottom`}>Edit</Tooltip>}
                    >
                      <button
                        onClick={() => {
                          setEditID(item);
                          handleClickOpen();
                        }}
                        type="button"
                        className="c-edit-btn"
                      >
                        <BiEdit />
                      </button>
                    </OverlayTrigger>
                  ) : null}
                  {access.isDelete ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id={`tooltip-bottom`}>Delete</Tooltip>}
                    >
                      <button
                        onClick={() => deleteItem(item.id)}
                        type="button"
                        className="c-edit-btn"
                      >
                        <BsTrash />
                      </button>
                    </OverlayTrigger>
                  ) : null}
                </>
              )
            );
          }}
        />
      </DataTable>
    );
  };
  return access ? (
    <div className="table-responsive">
      <ToastContainer />
      {isLoading && <PageLoader />}
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h1>Menu List</h1>
        <div className="d-flex align-items-center add-searchBtn">
          {(access.isWrite || access.isDelete) && (
            <button
              type="button"
              className="c-btn-1 me-1"
              onClick={() => setAdd(true)}
            >
              <span>
                <FiPlus />
              </span>
              <strong>Menu</strong>
            </button>
          )}
        </div>
      </div>
      <DataTable
        tableClassName="table table-bordered"
        value={menuList1}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column expander={allowExpansion} style={{ width: "2rem" }} />
        <Column field="name" header="Name" />
        <Column field="lightIcon" header="Light Icon" />
        <Column field="darkIcon" header="Dark Icon" />
        <Column field="url" header="Url" />
        <Column field="position" header="Position" />
        <Column
          header="Action"
          body={(item) => {
            return (
              (access.isWrite || access.isDelete) && (
                <div className="d-flex">
                  {access.isWrite ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id={`tooltip-bottom`}>Edit</Tooltip>}
                    >
                      <button
                        onClick={() => {
                          setEditID(item);
                          handleClickOpen();
                        }}
                        type="button"
                        className="c-edit-btn"
                      >
                        <BiEdit />
                      </button>
                    </OverlayTrigger>
                  ) : null}
                  {access.isDelete ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id={`tooltip-bottom`}>Delete</Tooltip>}
                    >
                      <button
                        onClick={() => deleteItem(item.id)}
                        type="button"
                        className="c-edit-btn"
                      >
                        <BsTrash />
                      </button>
                    </OverlayTrigger>
                  ) : null}
                </div>
              )
            );
          }}
        />
      </DataTable>
      {/* <table className="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Light Icon</th>
            <th>Dark Icon</th>
            <th>Url</th>
            <th>Position</th>
            <th>Child Menu</th>
            {(access.isWrite || access.isDelete) && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {menuList1.map((item, index) => (
            <tr key={`menuList1-${index}`}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.lightIcon}</td>
              <td>{item.darkIcon}</td>

              <td>{item.url}</td>
              <td>{item.position}</td>
              <td>
                {item.child?.map((elm, i) => (
                  <small key={i}>{elm?.name}</small>
                ))}
              </td>
              {(access.isWrite || access.isDelete) && (
                <td className="d-flex">
                  {access.isWrite ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id={`tooltip-bottom`}>Edit</Tooltip>}
                    >
                      <button
                        onClick={() => getIdforUpdate(item.id)}
                        type="button"
                        className="c-edit-btn"
                      >
                        <BiEdit onClick={handleClickOpen} />{" "}
                      </button>
                    </OverlayTrigger>
                  ) : null}
                  {access.isDelete ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id={`tooltip-bottom`}>Delete</Tooltip>}
                    >
                      <button
                        onClick={() => deleteItem(item.id)}
                        type="button"
                        className="c-edit-btn"
                      >
                        {" "}
                        <BsTrash />{" "}
                      </button>
                    </OverlayTrigger>
                  ) : null}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table> */}
      {/* 
      <Paginator
        pageSize={menuList1.pageSize}
        firstPage={menuList1.firstPage}
        lastPage={menuList1.lastPage}
        decrement={decrement}
        increment={increment}
        pagesizechange={pagesizechange}
        pageNo={menuList1.pageNo}
        totalPages={menuList1.totalPages}
      /> */}

      {editModal ? (
        <EditMenu
          menuList={menuList1}
          menuData={editID}
          menuUpdate={menuUpdate}
          close={handleClickClose}
          isLoading={isLoading}
        />
      ) : null}

      {add ? (
        <AddMenu
          menuList={menuList1}
          handelAdd={menuAdd}
          close={handleCloseAdd}
          isLoading={isLoading}
        />
      ) : null}
    </div>
  ) : null;
};

export default ListMenu;
