import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import BankingServices from "../../services/BankingServices";
import { toast } from "react-toastify";
import PageLoader from "../components/PageLoader";
import { Dialog } from "primereact/dialog";
import moment from "moment";

const LedgerModel = ({ onClose, ledgerDetails, heading }) => {
  const bankService = new BankingServices();
  const [isOpenLedgerView, setIsOpenLedgerVIew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ledgerData, setLedgerData] = useState([]);

  const onShowLedger = (id) => {
    setIsLoading(true);
    bankService
      .getBalanceSheetLedgerView(id)
      .then((res) => {
        setLedgerData(res);
        setIsLoading(false);
        setIsOpenLedgerVIew(true);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
      });
  };

  return (
    <div className="table-responsive">
      <Dialog
        draggable={false}
        visible={true}
        onHide={() => onClose()}
        header={<h1>{heading} Details</h1>}
        style={{ width: "60%" }}
      >
        <div className="p-3">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Description</th>
                <th>Opening Balance</th>
                <th>Current Balance</th>
                <th>Closing Balance</th>
              </tr>
            </thead>
            <tbody>
              {ledgerDetails?.map((item, index) => {
                return (
                  <tr key={index.toString()}>
                    <td>{index + 1}</td>
                    <td
                      className="cursor-pointer"
                      onClick={() => onShowLedger(item.ledgerId)}
                    >
                      {item.description}
                    </td>
                    <td>{Math.abs(item?.openingBalance).toFixed(2)} </td>
                    <td>{Math.abs(item?.currentBalance).toFixed(2)} </td>
                    <td>{Math.abs(item?.closingBalance).toFixed(2)} </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {isLoading && <PageLoader />}
      </Dialog>

      <Dialog
        draggable={false}
        visible={isOpenLedgerView}
        onHide={() => setIsOpenLedgerVIew(false)}
        header={<h1>{ledgerDetails[0]?.description} Ledger</h1>}
        style={{ width: "60%" }}
      >
        <div className="p-3">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Transaction Date</th>
                <th>Narration</th>
                <th>Type</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {ledgerData?.map((item, index) => {
                return (
                  <tr key={index.toString()}>
                    <td>{index + 1}</td>
                    <td>{moment(item.txnDate).format("Do MMM, YYYY")}</td>
                    <td>{item.narration} </td>
                    <td>{item.type} </td>
                    <td>{item.amount} </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Dialog>
    </div>
  );
};

export default LedgerModel;
