import { useEffect, useState } from "react";
// import CreditCardService from "../../services/CreditCardService";
import FolderImg from "../../assets/images/folder.png";
import PageLoader from "../components/PageLoader";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Field, Form, Formik } from "formik";
import DocumentService from "../../services/DocumentService";
import Config from "../../shared/Config";
import DocumentUpload from "../components/DocumentUpload";
import { S3 } from "aws-sdk";
import { toast } from "react-toastify";
import { FiPlus, FiChevronLeft } from "react-icons/fi";

const AllDocuments = () => {
  const [state, setstate] = useState([]);
  const [count, setCount] = useState(1);
  const [loader, setLoader] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [dialog2, setDialog2] = useState(false);
  // const productService = new CreditCardService();
  const documentService = new DocumentService();
  const s3Clint = new S3({
    accessKeyId: Config.S3_CONFIG.S3_ACCESSKEYID,
    secretAccessKey: Config.S3_CONFIG.S3_SECRETACCESSKEY,
    region: Config.S3_CONFIG.S3_REGION,
    signatureVersion: "v4",
  });
  const getData = () => {
    setLoader(true);
    // productService
    //   .getProductList()
    // .then((res) => {
    //   setstate(res);
    //   setCount(1);
    // })
    // .finally(() => setLoader(false));
    setCount(1);
    documentService
      .getAllFolder()
      .then((res) => {
        setstate(res);
      })
      .finally(() => setLoader(false));
  };
  const handleDelete = (id) => {
    documentService.deleteFolderById(id).then(() => {
      parentId ? getSubData(parentId) : getData();
    });
  };
  const getSubData = (id) => {
    setLoader(true);

    documentService
      .getFolderByFolderId(id)
      .then((res) => {
        setstate(res);
      })
      .finally(() => setLoader(false));
  };
  useEffect(() => {
    parentId ? getSubData(parentId) : getData();
  }, []);
  const handleForm = (val) => {
    documentService.createFolder({ ...val, parentId }).then(() => {
      setDialog(false);
      parentId ? getSubData(parentId) : getData();
    });
  };
  const deleteData = (id, fileName) => {
    setLoader(true);
    const params = {
      Bucket: Config.S3_CONFIG.S3_BUCKET + "/documents/",
      Key: fileName,
    };
    s3Clint.deleteObject(params, (err, data) => {
      if (err) {
        setLoader(false);
      } else {
        documentService
          .deleteDocumentById(id)
          .then(() => {
            toast.success("Document deleted successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            parentId ? getSubData(parentId) : getData();
          })
          .finally(() => {
            setLoader(false);
          });
      }
    });
  };
  const handleReturn = () => {
    parentId ? getSubData(parentId) : getData();
  };
  return (
    <>
      <Dialog
        visible={dialog2}
        style={{ width: "50%" }}
        onHide={() => setDialog2(false)}
        header={<h1>Document Upload</h1>}
      >
        <DocumentUpload
          name={"Credit_Card"}
          getData={handleReturn}
          parentId={parentId}
        />
      </Dialog>
      <Dialog
        header={<h1>Create Folder</h1>}
        style={{ width: "30%" }}
        visible={dialog}
        onHide={() => setDialog(false)}
      >
        <Formik
          initialValues={{
            folderName: "",
          }}
          onSubmit={handleForm}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              {/* <Field required name="folderName" placeholder="Folder Name" /> */}
              <div className="form-group">
                <Field
                  name="folderName"
                  type="text"
                  className="form-control mb-3"
                  placeholder="Folder Name"
                />
              </div>
              {/* <Button label="Create" type="submit" /> */}
              <div className="d-flex justify-content-end align-items-center">
                <button
                  type="submit"
                  className="c-btn-1 me-1"
                  onClick={() => setDialog(true)}
                >
                  <span>
                    <FiPlus />
                  </span>
                  <strong>Create</strong>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
      {loader && <PageLoader />}
      <div className="d-flex pb-4 gap-2 align-items-center justify-content-end">
        {count < 3 && (
          // <Button onClick={() => setDialog(true)} label="Create Folder" />
          <button
            type="button"
            className="c-btn-1 me-1"
            onClick={() => setDialog(true)}
          >
            <span>
              <FiPlus />
            </span>
            <strong>Create Folder</strong>
          </button>
        )}
        {count > 1 && (
          // <Button onClick={() => setDialog2(true)} label="Add File" />
          <button
            type="button"
            className="c-btn-1 me-1"
            onClick={() => setDialog2(true)}
          >
            <span>
              <FiPlus />
            </span>
            <strong>Add File</strong>
          </button>
        )}
        {count > 1 && (
          // <Button
          //   type="button"
          //   onClick={() => {
          //     setParentId(null);
          //     setCount(1);
          //     getData();
          //   }}
          //   icon={"pi pi-arrow-left"}
          // />
          <button
            type="button"
            className="c-btn-1 me-1"
            onClick={() => {
              setParentId(null);
              setCount(1);
              getData();
            }}
          >
            <span>
              <FiChevronLeft />
            </span>
            <strong>Back</strong>
          </button>
        )}
      </div>
      <div className="row">
        {state?.map((elm) => {
          return (
            <div className="col-md-2 col-12 cr-fileList" key={elm?.id}>
              {!elm?.isDefault && (
                <Button
                  icon={"pi pi-trash"}
                  onClick={() => {
                    if (elm?.type === "FILE") {
                      deleteData(elm?.id, elm?.generatedFile);
                    } else {
                      handleDelete(elm?.id);
                    }
                  }}
                />
              )}
              {elm?.type === "FILE" ? (
                <div className="cr-folderBx d-flex justify-content-center align-items-top">
                  <a
                    href={
                      Config.S3_CONFIG.S3_ACCESS_URL +
                      "documents/" +
                      elm?.generatedFile
                    }
                    target="_blank"
                    alt={elm?.generatedFile}
                    rel="noreferrer"
                  >
                    <div>
                      {/* <BsFileImage /> */}
                      <img
                        src={
                          Config.S3_CONFIG.S3_ACCESS_URL +
                          "documents/" +
                          elm?.generatedFile
                        }
                        alt={elm?.folderName?.replaceAll("_", " ")}
                      />
                      <h5>{elm?.folderName?.replaceAll("_", " ")} </h5>
                    </div>
                  </a>
                </div>
              ) : (
                <div
                  onClick={() => {
                    if (count <= 3) {
                      if (count > 2) {
                        setCount(3);
                      } else {
                        setCount((count) => count + 1);
                      }
                      getSubData(elm?.id);
                      setParentId(elm?.id);
                    }
                  }}
                  className="cr-folderBx d-flex justify-content-center align-items-top"
                >
                  <div>
                    <img src={FolderImg} alt={elm?.folderName} />
                    <h5>{elm?.folderName?.replaceAll("_", " ")}</h5>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AllDocuments;
