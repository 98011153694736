import React, { useEffect, useState } from "react";
import BankingServices from "../../services/BankingServices";
import { Form } from "react-bootstrap";
import DropdownComponent from "./primereactComponent/DropdownComponent";

const CurrencyDropDown = ({ field, form: { touched, errors }, ...props }) => {
  const [currency, setCurrency] = useState([]);
  const bankingService = new BankingServices();

  useEffect(() => {
    currencyList();
  }, []);

  const currencyList = () => {
    bankingService.getCurrencyList().then((res) => {
      setCurrency(res);
    });
  };

  return (
    <div>
      <DropdownComponent
        options={currency}
        placeholder={props.placeholder}
        name={field.name}
        className={"p-0 "}
        filter
        optionLabel={"name"}
        optionValue={"id"}
      />
      {/* <Form.Select className="form-control" {...field} {...props}>
        <option value="null">Select Currency</option>
        {currency.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))} */}
      {/* </Form.Select> */}
    </div>
  );
};

export default CurrencyDropDown;
