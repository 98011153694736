import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import MutualFundsService from "../../../services/MutualFundsService";
import moment from "moment";
import { BiChevronsRight, BiEdit, BiRevision } from "react-icons/bi";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FormLabel } from "react-bootstrap";
import MutualFundDropDown from "../../components/MutualFundDropDown";
import { BsTrash } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
const MutualRedemptionTableModal = ({ close, valueUpdateData }) => {
  const mutualFundService = new MutualFundsService(); //-----------For Service Call-------//
  const [initialValues, setInitialValues] = useState(null); //---------For edit--------//
  const [showForm, setShowForm] = useState(false); //---------For Edit Form----------//
  const [redemptionUpdate, setRedemptionUpdate] = useState([]);
  const [loading, setLoading] = useState(false);

  const mutualRedemptionSchema = Yup.object().shape({
    mutualFundId: Yup.string().required("MutualFund can't be empty"),
    // sellPrice: Yup.string().required("Price can't be empty"),
    // redeemedUnit: Yup.string().required("Unit Redeemed can't be empty"),
    // redeemedValue: Yup.string().required("Value Redeemed can'tbe empty"),
    // sellDate: Yup.string().required("Date can't be empty"),
  });

  //////////For Edit////////
  const getFundRedemptionDetails = async (id) => {
    const res = await mutualFundService.getFandRedemptionDetails(id);
    res.sellDate = res.valueDate
      ? moment(res.valueDate).format("YYYY-MM-DD")
      : null;

    setInitialValues(res);
    setShowForm(true);
  };

  //For useing Update Endpoint/////

  // const fundUpdate = (values) => {
  //   console.log("values==>", values);
  //   mutualFundService.updateFundRedemption(values).then((res) => {
  //     setRedemptionUpdate(res);
  //     close();
  //   });
  // };

  const fundUpdate = (values) => {
    setLoading(true);
    mutualFundService
      .updateFundRedemption(values)
      .then((res) => {
        setRedemptionUpdate(res);
        toast.success("Redemption updated successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        close();
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  ///////////Delete /////////
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            mutualFundService.deleteFundRede(id).then((res) => {
              toast.success("Redemption Deleted ", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            });
            close();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div>
      {/* <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      /> */}

      <Dialog
        draggable={false}
        visible={true}
        onHide={() => close()}
        header={
          showForm ? (
            <h1>Edit MutualFund Redemption!!</h1>
          ) : (
            <h1>MutualFund Redemption!!</h1>
          )
        }
        style={{ width: "80%" }}
      >
        <div className="c-content-in">
          {showForm ? (
            <Formik
              initialValues={{
                ...initialValues,
              }}
              validationSchema={mutualRedemptionSchema}
              onSubmit={(values, { resetForm }) => {
                let reqData = { ...values };
                reqData.sellDate = values.sellDate
                  ? moment(values.sellDate)
                  : null;
                fundUpdate(reqData);
                resetForm();
              }}
              enableReinitialize
            >
              {({ handleChange, values, errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>MutualFund </FormLabel>

                        <Field
                          disabled={true}
                          name="mutualFundId"
                          className="form-control"
                          component={MutualFundDropDown}
                          placeholder="Mutual Fund"
                        />
                        {errors.mutualFundId && touched.mutualFundId ? (
                          <div className="text-danger">
                            {errors.mutualFundId}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Price</FormLabel>
                        <Field
                          name="sellPrice"
                          className="form-control"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder="price"
                        />
                        {errors.sellPrice && touched.sellPrice ? (
                          <div className="text-danger">{errors.sellPrice}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Unit Redeemed</FormLabel>

                        <Field
                          name="redeemedUnit"
                          className="form-control"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Unit Redeemed"
                        />
                        {errors.redeemedUnit && touched.redeemedUnit ? (
                          <div className="text-danger">
                            {errors.redeemedUnit}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Value Redeemed</FormLabel>
                        <Field
                          name="redeemedValue"
                          className="form-control"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Value Redeemed"
                        />
                        {errors.redeemedValue && touched.redeemedValue ? (
                          <div className="text-danger">
                            {errors.redeemedValue}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Date</FormLabel>
                        {/* <Field
                          name="sellDate"
                          className="form-control"
                          type="date"
                          placeholder="Date"
                        /> */}
                        <Calendar
                          name="sellDate"
                          className="w-100 mb-2 form-group c-calender-w"
                          value={values.sellDate}
                          onChange={handleChange}
                          showIcon
                          dateFormat="dd/mm/yy"
                          placeholder="dd/mm/yyyy"
                          readOnlyInput
                        />
                        {errors.sellDate && touched.sellDate ? (
                          <div className="text-danger">{errors.sellDate}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="d-flex c-btn-group">
                        <button
                          type="submit"
                          className="c-btn-1"
                          disabled={loading}
                        >
                          <strong>Update</strong>
                          {loading ? (
                            <span className="rotate-infinite">
                              <FiRotateCw />
                            </span>
                          ) : (
                            <span>
                              <FiChevronRight />
                            </span>
                          )}
                        </button>

                        <button
                          onClick={() => close()}
                          type="reset"
                          className="c-btn-1 c-del-btn"
                        >
                          <strong>Cancel</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          ) : null}

          <div className="table-responsive p-3">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>MutualFund</th>
                  <th>Price</th>
                  <th>Value Redeemed</th>
                  <th>Units Redeemed</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {valueUpdateData.length > 0
                  ? valueUpdateData.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item.mutualFundId}</td>
                          <td>{item.sellPrice}</td>
                          <td>{item.redeemedUnit}</td>
                          <td>{item.redeemedValue}</td>
                          <td>
                            {moment(item.sellDate).format("Do MMM, YYYY")}
                          </td>

                          <td>
                            <button
                              onClick={() => getFundRedemptionDetails(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <BiEdit />{" "}
                            </button>
                            <button
                              type="button"
                              className="c-edit-btn"
                              onClick={() => deleteItem(item.id)}
                            >
                              <BsTrash />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default MutualRedemptionTableModal;
