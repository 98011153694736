import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class AccessControlService {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  ////////////Create AccesControl ///////////

  sevedAccesControl = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/user-menu/save`, payload, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////Accountant For Single ID///////////

  getSelectWithId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/user-menu/get/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////  GET Menu list //////////
  getAllMenu = async () => {
    try {
      const response = await axios.get(`${baseUrl}/menu/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
