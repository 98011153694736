import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class BankingServices {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  ///// Create Bank////

  addBank = async (payload) => {
    try {
      const response = await axios.post(baseUrl + "/bank/create", payload, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////// For Create Account/////

  addAccount = async (payload) => {
    try {
      const response = await axios.post(
        // apiPath + "/super/admin/svc/product/create",
        payload,
        { headers: this.headers }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////For Bank Listing////////

  getBankList = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/bank/search`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////For Country List///////

  getCountryList = async () => {
    try {
      const response = await axios.get(`${baseUrl}/country/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////For Currency List///////

  getCurrencyList = async () => {
    try {
      const response = await axios.get(`${baseUrl}/currency/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////For Update Bank ////////

  updateBank = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/bank/update`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////For Single ID///////////

  getUpdateId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/bank/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getAccountDetails = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/customer-account/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////For Search Bank //////
  searchBank = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bank/search`,
        payload,

        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Create Bank Account For Coustomar//////
  createBankAccount = async (payload) => {
    try {
      const response = await axios.post(
        baseUrl + "/customer-account/create",
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  updateBankAccount = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/customer-account/update`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Listing Bank Account Coustomar/////////

  listingBankAccount = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/customer-account/search`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getAllaccount = async () => {
    try {
      const response = await axios.get(`${baseUrl}/customer-account/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  deleteBank = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bank/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  deleteAccount = async (id, type) => {
    try {
      const response = await axios.post(
        `${baseUrl}/customer-account/delete/${id}/${type}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getLedgerView = async (id, value) => {
    try {
      const response = await axios.get(
        `${baseUrl}/bank-statement-voucher/stock-mutual-view/${id}/${value}`,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getBalanceSheetLedgerView = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/bank-statement-voucher/balanceSheet-ledger-view/${id}`,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
