import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import LedgerService from "../../services/LedgerService";
import PageLoader from "../components/PageLoader";
import { convertToRoman } from "../../shared/UtilsFunction";
import SearchByYear from "../components/SearchByYear";
import moment from "moment";
import LedgerModel from "../ProfitLoss/LedgerModel";
import jsPDF from "jspdf";
import { Fragment } from "react";
import { SplitButton } from "primereact/splitbutton";
import { utils, writeFile } from "xlsx";
const BalanceSheet = () => {
  const ledgerService = new LedgerService();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ledgerDetails, setLedgerDetails] = useState(null);
  const [isShowLedgerModel, setIsShowLedgerModal] = useState(false);
  const [searchReqData, setSearchReqData] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [totalAssets, setTotalAssets] = useState(0);
  const [totalLiability, setTotalLiability] = useState(0);
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));

  const tableRef = useRef(null);
  const assetRef = useRef(null);
  const liabilityRef = useRef(null);
  useEffect(() => {
    if (menuList !== null) {
      let reqData = {
        date: "CURRENT_FINANCIAL_YEAR",
      };
      getData(reqData);
    }
  }, []);
  const generateXlsx = () => {
    const label =
      searchReqData.date === "CUSTOM_DATE_RANGE"
        ? searchReqData.startYear + "_" + searchReqData.endYear
        : searchReqData.date;
    var wb = utils.book_new();
    const book1 = liabilityRef.current;
    const book2 = assetRef.current;
    const ws1 = utils.table_to_sheet(book1);
    const ws2 = utils.table_to_sheet(book2);
    utils.book_append_sheet(wb, ws1, "Liability");
    utils.book_append_sheet(wb, ws2, "Assets");
    writeFile(wb, `Balance_sheet_${label}.xlsx`);
  };
  const getData = (value) => {
    let reqData = value
      ? {
          date: Boolean(value.date) ? value.date : null,
          startYear: value?.startYear
            ? moment(value?.startYear).format("YYYY-MM-DD")
            : null,
          endYear: value?.endYear
            ? moment(value?.endYear).format("YYYY-MM-DD")
            : null,
        }
      : null;
    ledgerService
      .getBalanceSheet(reqData)
      .then((response) => {
        let balanceSheetResponse = response;

        let assetsPrice = 0;
        let liabilitiesPrice = 0;

        response?.liability?.groupList?.forEach((element) => {
          element?.subGroupDtoList?.forEach((ele) => {
            liabilitiesPrice += ele.amount;
          });
        });

        response?.asset?.groupList?.forEach((element) => {
          element?.subGroupDtoList?.forEach((ele) => {
            assetsPrice += ele.amount;
          });
        });

        setTotalLiability(liabilitiesPrice);
        setTotalAssets(assetsPrice);
        setData(balanceSheetResponse);
        setSearchReqData(reqData);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };
  const genaratePdf = () => {
    const label =
      searchReqData.date === "CUSTOM_DATE_RANGE"
        ? searchReqData.startYear + "_" + searchReqData.endYear
        : searchReqData.date;
    const pdf = new jsPDF("landscape", "px", [1320, 1200]);
    const data = tableRef.current;
    pdf.html(data, { margin: 40 }).then(() => {
      pdf.save(`Balance_sheet_${label}.pdf`);
    });
  };
  const onShowLegerDetails = (item) => {
    let reqData = {
      subGroupId: item.subgroupId,
      timeRange: searchReqData,
    };
    setLoading(true);
    ledgerService
      .getLedgerDetails(reqData)
      .then((res) => {
        setSelectedName(item.subGroupName);
        setLedgerDetails(res);
        setLoading(false);
        setIsShowLedgerModal(true);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  const onCloseLedgerModel = () => {
    setIsShowLedgerModal(false);
  };

  const onSearch = (value) => {
    setLoading(true);
    getData(value);
  };
  const items = [
    {
      label: "CSV",
      icon: "pi pi-download",
      command: () => {
        generateXlsx();
      },
    },
    {
      label: "PDF",
      icon: "pi pi-download",
      command: () => {
        genaratePdf();
      },
    },
  ];
  return (
    <>
      {loading && <PageLoader />}

      <div className="c-balansheet-table">
        <div className="row align-team-center">
          <SearchByYear
            heading={"FOR BALANCE SHEET ITEMS"}
            onSearch={onSearch}
          />
          <div className="d-flex justify-content-end pb-2">
            <SplitButton className="cr-split" label="Export" model={items} />
          </div>
        </div>
        <div ref={tableRef} className="row g-2 cr-tableBx">
          <div className="col-6">
            <table ref={liabilityRef} style={{ width: "100%" }}>
              <tbody>
                <tr style={{ backgroundColor: "#253d98", color: "white" }}>
                  <th width="15%">SL. NO.</th>
                  <th width="60%">LIABILITIES</th>
                  <th width="25%" style={{ textAlign: "right" }}>
                    AMOUNT
                  </th>
                </tr>
                {data?.liability?.groupList.map((item, index) => (
                  <Fragment key={item}>
                    <tr key={index}>
                      <td
                        width="15%"
                        style={{ textAlign: "center !important" }}
                      >
                        {(index + 1 + 9).toString(36).toUpperCase()}.
                      </td>
                      <td width="60%">
                        <strong>{item.ledgerGroupName}</strong>
                      </td>
                      <td
                        width="25%"
                        style={{ textAlign: "right !important" }}
                      ></td>
                    </tr>
                    {item?.subGroupDtoList.map((elm, ind) => (
                      <tr key={ind} width="15%">
                        <td style={{ textAlign: "right !important" }}>
                          {convertToRoman(ind + 1)}.
                        </td>
                        <td
                          width="60%"
                          onClick={() => onShowLegerDetails(elm)}
                          className="cursor-pointer"
                        >
                          {elm.subGroupName}
                        </td>
                        <td
                          width="25%"
                          style={{ textAlign: "center !important" }}
                        >
                          {elm?.amount}
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
                <tr>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    <strong>Total Liability</strong>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <strong> {totalLiability}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-6">
            <table ref={assetRef} style={{ width: "100%" }}>
              <tbody>
                <tr style={{ backgroundColor: "#253d98", color: "white" }}>
                  <th width="15%">SL. NO.</th>
                  <th width="60%">ASSETS</th>
                  <th width="25%" style={{ textAlign: "right" }}>
                    AMOUNT
                  </th>
                </tr>
                {data?.asset?.groupList.map((item, index) => (
                  <Fragment key={item}>
                    <tr key={index}>
                      <td width="15%" style={{ textAlign: "center" }}>
                        {(index + 1 + 9).toString(36).toUpperCase()}.
                      </td>
                      <td width="60%">
                        <strong>{item.ledgerGroupName}</strong>
                      </td>
                      <td width="25%" style={{ textAlign: "right" }}></td>
                    </tr>
                    {item?.subGroupDtoList.map((elm, ind) => (
                      <tr key={ind} width="15%">
                        <td style={{ textAlign: "right" }}>
                          {convertToRoman(ind + 1)}.
                        </td>
                        <td
                          width="60%"
                          onClick={() => onShowLegerDetails(elm)}
                          className="cursor-pointer"
                        >
                          {elm.subGroupName}
                        </td>
                        <td width="25%" style={{ textAlign: "right" }}>
                          {elm?.amount}
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
                <tr>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    <strong>Total Assets</strong>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <strong> {totalAssets}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ margin: 20, textAlign: "right" }}></p>
          </div>
        </div>
        {/* <div ref={tableRef} className="table-responsive">
          <table className=" c-balansheet-table-main" style={{ border: 0 }}>
            <tr>
              <td>
                <table className=" table-bordered cr-nonBorderTbl">
                  <tr>
                    <th width="15%">SL. NO.</th>
                    <th width="60%">LIABILITIES</th>
                    <th width="25%">AMOUNT</th>
                  </tr>
                  {data?.liability?.groupList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td colSpan={3} className="no-padding">
                          <table className=" table-bordered cr-nonBorderTbl">
                            <tr className="c-balansheet-table-parent">
                              <td width="15%">
                                {(index + 1 + 9).toString(36).toUpperCase()}.
                              </td>
                              <td width="60%">
                                <strong>{item.ledgerGroupName}</strong>
                              </td>
                              <td width="25%"></td>
                            </tr>
                            {item?.subGroupDtoList.map((elm, ind) => {
                              return (
                                <tr key={ind} width="15%">
                                  <td>{convertToRoman(ind + 1)}.</td>
                                  <td
                                    width="60%"
                                    onClick={() => onShowLegerDetails(elm)}
                                    className="cursor-pointer"
                                  >
                                    {elm.subGroupName}
                                  </td>
                                  <td width="25%">{elm?.amount}</td>
                                </tr>
                              );
                            })}
                          </table>
                        </td>
                      </tr>
                    );
                  })}
                </table>
                <h3 style={{ margin: 20, textAlign: "right" }}>
                  Total Liability {totalLiability}
                </h3>
              </td>
              <td>
                <table className=" table-bordered cr-nonBorderTbl">
                  <tr>
                    <th width="15%">SL. NO.</th>
                    <th width="60%">ASSETS</th>
                    <th width="25%">AMOUNT</th>
                  </tr>
                  {data?.asset?.groupList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td colSpan={3} className="no-padding">
                          <table className=" table-bordered cr-nonBorderTbl">
                            <tr className="c-balansheet-table-parent">
                              <td width={"15%"}>
                                {(index + 1 + 9).toString(36).toUpperCase()}.
                              </td>
                              <td width={"60%"}>
                                <strong>{item.ledgerGroupName}</strong>
                              </td>
                              <td width={"25%"}></td>
                            </tr>

                            {item?.subGroupDtoList.map((elm, ind) => (
                              <tr key={ind}>
                                <td width={"15%"}>
                                  {convertToRoman(ind + 1)}.
                                </td>
                                <td
                                  width={"60%"}
                                  onClick={() => onShowLegerDetails(elm)}
                                  className="cursor-pointer"
                                >
                                  {elm.subGroupName}
                                </td>
                                <td width={"25%"}>{elm?.amount.toFixed(2)}</td>
                              </tr>
                            ))}
                          </table>
                        </td>
                      </tr>
                    );
                  })}
                </table>
                <h3 style={{ margin: 20, textAlign: "right" }}>
                  Total Assets {totalAssets}
                </h3>
              </td>
            </tr>
          </table>
        </div> */}
      </div>

      {isShowLedgerModel && (
        <LedgerModel
          onClose={onCloseLedgerModel}
          ledgerDetails={ledgerDetails}
          heading={selectedName}
        />
      )}
    </>
  );
};

export default BalanceSheet;
