import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class DocumentService {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }
  uploadDocument = async (payload) => {
    try {
      const response = await axios.post(baseUrl + "/document/save", payload, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  createFolder = async (payload) => {
    try {
      const response = await axios.post(
        baseUrl + "/document/save-folder",
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  deleteFolderById = async (payload) => {
    try {
      const response = await axios.get(
        baseUrl + "/document/delete-folder/" + payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  getAllFolder = async (payload) => {
    try {
      const response = await axios.get(baseUrl + "/document/all-folder", {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  getFolderByFolderId = async (payload) => {
    try {
      const response = await axios.get(
        baseUrl + "/document/folder/" + payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  getDocumentByOriginId = async (id) => {
    try {
      const response = await axios.get(
        baseUrl + "/document/origin-item/" + id,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  deleteDocumentById = async (id) => {
    try {
      const response = await axios.get(baseUrl + "/document/delete/" + id, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
