import React from "react";
import { NavLink } from "react-router-dom";

import { Panel } from "primereact/panel";

import Config from "../../shared/Config";

const SideNav = () => {
  const menuList = JSON.parse(sessionStorage.getItem("userNewMenuList")) || [];
  return (
    <aside className="c-side-bar-w">
      <div className="c-layer-bg"></div>
      <button className="c-sidebar-close"></button>

      <ul>
        {menuList?.length > 0 &&
          menuList?.map((item, index) => (
            <li key={`menu-${index}`} className="c-nav-accordian">
              {item?.child?.length > 0 ? (
                <Panel
                  unstyled
                  collapsed
                  expandIcon={"pi pi-angle-right"}
                  collapseIcon={"pi pi-angle-down"}
                  transitionOptions={{ timeout: 1 }}
                  header={
                    <div className="c-nav-accordian-btn">
                      <span>
                        <img
                          src={`${Config.S3_CONFIG.S3_ACCESS_URL}menuIcon/${item.lightIcon}`}
                          alt=""
                          width="30"
                        />
                      </span>
                      {item?.name}
                    </div>
                  }
                  toggleable
                >
                  <div className="c-nav-dropdown-list">
                    {item?.child?.map((elm, i) => (
                      <NavLink key={i} to={elm.url}>
                        <h4>{elm.name}</h4>
                      </NavLink>
                    ))}
                  </div>
                </Panel>
              ) : (
                // <ul>

                //     <li key={i}>
                // <NavLink to={elm.url}>
                //   <h4>{elm.name}</h4>
                // </NavLink>
                //     </li>

                // </ul>
                <NavLink to={item.url}>
                  <span>
                    <img
                      src={`${Config.S3_CONFIG.S3_ACCESS_URL}menuIcon/${item.lightIcon}`}
                      alt=""
                      width="30"
                    />
                  </span>
                  <h4>{item.name}</h4>
                </NavLink>
              )}
            </li>
          ))}
      </ul>
    </aside>
  );
};

export default SideNav;
