import React from "react";
import * as Yup from "yup";
import { FormLabel, Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { FiChevronsRight, FiRotateCw } from "react-icons/fi";
import { BiRevision } from "react-icons/bi";
import moment from "moment";
import DropdownComponent from "../components/primereactComponent/DropdownComponent";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";

const EditLedgers = ({
  loading,
  close,
  LedgerAccountEditFilterData,
  ledgerUpdate,
  allChildGroup,
}) => {
  console.log(LedgerAccountEditFilterData);
  const ledgerSchema = Yup.object().shape({
    description: Yup.string().required("Description can't be empty"),
    // alias: Yup.string().required("Alias can't be empty"),
    openingBalance: Yup.number().required("Opening Balance can't be empty"),
    accountGroupId: Yup.number().required("Account Group can't be empty"),
    // openingDate: Yup.string().required("Opening Date can't be empty"),
  });

  const onChangeId = (item, setFieldValue) => {
    setFieldValue("accountGroupId", item.value);
  };

  return (
    <div>
      <Dialog
        visible={true}
        style={{ width: "50%" }}
        onHide={() => {
          close();
        }}
        draggable={false}
        header={<h1>Edit Account Ledger!!</h1>}
      >
        <section className="c-content-in">
          <Formik
            initialValues={{
              id: LedgerAccountEditFilterData.id,
              description: LedgerAccountEditFilterData.description,
              alias: LedgerAccountEditFilterData.alias,
              openingBalance: LedgerAccountEditFilterData.openingBalance,
              accountGroupId: LedgerAccountEditFilterData.accountGroupId,
              openingDate: LedgerAccountEditFilterData.openingDate
                ? new Date(LedgerAccountEditFilterData.openingDate)
                : null,
            }}
            validationSchema={ledgerSchema}
            onSubmit={(values) => {
              ledgerUpdate(values);
            }}
          >
            {({ handleChange, values, errors, touched, setFieldValue }) => (
              <Form>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Description</FormLabel>

                      <Field
                        name="description"
                        className="form-control"
                        type="textarea"
                        placeholder="Description"
                      />
                      {errors.description && touched.description ? (
                        <div className="text-danger">{errors.description}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Alias</FormLabel>

                      <Field
                        name="alias"
                        className="form-control"
                        type="text"
                        placeholder="Alias"
                      />
                      {errors.alias && touched.alias ? (
                        <div className="text-danger">{errors.alias}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <DropdownComponent
                      filter
                      options={allChildGroup}
                      placeholder="Account Group"
                      optionLabel="name"
                      header={"Account Group"}
                      optionValue="id"
                      name="accountGroupId"
                      className="cr-autoSearch"
                      onChange={(e) => onChangeId(e, setFieldValue)}
                    />
                    {errors.accountGroupId && touched.accountGroupId ? (
                      <div className="text-danger">{errors.accountGroupId}</div>
                    ) : null}
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Opening Balance</FormLabel>

                      <Field
                        name="openingBalance"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Opening Balance"
                      />
                      {errors.openingBalance && touched.openingBalance ? (
                        <div className="text-danger">
                          {errors.openingBalance}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Opening Date </FormLabel>

                      <Calendar
                        name="openingDate"
                        className="w-100 mb-2 form-group c-calender-w"
                        value={values.openingDate}
                        onChange={handleChange}
                        showIcon
                        dateFormat="dd/mm/yy"
                        placeholder="dd/mm/yyyy"
                        readOnlyInput
                      />

                      {errors.openingDate && touched.openingDate ? (
                        <div className="text-danger">{errors.openingDate}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="d-flex c-btn-group">
                      <button
                        type="submit"
                        className="c-btn-1"
                        disabled={loading}
                      >
                        <strong>Update</strong>
                        {loading ? (
                          <span className="rotate-infinite">
                            <FiRotateCw />
                          </span>
                        ) : (
                          <span>
                            <FiChevronsRight />
                          </span>
                        )}
                      </button>

                      <button
                        type="reset"
                        onClick={() => close()}
                        className="c-btn-1 c-del-btn"
                      >
                        <strong>Cancel</strong>
                        <span>
                          <BiRevision />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </Dialog>
    </div>
  );
};

export default EditLedgers;
