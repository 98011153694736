import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class PropertyService {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  ////////Create Property//////////

  createProperty = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/property/create`, payload, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  ///////////Listing Property////////

  getPropertyList = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/property/search`,
        payload,

        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////////Search Property////////

  searchProperty = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/property/search`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////All Properties////////

  getAllProperty = async () => {
    try {
      const response = await axios.get(`${baseUrl}/property/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////One Data Fatch With ID For Property////////

  getUpdateWithId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/property/${id}`, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Update  For Property////////

  updateProperty = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/property/update`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////Create Property Expenses////////

  createExpenses = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/property-expenses/create`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////////Listing For Property Expenses////////

  getExpensesList = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/property-expenses/search`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////////Search Property Expenses////////

  searchExpenses = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/property-expenses/search`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////All Properties Expenses////////

  getAllExpenses = async () => {
    try {
      const response = await axios.get(`${baseUrl}/property-expenses/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////One Data Fatch With ID For Property Expenses////////

  getUpdateWithIdExpenses = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/property-expenses/${id}`, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Update  For Property Expenses////////

  updateExpenses = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/property-expenses/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Preperty Expenses For Row ID API//////////
  getExpenses = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/property-expenses/all-property-expenses/${id}`,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Delete Propert////////
  deleteProperty = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/property/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Delete Expenses////////
  deleteExpenses = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/property-expenses/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getProductTransaction = async (id, value) => {
    try {
      const response = await axios.get(
        `${baseUrl}/bank-statement-voucher/item-view/${id}/${value}`,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
