import { PrimeReactProvider } from "primereact/api";
import "primeicons/primeicons.css";
import "./App.css";
import "../src/assets/css/custom.css";
import "../src/assets/css/responsive.css";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Layout from "./pages/components/Layout";
import Dashboard from "./pages/Dashboard";
import ProductCode from "./pages/Products/ProductCodeManagement/ProductCode";
import ForgotPassword from "./pages/ForgotPassword";
import VerifyOtp from "./pages/VerifyOtp";
import ResetPassword from "./pages/ResetPassword";
import ProductMapping from "./pages/Products/ProductMapping/ProductMapping";
import BankListing from "./pages/Bank/bankmanagement/BankListing";
import RelationshipList from "./pages/Bank/BankRelationManagement/RelationshipList";
import ListBankAccount from "./pages/Bank/bankAccountManagement/ListBankAccount";
import CreditCardListing from "./pages/CreditCardManagement/CreditCardListing";
import ListInsurance from "./pages/insuranceManagement/ListInsurance";
import AddInsurance from "./pages/insuranceManagement/AddInsurance";
import UlipValueUpdate from "./pages/insuranceManagement/UlipValueUpdate";
import UpliWithdrawal from "./pages/insuranceManagement/UpliWithdrawal";
import BondsList from "./pages/Bonds/BondsList";
import AddMutualFunds from "./pages/mutualFundsManagement/AddMutualFunds";
import ListMutualFunds from "./pages/mutualFundsManagement/ListMutualFunds";
import MutualValueUpdate from "./pages/mutualFundsManagement/MutualValueUpdate";
import MutualRedemption from "./pages/mutualFundsManagement/MutualRedemption";
import ListProperty from "./pages/propertyManagement/ListProperty";
import AddProperty from "./pages/propertyManagement/AddProperty";
import ListStock from "./pages/stockManagement/ListStock";
import AddStock from "./pages/stockManagement/AddStock";
import StockValueUpdate from "./pages/stockManagement/StockValueUpdate";
import StockSale from "./pages/stockManagement/StockSale";
import ChangePassword from "./pages/ChangePassword";
import AccessControl from "./pages/Bank/accessControl/AccessControl";
import AccountGroupAdd from "./pages/accountGroup/AccountGroupAdd";
import AccountGroupList from "./pages/accountGroup/AccountGroupList";
import AddLedgers from "./pages/accountLedgers/AddLedgers";
import ListLedgers from "./pages/accountLedgers/ListLedgers";
import ListMenu from "./pages/menu/ListMenu";
import ListAccountant from "./pages/accountant/ListAccountant";
import AddAccountant from "./pages/accountant/AddAccountant";
import AddUploadStatment from "./pages/uploadStatment/AddUploadStatment";
import ListUploadStatment from "./pages/uploadStatment/ListUploadStatment";
import BalanceSheet from "./pages/balanceSheet/BalanceSheet";
import ProfitLoss from "./pages/ProfitLoss/ProfitLoss";

import LedgerView from "./pages/components/LedgerView";
import { ToastContainer } from "react-toastify";
import AllDocuments from "./pages/AllDocuments/AllDocuments";
import AllFileRoute from "./pages/AllDocuments";
import AllFile from "./pages/AllDocuments/AllFile";
import ListJournal from "./pages/journal/ListJournal";
import Addjournal from "./pages/journal/AddJournal";
import NetWorth from "./pages/NetWorth";
import { queryClient } from "./pages/utils/utils";

function App() {
  return (
    <PrimeReactProvider>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/verify/otp" element={<VerifyOtp />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/changepassword" element={<ChangePassword />} />

          <Route element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route
              path="/access-control/:accountant_Id"
              element={<AccessControl />}
            />
            <Route path="/account-group-add" element={<AccountGroupAdd />} />
            <Route path="/account-group" element={<AccountGroupList />} />
            <Route path="/accountant" element={<ListAccountant />} />
            <Route path="/add-accountant" element={<AddAccountant />} />
            <Route path="/ledger-view" element={<LedgerView />} />

            <Route path="/menu" element={<ListMenu />} />
            <Route path="/ledger-add" element={<AddLedgers />} />
            <Route path="/ledger" element={<ListLedgers />} />
            <Route path="/product-mapping" element={<ProductMapping />} />
            <Route path="/product-code" element={<ProductCode />} />
            <Route path="/bank" element={<BankListing />} />
            <Route path="/bank-relationship" element={<RelationshipList />} />
            <Route path="/bank-account" element={<ListBankAccount />} />
            <Route path="/credit-card" element={<CreditCardListing />} />
            <Route path="/insurance" element={<ListInsurance />} />
            <Route path="/insurance-add" element={<AddInsurance />} />
            <Route path="/ulip-value-update" element={<UlipValueUpdate />} />
            <Route path="/ulip-withdrawal" element={<UpliWithdrawal />} />
            <Route path="/bonds" element={<BondsList />} />
            <Route path="/mutualfund-add" element={<AddMutualFunds />} />
            <Route path="/mutualfund" element={<ListMutualFunds />} />
            <Route
              path="/mutual-value-update"
              element={<MutualValueUpdate />}
            />
            <Route path="/mutual-redemption" element={<MutualRedemption />} />
            <Route path="/property" element={<ListProperty />} />
            <Route path="/property-add" element={<AddProperty />} />
            <Route path="/stock" element={<ListStock />} />
            <Route path="/stock-report" element={<ListStock />} />
            <Route path="/mutual-fund-report" element={<ListMutualFunds />} />
            <Route path="/stock-add" element={<AddStock />} />
            <Route path="/stock-value" element={<StockValueUpdate />} />
            <Route path="/stock-sell" element={<StockSale />} />
            <Route path="/addupload" element={<AddUploadStatment />} />
            <Route path="/upload" element={<ListUploadStatment />} />
            <Route path="/journal" element={<ListJournal />} />
            <Route path="/addjournal" element={<Addjournal />} />
            <Route path="/net-worth" element={<NetWorth />} />
            <Route path="/balance-sheet" element={<BalanceSheet />} />
            <Route path="/profit-and-loss" element={<ProfitLoss />} />
            <Route path="/all-documents" element={<AllFileRoute />}>
              <Route index element={<AllDocuments />} />
              <Route path="/all-documents/documents" element={<AllFile />} />
            </Route>
          </Route>
        </Routes>
      </QueryClientProvider>
    </PrimeReactProvider>
  );
}

export default App;
