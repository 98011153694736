import React, { useEffect, useRef, useState } from "react";

import { FiChevronRight, FiChevronsRight, FiRotateCw } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AccessControlService from "../../../services/AccesControlService";
import PageLoader from "../../components/PageLoader";

const AccessControl = () => {
  const { accountant_Id } = useParams();
  const accesControlService = new AccessControlService(); //-----------For Service Call-------//

  const [accescontrolList, setAccesControlList] = useState([]); //-----------For Listing--------//
  const toastTL = useRef(null); //-----------For Toster Msg----------------//
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleCheckChange = (index) => {
    const updatedRowData = rowData.map((checked, index) =>
      index === index || index === index + 1 || index === index + 2
        ? true
        : checked
    );

    setRowData(updatedRowData);
  };

  const handleCheckboxChange = (index) => {
    const updatedRowData = [...rowData];
    updatedRowData[index] = !updatedRowData[index];
    setRowData(updatedRowData);

    const nextCheckboxIndex = index + 1;
    if (nextCheckboxIndex < updatedRowData.length) {
      updatedRowData[nextCheckboxIndex] = true;
      setRowData(updatedRowData);
    }
  };

  useEffect(() => {
    Promise.all([
      accesControlService.getAllMenu(),
      accesControlService.getSelectWithId(accountant_Id),
    ])
      .then((response) => {
        const allMenu = response[0];
        const selectedData = response[1];

        let modyArray = allMenu.map((item) => {
          return { isRead: false, isWrite: false, isDelete: false, ...item };
        });

        if (selectedData.length > 0) {
          selectedData.map((item) => {
            let ind = modyArray.findIndex((it) => it.id === item.menuId);
            modyArray[ind].isRead = item.isRead;
            modyArray[ind].isWrite = item.isWrite;
            modyArray[ind].isDelete = item.isDelete;
          });
        }

        setAccesControlList(modyArray);
        setIsLoading(false);
      })
      .catch((e) => {
        toastTL.current.show({
          severity: "error",
          summary: e.name,
          detail: e.message,
          style: { color: "#000000" },
          sticky: true,
        });
        setIsLoading(false);
      });
  }, []);

  /////////For Listing///////////
  const accesControlList = () => {
    accesControlService
      .getAllMenu()
      .then((res) => {
        let response = res.map((item) => {
          return { isRead: false, isWrite: false, isDelete: false, ...item };
        });
        setAccesControlList(response);
      })

      .catch((e) => {
        toastTL.current.show({
          severity: "error",
          summary: e.name,
          detail: e.message,
          style: { color: "#000000" },
          sticky: true,
        });
      });
  };

  const createAccessControl = () => {
    let data = [];

    accescontrolList.filter((item) => {
      if (item.isRead || item.isWrite || item.isDelete) {
        data.push({
          menuId: item.id,
          isRead: typeof item.isRead === "undefined" ? false : item.isRead,
          isWrite: typeof item.isWrite === "undefined" ? false : item.isWrite,
          isDelete:
            typeof item.isDelete === "undefined" ? false : item.isDelete,
        });
      }
    });
    if (data.length > 0) {
      setError(false);
      const payload = {
        accountantId: accountant_Id,
        userMenuDtoList: data,
      };
      setLoading(true);
      accesControlService
        .sevedAccesControl(payload)
        .then((res) => {
          // toast.success("Access Menu added", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
          navigate("/accountant");
          setLoading(false);
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        });
    } else {
      setError(true);
      toast.error("Plese Select Checkbox", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const onChangeRead = (index) => {
    const accesControlList = [...accescontrolList];
    // accesControlList[index].isRead = !accesControlList[index].isRead;
    if (accesControlList[index].isRead) {
      accesControlList[index].isRead = false;
      accesControlList[index].isWrite = false;
      accesControlList[index].isDelete = false;
    } else {
      accesControlList[index].isRead = true;
    }

    setAccesControlList(accesControlList);
  };

  const onChangeWrite = (index) => {
    const accesControlList = [...accescontrolList];
    if (accesControlList[index].isWrite) {
      accesControlList[index].isDelete = false;
      accesControlList[index].isWrite = false;
    } else {
      accesControlList[index].isRead = true;
      accesControlList[index].isWrite = true;
    }

    setAccesControlList(accesControlList);
  };

  const onChangeDelete = (index) => {
    const accesControlList = [...accescontrolList];
    if (accesControlList[index].isDelete) {
      // accesControlList[index].isRead = false;
      // accesControlList[index].isWrite = false;
      accesControlList[index].isDelete = false;
    } else {
      accesControlList[index].isRead = true;
      accesControlList[index].isWrite = true;
      accesControlList[index].isDelete = true;
    }

    setAccesControlList(accesControlList);
  };

  return (
    <>
      <div className="table-responsive">
        <ToastContainer />
        {isLoading && <PageLoader />}
        <div className="d-flex align-items-center justify-content-between w-100">
          <h1>Access Control</h1>
          <Link to="/accountant">
            <button type="button" className="c-btn-1">
              <span>
                <FiChevronRight />
              </span>
              <strong>Back</strong>
            </button>
          </Link>
        </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Menu Name</th>
              <th>Read </th>
              <th>Write</th>
              <th>Delete </th>
            </tr>
          </thead>
          <tbody>
            {accescontrolList.length > 0
              ? accescontrolList.map((item, index) => {
                  return (
                    <tr key={`accountantListData-${index}`}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        <label className="c-check-box">
                          <input
                            type="checkbox"
                            required="required"
                            checked={item.isRead}
                            onChange={() => onChangeRead(index)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </td>
                      <td>
                        <label className="c-check-box">
                          <input
                            type="checkbox"
                            required="required"
                            checked={item.isWrite}
                            onChange={() => onChangeWrite(index)}
                          />

                          <span className="checkmark"></span>
                        </label>
                      </td>
                      <td>
                        <label className="c-check-box">
                          <input
                            type="checkbox"
                            required="required"
                            checked={item.isDelete}
                            onChange={() => onChangeDelete(index)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        <div className="d-flex justify-content-end mt-3">
          <button
            type="submit"
            onClick={createAccessControl}
            className="c-btn-1"
            disabled={loading}
          >
            <strong>Submit</strong>
            {loading ? (
              <span className="rotate-infinite">
                <FiRotateCw />
              </span>
            ) : (
              <span>
                <FiChevronsRight />
              </span>
            )}
          </button>
        </div>
      </div>
    </>
  );
};
export default AccessControl;
