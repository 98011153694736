const Config = {
  CURRENT_ACCOUNT_ID: "CHECKING_CURRENT_ACCOUNT",
  SAVINGS_ACCOUNT_ID: "SAVINGS_ACCOUNT",
  DEPOSIT_ACCOUNT_ID: "DEPOSIT_ACCOUNT",
  LOAN_ID: "LOAN",
  CREDIT_CARD_ID: "CREDIT_CARD",
  TERM_LIFE_INSURANCE_ID: "TERM_LIFE_INSURANCE",
  MEDICAL_INSURANCE_ID: "MEDICAL_INSURANCE",
  BOND_ID: "BONDS",
  LIFE_INSURANCE_ID: "LIFE_INSURANCE",
  STOCK_ID: "STOCKS",
  MUTUAL_FUND_ID: "MUTUAL_FUNDS",
  PROPERTY_ID: "PROPERTY",
  ULIP_ID: "ULIP",
  TRADITIONAL_ID: "TRADITIONAL",

  PRODUCT_TYPE: {
    assets: "ASSET",
    liability: "LIABILITY",
    expense: "EXPENSE",
  },
  REQUEST_CONFIG: {
    ini: "INI",
    start: "FETCH_START",
    success: "FETCH_SUCCESS",
    error: "FETCH_ERROR",
    stop: "FETCH_STOP",
    fetched: "FETCH_DONE",
  },
  SEARCH_CONFIG: {
    sortByColumn: "SORT_BY_COLUMN",
    sortType: "SORT_TYPE",
    searchRequest: "SEARCH_REQUEST",
    pageSize: "PAGE_SIZE",
    increment: "INCREMENT",
    decrement: "DECREMENT",
    reset: "RESET",
    pageNo: "PAGE_NO",
  },
  S3_CONFIG: {
    S3_ACCESSKEYID: "AKIAWU5RASNRGUAM2AVI",
    S3_SECRETACCESSKEY: "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",
    S3_BUCKET: "pifs",
    S3_REGION: "ap-south-1",
    S3_ACCESS_URL: "https://pifs.s3.ap-south-1.amazonaws.com/",
  },
  BONDTYPE: [
    { label: "Buy", value: "BUY" },
    { label: "Sell", value: "SELL" },
    { label: "Dividend", value: "DIVIDEND" },
  ],
  PROPERTYTYPE: [
    { label: "Buy", value: "BUY" },
    { label: "Sell", value: "SELL" },
    { label: "Rent Paid", value: "RENT_PAID" },
    { label: "Rent Received", value: "RENT_RECEIVED" },
    { label: "Property Expense", value: "PROPERTY_EXPENSE" },
    { label: "Stamp Duty", value: "STAMP_DUTY" },
  ],

  ACCOUNTTYPE: [
    { label: "Saving", value: "SAVINGS_ACCOUNT" },
    { label: "Current", value: "CHECKING_CURRENT_ACCOUNT" },
  ],

  ACCOUNT_TYPE: [
    { label: "Transaction", value: "TRANSACTION" },
    { label: "Interest Gain", value: "INTEREST_GAIN" },
    { label: "Bank Charges", value: "BANK_CHARGES" },
  ],

  STOCKTYPE: [
    { label: "Buy", value: "BUY" },
    { label: "Sell", value: "SELL" },
  ],

  LOANTYPE: [
    { label: "Disbursement", value: "DISBURSEMENT" },
    { label: "EMI", value: "EMI" },
    { label: "Pre Close", value: "PRE_CLOSE" },
    { label: "Part Payment", value: "PART_PAYMENT" },
    { label: "Loan Fee", value: "LOAN_FEE" },
  ],

  INSURANCETYPE: [
    { label: "Premium", value: "PREMIUM" },
    { label: "Mature", value: "MATURE" },
  ],

  MONTHS: [
    { lable: "January", id: 1 },
    { lable: "February", id: 2 },
    { lable: "March", id: 3 },
    { lable: "April", id: 4 },
    { lable: "May", id: 5 },
    { lable: "June", id: 6 },
    { lable: "July", id: 7 },
    { lable: "August", id: 8 },
    { lable: "September", id: 9 },
    { lable: "October", id: 10 },
    { lable: "November", id: 11 },
    { lable: "December", id: 12 },
  ],
  BalanceSheetSearhDateEnum: [
    { label: "CURRENT FINANCIAL YEAR", value: "CURRENT_FINANCIAL_YEAR" },
    { label: "PREVIOUS FINANCIAL YEAR", value: "PREVIOUS_FINANCIAL_YEAR" },
    { label: "CUSTOM DATE RANGE", value: "CUSTOM_DATE_RANGE" },
  ],
};
// export const baseUrl = "http://192.168.0.141:8086/pifs";
// export const baseUrl = "http://192.168.0.134:8086/pifs";
export const baseUrl = "https://kalidasghose.info/pifs";

export default Config;
