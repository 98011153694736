import { Field, Formik, Form } from "formik";
import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { BiRevision } from "react-icons/bi";
import { FiRotateCw, FiChevronRight, FiPlus } from "react-icons/fi";
import * as Yup from "yup";
import ProductDropDown from "../../components/ProductDropDown";
import BankDropDown from "../../components/BankDropDown";
import BankAdd from "../../Bank/bankmanagement/BankAdd";
import { toast } from "react-toastify";
import BankingServices from "../../../services/BankingServices";
import { Dialog } from "primereact/dialog";

const AddProduct = ({ close, isLoading, handelAdd }) => {
  const productSchema = Yup.object().shape({
    bankId: Yup.string().required("Bank Name can't be empty"),
    productId: Yup.string().required("Product  can't be empty"),
    // description: Yup.string().required("Product Description can't be empty"),
    // code: Yup.string().required("Product Code"),
  });

  const bankingService = new BankingServices();

  const [add, setAdd] = useState(false);
  const [isBankLoading, setisBankLoading] = useState(false);

  const handelBankAdd = (value) => {
    setisBankLoading(true);
    bankingService
      .addBank(value)
      .then((res) => {
        if (res) {
          toast.success("Bank Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setisBankLoading(false);
          setAdd(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisBankLoading(false);
      });
  };

  const handleCloseAdd = () => {
    setAdd(false);
    setisBankLoading(false);
  };

  return (
    <Dialog
      draggable={false}
      header={<h1>Add Product Code</h1>}
      visible={true}
      onHide={() => close()}
      style={{ width: "80%" }}
    >
      <section className="c-content-in">
        <Formik
          initialValues={{
            bankId: "",
            productId: "",
            productType: "",
            description: "",
            code: "",
          }}
          validationSchema={productSchema}
          onSubmit={(values, { resetForm }) => {
            handelAdd(values);
            resetForm();
          }}
        >
          {({ handleChange, onSubmit, values, errors, touched }) => (
            <Form>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <div style={{ width: "85%" }}>
                      {!add && (
                        <Field
                          name="bankId"
                          className="form-control"
                          component={BankDropDown}
                        />
                      )}
                      {errors.bankId && touched.bankId ? (
                        <div className="text-danger">{errors.bankId}</div>
                      ) : null}
                    </div>

                    <button
                      type="button"
                      className="btn btn-primary cr-BtnCus"
                      style={{ borderRadius: "50%" }}
                      onClick={() => setAdd(true)}
                    >
                      <span>
                        <FiPlus />
                      </span>
                    </button>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <Field name="productId" component={ProductDropDown} />

                    {errors.productId && touched.productId ? (
                      <div className="text-danger">{errors.productId}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <Field
                      name="code"
                      type="text"
                      className="form-control"
                      placeholder="Product Code"
                    />
                    {errors.code && touched.code ? (
                      <div className="text-danger">{errors.code}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <Field
                      name="description"
                      as="textarea"
                      rows={5}
                      className="form-control"
                      placeholder=" Product Description"
                    />
                    {errors.description && touched.description ? (
                      <div className="text-danger">{errors.description}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="d-flex c-btn-group">
                        <button
                          type="submit"
                          className="c-btn-1"
                          // loading={this.state.loading}
                        >
                          <strong>Submit</strong>
                          {isLoading ? (
                            <span className="rotate-infinite">
                              <FiRotateCw />
                            </span>
                          ) : (
                            <span>
                              <FiChevronRight />
                            </span>
                          )}
                        </button>

                        <button
                          onClick={() => close()}
                          type="close"
                          className="c-btn-1 c-del-btn"
                        >
                          <strong>Cancel</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>

      {add ? (
        <BankAdd
          handelAdd={handelBankAdd}
          close={handleCloseAdd}
          isLoading={isBankLoading}
        />
      ) : null}
    </Dialog>
  );
};

export default AddProduct;
