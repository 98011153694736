import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { BiUserCircle } from "react-icons/bi";
import Logo_img from "../../assets/images/logo.png";
import Logout_img from "../../assets/images/logout-img.png";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import moment from "moment";

const Header = () => {
  const navigate = useNavigate();
  const customer = JSON.parse(sessionStorage.getItem("customer")) || null;
  const account = JSON.parse(sessionStorage.getItem("userData")) || null;

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
  };
  return (
    <header className="c-header-w">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12">
          <div className="c-header-logo">
            <Link to="/">
              <img className="img-fluid" src={Logo_img} alt="Logoimg" />
            </Link>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="c-header-right">
            <ul>
              <li className="c-header-user">
                {/* <span>
                  <BiUserCircle />
                </span>
                <h4>welcome username</h4> */}

                <Dropdown>
                  <span>
                    <BiUserCircle />
                  </span>
                  <Dropdown.Toggle id="dropdown-basic">
                    <h4>
                      {customer
                        ? `${customer.firstName} ${customer.lastName}`
                        : account
                        ? `${account.fullName}`
                        : null}
                    </h4>
                    <p>{moment().format("Do MMM, YYYY")}</p>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1" onClick={() => logout()}>
                      Log Out
                    </Dropdown.Item>
                    <Dropdown.Item href="/changepassword">
                      Change Password
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="c-logout-btn">
                <button onClick={() => logout()} type="button">
                  <img className="img-fluid" src={Logout_img} alt="Logoimg" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
