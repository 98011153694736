import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import InsuranceServices from "../../services/InsuranceService";
import PolicyNumberDropDown from "./component/PolicyNumberDropDown";
import { FormLabel } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { BiChevronsRight, BiRevision } from "react-icons/bi";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import { Calendar } from "primereact/calendar";
import moment from "moment";

const UpliWithdrawal = () => {
  const [loading, setLoading] = useState(false); //---------For Loading------//
  const toastTL = useRef(null); //--------For Toster Msg------//

  const insuranceService = new InsuranceServices(); //--------For Services Call--------//
  const navigate = useNavigate();

  const withDrawalSchema = Yup.object().shape({
    insuranceId: Yup.number().required(
      "Insurance Police Number can't be empty"
    ),
    // sellPrice: Yup.number().required("Price can't be empty"),
    // unitRedeemed: Yup.number().required("Unit Redeemed can't be empty"),
    // valueRedeemed: Yup.number().required("Value Redeemed can'tbe empty"),
    // sellDate: Yup.string().required("Date can't be empty"),
  });

  /////Create ULIP WithDrawal/////////

  const withDrawalSubmit = (values) => {
    const number = Number(values.insuranceId);
    values.insuranceId = number;
    setLoading(true);
    insuranceService
      .addwithDrawal(values)
      .then((res) => {
        toast.success("WithDrawal Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/insurance");
        }, 1000);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  return (
    <div>
      <ToastContainer />
      <div className="d-flex align-items-center justify-content-between w-100">
        <h1>ULIP Withdrawal</h1>
        <Link to="/insurance">
          <button type="button" className="c-btn-1">
            <span>
              <FiChevronRight />
            </span>
            <strong>Back</strong>
          </button>
        </Link>
      </div>
      <div>
        <section className="c-content-in">
          <Formik
            initialValues={{
              insuranceId: "",
              sellPrice: "",
              unitRedeemed: "",
              valueRedeemed: "",
              sellDate: "",
            }}
            validationSchema={withDrawalSchema}
            onSubmit={(values, { resetForm }) => {
              let reqData = { ...values };
              reqData.sellDate = values.sellDate
                ? moment(values.sellDate).format("YYYY-MM-DD")
                : null;
              withDrawalSubmit(reqData);
              resetForm();
            }}
          >
            {({ handleChange, values, errors, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Insurance Police Number</FormLabel>

                      <Field
                        name="insuranceId"
                        className="form-control"
                        component={PolicyNumberDropDown}
                        placeholder="Insurance policy number"
                      />
                      {errors.insuranceId && touched.insuranceId ? (
                        <div className="text-danger">{errors.insuranceId}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Price</FormLabel>
                      <Field
                        name="sellPrice"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Price"
                      />
                      {errors.sellPrice && touched.sellPrice ? (
                        <div className="text-danger">{errors.sellPrice}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Value Redeemed</FormLabel>

                      <Field
                        name="valueRedeemed"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Value Redeemed"
                      />
                      {errors.valueRedeemed && touched.valueRedeemed ? (
                        <div className="text-danger">
                          {errors.valueRedeemed}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Units Redeemed</FormLabel>
                      <Field
                        name="unitRedeemed"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Units Redeemed"
                      />
                      {errors.unitRedeemed && touched.unitRedeemed ? (
                        <div className="text-danger">{errors.unitRedeemed}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Date</FormLabel>
                      {/* <Field
                        name="sellDate"
                        className="form-control"
                        type="date"
                        placeholder="Date"
                      /> */}
                      <Calendar
                        name="sellDate"
                        className="w-100 mb-2 form-group c-calender-w"
                        value={values.sellDate}
                        onChange={handleChange}
                        showIcon
                        dateFormat="dd/mm/yy"
                        placeholder="dd/mm/yyyy"
                        readOnlyInput
                      />
                      {errors.sellDate && touched.sellDate ? (
                        <div className="text-danger">{errors.sellDate}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="d-flex c-btn-group">
                      <button
                        type="submit"
                        className="c-btn-1"
                        disabled={loading}
                      >
                        <strong>Submit</strong>
                        {loading ? (
                          <span className="rotate-infinite">
                            <FiRotateCw />
                          </span>
                        ) : (
                          <span>
                            <FiChevronRight />
                          </span>
                        )}
                      </button>

                      <button type="reset" className="c-btn-1 c-del-btn">
                        <strong>Reset</strong>
                        <span>
                          <BiRevision />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </div>
    </div>
  );
};

export default UpliWithdrawal;
