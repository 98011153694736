import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class StatementService {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  getAccountDetails = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/customer-account/account-type/${id}`,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getProductDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/product/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getProductTypeDetails = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/product/search`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getLedgerByProduct = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/customer-product/find-ledger/${id}`,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getProductByProductType = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/product/details`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  saveStatement = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bank-statement-voucher/save`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  updateStatement = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bank-statement-voucher/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getStatement = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bank-statement-voucher/search`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getByProductId = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/product/ledger`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getStatementDetails = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/bank-statement-voucher/${id}`,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getYear = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/bank-statement-voucher/financial-year/all`,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  uploadCsv = async (payload) => {
    try {
      const formData = new FormData();
      formData.append("file", payload);

      const response = await axios({
        method: "post",
        url: `${baseUrl}/excel/read`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
