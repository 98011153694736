import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class StockService {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  ////////Create Stock//////////

  createStock = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/stock/create`, payload, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  ///////////Listing Stock////////

  getStockList = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/stock/search`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////////Search Stock////////

  searchStock = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/stock/search`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////All Stock////////

  getAllStock = async () => {
    try {
      const response = await axios.get(`${baseUrl}/stock/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////One Data Fatch With ID For Stock////////

  getUpdateWithId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/stock/${id}`, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Update  For Stock////////

  updateStock = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/stock/update`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Delete Stock////////
  deleteStock = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/stock/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////Create Stock-Value Update//////////

  createStockValue = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/stock-Value/create`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////////Listing Stock-Value////////

  getStockValuelID = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/stock-Value/all-stock-Value/${id}`,

        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Get One Data Id For Stock-Value Update////////

  getValueDataWithId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/stock-Value/${id}`, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Update For Stock Value////////

  updateStockValue = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/stock-Value/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////Create Stock-Sell//////////

  createStockSell = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/stock-Sale/create`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Listing For Stock Sell/////////////

  getStockSellID = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/stock-Sale/all-sale-value/${id}`,

        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Get One Data Id For Stock-Value Update////////

  getSellDataWithId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/stock-Sale/${id}`, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Update For Stock Value////////

  updateStockSell = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/stock-Sale/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Delete Value////////
  deleteValue = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/stock-Value/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Delete Sell////////
  deleteSell = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/stock-Sale/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
