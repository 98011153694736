import React, { useEffect, useState } from "react";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import { BiEdit } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ProductService from "../../../services/ProductService";
import PageLoader from "../../components/PageLoader";
import {
  errorToaster,
  successToaster,
} from "../../../Global Component/FormField";
import MappingProduct from "../../components/MappingProduct";

const ProductMapping = () => {
  const navigate = useNavigate();
  const productService = new ProductService();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [Mapping, setMapping] = useState([]);
  const [productId, setProductId] = useState(undefined);
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const [loader, setLoader] = useState(true);
  const [isMappingLoading, setIsMappingLoading] = useState(false);
  const [isShowMapping, setIsShowMapping] = useState(false);

  useEffect(() => {
    if (menuList !== null) {
      getProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ////////for product list api/////

  const getProduct = () => {
    Promise.all([
      productService.getAllProduct(),
      productService.getCustomerProduct(),
    ])
      .then((res) => {
        const allProducts = res[0];
        const customerProduct = res[1];
        const modProducts = [];

        // allProducts.map((element) => {
        //   customerProduct.forEach((item) => {
        //     if (element.id === item.productId) {
        //       modProducts.push({
        //         ...element,
        //         accounting: item.accounting,
        //         ledgerName: item.ledgerName,
        //         ledgerGroupName: item.ledgerGroupName,
        //       });
        //     } else {
        //       modProducts.push(element);
        //     }
        //   });
        // });
        setProduct(allProducts);
        setMapping(customerProduct);
        setLoader(false);
      })

      .catch((e) => {
        errorToaster({ label: e.message });
        setLoader(false);
      });
  };

  const submitProductValues = () => {
    const payload = Mapping.map((e) => {
      return {
        productId: e,
        isActive: true,
      };
    });
    setLoading(true);
    productService
      .submitActiveProducts(payload)
      .then((res) => {
        if (res) {
          successToaster({ label: "Product Mapped Successfully" });
          setTimeout(() => {
            navigate("/");
          }, 1000);
          setLoading(false);
        }
      })
      .catch((e) => {
        errorToaster({ label: e.message });
        setLoading(false);
      });
  };

  const addMapping = (value) => {
    setIsMappingLoading(true);

    let modValue = {
      ...value,
      ledgerId: value.ledgerId.id,
    };

    if (value.hasOwnProperty("id")) {
      productService
        .productMappingUpdate(modValue)
        .then((res) => {
          setIsMappingLoading(false);
          setIsShowMapping(false);
          setProductId(undefined);
          getProduct();
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsMappingLoading(false);
        });
    } else {
      productService
        .productMapping(modValue)
        .then((res) => {
          setIsMappingLoading(false);
          setIsShowMapping(false);
          setProductId(undefined);
          getProduct();
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsMappingLoading(false);
        });
    }
  };

  const onCloseMapping = () => {
    setProductId(undefined);
    setIsShowMapping(false);
  };

  const editItem = (id) => {
    setProductId(id);
    setIsShowMapping(true);
  };

  return (
    <>
      <div className="table-responsive">
        <ToastContainer />
        <div className="d-flex align-items-center justify-content-between w-100">
          {loader && <PageLoader />}
          <h1>Product Mapping</h1>
          <div>
            <button
              type="button"
              className="c-btn-1"
              style={{ marginRight: "20px" }}
              onClick={() => setIsShowMapping(true)}
            >
              <span>
                <FiChevronRight />
              </span>
              <strong>Mapping</strong>
            </button>
            <Link to="/">
              <button type="button" className="c-btn-1">
                <span>
                  <FiChevronRight />
                </span>
                <strong>Back</strong>
              </button>
            </Link>
          </div>
        </div>
        {/* {!product && <PageLoader />} */}

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Product</th>
              <th>Parent Group </th>
              <th>Child Group</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Mapping.map((item, index) => {
              return (
                <tr key={index.toString()}>
                  <td>{item.productName}</td>
                  <td style={{ textTransform: "uppercase" }}>
                    {item.ledgerGroupName}
                  </td>
                  <td style={{ textTransform: "uppercase" }}>
                    {item.childGroupName}
                  </td>
                  <td>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id={`tooltip-bottom`}>Edit</Tooltip>}
                    >
                      <button
                        type="button"
                        className="c-edit-btn"
                        onClick={() => editItem(item.productId)}
                      >
                        <BiEdit />
                      </button>
                    </OverlayTrigger>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <button
          onClick={() => submitProductValues()}
          className="c-btn-1 "
          type="submit"
          disabled={loading}
        >
          <strong>Submit</strong>
          {loading ? (
            <span className="rotate-infinite">
              <FiRotateCw />
            </span>
          ) : (
            <span>
              <FiChevronRight />
            </span>
          )}
        </button>
      </div>

      {isShowMapping && (
        <MappingProduct
          productId={productId}
          allProducts={product}
          handelAddMapping={addMapping}
          onClose={onCloseMapping}
          isLoading={isMappingLoading}
        />
      )}
    </>
  );
};

export default ProductMapping;
