import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import MutualFundsService from "../../services/MutualFundsService";

import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { FormLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import { FiChevronRight, FiPlus, FiRotateCw } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ProductService from "../../services/ProductService";
import AddProduct from "../Products/ProductCodeManagement/AddProduct";
import MutualFundTypeDropDown from "../components/MutualFundTypeDropDown";
import PageLoader from "../components/PageLoader";
import InputComponent from "../components/primereactComponent/InputComponent";
import AddMutualFundType from "./mutualFundsType/AddMutualFundType";

const AddMutualFunds = () => {
  const navigate = useNavigate();
  const mutualFundService = new MutualFundsService(); //-----------For Service Call-------//
  const productService = new ProductService();

  const [productCode, setProductCode] = useState([]);
  const [addedMf, setAddedMf] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openFundModal, setOpenFundModal] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [add, setAdd] = useState(false);
  const [isOverlayLoading, setIsOverlayLoadingAdd] = useState(false);

  const fundSchema = Yup.object().shape({
    fundTypeId: Yup.number().required("Fund Type can't be empty"),
    // productId: Yup.number().required("Product Name can't be empty"),
    productCodeId: Yup.number().required("Product Code can't be empty"),
    nav: Yup.number().required("Nav can't be empty"),
    totalAmount: Yup.number().required("Total Amount can't be empty"),
    // companyName: Yup.string().required("Company Name can't be empty"),
    type: Yup.string().required("Type can't be empty"),
    // accountNo: Yup.string().required("Account No can't be empty"),
    // paymentMode: Yup.string().required("Payment Mode can't be empty"),
    startDate: Yup.string().required("Start Date can't be empty"),
    // endDate: Yup.string().required("End Date can't be empty"),
    // periodNo: Yup.number().required("Period No can't be empty"),
    // amount: Yup.number().required("Amount can't be empty"),
    // purchaseAmount: Yup.number().required("Purchase Amount can't be empty"),
    // purchaseDate: Yup.string().required("Purchase Date can't be empty"),
    // paymentFreq: Yup.string().required("Payment Frequency can't be empty"),
    fundName: Yup.string().required("Fund Name can't be empty"),
    ledgerName: Yup.string().required("Ledger name can't be empty"),
  });

  const createFundSubmit = (values) => {
    const fundTypeId = Number(values.fundTypeId);
    // const productId = Number(values.productId);
    const productCodeId = Number(values.productCodeId);
    const requestBody = {
      ...values,
      fundTypeId: fundTypeId,
      // productId: productId,
      productCodeId: productCodeId,
    };

    setLoading(true);
    mutualFundService
      .createMutualfund(requestBody)
      .then((res) => {
        toast.success("MutualFund Added", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/mutualfund");
        }, 1000);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  const handleClickOpen = () => {
    setOpenFundModal(true);
  };

  const handleClickClose = () => {
    setOpenFundModal(false);
  };

  const onAddMf = (value) => {
    setIsBtnLoading(true);
    mutualFundService
      .addFundsType(value)
      .then((res) => {
        setAddedMf(res);
        toast.success("Fund Type Added", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
        setOpenFundModal(false);
        // listFundType();
        // close();
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  const onUpdateMf = (value) => {
    setIsBtnLoading(true);
    mutualFundService
      .updateFundType(value)
      .then((res) => {
        setAddedMf(res);
        toast.success("Fund Type Added", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
        setOpenFundModal(false);
        // listFundType();
        // close();
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  useEffect(() => {
    productService
      .getProductCodeByAliasID("MUTUAL_FUNDS")
      .then((res) => {
        setProductCode(res);
        setIsPageLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsPageLoading(false);
      });
  }, [add]);

  const handleCloseAdd = () => {
    setAdd(false);
  };

  const handelAddProduct = (value) => {
    setIsOverlayLoadingAdd(true);
    productService
      .createProduct(value)
      .then((res) => {
        if (res) {
          toast.success("Product code Added Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsOverlayLoadingAdd(false);
          setAdd(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsOverlayLoadingAdd(false);
      });
  };

  return (
    <div className="row">
      {isPageLoading && <PageLoader />}
      <ToastContainer />
      <div className="d-flex align-items-center justify-content-between w-100">
        <h1>Add Mutual Fund</h1>
        <Link to="/mutualfund">
          <button type="button" className="c-btn-1">
            <span>
              <FiChevronRight />
            </span>
            <strong>Back</strong>
          </button>
        </Link>
      </div>

      <Formik
        initialValues={{
          fundTypeId: "",
          // productId: "",
          productCodeId: "",
          companyName: "",
          type: "",
          accountNo: "",
          paymentMode: "",
          startDate: "",
          endDate: "",
          periodNo: "",
          // amount: "",
          // purchaseAmount: "",
          purchaseDate: "",
          paymentFreq: "",
          fundName: "",
          ledgerName: "",
          nav: "",
        }}
        validationSchema={fundSchema}
        onSubmit={(values, { resetForm }) => {
          let reqData = { ...values };
          reqData.purchaseDate = values.purchaseDate
            ? moment(values.purchaseDate).format("YYYY-MM-DD")
            : null;
          reqData.startDate = values.startDate
            ? moment(values.startDate).format("YYYY-MM-DD")
            : null;
          reqData.endDate = values.endDate
            ? moment(values.endDate).format("YYYY-MM-DD")
            : null;
          createFundSubmit(reqData);
          resetForm();
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue }) => {
          console.log(errors);
          return (
            <Form>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group c-MutualFund-text">
                    <FormLabel>Mutual Fund Type</FormLabel>
                    <div className="d-flex">
                      <Field
                        name="fundTypeId"
                        className="form-control"
                        component={MutualFundTypeDropDown}
                        placeholder="Mutual Fund Type"
                        addedMf={addedMf}
                      />
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            Add MeutualFunds Type
                          </Tooltip>
                        }
                      >
                        <button
                          type="button"
                          className="c-rounded-btn"
                          onClick={() => handleClickOpen()}
                        >
                          <FiPlus />
                        </button>
                      </OverlayTrigger>
                    </div>
                    {errors.fundTypeId && touched.fundTypeId ? (
                      <div className="text-danger">{errors.fundTypeId}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  {/* <div className="form-group">
                  <FormLabel>Product</FormLabel>

                  <Field
                    name="productId"
                    className="form-control"
                    component={ProductDropDown}
                  />

                  {errors.productId && touched.productId ? (
                    <div className="text-danger">{errors.productId}</div>
                  ) : null}
                </div> */}

                  <div className="form-group d-flex justify-content-between align-items-center">
                    <div style={{ width: "85%" }}>
                      <FormLabel>Product Code</FormLabel>
                      <Field
                        as="select"
                        name="productCodeId"
                        className="form-control"
                      >
                        <option value="">Select Product Code</option>
                        {productCode.map((item, index) => (
                          <option value={item.id} key={`product-code-${index}`}>
                            {item.code}
                          </option>
                        ))}
                      </Field>
                      {errors.productCodeId && touched.productCodeId ? (
                        <div className="text-danger">
                          {errors.productCodeId}
                        </div>
                      ) : null}
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary cr-BtnCus"
                      style={{ borderRadius: "50%", marginTop: 20 }}
                      onClick={() => setAdd(true)}
                    >
                      <span>
                        <FiPlus />
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="row">
                {/* <div className="col-lg-4 col-md-12">
                <div className="form-group">
                  <FormLabel>Product Code</FormLabel>
                  <Field
                    name="productCodeId"
                    className="form-control"
                    component={ProductCodeDropDown}
                  />
                  {errors.productCodeId && touched.productCodeId ? (
                    <div className="text-danger">{errors.productCodeId}</div>
                  ) : null}
                </div>
              </div> */}

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Company Name</FormLabel>
                    <Field
                      name="companyName"
                      className="form-control"
                      type="text"
                      placeholder="Company Name"
                    />
                    {errors.companyName && touched.companyName ? (
                      <div className="text-danger">{errors.companyName}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Fund Name</FormLabel>
                    <Field
                      name="fundName"
                      className="form-control"
                      type="text"
                      placeholder="Fund Name"
                    />
                    {errors.fundName && touched.fundName ? (
                      <div className="text-danger">{errors.fundName}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Bank Account</FormLabel>
                    <Field
                      name="accountNo"
                      className="form-control"
                      type="text"
                      placeholder="Bank Account"
                    />
                    {errors.accountNo && touched.accountNo ? (
                      <div className="text-danger">{errors.accountNo}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Payment Mode</FormLabel>
                    <Field
                      name="paymentMode"
                      className="form-control"
                      type="text"
                      placeholder="Payment Mode"
                    />
                    {errors.paymentMode && touched.paymentMode ? (
                      <div className="text-danger">{errors.paymentMode}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Start Date</FormLabel>
                    {/* <Field
                      name="startDate"
                      className="form-control"
                      type="date"
                      placeholder="Payment Mode"
                    /> */}
                    <Calendar
                      name="startDate"
                      className="w-100 mb-2 form-group c-calender-w"
                      value={values.startDate}
                      onChange={handleChange}
                      showIcon
                      dateFormat="dd/mm/yy"
                      placeholder="dd/mm/yyyy"
                      readOnlyInput
                    />
                    {errors.startDate && touched.startDate ? (
                      <div className="text-danger">{errors.startDate}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>End Date</FormLabel>
                    {/* <Field
                      name="endDate"
                      className="form-control"
                      type="date"
                      placeholder="Payment Mode"
                    /> */}
                    <Calendar
                      name="endDate"
                      className="w-100 mb-2 form-group c-calender-w"
                      value={values.endDate}
                      onChange={handleChange}
                      showIcon
                      dateFormat="dd/mm/yy"
                      placeholder="dd/mm/yyyy"
                      readOnlyInput
                    />
                    {errors.endDate && touched.endDate ? (
                      <div className="text-danger">{errors.endDate}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Period</FormLabel>
                    <Field
                      name="periodNo"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Period"
                    />
                    {errors.periodNo && touched.periodNo ? (
                      <div className="text-danger">{errors.periodNo}</div>
                    ) : null}
                  </div>
                </div>
                {/* <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Amount</FormLabel>
                    <Field
                      name="amount"
                      className="form-control"
                       type="number"onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Amount"
                    />
                    {errors.amount && touched.amount ? (
                      <div className="text-danger">{errors.amount}</div>
                    ) : null}
                  </div>
                </div> */}

                {/* <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Purchase Amount</FormLabel>
                    <Field
                      name="purchaseAmount"
                      className="form-control"
                       type="number"onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Purchase Amount"
                    />
                    {errors.purchaseAmount && touched.purchaseAmount ? (
                      <div className="text-danger">{errors.purchaseAmount}</div>
                    ) : null}
                  </div>
                </div> */}
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Nav</FormLabel>
                    <Field
                      name="nav"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Nav"
                      onChange={(e) => {
                        setFieldValue("nav", e.target.value);
                        setFieldValue(
                          "unitPrice",
                          Number(values.totalAmount) / Number(e.target.value)
                        );
                      }}
                    />

                    {errors.nav && touched.nav ? (
                      <div className="text-danger">{errors.nav}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="row">
                {/* <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Purchase Date</FormLabel>
                   
                    <Calendar
                      name="purchaseDate"
                      className="w-100 mb-2 form-group c-calender-w"
                      value={values.purchaseDate}
                      onChange={handleChange}
                      showIcon
                      dateFormat="dd/mm/yy"
                      placeholder="dd/mm/yyyy"
                      readOnlyInput
                    />
                    {errors.purchaseDate && touched.purchaseDate ? (
                      <div className="text-danger">{errors.purchaseDate}</div>
                    ) : null}
                  </div>
                </div> */}

                {/* <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Nav</FormLabel>
                    <Field
                      name="nav"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Nav"
                      onChange={(e) => {
                        setFieldValue("nav", e.target.value);
                        setFieldValue(
                          "unitPrice",
                          Number(values.totalAmount) / Number(e.target.value)
                        );
                      }}
                    />

                    {errors.nav && touched.nav ? (
                      <div className="text-danger">{errors.nav}</div>
                    ) : null}
                  </div>
                </div> */}
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Total Amount</FormLabel>
                    <Field
                      name="totalAmount"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Total Amount"
                      onChange={(e) => {
                        setFieldValue("totalAmount", e.target.value);
                        setFieldValue(
                          "unitPrice",
                          Number(e.target.value) / Number(values.nav)
                        );
                      }}
                    />
                    {errors.totalAmount && touched.totalAmount ? (
                      <div className="text-danger">{errors.totalAmount}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Unit Price</FormLabel>
                    <Field
                      disabled
                      name="unitPrice"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Unit Price"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                {/* <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Payment Frequency</FormLabel>
                    <Field
                      name="paymentFreq"
                      className="form-control"
                      type="text"
                      placeholder="Payment Frequency"
                    />
                    {errors.paymentFreq && touched.paymentFreq ? (
                      <div className="text-danger">{errors.paymentFreq}</div>
                    ) : null}
                  </div>
                </div> */}

                <div className="col-lg-6 col-md-12">
                  <InputComponent
                    unstyled={true}
                    className="form-control"
                    name="ledgerName"
                    header="Ledger Name"
                    placeholder="Ledger Name"
                    value={values.ledgerName}
                    onChange={(e) =>
                      setFieldValue("ledgerName", e.target.value?.toUpperCase())
                    }
                  />
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Select Fund Type</FormLabel>
                    <select
                      value={values.type}
                      onChange={handleChange}
                      name="type"
                      className="form-control"
                      aria-label="Default select example"
                    >
                      <option>Select Fund Type</option>
                      <option value="SIP">SIP</option>
                      <option value="ONE_TIME">One Time</option>
                    </select>
                    {errors.type && touched.type ? (
                      <div className="text-danger">{errors.type}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              {values.type === "SIP" && (
                <>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Start Date</FormLabel>
                        {/* <Field
                          name="startDate"
                          placeholder="Date"
                          type="date"
                          className="form-control"
                        /> */}
                        <Calendar
                          name="startDate"
                          className="w-100 mb-2 form-group c-calender-w"
                          value={values.startDate}
                          onChange={handleChange}
                          showIcon
                          dateFormat="dd/mm/yy"
                          placeholder="dd/mm/yyyy"
                          readOnlyInput
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>End Date</FormLabel>
                        {/* <Field
                          name="endDate"
                          placeholder="Date"
                          type="date"
                          className="form-control"
                        /> */}
                        <Calendar
                          name="endDate"
                          className="w-100 mb-2 form-group c-calender-w"
                          value={values.endDate}
                          onChange={handleChange}
                          showIcon
                          dateFormat="dd/mm/yy"
                          placeholder="dd/mm/yyyy"
                          readOnlyInput
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Payment Frequency</FormLabel>

                        <select
                          className="form-control"
                          name="paymentFreq"
                          value={values.paymentFreq}
                          onChange={handleChange}
                          aria-label="Default select example"
                        >
                          <option>Select Payment</option>
                          <option value="monthly">Monthly</option>
                          <option value="daily">Daily</option>
                          <option value="fortnightly">Fornightly</option>
                        </select>
                        {errors.paymentFreq && touched.paymentFreq ? (
                          <div className="text-danger">
                            {errors.paymentFreq}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>SIP Amount</FormLabel>
                        <Field
                          name="amount"
                          placeholder="SIP Amount"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* {values.type === "ONE_TIME" && (
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Purchase Date</FormLabel>

                      <Calendar
                        name="purchaseDate"
                        className="w-100 mb-2 form-group c-calender-w"
                        value={values.purchaseDate}
                        onChange={handleChange}
                        showIcon
                        dateFormat="dd/mm/yy"
                        placeholder="dd/mm/yyyy"
                        readOnlyInput
                      />
                    </div>
                  </div>
                </div>
              )} */}

              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="d-flex c-btn-group">
                    <button
                      type="submit"
                      className="c-btn-1"
                      disabled={loading}
                    >
                      <strong>Submit</strong>
                      {loading ? (
                        <span className="rotate-infinite">
                          <FiRotateCw />
                        </span>
                      ) : (
                        <span>
                          <FiChevronRight />
                        </span>
                      )}
                    </button>

                    <button type="reset" className="c-btn-1 c-del-btn">
                      <strong>Reset</strong>
                      <span>
                        <BiRevision />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      <Dialog
        draggable={false}
        visible={openFundModal}
        onHide={() => setOpenFundModal(false)}
        header={<h1>Mutual Fund Type</h1>}
        className="p-3 bg-white"
        style={{ width: "80%" }}
      >
        <AddMutualFundType
          onAdd={onAddMf}
          onUpdateMf={onUpdateMf}
          isLoading={isBtnLoading}
        />
      </Dialog>

      {add ? (
        <AddProduct
          handelAdd={handelAddProduct}
          close={handleCloseAdd}
          isLoading={isOverlayLoading}
        />
      ) : null}
    </div>
  );
};

export default AddMutualFunds;
