import React, { useEffect, useState } from "react";
import { FormLabel, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import { BiEdit } from "react-icons/bi";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import BondService from "../../services/BondService";
import { Form, Formik, Field } from "formik";
import { BsTrash } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert";
import { Dialog } from "primereact/dialog";

const BondType = ({ close, access }) => {
  const bondServices = new BondService();
  const [bondsType, setBondsType] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [initialValue, setinitialValue] = useState({ name: "" });
  const [isEdit, setEdit] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const typeSchema = Yup.object().shape({
    name: Yup.string().required("Bond type can't be empty"),
  });

  //////////Bond List////////
  const getType = () => {
    bondServices
      .getBondType()
      .then((res) => {
        setBondsType(res);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  useEffect(() => {
    getType();
  }, []);
  /////////Bond Submit/////////

  const handelForm = (value) => {
    setisLoading(true);
    if (isEdit) {
      setEdit(false);
      bondServices
        .updateBondType(value)
        .then((res) => {
          if (res) {
            setisLoading(false);
            toast.success("Bond Type Updated", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            getType();
            setinitialValue({ name: "" });
            close();
          }
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else {
      bondServices
        .createBondType(value)
        .then((res) => {
          if (res) {
            setisLoading(false);
            toast.success("Bond Type Added", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            getType();
            close();
          }
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  };

  const getBondType = (id) => {
    setEdit(true);
    bondServices
      .getBondTypeById(id)
      .then((res) => {
        const { id, name } = res;
        setinitialValue({
          id: id,
          name: name,
        });
        setShowForm(true);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  ///////////Delete Bonds/////////
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete this Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            bondServices.deleteBondType(id).then((res) => {
              toast.success(res, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            });
            getType();
            close();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <Dialog
      draggable={false}
      visible={true}
      onHide={() => close()}
      header={showForm ? <h1>Edit Bond Type</h1> : <h1>Add Bond Type</h1>}
      style={{ width: "80%" }}
    >
      <section className="c-content-in">
        <ToastContainer />
        {(access.isWrite || access.isDelete) && (
          <Formik
            initialValues={{
              ...initialValue,
            }}
            validationSchema={typeSchema}
            onSubmit={(values, formmikHandeler) => {
              handelForm(values);
              formmikHandeler.resetForm();
            }}
            enableReinitialize
          >
            {({ errors }) => (
              <Form>
                <div className="row">
                  <div className="col-9">
                    <div className="form-group">
                      <FormLabel>Bond Type</FormLabel>
                      <Field
                        name="name"
                        className="form-control"
                        placeholder="Bond Type"
                      />

                      {errors.name ? (
                        <div className="text-danger">{errors.name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-3">
                    <button
                      type="submit"
                      className="c-btn-1 mt-4"
                      disabled={isLoading}
                    >
                      {showForm ? (
                        <strong>Update</strong>
                      ) : (
                        <strong>Submit</strong>
                      )}
                      {isLoading ? (
                        <span className="rotate-infinite">
                          <FiRotateCw />
                        </span>
                      ) : (
                        <span>
                          <FiChevronRight />
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Type</th>
                    {(access.isWrite || access.isDelete) && <th>Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {bondsType.map((item, index) => (
                    <tr key={`bond-type-${index}`}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      {(access.isWrite || access.isDelete) && (
                        <td>
                          {access.isWrite && (
                            <button
                              type="button"
                              className="c-edit-btn"
                              onClick={() => getBondType(item.id)}
                            >
                              <BiEdit />
                            </button>
                          )}
                          {access.isDelete && (
                            <button
                              onClick={() => deleteItem(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <BsTrash />{" "}
                            </button>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </Dialog>
  );
};

export default BondType;
