import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class BondService {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  //Create Bond:
  createBond = async (payload) => {
    try {
      const response = await axios.post(baseUrl + "/bond/create", payload, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  // For Listing Bond Data:

  getBondList = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/bond/search`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getBondSearchList = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/bond/search`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getBondDetails = async (id) => {
    try {
      const response = await axios.get(baseUrl + "/bond/" + id, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  upDateBond = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/bond/update`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getBondType = async () => {
    try {
      const response = await axios.get(`${baseUrl}/bond-type/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  createBondType = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bond-type/create`,
        payload,
        { headers: this.headers }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getBondTypeById = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/bond-type/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  updateBondType = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bond-type/update`,
        payload,
        { headers: this.headers }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  /////////////Delete Bonds/////////

  deleteBond = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bond/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  deleteBondType = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bond-type/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  deleteStatement = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bank-statement-voucher/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
