import { useEffect, useState } from "react";
import {
  BsFileEarmark,
  BsFileEarmarkPdf,
  BsFileImage,
  BsGrid,
  BsListTask,
} from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import DocumentService from "../../services/DocumentService";
import pdfImg from "../../assets/images/pdf.png";
import imageImg from "../../assets/images/image-i.png";
import PageLoader from "../components/PageLoader";
import Config from "../../shared/Config";
import { BiArrowBack } from "react-icons/bi";
import { Button } from "react-bootstrap";

const AllFile = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const documentUpload = new DocumentService();

  const [isLoading, setIsLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [isType, setIsType] = useState(1);

  function getFileExtension(filename) {
    const extension = filename.split(".")[1];
    return extension;
  }

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    documentUpload.getDocumentByOriginId(state.origiItemId).then((res) => {
      setAllData(res);
      setIsLoading(false);
    });
  };

  const onChangeShowingType = (value) => {
    setIsType(value);
  };

  const onBack = () => {
    navigate(-1);
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <div className="row">
        <div className="col-md-6 col-12">
          <div className="cr-pageTitle">
            <h5>Folder Name</h5>
          </div>
        </div>

        <div className="col-md-6 col-12">
          <div className="d-flex justify-content-end align-items-end">
            <div className="d-flex" style={{ marginRight: 10 }}>
              <Button
                type="button"
                onClick={() => {
                  onBack();
                }}
              >
                <BiArrowBack />
              </Button>
            </div>
            <div
              className={`cr-socialIcn me-1 ${isType === 0 ? "active" : ""}`}
              onClick={() => onChangeShowingType(0)}
            >
              <BsGrid />
            </div>
            <div
              // className="cr-socialIcn"
              className={`cr-socialIcn ${isType === 1 ? "active" : ""}`}
              onClick={() => onChangeShowingType(1)}
            >
              <BsListTask />
            </div>
          </div>
        </div>
      </div>
      {/* For List */}
      {isType === 0 ? (
        <div className="row">
          {allData.map((item, index) => {
            const extention = getFileExtension(item.documentFileName);
            return (
              <div className="cr-fileList">
                <ul>
                  <li>
                    {extention === "pdf" ? (
                      <a
                        href={
                          Config.S3_CONFIG.S3_ACCESS_URL +
                          "documents/" +
                          item?.documentFileName
                        }
                        target="_blank"
                      >
                        {" "}
                        <BsFileEarmarkPdf /> {item.documentFileName}{" "}
                      </a>
                    ) : (
                      <a
                        href={
                          Config.S3_CONFIG.S3_ACCESS_URL +
                          "documents/" +
                          item?.documentFileName
                        }
                        target="_blank"
                      >
                        {" "}
                        <BsFileImage /> {item.documentFileName}
                      </a>
                    )}
                  </li>
                </ul>
              </div>
            );
          })}
        </div>
      ) : (
        //  For Grid
        <div className="row">
          {allData.map((item, index) => {
            const extention = getFileExtension(item.documentFileName);
            return (
              <div className="col-md-2">
                <div className="cr-fileGrid">
                  {extention === "pdf" ? (
                    <a
                      href={
                        Config.S3_CONFIG.S3_ACCESS_URL +
                        "documents/" +
                        item?.documentFileName
                      }
                      target="_blank"
                    >
                      <img src={pdfImg} alt="" />
                    </a>
                  ) : (
                    <a
                      href={
                        Config.S3_CONFIG.S3_ACCESS_URL +
                        "documents/" +
                        item?.documentFileName
                      }
                      target="_blank"
                    >
                      <img src={imageImg} alt="" />
                    </a>
                  )}
                  <h5 style={{ overflowWrap: "break-word" }}>
                    {item.documentFileName}
                  </h5>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AllFile;
