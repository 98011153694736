import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import PageLoader from "./PageLoader";
import PropertyService from "../../services/PropertyService";
import { Dialog } from "primereact/dialog";

const ProductLedger = ({ onClose, rowId, defaultValue }) => {
  const propertyService = new PropertyService();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    propertyService
      .getProductTransaction(rowId, defaultValue)
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  }, []);

  return loading ? (
    <PageLoader />
  ) : (
    <div className="table-responsive">
      <Dialog
        draggable={false}
        visible={true}
        onHide={() => onClose()}
        header={<h1>Transaction Details</h1>}
        style={{ width: "80%" }}
      >
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Transaction Date</th>
              <th>Ledger</th>
              <th>Description</th>
              <th>Dr.</th>
              <th>Cr.</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr key={index.toString()}>
                  <td>{index + 1}</td>
                  <td>{moment(item.txnDate).format("Do MMM, YYYY")}</td>
                  <td>{item.ledgerName}</td>
                  <td>{item.narration}</td>
                  <td>{item.debit} </td>
                  <td>{item.credit} </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Dialog>
    </div>
  );
};

export default ProductLedger;
