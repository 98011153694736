import React, { useState } from "react";
import * as Yup from "yup";
import { S3 } from "aws-sdk";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import { FormLabel, Modal } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import Config from "../../shared/Config";
import { Field, Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";

const s3Clint = new S3({
  accessKeyId: Config.S3_CONFIG.S3_ACCESSKEYID,
  secretAccessKey: Config.S3_CONFIG.S3_SECRETACCESSKEY,
  region: Config.S3_CONFIG.S3_REGION,
  signatureVersion: "v4",
});

const AddMenu = ({ handelAdd, close, isLoading, menuList }) => {
  const [iconName, setIconName] = useState(null);
  const [darkIconName, setDarkIconName] = useState(null);

  const menuSchema = Yup.object().shape({
    name: Yup.string().required("Name can't be empty"),
    // lightIcon: Yup.string().required("Image Icon can't be empty"),
    // url: Yup.string().required("Url can't be empty"),
    position: Yup.number().required("position can't be empty"),
  });

  const handelFileUpload = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;
    const fileNameArray = fileName.split(".");
    const fileExtension = fileNameArray[fileNameArray.length - 1];
    const newFileName = `${
      fileNameArray[0]
    }_${new Date().getTime()}.${fileExtension}`;
    const params = {
      Body: file,
      Bucket: Config.S3_CONFIG.S3_BUCKET + "/" + "menuIcon",
      Key: newFileName,
      ContentType: file.type,
    };
    s3Clint.upload(params, (err, resp) => {
      if (err) {
        // add error message as tosta.
      } else {
        setIconName(newFileName);
      }
    });
  };

  const handelFileUploadDark = (e) => {
    const file = e.target.files[0];
    const fileName = file.name;
    const fileNameArray = fileName.split(".");
    const fileExtension = fileNameArray[fileNameArray.length - 1];
    const newFileName = `${
      fileNameArray[0]
    }_${new Date().getTime()}.${fileExtension}`;
    const params = {
      Body: file,
      Bucket: Config.S3_CONFIG.S3_BUCKET + "/" + "menuIcon",
      Key: newFileName,
      ContentType: file.type,
    };
    s3Clint.upload(params, (err, resp) => {
      if (err) {
        // add error message as tosta.
      } else {
        setDarkIconName(newFileName);
      }
    });
  };

  return (
    <div>
      <Dialog
        draggable={false}
        visible={true}
        onHide={() => close()}
        header={<h1>Add Menu</h1>}
        style={{ width: "80%" }}
      >
        <section className="c-content-in">
          <Formik
            initialValues={{
              name: "",
              lightIcon: "",
              darkIcon: "",
              url: "",
              position: "",
              parentId: "",
            }}
            validationSchema={menuSchema}
            onSubmit={(values, { resetForm }) => {
              values.lightIcon = iconName;
              values.darkIcon = darkIconName;

              handelAdd(values);
              resetForm();
            }}
          >
            {({ handleChange, values, errors, touched }) => {
              return (
                <Form>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Name</FormLabel>
                        <Field
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name"
                        />
                        {errors.name && touched.name ? (
                          <div className="text-danger">{errors.name}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Upload Image Light Icon</FormLabel>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => handelFileUpload(e)}
                          name="lightIcon"
                          // required
                        />
                        {errors.lightIcon && touched.lightIcon ? (
                          <div className="text-danger">{errors.lightIcon}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Upload Image Dark Icon</FormLabel>
                        <input
                          type="file"
                          className="form-control"
                          onChange={(e) => handelFileUploadDark(e)}
                          name="darkIcon"
                          // required
                        />
                        {errors.darkIcon && touched.darkIcon ? (
                          <div className="text-danger">{errors.darkIcon}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Type URL</FormLabel>
                        <Field
                          name="url"
                          type="text"
                          className="form-control"
                          placeholder="www.example.com"
                        />
                        {errors.url && touched.url ? (
                          <div className="text-danger">{errors.url}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Position</FormLabel>
                        <Field
                          name="position"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          className="form-control"
                          placeholder="Position"
                        />
                        {errors.position && touched.position ? (
                          <div className="text-danger">{errors.position}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Parent</FormLabel>
                        <Field
                          name="parentId"
                          className="form-control"
                          placeholder="Parent"
                          component="select"
                        >
                          <option value={""}>Select</option>
                          {menuList?.map((elm, i) => (
                            <option key={i} value={elm?.id?.toString()}>
                              {elm?.name}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="d-flex c-btn-group">
                        <button
                          type="submit"
                          className="c-btn-1"
                          disabled={isLoading}
                        >
                          <strong>Submit</strong>
                          {isLoading ? (
                            <span className="rotate-infinite">
                              <FiRotateCw />
                            </span>
                          ) : (
                            <span>
                              <FiChevronRight />
                            </span>
                          )}
                        </button>

                        <button
                          type="reset"
                          className="c-btn-1 c-del-btn"
                          onClick={() => close()}
                        >
                          <strong>Cancel</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </section>
        ;
      </Dialog>
    </div>
  );
};

export default AddMenu;
