import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { FormLabel } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import {
  FiChevronRight,
  FiChevronsRight,
  FiPlus,
  FiRotateCw,
  FiTrash2,
  FiUpload,
} from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AccountService from "../../services/AccountService";
import BankingServices from "../../services/BankingServices";
import BondService from "../../services/BondService";
import CreditCardService from "../../services/CreditCardService";
import InsuranceServices from "../../services/InsuranceService";
import LedgerService from "../../services/LedgerService";
import MutualFundsService from "../../services/MutualFundsService";
import PropertyService from "../../services/PropertyService";
import StatementService from "../../services/StatementService";
import StockService from "../../services/StockService";
import AddAccountLedger from "../components/AddAccountLeader";
import PageLoader from "../components/PageLoader";
import BankAccountForm from "../components/StatementProductForm/BankAccountForm";
import BondForm from "../components/StatementProductForm/BondForm";
import CreditCardForm from "../components/StatementProductForm/CreditCardForm";
import InsuranceForm from "../components/StatementProductForm/InsuranceForm";
import MFform from "../components/StatementProductForm/MFform";
import PropertyForm from "../components/StatementProductForm/PropertyForm";
import StockForm from "../components/StatementProductForm/StockForm";
import DropdownComponent from "../components/primereactComponent/DropdownComponent";
import InputComponent from "../components/primereactComponent/InputComponent";

import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import Config from "../../shared/Config";
import AddBankAccount from "../Bank/bankAccountManagement/AddBankAccount";
import UploaCsv from "../components/UploaCsv";
import { convertNullToString } from "../utils/utils";
import moment from "moment";

const AddUploadStatment = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state;

  const statementService = new StatementService();
  const mutualFundService = new MutualFundsService();
  const stockService = new StockService();
  const ledgerService = new LedgerService();
  const bondservice = new BondService();
  const creditcardService = new CreditCardService();
  const bankservice = new BankingServices();
  const propertyService = new PropertyService();
  const insuranceService = new InsuranceServices();
  const accountSevice = new AccountService();

  const [selectedChildGroup, setSelectedChildGroup] = useState("");
  const [allYear, setAllYear] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [addingLoader, setAddingLoader] = useState(false);
  const [isShowAccountLedger, setIsShowAccountLedger] = useState(false);

  const [productData, setProductData] = useState([]);
  const [allParentGroup, setAllParentGroup] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(undefined);
  const [selectedAlias, setSelectedAlias] = useState(undefined);
  const [ledgerDialog, setLedgerDialog] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [amountError, setAmountError] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isOpenAccountForm, setIsOpenAccountForm] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [selectedType, setSelectedType] = useState(undefined);
  const [isCsvUploading, setIsCsvUploading] = useState(false);

  const editMode = id === null ? false : true;

  const [initialValue, setInitialValue] = useState({
    accountType: "",
    accountId: "",
    statement: "",
    year: "",
    month: "",
    voucherDetailsDtos: [
      {
        id: "",
        origin: "",
        allLedger: [],
        ledgerId: "",
        type: "",
        amount: "",
        stockQty: "",
        stockCharges: "",
        // originName: "",
        // originItemId: "",
        // allProductName: [],
        description: "",
        withdrawl: "",
        deposit: "",
        // alias: "",
        txnDate: "",
        nav: "",
        interest: "",
        principalAmount: "",
        loanFee: "",
        mode: "add",
      },
    ],
  });

  const uploadStatmentSchema = Yup.object().shape({
    accountType: Yup.string().required("Account Type required"),
    accountId: Yup.string().required("Account Number required"),
    statement: Yup.string().required("Statment Name required"),
    year: Yup.string().required("Year required"),
    month: Yup.string().required("Month required"),
    voucherDetailsDtos: Yup.array().of(
      Yup.object().shape({
        origin: Yup.string().required("Product is required"),
        // productName: Yup.string().required("Product Name is required"),
        ledgerId: Yup.string().required("Ledger is required"),
        type: Yup.string().required("Type is required"),
        description: Yup.string().required("Narration is required"),
        // position: Yup.string().when("origin", {
        //   is: (origin) => origin === Config.STOCK_ID,
        //   then: () => Yup.string().required("Position can't be empty"),
        // }),
        // withdrawl: Yup.string().required("Withdrawl is required"),
        // deposit: Yup.string().required("Deposit is required"),
        txnDate: Yup.string().required("Transaction Date required"),
        amount: Yup.string().when(["origin", "type"], {
          is: (origin, type) =>
            type === "DISBURSEMENT" ||
            (origin === Config.MUTUAL_FUND_ID && type === "BUY") ||
            (origin === Config.MUTUAL_FUND_ID && type === "SELL") ||
            (origin === Config.STOCK_ID && type === "BUY") ||
            (origin === Config.STOCK_ID && type === "SELL"),
          then: () => Yup.string().required("Amount can't be empty"),
        }),
        stockQty: Yup.string().when("origin", {
          is: (type) => type?.id === Config.STOCK_ID,
          then: () => Yup.string().required("Quantity can't be empty"),
        }),
        stockCharges: Yup.string().when(["origin", "type"], {
          is: (origin, type) =>
            origin === Config.MUTUAL_FUND_ID && type === "SELL",
          then: () => Yup.string().required("Charges can't be empty"),
        }),
        nav: Yup.string().when(["origin", "type"], {
          is: (origin, type) =>
            (origin === Config.MUTUAL_FUND_ID && type === "BUY") ||
            (origin === Config.MUTUAL_FUND_ID && type === "SELL"),
          then: () => Yup.string().required("Nav can't be empty"),
        }),
        interest: Yup.string().when("type", {
          is: (type) =>
            type === "EMI" || type === "PRE_CLOSE" || type === "PART_PAYMENT",
          then: () => Yup.string().required("Interest can't be empty"),
        }),
        principalAmount: Yup.string().when("type", {
          is: (type) =>
            type === "EMI" || type === "PRE_CLOSE" || type === "PART_PAYMENT",
          then: () => Yup.string().required("Principal Amount can't be empty"),
        }),
        loanFee: Yup.string().when("type", {
          is: (type) => type === "DISBURSEMENT",
          then: () => Yup.string().required("Loan Fee can't be empty"),
        }),
      })
    ),
  });

  const months = [
    { label: "January", value: "January" },
    { label: "February", value: "February" },
    { label: "March", value: "March" },
    { label: "April", value: "April" },
    { label: "May", value: "May" },
    { label: "June", value: "June" },
    { label: "July", value: "July" },
    { label: "August", value: "August" },
    { label: "September", value: "September" },
    { label: "October", value: "October" },
    { label: "November", value: "November" },
    { label: "December", value: "December" },
  ];

  const accountGroupchema = Yup.object().shape({
    name: Yup.string().required("Name can't be empty"),
    position: Yup.string().required("Position can't be empty"),
    // parentId: Yup.string().required("User Id can't be empty"),
    // accounting: Yup.string().required("Account Header can't be empty"),
    // ledgerName: Yup.string().required("LedgerName Id can't be empty"),
  });

  useEffect(() => {
    const previousYear = 2018;
    const currentYear = new Date().getFullYear();
    const arrayCount = currentYear - previousYear + 1;
    const yearArray = new Array(arrayCount);
    const newYear = [];

    for (let index = 0; index < yearArray.length; index++) {
      newYear.push({
        label: previousYear + index,
        value: previousYear + index,
      });
    }
    setAllYear(newYear);

    if (!editMode) {
      Promise.all([statementService.getProductDetails()])
        .then((response) => {
          const productDetailsResponse = response[0];

          productDetailsResponse.push({
            alias: "OTHERS",
            category: null,
            id: 0,
            name: "Others",
          });

          setProductData(productDetailsResponse);
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        });
    } else {
      Promise.all([
        statementService.getProductDetails(),
        statementService.getStatementDetails(id),
      ])
        .then((response) => {
          const productDetailsResponse = response[0];
          const statementDetailsResponse = response[1];

          Promise.all([
            statementService.getAccountDetails(
              statementDetailsResponse.accountType
            ),
          ])
            .then((res) => {
              let accountRes = res[0];
              productDetailsResponse.push({
                alias: "OTHERS",
                category: null,
                id: 0,
                name: "Others",
              });

              let convertedData = convertNullToString(statementDetailsResponse);

              let mapp = convertedData?.voucherDetailsDtos.map((item) => {
                return {
                  ...item,
                  ledgerId: item.ledgerName,
                  txnDate: new Date(item.txnDate),
                  mode: "edit",
                };
              });
              convertedData.voucherDetailsDtos = mapp;
              setAccountData(accountRes);
              setInitialValue(convertedData);
              setProductData(productDetailsResponse);
              setIsLoading(false);
            })
            .catch((error) => {
              toast.error(error.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setIsLoading(false);
            });
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddAccountGroup = (value) => {
    accountSevice
      .createLedger(value)
      .then((res) => {})
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const onCloseAccountLeger = () => {
    setSelectedIndex(undefined);
    setIsShowAccountLedger(false);
  };

  const onAddAccountLedger = (value) => {
    setAddingLoader(true);

    // if (productId === 2007 || productId === 2010) {
    ledgerService
      .createLedgerAccount(value)
      .then((res) => {
        const allLedger = [
          ...formRef.current.values.voucherDetailsDtos[selectedIndex].allLedger,
        ];
        allLedger.push(res);
        formRef.current.values.voucherDetailsDtos[selectedIndex].allLedger =
          allLedger;
        // setAllLedger(allLedger);
        setAddingLoader(false);
        setIsShowAccountLedger(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setAddingLoader(false);
      });
  };

  const handleFormUpdate = (value) => {
    setAmountError(false);
    const voucherDetailsDtos = [];
    value.voucherDetailsDtos.forEach((element) => {
      if (element.mode === "edit") {
        voucherDetailsDtos.unshift({
          id: element.id,
          origin: element.origin,
          ledgerName: element.ledgerId,
          // ledgerId: element.ledgerId,
          type: element.type,
          description: element.description,
          withdrawl: element.withdrawl,
          deposit: element.deposit,
          // originItemId: element.originItemId,
          txnDate: moment(element.txnDate).format("YYYY-MM-DD"),
          amount: element.amount,
          stockQty: element.stockQty,
          stockCharges: element.stockCharges,
          nav: element.nav,
          loanFee: element.loanFee,
          principalAmount: element.principalAmount,
          interest: element.interest,
        });
      } else {
        voucherDetailsDtos.unshift({
          origin: element.origin,
          // originName: element.originName,
          ledgerId: element.ledgerId,
          type: element.type,
          description: element.description,
          withdrawl: element.withdrawl,
          deposit: element.deposit,
          // originItemId: element.originItemId,
          txnDate: moment(element.txnDate).format("YYYY-MM-DD"),
          amount: element.amount,
          stockQty: element.stockQty,
          stockCharges: element.stockCharges,
          nav: element.nav,
          loanFee: element.loanFee,
          principalAmount: element.principalAmount,
          interest: element.interest,
        });
      }
    });
    const filter = voucherDetailsDtos.filter(
      (item) => item.withdrawl === null && item.deposit === null
    );
    if (filter.length > 0) {
      setAmountError(true);
    } else {
      setAmountError(false);
      setIsLoading(true);
      let reqData = {
        id,
        accountType: value.accountType,
        accountId: Number(value.accountId),
        year: value.year,
        month: value.month,
        statement: value.statement,
        voucherDetailsDtos: voucherDetailsDtos,
      };
      statementService
        .updateStatement(reqData)
        .then((res) => {
          toast.success("Statement Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
          navigate("/upload");
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        });
    }
  };

  const handleFormSubmit = (value) => {
    setAmountError(false);
    const voucherDetailsDtos = [];
    value.voucherDetailsDtos.forEach((element) => {
      voucherDetailsDtos.unshift({
        origin: element.origin,
        // originName: element.originName,
        ledgerId: element.ledgerId,
        type: element.type,
        description: element.description,
        withdrawl: element.withdrawl,
        deposit: element.deposit,
        // originItemId: element.originItemId,
        txnDate: moment(element.txnDate).format("YYYY-MM-DD"),
        amount: element.amount,
        stockQty: element.stockQty,
        stockCharges: element.stockCharges,
        nav: element.nav,
        loanFee: element.loanFee,
        principalAmount: element.principalAmount,
        interest: element.interest,
      });
    });
    const filter = voucherDetailsDtos.filter(
      (item) => item.withdrawl === null && item.deposit === null
    );
    if (filter.length > 0) {
      setAmountError(true);
    } else {
      setAmountError(false);
      setIsLoading(true);
      let reqData = {
        accountType: value.accountType,
        accountId: Number(value.accountId),
        year: value.year,
        month: value.month,
        statement: value.statement,
        voucherDetailsDtos: voucherDetailsDtos,
      };
      statementService
        .saveStatement(reqData)
        .then((res) => {
          toast.success("Statement Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
          navigate("/upload");
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        });
    }
  };

  const onAddMf = (value, setFieldValue) => {
    // const fundTypeId = Number(values.fundTypeId);
    // const productId = Number(values.productId);
    const productCodeId = Number(value.productCodeId);
    const requestBody = {
      ...value,
      // fundTypeId: fundTypeId,
      // productId: productId,
      productCodeId: productCodeId,
    };

    setIsBtnLoading(true);
    mutualFundService
      .createMutualfund(requestBody)
      .then((res) => {
        toast.success("MutualFund Added", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const allLedger = [
          ...formRef.current.values.voucherDetailsDtos[selectedIndex].allLedger,
        ];
        allLedger.push(res);
        setFieldValue(
          `voucherDetailsDtos[${selectedIndex}].allLedger`,
          allLedger
        );
        setIsBtnLoading(false);
        setIsShowModal(false);
        setSelectedIndex(undefined);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  const onAddStock = (value, setFieldValue) => {
    const number = Number(value.productCodeId);

    value.productCodeId = number;

    setIsBtnLoading(true);
    stockService
      .createStock(value)
      .then((res) => {
        toast.success("Stock Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const allLedger = [
          ...formRef.current.values.voucherDetailsDtos[selectedIndex].allLedger,
        ];
        allLedger.push(res);
        setFieldValue(
          `voucherDetailsDtos[${selectedIndex}].allLedger`,
          allLedger
        );
        setIsBtnLoading(false);
        setIsShowModal(false);
        setSelectedIndex(undefined);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  const onAddBond = (data, setFieldValue) => {
    setIsBtnLoading(true);

    bondservice
      .createBond(data)
      .then((res) => {
        toast.success("Bond Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const allLedger = [
          ...formRef.current.values.voucherDetailsDtos[selectedIndex].allLedger,
        ];
        allLedger.push(res);
        setFieldValue(
          `voucherDetailsDtos[${selectedIndex}].allLedger`,
          allLedger
        );
        setIsBtnLoading(false);
        setIsShowModal(false);
        setSelectedIndex(undefined);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  const onAddCreditCard = (value, setFieldValue) => {
    setIsBtnLoading(true);

    creditcardService
      .createCreditCard(value)
      .then((res) => {
        if (res) {
          toast.success("Credit Card Added Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          const allLedger = [
            ...formRef.current.values.voucherDetailsDtos[selectedIndex]
              .allLedger,
          ];
          setFieldValue(`voucherDetailsDtos[${selectedIndex}].allProductName`, [
            ...allLedger,
            ...res,
          ]);
          // setFieldValue(
          //   `voucherDetailsDtos[${selectedIndex}].allProductName`,
          //   res
          // );
          setIsBtnLoading(false);
          setIsShowModal(false);
          setSelectedIndex(undefined);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  const onAddBankAccount = (value, setFieldValue) => {
    setIsBtnLoading(true);

    bankservice
      .createBankAccount(value)
      .then((res) => {
        if (res) {
          toast.success("Account Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          const allLedger = [
            ...formRef.current.values.voucherDetailsDtos[selectedIndex]
              .allLedger,
          ];
          allLedger.push(res);
          setFieldValue(
            `voucherDetailsDtos[${selectedIndex}].allLedger`,
            allLedger
          );
          setIsBtnLoading(false);
          setIsShowModal(false);
          setSelectedIndex(undefined);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  const onAddProperty = (value, setFieldValue) => {
    setIsBtnLoading(true);

    const countryId = Number(value.countryId);
    // const productID = Number(value.productId);
    const requestBody = {
      ...value,
      countryId: countryId,
      // productId: productID,
    };
    // console.log(requestBody);
    propertyService
      .createProperty(requestBody)
      .then((res) => {
        toast.success(" Property Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        const allLedger = [
          ...formRef.current.values.voucherDetailsDtos[selectedIndex].allLedger,
        ];
        allLedger.push(res);
        setFieldValue(
          `voucherDetailsDtos[${selectedIndex}].allLedger`,
          allLedger
        );
        setIsBtnLoading(false);
        setIsShowModal(false);
        setSelectedIndex(undefined);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  const onAddInsurance = (value, setFieldValue) => {
    setIsBtnLoading(true);
    insuranceService
      .addInsurance(value)
      .then((res) => {
        toast.success("Insurance Type Added Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const allLedger = [
          ...formRef.current.values.voucherDetailsDtos[selectedIndex].allLedger,
        ];
        allLedger.push(res);
        setFieldValue(
          `voucherDetailsDtos[${selectedIndex}].allLedger`,
          allLedger
        );
        setIsBtnLoading(false);
        setIsShowModal(false);
        setSelectedIndex(undefined);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsBtnLoading(false);
      });
  };

  // const onChangeParentGroup = (value, setFieldValue) => {
  //   setSelectedGroup(value);
  //   accountSevice
  //     .getChildGroup(value)
  //     .then((res) => {
  //       setAllChildGroup(res);
  //     })
  //     .catch((e) => {
  //       toast.error(e.message, {
  //         position: "top-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     });
  // };

  //******************************* new funtion ************************************//

  const handleAccount = (e, setFieldValue) => {
    setIsLoading(true);
    setFieldValue("accountId", "");
    setFieldValue("accountType", e, true);
    setSelectedType(e);
    e &&
      statementService
        .getAccountDetails(e)
        .then((res) => {
          setAccountData(res);
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        });
  };

  const onChangeType = (index, item, setFieldValue) => {
    setFieldValue(`voucherDetailsDtos[${index}].type`, item);
    setFieldValue(`voucherDetailsDtos[${index}].deposit`, "");
    setFieldValue(`voucherDetailsDtos[${index}].withdrawl`, "");
    setFieldValue(`voucherDetailsDtos[${index}].loanFee`, "");
    setFieldValue(`voucherDetailsDtos[${index}].principalAmount`, "");
    setFieldValue(`voucherDetailsDtos[${index}].amount`, "");
    setFieldValue(`voucherDetailsDtos[${index}].stockQty`, "");
    setFieldValue(`voucherDetailsDtos[${index}].interest`, "");
  };

  const onChangeProduct = (index, item, setFieldValue) => {
    // setFieldValue(`voucherDetailsDtos[${index}].alias`, item.alias);
    setFieldValue(`voucherDetailsDtos[${index}].origin`, item.value);
    setIsLoading(true);
    setFieldValue(`voucherDetailsDtos[${index}].ledgerId`, "");
    setFieldValue(`voucherDetailsDtos[${index}].allLedger`, []);
    setFieldValue(`voucherDetailsDtos[${index}].type`, "");
    // setFieldValue(`voucherDetailsDtos[${index}].originName`, "");
    setIsShowModal(false);

    if (item.value !== "OTHERS") {
      let reqData = {
        productType: item.value,
      };

      Promise.all([statementService.getByProductId(reqData)])
        .then((response) => {
          const ledgerResponse = response[0];
          setFieldValue(
            `voucherDetailsDtos[${index}].allLedger`,
            ledgerResponse
          );
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        });
    } else {
      Promise.all([
        ledgerService.allLedgerAccount(),
        // accountSevice.getAccountGroupListAll(),
      ])
        .then((response) => {
          const allLedgerRes = response[0];
          // const allParentGroup = response[1];

          // setAllParentGroup(allParentGroup);
          setFieldValue(`voucherDetailsDtos[${index}].allLedger`, allLedgerRes);
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        });
    }
  };

  const onClickProductName = (index, item) => {
    setSelectedAlias(item.origin);
    setSelectedIndex(index);
    setIsShowModal(true);
  };

  const onAddLedger = (index) => {
    setSelectedIndex(index);
    setIsShowAccountLedger(true);
  };

  const onHideUpload = () => {
    setIsUpload(false);
  };

  const handleCloseAdd = () => {
    setIsSubmiting(false);
    setIsOpenAccountForm(false);
  };

  const handelAdd = (value) => {
    const allAccounts = [...accountData];
    setIsSubmiting(true);
    bankservice
      .createBankAccount(value)
      .then((res) => {
        if (res) {
          let selectedType = formRef.current.values.accountType;

          if (selectedType === res.type) {
            allAccounts.push(res);
            setAccountData(allAccounts);
          }

          toast.success("Account Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // listAccount(payload);
          setIsSubmiting(false);
          setIsOpenAccountForm(false);
        }
      })
      .catch((e) => {
        toast.success(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsSubmiting(false);
      });
  };

  const onUploading = (file) => {
    setIsCsvUploading(true);
    statementService
      .uploadCsv(file)
      .then((res) => {
        let preValue = formRef.current.values.voucherDetailsDtos;

        let totalValue = [...preValue, ...res];

        let Data = totalValue.map((item) => {
          const items = { ...item, txnDate: new Date(item.txnDate) };

          if (item.origin === Config.LOAN_ID && item.type === "DISBURSEMENT") {
            items.deposit = item.amount - items.loanFee;
          }

          if (
            item.type === "EMI" ||
            item.type === "PRE_CLOSE" ||
            item.type === "PART_PAYMENT"
          ) {
            items.withdrawl = item.principalAmount + item.interest;
          }

          if (item.origin === Config.STOCK_ID && item.type === "BUY") {
            items.withdrawl = item.amount * item.stockQty;
          }

          if (item.origin === Config.STOCK_ID && item.type === "SELL") {
            items.deposit = item.amount * item.stockQty;
          }

          if (item.origin === Config.MUTUAL_FUND_ID && item.type === "BUY") {
            items.amount = item.withdrawl / item.nav;
          }

          if (item.origin === Config.MUTUAL_FUND_ID && item.type === "SELL") {
            items.amount = item.deposit / item.nav;
          }

          return convertNullToString(items);
        });

        let modifyData = {
          accountType: "",
          accountId: "",
          statement: "",
          voucherDetailsDtos: Data,
        };
        setIsCsvUploading(false);
        setInitialValue(modifyData);
        onHideUpload();
      })
      .catch((e) => {
        setIsCsvUploading(false);
        toast.success(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <div className="row">
        <div className="d-flex align-items-center justify-content-between w-100">
          <h1>{!editMode ? "Add" : "View"} Statement</h1>

          <div>
            {/* <Link to="/upload"> */}

            {!editMode ? (
              <button
                type="button"
                className="c-btn-1"
                onClick={() => setIsUpload(true)}
              >
                <span>
                  <FiUpload />
                </span>
                <strong>Upload</strong>
              </button>
            ) : null}
            {/* </Link> */}

            <Link to="/upload">
              <button type="button" className="c-btn-1 ms-2">
                <span>
                  <FiChevronRight />
                </span>
                <strong>Back</strong>
              </button>
            </Link>
          </div>
        </div>
        <Formik
          initialValues={initialValue}
          innerRef={formRef}
          validationSchema={uploadStatmentSchema}
          onSubmit={editMode ? handleFormUpdate : handleFormSubmit}
          enableReinitialize
        >
          {({ handleChange, values, errors, touched, setFieldValue }) => {
            return (
              <div>
                <Form>
                  <div className="row">
                    <div className="col-lg-4 col-md-12 mb-2">
                      <FormLabel>Account Type</FormLabel>
                      <Dropdown
                        filter
                        name="accountType"
                        value={values.accountType}
                        options={Config.ACCOUNTTYPE}
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Select Product"
                        className="w-100 mb-2"
                        onChange={(e) => {
                          handleAccount(e.target.value, setFieldValue);
                        }}
                      />
                      {errors.accountType && touched.accountType ? (
                        <div className="text-danger">{errors.accountType}</div>
                      ) : null}
                    </div>

                    <div className="col-lg-4 col-md-12 mb-2">
                      <div className="col">
                        <FormLabel>Select Account</FormLabel>
                        <div className="p-inputgroup flex-1">
                          <Dropdown
                            filter
                            name="accountId"
                            value={values.accountId}
                            options={accountData}
                            optionLabel="accountNo"
                            optionValue="id"
                            placeholder="Select Account"
                            className="w-100 mb-2"
                            onChange={(e) =>
                              setFieldValue("accountId", e.value)
                            }
                          />

                          {!editMode ? (
                            <span
                              className="p-inputgroup-addon cr-addonBtn"
                              onClick={() =>
                                !values.accountType
                                  ? undefined
                                  : setIsOpenAccountForm(true)
                              }
                            >
                              <FiPlus />
                            </span>
                          ) : null}
                        </div>
                        {errors.accountId && touched.accountId ? (
                          <div className="text-danger">{errors.accountId}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12 mb-2">
                      <div className="col">
                        <FormLabel>Select Year</FormLabel>
                        <div className="p-inputgroup flex-1">
                          <Dropdown
                            filter
                            name="year"
                            value={values.year}
                            options={allYear}
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Select Year"
                            className="w-100 mb-2"
                            onChange={(e) => setFieldValue("year", e.value)}
                          />
                        </div>
                        {errors.year && touched.year ? (
                          <div className="text-danger">{errors.year}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12 mb-2">
                      <div className="col">
                        <FormLabel>Select Month</FormLabel>
                        <div className="p-inputgroup flex-1">
                          <Dropdown
                            filter
                            name="month"
                            value={values.month}
                            options={months}
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Select Month"
                            className="w-100 mb-2"
                            onChange={(e) => setFieldValue("month", e.value)}
                          />
                        </div>
                        {errors.month && touched.month ? (
                          <div className="text-danger">{errors.month}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12 mb-2">
                      <FormLabel>Statement Name</FormLabel>
                      <InputText
                        name="statement"
                        value={values.statement}
                        onChange={(e) =>
                          setFieldValue("statement", e.target.value)
                        }
                        placeholder="Statement Name"
                        className="w-100 mb-2"
                      />
                      {errors.statement && touched.statement ? (
                        <div className="text-danger">{errors.statement}</div>
                      ) : null}
                    </div>

                    <FieldArray
                      name="voucherDetailsDtos"
                      render={(arrayHelpers) => {
                        return (
                          <>
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <button
                                type="button"
                                className="c-btn-1 c-btn-right w-200"
                                onClick={() =>
                                  arrayHelpers.unshift({
                                    origin: "",
                                    allLedger: [],
                                    ledgerId: "",
                                    type: "",
                                    amount: "",
                                    stockQty: "",
                                    stockCharges: "",
                                    // originItemId: "",
                                    // originName: "",
                                    // allProductName: [],
                                    description: "",
                                    withdrawl: "",
                                    deposit: "",
                                    txnDate: "",
                                    nav: "",
                                    interest: "",
                                    principalAmount: "",
                                    loanFee: "",
                                    mode: "add",
                                  })
                                }
                              >
                                <span>
                                  <FiPlus />
                                </span>
                                <strong> Add Line</strong>
                              </button>
                            </div>

                            <div className="mb-2">
                              <strong className="text-danger">
                                {amountError
                                  ? "*Please enter withdrawl or deposit amount"
                                  : null}
                              </strong>
                            </div>

                            {values.voucherDetailsDtos &&
                            values.voucherDetailsDtos.length > 0
                              ? values.voucherDetailsDtos.map((item, index) => {
                                  let originId =
                                    values.voucherDetailsDtos[index].origin;

                                  let isDisbursement =
                                    values.voucherDetailsDtos[index].type ===
                                    "DISBURSEMENT";

                                  let isEmi =
                                    values.voucherDetailsDtos[index].type ===
                                      "EMI" ||
                                    values.voucherDetailsDtos[index].type ===
                                      "PRE_CLOSE" ||
                                    values.voucherDetailsDtos[index].type ===
                                      "PART_PAYMENT";

                                  let isStockBuy =
                                    originId === Config.STOCK_ID &&
                                    values.voucherDetailsDtos[index].type ===
                                      "BUY";

                                  let isStockSell =
                                    originId === Config.STOCK_ID &&
                                    values.voucherDetailsDtos[index].type ===
                                      "SELL";

                                  let isMutualBuy =
                                    originId === Config.MUTUAL_FUND_ID &&
                                    values.voucherDetailsDtos[index].type ===
                                      "BUY";

                                  let isMutualSell =
                                    originId === Config.MUTUAL_FUND_ID &&
                                    values.voucherDetailsDtos[index].type ===
                                      "SELL";

                                  return (
                                    <div key={index}>
                                      <div className="cr-newFormBx mb-3">
                                        <div className="row row-cols-2 row-cols-lg-5 justify-content-end">
                                          <div className="col">
                                            <label className="cr-label mb-2">
                                              Product
                                            </label>
                                            <Dropdown
                                              disabled={
                                                values.voucherDetailsDtos[index]
                                                  .mode === "edit"
                                              }
                                              filter
                                              name="origin"
                                              value={originId}
                                              onChange={(e) =>
                                                onChangeProduct(
                                                  index,
                                                  e,
                                                  setFieldValue
                                                )
                                              }
                                              options={productData}
                                              optionLabel="name"
                                              optionValue="alias"
                                              placeholder="Select Product"
                                              className="w-100 mb-2"
                                            />
                                            <ErrorMessage
                                              name={`voucherDetailsDtos[${index}].origin`}
                                            >
                                              {(msg) => (
                                                <div style={{ color: "red" }}>
                                                  {msg}
                                                </div>
                                              )}
                                            </ErrorMessage>
                                          </div>

                                          {values.voucherDetailsDtos[index]
                                            .mode === "add" ? (
                                            <div className="col">
                                              <label className="cr-label mb-2">
                                                Ledger
                                              </label>
                                              <div className="p-inputgroup flex-1">
                                                <Dropdown
                                                  filter
                                                  name="ledgerId"
                                                  value={
                                                    values.voucherDetailsDtos[
                                                      index
                                                    ].ledgerId
                                                  }
                                                  onChange={(e) =>
                                                    setFieldValue(
                                                      `voucherDetailsDtos[${index}].ledgerId`,
                                                      e.value
                                                    )
                                                  }
                                                  options={
                                                    values.voucherDetailsDtos[
                                                      index
                                                    ].allLedger
                                                  }
                                                  optionLabel="ledgerName"
                                                  optionValue="ledgerId"
                                                  placeholder="Select Ledger"
                                                  className="w-100 mb-2"
                                                />
                                                {values.voucherDetailsDtos[
                                                  index
                                                ].mode === "add" ? (
                                                  <span
                                                    className="p-inputgroup-addon cr-addonBtn"
                                                    onClick={() =>
                                                      values
                                                        ?.voucherDetailsDtos[
                                                        index
                                                      ]?.origin === "OTHERS"
                                                        ? onAddLedger(index)
                                                        : onClickProductName(
                                                            index,
                                                            item
                                                          )
                                                    }
                                                  >
                                                    <FiPlus />
                                                  </span>
                                                ) : null}
                                              </div>
                                              <ErrorMessage
                                                name={`voucherDetailsDtos[${index}].ledgerId`}
                                              >
                                                {(msg) => (
                                                  <div style={{ color: "red" }}>
                                                    {msg}
                                                  </div>
                                                )}
                                              </ErrorMessage>
                                            </div>
                                          ) : (
                                            <div className="col">
                                              <label className="cr-label mb-2">
                                                Ledger
                                              </label>
                                              <InputText
                                                disabled
                                                className="w-100 mb-2"
                                                name="ledgerId"
                                                value={
                                                  values.voucherDetailsDtos[
                                                    index
                                                  ].ledgerId
                                                }
                                                placeholder="Ledger"
                                              />
                                            </div>
                                          )}

                                          <div className="col">
                                            <label className="cr-label mb-2">
                                              Type
                                            </label>
                                            <Dropdown
                                              disabled={
                                                values.voucherDetailsDtos[index]
                                                  .mode === "edit"
                                              }
                                              filter
                                              name="type"
                                              value={
                                                values.voucherDetailsDtos[index]
                                                  .type
                                              }
                                              onChange={(e) =>
                                                onChangeType(
                                                  index,
                                                  e.value,
                                                  setFieldValue
                                                )
                                              }
                                              options={
                                                originId === Config.BOND_ID ||
                                                originId === Config.STOCK_ID ||
                                                originId ===
                                                  Config.MUTUAL_FUND_ID
                                                  ? Config.BONDTYPE
                                                  : originId ===
                                                    Config.PROPERTY_ID
                                                  ? Config.PROPERTYTYPE
                                                  : originId ===
                                                      Config.CURRENT_ACCOUNT_ID ||
                                                    originId ===
                                                      Config.SAVINGS_ACCOUNT_ID ||
                                                    originId ===
                                                      Config.DEPOSIT_ACCOUNT_ID
                                                  ? Config.ACCOUNT_TYPE
                                                  : originId === Config.LOAN_ID
                                                  ? Config.LOANTYPE
                                                  : originId ===
                                                      Config.ULIP_ID ||
                                                    originId ===
                                                      Config.TRADITIONAL_ID ||
                                                    originId ===
                                                      Config.LIFE_INSURANCE_ID ||
                                                    originId ===
                                                      Config.TERM_LIFE_INSURANCE_ID ||
                                                    originId ===
                                                      Config.MEDICAL_INSURANCE_ID
                                                  ? Config.INSURANCETYPE
                                                  : [
                                                      {
                                                        label: "Payment",
                                                        value: "PAYMENT",
                                                      },
                                                      {
                                                        label: "Receipt",
                                                        value: "RECEIPT",
                                                      },
                                                    ]
                                              }
                                              optionLabel="label"
                                              optionValue="value"
                                              placeholder="Select Type"
                                              className="w-100 mb-2"
                                            />
                                            <ErrorMessage
                                              name={`voucherDetailsDtos[${index}].type`}
                                            >
                                              {(msg) => (
                                                <div style={{ color: "red" }}>
                                                  {msg}
                                                </div>
                                              )}
                                            </ErrorMessage>
                                          </div>

                                          {values.voucherDetailsDtos[index]
                                            .type === "DISBURSEMENT" ? (
                                            <div className="col">
                                              <label className="cr-label mb-2">
                                                Loan Fees
                                              </label>
                                              <InputNumber
                                                disabled={editMode}
                                                className="w-100 mb-2"
                                                name="loanFee"
                                                inputId="integeronly"
                                                minFractionDigits={2}
                                                value={
                                                  values.voucherDetailsDtos[
                                                    index
                                                  ].loanFee
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `voucherDetailsDtos[${index}].loanFee`,
                                                    e.value
                                                  );
                                                  setFieldValue(
                                                    `voucherDetailsDtos[${index}].deposit`,
                                                    values.voucherDetailsDtos[
                                                      index
                                                    ].amount - e.value || 0
                                                  );
                                                }}
                                                placeholder="Loan Fees"
                                              />
                                              <ErrorMessage
                                                name={`voucherDetailsDtos[${index}].loanFee`}
                                              >
                                                {(msg) => (
                                                  <div style={{ color: "red" }}>
                                                    {msg}
                                                  </div>
                                                )}
                                              </ErrorMessage>
                                            </div>
                                          ) : null}

                                          {values.voucherDetailsDtos[index]
                                            .type === "EMI" ||
                                          values.voucherDetailsDtos[index]
                                            .type === "PRE_CLOSE" ||
                                          values.voucherDetailsDtos[index]
                                            .type === "PART_PAYMENT" ? (
                                            <div className="col">
                                              <label className="cr-label mb-2">
                                                Principal Amount
                                              </label>
                                              <InputNumber
                                                disabled={editMode}
                                                className="w-100 mb-2"
                                                name="principalAmount"
                                                inputId="integeronly"
                                                minFractionDigits={2}
                                                value={
                                                  values.voucherDetailsDtos[
                                                    index
                                                  ].principalAmount
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `voucherDetailsDtos[${index}].principalAmount`,
                                                    e.value
                                                  );
                                                  setFieldValue(
                                                    `voucherDetailsDtos[${index}].withdrawl`,
                                                    e.value +
                                                      values.voucherDetailsDtos[
                                                        index
                                                      ].interest || 0
                                                  );
                                                }}
                                                placeholder="Principal Amount"
                                              />
                                              <ErrorMessage
                                                name={`voucherDetailsDtos[${index}].principalAmount`}
                                              >
                                                {(msg) => (
                                                  <div style={{ color: "red" }}>
                                                    {msg}
                                                  </div>
                                                )}
                                              </ErrorMessage>
                                            </div>
                                          ) : null}

                                          {values.voucherDetailsDtos[index]
                                            .type === "EMI" ||
                                          values.voucherDetailsDtos[index]
                                            .type === "PRE_CLOSE" ||
                                          values.voucherDetailsDtos[index]
                                            .type === "PART_PAYMENT" ? (
                                            <div className="col">
                                              <label className="cr-label mb-2">
                                                Interest
                                              </label>
                                              <InputNumber
                                                disabled={editMode}
                                                className="w-100 mb-2"
                                                name="interest"
                                                inputId="integeronly"
                                                minFractionDigits={2}
                                                value={
                                                  values.voucherDetailsDtos[
                                                    index
                                                  ].interest
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `voucherDetailsDtos[${index}].interest`,
                                                    e.value
                                                  );
                                                  setFieldValue(
                                                    `voucherDetailsDtos[${index}].withdrawl`,
                                                    values.voucherDetailsDtos[
                                                      index
                                                    ].principalAmount +
                                                      e.value || 0
                                                  );
                                                }}
                                                placeholder="Interest"
                                              />
                                              <ErrorMessage
                                                name={`voucherDetailsDtos[${index}].interest`}
                                              >
                                                {(msg) => (
                                                  <div style={{ color: "red" }}>
                                                    {msg}
                                                  </div>
                                                )}
                                              </ErrorMessage>
                                            </div>
                                          ) : null}

                                          {(originId === Config.STOCK_ID &&
                                            values.voucherDetailsDtos[index]
                                              .type !== "DIVIDEND") ||
                                          (originId === Config.MUTUAL_FUND_ID &&
                                            values.voucherDetailsDtos[index]
                                              .type !== "DIVIDEND") ||
                                          values.voucherDetailsDtos[index]
                                            .type === "DISBURSEMENT" ? (
                                            <div className="col">
                                              <label className="cr-label mb-2">
                                                {values.voucherDetailsDtos[
                                                  index
                                                ].type === "BUY"
                                                  ? "Buy Unit Price"
                                                  : values.voucherDetailsDtos[
                                                      index
                                                    ].type === "SELL"
                                                  ? "Sell Unit Price"
                                                  : "Amount"}
                                              </label>
                                              <InputNumber
                                                disabled={
                                                  // editMode ||
                                                  isMutualBuy || isMutualSell
                                                }
                                                className="w-100 mb-2"
                                                name="amount"
                                                inputId="integeronly"
                                                minFractionDigits={2}
                                                value={
                                                  values.voucherDetailsDtos[
                                                    index
                                                  ].amount
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `voucherDetailsDtos[${index}].amount`,
                                                    e.value
                                                  );
                                                  isStockBuy &&
                                                    setFieldValue(
                                                      `voucherDetailsDtos[${index}].withdrawl`,
                                                      e.value *
                                                        values
                                                          .voucherDetailsDtos[
                                                          index
                                                        ].stockQty || 0
                                                    );
                                                  isStockSell &&
                                                    setFieldValue(
                                                      `voucherDetailsDtos[${index}].deposit`,
                                                      e.value *
                                                        values
                                                          .voucherDetailsDtos[
                                                          index
                                                        ].stockQty || 0
                                                    );

                                                  originId === Config.LOAN_ID &&
                                                    values.voucherDetailsDtos[
                                                      index
                                                    ].type === "DISBURSEMENT" &&
                                                    setFieldValue(
                                                      `voucherDetailsDtos[${index}].deposit`,
                                                      e.value -
                                                        values
                                                          .voucherDetailsDtos[
                                                          index
                                                        ].loanFee || 0
                                                    );
                                                }}
                                                placeholder="Amount"
                                              />
                                              <ErrorMessage
                                                name={`voucherDetailsDtos[${index}].amount`}
                                              >
                                                {(msg) => (
                                                  <div style={{ color: "red" }}>
                                                    {msg}
                                                  </div>
                                                )}
                                              </ErrorMessage>
                                            </div>
                                          ) : null}

                                          {originId === Config.STOCK_ID &&
                                          values.voucherDetailsDtos[index]
                                            .type !== "DIVIDEND" ? (
                                            <div className="col">
                                              <label className="cr-label mb-2">
                                                {values.voucherDetailsDtos[
                                                  index
                                                ].type === "BUY"
                                                  ? "Buy Quantity"
                                                  : "Sell Quantity"}
                                              </label>
                                              <InputNumber
                                                // disabled={values.voucherDetailsDtos[index]
                                                //   .mode === "edit"}
                                                className="w-100 mb-2"
                                                name="stockQty"
                                                inputId="integeronly"
                                                minFractionDigits={2}
                                                value={
                                                  values.voucherDetailsDtos[
                                                    index
                                                  ].stockQty
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `voucherDetailsDtos[${index}].stockQty`,
                                                    e.value
                                                  );
                                                  isStockSell
                                                    ? setFieldValue(
                                                        `voucherDetailsDtos[${index}].deposit`,
                                                        e.value *
                                                          values
                                                            .voucherDetailsDtos[
                                                            index
                                                          ].amount || 0
                                                      )
                                                    : setFieldValue(
                                                        `voucherDetailsDtos[${index}].withdrawl`,
                                                        e.value *
                                                          values
                                                            .voucherDetailsDtos[
                                                            index
                                                          ].amount || 0
                                                      );
                                                }}
                                                placeholder="Quantity"
                                              />
                                              <ErrorMessage
                                                name={`voucherDetailsDtos[${index}].stockQty`}
                                              >
                                                {(msg) => (
                                                  <div style={{ color: "red" }}>
                                                    {msg}
                                                  </div>
                                                )}
                                              </ErrorMessage>
                                            </div>
                                          ) : null}

                                          {originId === Config.MUTUAL_FUND_ID &&
                                          values.voucherDetailsDtos[index]
                                            .type !== "DIVIDEND" ? (
                                            <div className="col">
                                              <label className="cr-label mb-2">
                                                Nav
                                              </label>
                                              <InputNumber
                                                // disabled={values.voucherDetailsDtos[index]
                                                //   .mode === "edit"}
                                                className="w-100 mb-2"
                                                name="nav"
                                                inputId="integeronly"
                                                minFractionDigits={2}
                                                value={
                                                  values.voucherDetailsDtos[
                                                    index
                                                  ].nav
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `voucherDetailsDtos[${index}].nav`,
                                                    e.value
                                                  );

                                                  isMutualBuy
                                                    ? setFieldValue(
                                                        `voucherDetailsDtos[${index}].amount`,
                                                        e.value
                                                          ? values
                                                              .voucherDetailsDtos[
                                                              index
                                                            ].withdrawl /
                                                              e.value
                                                          : 0
                                                      )
                                                    : setFieldValue(
                                                        `voucherDetailsDtos[${index}].amount`,
                                                        e.value
                                                          ? values
                                                              .voucherDetailsDtos[
                                                              index
                                                            ].deposit / e.value
                                                          : 0
                                                      );
                                                }}
                                                placeholder="Nav"
                                              />
                                              <ErrorMessage
                                                name={`voucherDetailsDtos[${index}].nav`}
                                              >
                                                {(msg) => (
                                                  <div style={{ color: "red" }}>
                                                    {msg}
                                                  </div>
                                                )}
                                              </ErrorMessage>
                                            </div>
                                          ) : null}

                                          {originId === Config.MUTUAL_FUND_ID &&
                                          values.voucherDetailsDtos[index]
                                            .type === "SELL" ? (
                                            <div className="col">
                                              <label className="cr-label mb-2">
                                                Charges
                                              </label>
                                              <InputNumber
                                                // disabled={values.voucherDetailsDtos[index]
                                                //   .mode === "edit"}
                                                className="w-100 mb-2"
                                                name="stockCharges"
                                                inputId="integeronly"
                                                minFractionDigits={2}
                                                value={
                                                  values.voucherDetailsDtos[
                                                    index
                                                  ].stockCharges
                                                }
                                                onChange={(e) =>
                                                  setFieldValue(
                                                    `voucherDetailsDtos[${index}].stockCharges`,
                                                    e.value
                                                  )
                                                }
                                                placeholder="Charges"
                                              />
                                              <ErrorMessage
                                                name={`voucherDetailsDtos[${index}].stockCharges`}
                                              >
                                                {(msg) => (
                                                  <div style={{ color: "red" }}>
                                                    {msg}
                                                  </div>
                                                )}
                                              </ErrorMessage>
                                            </div>
                                          ) : null}

                                          <div className="col">
                                            <label className="cr-label mb-2">
                                              Txn date
                                            </label>
                                            {/* <input
                                              name="txnDate"
                                              type="date"
                                              value={
                                                values.voucherDetailsDtos[index]
                                                  .txnDate
                                              }
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `voucherDetailsDtos[${index}].txnDate`,
                                                  e.target.value
                                                )
                                              }
                                            /> */}
                                            <Calendar
                                              dateFormat="dd/mm/yy"
                                              // disabled={editMode}
                                              name="txnDate"
                                              value={
                                                values.voucherDetailsDtos[index]
                                                  .txnDate
                                              }
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `voucherDetailsDtos[${index}].txnDate`,
                                                  e.value
                                                )
                                              }
                                              placeholder="Txn Date Ex-10/10/2010"
                                              className="w-100 mb-2"
                                            />
                                            <ErrorMessage
                                              name={`voucherDetailsDtos[${index}].txnDate`}
                                            >
                                              {(msg) => (
                                                <div style={{ color: "red" }}>
                                                  {msg}
                                                </div>
                                              )}
                                            </ErrorMessage>
                                          </div>

                                          <div className="col">
                                            <label className="cr-label mb-2">
                                              Narration
                                            </label>
                                            <InputText
                                              // disabled={editMode}
                                              className="w-100 mb-2"
                                              name="description"
                                              value={
                                                values.voucherDetailsDtos[index]
                                                  .description
                                              }
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `voucherDetailsDtos[${index}].description`,
                                                  e.target.value
                                                )
                                              }
                                              placeholder="Narration"
                                            />
                                            <ErrorMessage
                                              name={`voucherDetailsDtos[${index}].description`}
                                            >
                                              {(msg) => (
                                                <div style={{ color: "red" }}>
                                                  {msg}
                                                </div>
                                              )}
                                            </ErrorMessage>
                                          </div>

                                          <div className="col">
                                            <label className="cr-label mb-2">
                                              Deposit
                                            </label>
                                            <InputNumber
                                              className="w-100 mb-2"
                                              disabled={
                                                // editMode ||
                                                values.voucherDetailsDtos[index]
                                                  .withdrawl ||
                                                isDisbursement ||
                                                isStockBuy ||
                                                isStockSell ||
                                                isMutualBuy ||
                                                isEmi ||
                                                values.voucherDetailsDtos[index]
                                                  .type === "PAYMENT"
                                                  ? true
                                                  : false
                                              }
                                              name="deposit"
                                              inputId="integeronly"
                                              minFractionDigits={2}
                                              value={
                                                values.voucherDetailsDtos[index]
                                                  .deposit
                                              }
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `voucherDetailsDtos[${index}].deposit`,
                                                  e.value
                                                );

                                                isMutualSell &&
                                                  setFieldValue(
                                                    `voucherDetailsDtos[${index}].amount`,
                                                    e.value
                                                      ? e.value /
                                                          values
                                                            .voucherDetailsDtos[
                                                            index
                                                          ].nav
                                                      : 0
                                                  );
                                              }}
                                              placeholder="Deposit"
                                            />
                                          </div>

                                          <div className="col">
                                            <label className="cr-label mb-2">
                                              Withdrawl
                                            </label>
                                            <InputNumber
                                              className="w-100 mb-2"
                                              disabled={
                                                // editMode ||
                                                values.voucherDetailsDtos[index]
                                                  .deposit ||
                                                isDisbursement ||
                                                isStockBuy ||
                                                isStockSell ||
                                                isMutualSell ||
                                                isEmi ||
                                                values.voucherDetailsDtos[index]
                                                  .type === "RECEIPT"
                                                  ? true
                                                  : false
                                              }
                                              name="withdrawl"
                                              inputId="integeronly"
                                              minFractionDigits={2}
                                              value={
                                                values.voucherDetailsDtos[index]
                                                  .withdrawl
                                              }
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `voucherDetailsDtos[${index}].withdrawl`,
                                                  e.value
                                                );
                                                isMutualBuy &&
                                                  setFieldValue(
                                                    `voucherDetailsDtos[${index}].amount`,
                                                    e.value
                                                      ? e.value /
                                                          values
                                                            .voucherDetailsDtos[
                                                            index
                                                          ].nav
                                                      : 0
                                                  );
                                              }}
                                              placeholder="Withdrawl"
                                            />
                                          </div>
                                        </div>

                                        {values.voucherDetailsDtos.length >
                                        1 ? (
                                          <div
                                            className="cr-delBtn"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            <FiTrash2 />
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  );
                                })
                              : null}
                          </>
                        );
                      }}
                    />

                    <div className="d-flex align-items-center justify-content-between w-100">
                      <button type="submit" className="c-btn-1 c-btn-right">
                        <span>
                          <FiChevronsRight />
                        </span>
                        <strong>{editMode ? "Update" : "Save"}</strong>
                      </button>
                    </div>
                  </div>
                </Form>

                {isShowModal ? (
                  selectedAlias === "MUTUAL_FUNDS" ? (
                    <MFform
                      onClose={() => {
                        setSelectedIndex(undefined);
                        setIsShowModal(false);
                      }}
                      onAdd={(value) => {
                        onAddMf(value, setFieldValue);
                      }}
                      isLoading={isBtnLoading}
                    />
                  ) : selectedAlias === "STOCKS" ? (
                    <StockForm
                      onClose={() => {
                        setSelectedIndex(undefined);
                        setIsShowModal(false);
                      }}
                      onAdd={(value) => {
                        onAddStock(value, setFieldValue);
                      }}
                      isLoading={isBtnLoading}
                    />
                  ) : selectedAlias === "BONDS" ? (
                    <BondForm
                      onClose={() => {
                        setSelectedIndex(undefined);
                        setIsShowModal(false);
                      }}
                      onAdd={(value) => {
                        onAddBond(value, setFieldValue);
                      }}
                      isLoading={isBtnLoading}
                    />
                  ) : selectedAlias === "CREDIT_CARD" ? (
                    <CreditCardForm
                      onClose={() => {
                        setSelectedIndex(undefined);
                        setIsShowModal(false);
                      }}
                      onAdd={(value) => {
                        onAddCreditCard(value, setFieldValue);
                      }}
                      isLoading={isBtnLoading}
                    />
                  ) : selectedAlias === "CHECKING_CURRENT_ACCOUNT" ||
                    selectedAlias === "LOAN" ||
                    selectedAlias === "SAVINGS_ACCOUNT" ||
                    selectedAlias === "DEPOSIT_ACCOUNT" ? (
                    <BankAccountForm
                      onClose={() => {
                        setSelectedIndex(undefined);
                        setIsShowModal(false);
                      }}
                      onAdd={(value) => {
                        onAddBankAccount(value, setFieldValue);
                      }}
                      alias={selectedAlias}
                      isLoading={isBtnLoading}
                    />
                  ) : selectedAlias === "PROPERTY" ? (
                    <PropertyForm
                      onClose={() => {
                        setSelectedIndex(undefined);
                        setIsShowModal(false);
                      }}
                      onAdd={(value) => {
                        onAddProperty(value, setFieldValue);
                      }}
                      isLoading={isBtnLoading}
                    />
                  ) : selectedAlias === "ULIP" ||
                    selectedAlias === "TRADITIONAL" ||
                    selectedAlias === "TERM_LIFE_INSURANCE" ||
                    selectedAlias === "MEDICAL_INSURANCE" ||
                    selectedAlias === "LIFE_INSURANCE" ? (
                    <InsuranceForm
                      onClose={() => {
                        setSelectedIndex(undefined);
                        setIsShowModal(false);
                      }}
                      onAdd={(value) => {
                        onAddInsurance(value, setFieldValue);
                      }}
                      alias={selectedAlias}
                      isLoading={isBtnLoading}
                    />
                  ) : null
                ) : null}
              </div>
            );
          }}
        </Formik>
      </div>

      <Dialog
        draggable={false}
        visible={ledgerDialog}
        onHide={() => setLedgerDialog(false)}
        header={<h1>Add Account Ledger</h1>}
        style={{ width: "60%" }}
        headerClassName="p-3"
        contentClassName="p-3"
      >
        <Formik
          initialValues={{
            name: "",
            position: "",
            accounting: "",
            parentId: "",
          }}
          validationSchema={accountGroupchema}
          onSubmit={(values, { resetForm }) => {
            onAddAccountGroup(values);
            resetForm();
          }}
        >
          {({ handleChange, onSubmit, values, errors, touched }) => (
            <Form>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <InputComponent
                    className={"p-2"}
                    name={"name"}
                    placeholder={"Name"}
                    header={"Name"}
                  />
                </div>

                <div className="col-lg-6 col-md-12">
                  <DropdownComponent
                    name="accounting"
                    header={"Account Header"}
                    options={[
                      { value: "ASSET", label: "ASSET" },
                      { value: "LIABILITY", label: "LIABILITY" },
                      { value: "INCOME", label: "INCOME" },
                      { value: "EXPENSES", label: "EXPENSES" },
                    ]}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-lg-6 col-md-12">
                  <InputComponent
                    name="position"
                    className="p-2"
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    header={"Position"}
                    placeholder="Position (1,2,3 etc..)"
                  />
                </div>

                <div className="col-lg-6 col-md-12">
                  <DropdownComponent
                    options={allParentGroup}
                    placeholder="Select Parent Group"
                    optionLabel="name"
                    header={"Select Parent Group"}
                    optionValue="id"
                    name="parentId"
                    onChange={handleChange}
                  />
                </div>

                <div className="col-lg-12 mt-3 col-md-12">
                  <div className="d-flex c-btn-group">
                    <button
                      type="submit"
                      className="c-btn-1"
                      disabled={isLoading}
                    >
                      <strong>Submit</strong>
                      {isLoading ? (
                        <span className="rotate-infinite">
                          <FiRotateCw />
                        </span>
                      ) : (
                        <span>
                          <FiChevronsRight />
                        </span>
                      )}
                    </button>

                    <button type="reset" className="c-btn-1 c-del-btn">
                      <strong>Reset</strong>
                      <span>
                        <BiRevision />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>

      {isShowAccountLedger && (
        <AddAccountLedger
          // isShowAccountLedger={isShowAccountLedger}
          onClose={onCloseAccountLeger}
          handelAdd={onAddAccountLedger}
          isLoading={addingLoader}
          selectedGroup={selectedGroup}
          // allChildGroup={allChildGroup}
          selectedChildGroup={selectedChildGroup}
          // allParentGroup={allParentGroup}
          // onChangeParentGroup={onChangeParentGroup}
        />
      )}

      <UploaCsv
        isLoading={isCsvUploading}
        isUpload={isUpload}
        onHideUpload={onHideUpload}
        onUploading={onUploading}
      />

      {isOpenAccountForm && (
        <AddBankAccount
          selectedType={selectedType}
          close={handleCloseAdd}
          handelAdd={handelAdd}
          isLoading={isSubmiting}
        />
      )}
    </>
  );
};

export default AddUploadStatment;
