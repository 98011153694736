import React, { useEffect, useState } from "react";
import InsuranceServices from "../../../services/InsuranceService";
import { Form } from "react-bootstrap";

const PolicyNumberDropDown = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const [policyNumber, setPolicyNumber] = useState([]);
  const insuranceService = new InsuranceServices();

  useEffect(() => {
    policyData();
  }, []);

  const policyData = () => {
    insuranceService.getAllInsurance().then((res) => {
      setPolicyNumber(res);
    });
  };

  // if (policyNumber && policyNumber.length > 0) {
  return (
    <div>
      <Form.Select className="form-control" {...field} {...props}>
        <option value="null">Select Policy Number</option>
        {policyNumber?.map((item) => (
          <option key={item.id} value={item.id}>
            {item.policyNo}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};
// };

export default PolicyNumberDropDown;
