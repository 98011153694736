import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useRef, useState } from "react";
import { BiRevision } from "react-icons/bi";
import { FiChevronRight, FiPlus, FiRotateCw } from "react-icons/fi";
import { toast } from "react-toastify";
import * as Yup from "yup";
import BankingServices from "../../../services/BankingServices";
import { RelationshipService } from "../../../services/RelationshipService";
import AccountDropDown from "../../components/AccountDropDown";
import BankDropDown from "../../components/BankDropDown";
import IndividualRelationShipDropDown from "../../components/IndividualRelationShipDropDown";
import RelationshipAdd from "../BankRelationManagement/RelationshipAdd";
import LoanDropDown from "./loanComponent/LoanDropDown";
import DepositDropDown from "./depositComponent/DepositDropDown";
import BankAdd from "../bankmanagement/BankAdd";

const EditBankAccount = ({ data, close, handelEdit, isLoading }) => {
  const editBankAccountSchema = Yup.object().shape({
    bankId: Yup.string().required("Bank Name can't be empty"),
    relationshipId: Yup.string().required("Relationship Number can't be empty"),
    accountNo: Yup.number().required("Account Number can't be empty"),
    type: Yup.string().required("Account Type can't be empty"),
    openingBalance: Yup.number().required("Opening Balance can't be empty"),
    openingBalanceDate: Yup.string().required(
      "Opening Balance Date can't be empty"
    ),
  });

  const bankingService = new BankingServices();
  const relationshipService = new RelationshipService();

  const [selectedBankId, setSelectedBankId] = useState("");
  const [add, setAdd] = useState(false);
  const [addRelationship, setAddRelationship] = useState(false);
  const [isRelationshipLoading, setRelationshipLoading] = useState(false);
  const [isBankLoading, setisBankLoading] = useState(false);

  const formikRef = useRef();

  const handelBankAdd = (value) => {
    setisBankLoading(true);
    bankingService
      .addBank(value)
      .then((res) => {
        if (res) {
          toast.success("Bank Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setisBankLoading(false);
          setAdd(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisBankLoading(false);
      });
  };

  const handleCloseAdd = () => {
    setAdd(false);
    setisBankLoading(false);
  };

  const handleCloseRelationshipAdd = () => {
    setAddRelationship(false);
    setRelationshipLoading(false);
  };

  const handelRelationshipAdd = (value) => {
    setRelationshipLoading(true);
    relationshipService
      .addRelation(value)
      .then((res) => {
        if (res) {
          toast.success("Relationship Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setRelationshipLoading(false);
          setAddRelationship(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setRelationshipLoading(false);
      });
  };

  return (
    <Dialog
      draggable={false}
      visible={true}
      onHide={() => close()}
      header={<h1>Edit Account</h1>}
      style={{ width: "80%" }}
    >
      <section className="c-content-in">
        <Formik
          initialValues={{
            ...data,
            openingBalanceDate: data.openingBalanceDate
              ? new Date(data.openingBalanceDate)
              : null,
            emiStartDate: data.emiStartDate
              ? new Date(data.emiStartDate)
              : null,
            depositDate: data.depositDate ? new Date(data.depositDate) : null,
          }}
          innerRef={formikRef}
          validationSchema={editBankAccountSchema}
          onSubmit={(values, { resetForm }) => {
            let reqData = { ...values };
            reqData.depositDate = values.depositDate
              ? moment(values.depositDate).format("YYYY-MM-DD")
              : null;
            reqData.emiStartDate = values.emiStartDate
              ? moment(values.emiStartDate).format("YYYY-MM-DD")
              : null;
            reqData.openingBalanceDate = values.openingBalanceDate
              ? moment(values.openingBalanceDate).format("YYYY-MM-DD")
              : null;

            handelEdit(reqData);
            resetForm();
          }}
          enableReinitialize
        >
          {({ handleChange, onSubmit, values, errors, touched }) => {
            return (
              <Form>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group d-flex justify-content-between align-items-center">
                      <div style={{ width: "85%" }}>
                        {!add && (
                          <Field
                            name="bankId"
                            className="form-control"
                            component={BankDropDown}
                          />
                        )}
                        {errors.bankId && touched.bankId ? (
                          <div className="text-danger">{errors.bankId}</div>
                        ) : null}
                      </div>

                      <button
                        type="button"
                        className="btn btn-primary cr-BtnCus"
                        style={{ borderRadius: "50%" }}
                        onClick={() => setAdd(true)}
                      >
                        <span>
                          <FiPlus />
                        </span>
                      </button>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="">
                      <div className="form-group d-flex justify-content-between align-items-center">
                        <div style={{ width: "85%" }}>
                          <Field
                            name="relationshipId"
                            className="form-control"
                            component={
                              !isRelationshipLoading &&
                              IndividualRelationShipDropDown
                            }
                            bankId={values.bankId}
                          />
                          {errors.relationshipId && touched.relationshipId ? (
                            <div className="text-danger">
                              {errors.relationshipId}
                            </div>
                          ) : null}
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary cr-BtnCus"
                          style={{ borderRadius: "50%" }}
                          onClick={() => {
                            setSelectedBankId(
                              formikRef && formikRef.current.values["bankId"]
                            );
                            setAddRelationship(true);
                          }}
                        >
                          <span>
                            <FiPlus />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <Field
                      name="bankId"
                      className="form-control"
                      component={BankDropDown}
                    />
                    {errors.bankId && touched.bankId ? (
                      <div className="text-danger">{errors.bankId}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <Field
                      name="relationshipId"
                      className="form-control"
                      component={RelationshipDropDown}
                    />
                    {errors.bankId && touched.bankId ? (
                      <div className="text-danger">{errors.bankId}</div>
                    ) : null}
                  </div>
                </div>
              </div> */}

                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <Field
                        name="accountNo"
                        type="text"
                        className="form-control"
                        placeholder="Account Number"
                      />
                      {errors.accountNo && touched.accountNo ? (
                        <div className="text-danger">{errors.accountNo}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <select
                        value={values.type}
                        onChange={handleChange}
                        name="type"
                        className="form-control"
                        aria-label="Default select example"
                      >
                        <option>Select Account Type</option>
                        <option value="CHECKING_CURRENT_ACCOUNT">
                          Current
                        </option>
                        <option value="SAVINGS_ACCOUNT">Savings</option>
                        <option value="DEPOSIT_ACCOUNT">Deposit</option>
                        {/* <option value="INVESTMENT">Iumulative</option> */}
                        <option value="LOAN">Loan</option>
                        {/* <option value="CREDIT_CARD">Credit Card</option> */}
                      </select>
                      {errors.type && touched.type ? (
                        <div className="text-danger">{errors.type}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <Field
                        name="parentAccountId"
                        className="form-control"
                        component={AccountDropDown}
                      />
                      {errors.parentAccountId && touched.parentAccountId ? (
                        <div className="text-danger">
                          {errors.parentAccountId}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <Field
                        name="accountDesc"
                        as="textarea"
                        className="form-control"
                        placeholder="Account Description"
                      />
                      {errors.parentAccountId && touched.parentAccountId ? (
                        <div className="text-danger">
                          {errors.parentAccountId}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <Field
                        name="openingBalance"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        className="form-control"
                        placeholder="Opening Balance"
                        // value={values.openingBalance}
                        // onChange={(e) =>
                        //   setFieldValue("openingBalance", e.target.value)
                        // }
                      />
                      {errors.openingBalance && touched.openingBalance ? (
                        <div className="text-danger">
                          {errors.openingBalance}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <Calendar
                        name="openingBalanceDate"
                        className="w-100 mb-2 form-group c-calender-w"
                        value={values.openingBalanceDate}
                        placeholder="Opening Balance Date"
                        onChange={handleChange}
                        dateFormat="dd/mm/yy"
                        showIcon
                        readOnlyInput
                      />
                      {errors.openingBalanceDate &&
                      touched.openingBalanceDate ? (
                        <div className="text-danger">
                          {errors.openingBalanceDate}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {values.type === "LOAN" && (
                  <>
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            name="loanType"
                            placeholder="Select Type Of Loan"
                            className="form-control"
                            component={LoanDropDown}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            name="emiAmount"
                            placeholder="EMI Amount"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          {/* <Field
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          {/* <Field
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          {/* <Field
                          name="emiStartDate"
                          placeholder=" Select Date"
                          className="form-control"
                          type="date"
                        /> */}
                          <Calendar
                            name="emiStartDate"
                            className="w-100 mb-2 form-group c-calender-w"
                            value={values.emiStartDate}
                            placeholder="Emi start Date"
                            onChange={handleChange}
                            dateFormat="dd/mm/yy"
                            showIcon
                            readOnlyInput
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            name="tenureMonths"
                            placeholder="Type Month"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            name="debitAccount"
                            placeholder="Account To Be Debited"
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {values.type === "DEPOSIT_ACCOUNT" && (
                  <>
                    {/* <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <Field
                          name=" depositDate"
                          placeholder=" Select DepositDate"
                          className="form-control"
                          type="date"
                        />
                        <Calendar
                          name="depositDate"
                          className="w-100 mb-2 form-group c-calender-w"
                          value={values.depositDate}
                          onChange={handleChange}
                          showIcon
                          dateFormat="dd/mm/yy"
                          placeholder="dd/mm/yyyy"
                          readOnlyInput
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <Field
                          name=" depositAmount"
                          placeholder="Type Deposit Amount"
                          className="form-control"
                          type="text"
                        />
                      </div>
                    </div>
                  </div> */}
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            name="interestRate"
                            placeholder="Type Interest Rate"
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            name="interestRate"
                            placeholder="Type Interest Rate"
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            name="tenureMonths"
                            placeholder="Period"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            name="debitAccount"
                            placeholder="Account To Be Debited"
                            className="form-control"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <Field
                            name="debitAccount"
                            placeholder=" Interest Payment Frequency"
                            className="form-control"
                            component={DepositDropDown}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {values.type === "CREDIT_CARD" && (
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <Field
                          name="creditLimit"
                          placeholder=" Credit Limit"
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="d-flex c-btn-group">
                      <button
                        type="submit"
                        className="c-btn-1"
                        disabled={isLoading}
                      >
                        <strong>Update</strong>
                        {isLoading ? (
                          <span className="rotate-infinite">
                            <FiRotateCw />
                          </span>
                        ) : (
                          <span>
                            <FiChevronRight />
                          </span>
                        )}
                      </button>

                      <button
                        type="reset"
                        className="c-btn-1 c-del-btn"
                        onClick={() => close()}
                      >
                        <strong>Cancel</strong>
                        <span>
                          <BiRevision />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </section>

      {add ? (
        <BankAdd
          handelAdd={handelBankAdd}
          close={handleCloseAdd}
          isLoading={isBankLoading}
        />
      ) : null}

      {addRelationship ? (
        <RelationshipAdd
          handelAdd={handelRelationshipAdd}
          selectedBankId={selectedBankId}
          close={handleCloseRelationshipAdd}
          isLoading={isRelationshipLoading}
        />
      ) : null}
    </Dialog>
  );
};

export default EditBankAccount;
