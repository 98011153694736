import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { FormLabel } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import { toast } from "react-toastify";
import * as Yup from "yup";
import PropertyService from "../../services/PropertyService";
import { getCurrencyFormat } from "../../shared/UtilsFunction";

const SellProperty = ({ close, propertyFilterData }) => {
  const propertyService = new PropertyService(); //----------For Suevice---------------//
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [sellPropertyList, setSellPropertyList] = useState([]);

  const propertySellSchema = Yup.object().shape({
    sellDate: Yup.string().required("Sell Date  can't be empty"),
    sellPrice: Yup.number().required("Sell Price  can't be empty"),
    totalValueSold: Yup.number().required("Total Value Sold can't be empty"),
  });

  useEffect(() => {
    getSellListProperties();
  }, []);

  //For using Update Endpoint/////

  // const getUpdatePropertyData = (values) => {
  //   propertyService.updateProperty(values).then((res) => {
  //     setUpdateData(res);
  //     close();
  //   });
  // };

  const getUpdatePropertyData = (values) => {
    let reqData = { ...values };
    reqData.sellDate = values.sellDate
      ? moment(values.sellDate).format("YYYY-MM-DD")
      : null;
    setLoading(true);
    propertyService
      .updateProperty(reqData)
      .then((res) => {
        setUpdateData(res);
        toast.success("Sell Property Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        close();
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  /////////For Listing///////
  const getSellListProperties = () => {
    propertyService.getAllProperty().then((res) => {
      setSellPropertyList(res);
    });
  };
  return (
    <div>
      <Dialog
        draggable={false}
        visible={true}
        onHide={() => close()}
        header={<h1>Sell Property</h1>}
        style={{ width: "80%" }}
      >
        <section className="c-content-in">
          <Formik
            initialValues={{
              id: propertyFilterData.id,
              countryId: propertyFilterData.countryId,
              productId: propertyFilterData.productId,
              address: propertyFilterData.address,
              fundType: propertyFilterData.fundType,
              funded: propertyFilterData.funded,
              totalValueFunded: propertyFilterData.totalValueFunded,
              loanAccountNumber: propertyFilterData.loanAccountNumber,
              purchaseDate: propertyFilterData.purchaseDate,
              purchasePrice: propertyFilterData.purchasePrice,
              stampDutyCost: propertyFilterData.stampDutyCost,
              registrationCost: propertyFilterData.registrationCost,
              otherCost: propertyFilterData.otherCost,
              sellDate: propertyFilterData.sellDate
                ? new Date(propertyFilterData.sellDate)
                : null,
              sellPrice: propertyFilterData.sellPrice,
              totalValueSold: propertyFilterData.totalValueSold,
            }}
            validationSchema={propertySellSchema}
            onSubmit={getUpdatePropertyData}
          >
            {({ handleChange, values, errors, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <FormLabel>Total Initial Cost</FormLabel>
                      <Field
                        name="totalValueSold"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Total Initial Cost"
                      />
                      {errors.totalValueSold && touched.totalValueSold ? (
                        <div className="text-danger">
                          {errors.totalValueSold}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <FormLabel>Sell Date</FormLabel>
                      {/* <Field
                        name="sellDate"
                        className="form-control"
                        type="date"
                        placeholder="Sell Date"
                      /> */}
                      <Calendar
                        name="sellDate"
                        className="w-100 mb-2 form-group c-calender-w"
                        value={values.sellDate}
                        onChange={handleChange}
                        showIcon
                        dateFormat="dd/mm/yy"
                        placeholder="dd/mm/yyyy"
                        readOnlyInput
                      />
                      {errors.sellDate && touched.sellDate ? (
                        <div className="text-danger">{errors.sellDate}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <FormLabel>Sell Price</FormLabel>
                      <Field
                        name="sellPrice"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Sell Price"
                      />
                      {errors.sellPrice && touched.sellPrice ? (
                        <div className="text-danger">{errors.sellPrice}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="d-flex c-btn-group">
                      <button
                        type="submit"
                        className="c-btn-1"
                        disabled={loading}
                      >
                        <strong>Submit</strong>
                        {loading ? (
                          <span className="rotate-infinite">
                            <FiRotateCw />
                          </span>
                        ) : (
                          <span>
                            <FiChevronRight />
                          </span>
                        )}
                      </button>

                      <button
                        type="button"
                        onClick={() => close()}
                        className="c-btn-1 c-del-btn"
                      >
                        <strong>Cancel</strong>
                        <span>
                          <BiRevision />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </section>
        <div className="table-responsive">
          <div className=" mb-2 p-4">
            <h2 className="mb-2">Sell Property List</h2>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Sell Price</th>
                  <th>Sell Date</th>
                  <th>Total Value Sold</th>
                </tr>
              </thead>
              <tbody>
                {sellPropertyList.length > 0
                  ? sellPropertyList.map((item, index) => {
                      return (
                        <tr key={`expensesList-${index}`}>
                          <td>{index + 1}</td>
                          <td>{getCurrencyFormat(item.sellPrice)}</td>
                          <td>
                            {moment(item.sellDate).format("Do MMM, YYYY")}
                          </td>

                          <td>{getCurrencyFormat(item.totalValueSold)}</td>

                          {/* <td>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                              }>
                              <button
                                // onClick={() => getIdforUpdate(item.id)}
                                type="button"
                                className="c-edit-btn">
                                {" "}
                                <BiEdit />{" "}
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                              }>
                              <button
                                // onClick={() => deleteItem(item.id)}
                                type="button"
                                className="c-edit-btn">
                                {" "}
                                <BsTrash />{" "}
                              </button>
                            </OverlayTrigger>
                          </td> */}
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SellProperty;
