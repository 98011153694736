import { Form } from "react-bootstrap";

const PaymentFreqDropDown = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  return (
    <div>
      <Form.Select className="form-control" {...field} {...props}>
        <option value="null">Select Payment Freq</option>
        <option value="monthly">Monthly</option>
        <option value="quarterly">Quarterly</option>
        <option value="six monthly">Six Monthly</option>
        <option value="yearly">Yearly</option>
        <option value="cumulative">Cumulative</option>
      </Form.Select>
    </div>
  );
};

export default PaymentFreqDropDown;
