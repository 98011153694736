import { Field, Form, Formik } from "formik";
import { Dialog } from "primereact/dialog";
import React from "react";
import { FormLabel } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import * as Yup from "yup";
import BankDropDown from "../../components/BankDropDown";

const RelationshipEdit = ({ bankFilterData, close, handelEdit, isLoading }) => {
  const bankEditSchema = Yup.object().shape({
    relationshipNo: Yup.string().required("This field is required"),
    bankId: Yup.string().required("This field is required"),
  });

  return (
    <Dialog
      draggable={false}
      header={<h1>Edit Relationship</h1>}
      visible={true}
      onHide={() => close()}
      style={{ width: "80%" }}
    >
      <section className="c-content-in">
        <Formik
          initialValues={{
            id: bankFilterData.id,
            relationshipNo: bankFilterData.relationshipNo,
            bankId: bankFilterData.bankId,
          }}
          validationSchema={bankEditSchema}
          onSubmit={(values, { resetForm }) => {
            handelEdit(values);
            resetForm();
          }}
          enableReinitialize
        >
          {({ handleChange, onSubmit, values, errors, touched }) => (
            <Form>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Relationship No</FormLabel>

                    <Field
                      name="relationshipNo"
                      type="text"
                      className="form-control"
                      placeholder="Relation ship No"
                    />

                    {errors.relationshipNo && touched.relationshipNo ? (
                      <div className="text-danger">{errors.relationshipNo}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Select Bank</FormLabel>
                    <Field
                      name="bankId"
                      placeholder="Select Bank"
                      component={BankDropDown}
                    />

                    {errors.bankId && touched.bankId ? (
                      <div className="text-danger">{errors.bankId}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="d-flex c-btn-group">
                        <button
                          type="submit"
                          className="c-btn-1"
                          disabled={isLoading}
                        >
                          <strong>Update</strong>
                          {isLoading ? (
                            <span className="rotate-infinite">
                              <FiRotateCw />
                            </span>
                          ) : (
                            <span>
                              <FiChevronRight />
                            </span>
                          )}
                        </button>

                        <button
                          onClick={() => close()}
                          type="close"
                          className="c-btn-1 c-del-btn"
                        >
                          <strong>Cancel</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </Dialog>
  );
};

export default RelationshipEdit;
