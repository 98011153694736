import React, { useEffect, useState } from "react";
import MutualFundsService from "../../services/MutualFundsService";
import { Form } from "react-bootstrap";

const MutualFundTypeDropDown = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const [mutualFund, setMutualFund] = useState([]);
  const mutualFundService = new MutualFundsService();

  useEffect(() => {
    mutualFundService.getAllFundList().then((res) => {
      setMutualFund(res);
    });
  }, [props.addedMf]);

  return (
    <div>
      <Form.Select className="form-control" {...field} {...props}>
        <option value="null">Select Mutual Fund</option>
        {mutualFund.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export default MutualFundTypeDropDown;
