import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class CreditCardService {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  //Create Credit Card:
  createCreditCard = async (payload) => {
    try {
      const response = await axios.post(
        baseUrl + "/credit-card/create",
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  //////////listing for credit card/////

  getCreditCardList = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/credit-card/search`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getBankList = async () => {
    try {
      const response = await axios.get(`${baseUrl}/credit-card/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  // For Product list:

  getProductList = async () => {
    try {
      const response = await axios.get(`${baseUrl}/product/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  // For Update Credit Card Details:

  updateCreditCard = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/credit-card/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  // for one data fatch:
  getUpdateId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/credit-card/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  searchCreditCard = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/credit-card/search`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Delete////////
  delete = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/credit-card/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
