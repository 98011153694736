import React, { useEffect, useRef, useState } from "react";
import PropertyService from "../../services/PropertyService";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import { FiFilter, FiPlus } from "react-icons/fi";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getCurrencyFormat } from "../../shared/UtilsFunction";
import { ImFileText2 } from "react-icons/im";
import moment from "moment";
import { BiEdit, BiRevision, BiSearchAlt } from "react-icons/bi";
import { BsArrowDown, BsArrowUp, BsTrash } from "react-icons/bs";
import { IoPricetagSharp } from "react-icons/io5";
import PropertyExpensesModal from "./PropertyExpensesModal";
import EditProperty from "./EditProperty";
import { Field, Form, Formik } from "formik";
import CountryDropDown from "../components/CountryDropDown";
import ProductDropDown from "../components/ProductDropDown";
import { confirmAlert } from "react-confirm-alert";
import { TiArrowMoveOutline, TiThList } from "react-icons/ti";
import SellProperty from "./SellProperty";
import Paginator from "../components/Paginator";
import PageLoader from "../components/PageLoader";
import ProductLedger from "../components/ProductLedger";
import { Calendar } from "primereact/calendar";

const ListProperty = () => {
  const propertyService = new PropertyService(); //----------For Suevice---------------//
  const [loading, setLoading] = useState(false);

  const [preportyList, setPreportyList] = useState([]); //----------For Property List-----//
  const toastTL = useRef(null); //-----------For Toster Msg----------------//
  const [expensesModal, setExpensesModal] = useState(false); //----------For Expenses Modal-----//
  const [expensesRowId, setExpensesRowId] = useState([]); //----------For Property Row ID--------------//
  const [editPropertyModal, setEditPropertyModal] = useState(false); //----------For Property Edit Modal--------//
  const [updateID, setupdateID] = useState([]); //--------For Fatching One Data--------//
  const [editID, setEditID] = useState([]); //--------For edit id---------//
  const [searchBar, setSearchBar] = useState(false); //--------For SearchBar---------//
  const [sellPropertyModal, setSellPropertyModal] = useState(false); //---------For SellPropertyModal---------//
  const [pageNo, setpageNo] = useState(1); //--------for pagenation-------//
  const [pageSize, setpageSize] = useState(10); //-----------for pagenation------//
  const { pathname } = useLocation();
  const [access, setAccess] = useState(null); //--------
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const [isLoading, setisLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState(false);
  const [fieldNmae, setFieldName] = useState(undefined);
  const [showLedger, setShowLedger] = useState(false);
  const [rowId, setRowId] = useState(undefined);

  useEffect(() => {
    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
    }
  }, []);

  /////////For Listing///////

  useEffect(() => {
    if (menuList !== null) {
      let payload = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (typeof fieldNmae !== "undefined") {
        payload.sorts = [`${fieldNmae},${order ? "asc" : "desc"}`];
      }
      getListProperties(payload);
    }
  }, [pageNo, pageSize, order]);

  /////////For Listing///////
  const getListProperties = (payload) => {
    propertyService
      .getPropertyList(payload)
      .then((res) => {
        setPreportyList(res);
        setLoader(true);
      })

      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoader(false);
      });
  };

  /////////for pagenation////////////
  const increment = () => {
    setpageNo(pageNo + 1);
  };

  const decrement = () => {
    setpageNo(pageNo - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * pageNo) / e.target.value);
    setpageSize(e.target.value);
    setpageNo(temp);
  };

  //////Property Expenses Row Id///////
  const rowIdExpenses = (id) => {
    propertyService.getExpenses(id).then((res) => {
      setExpensesRowId(res);
    });
  };

  //////////For Edit Property/////////

  const getIdforUpdate = (id) => {
    propertyService.getUpdateWithId(id).then((res) => {
      setupdateID(res);
    });

    let filterID = preportyList.data.filter((item) => item.id === id);

    setEditID(filterID[0]);
  };

  ///////////Delete Property/////////
  const deleteItem = (id) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            propertyService
              .deleteProperty(id)
              .then((res) => {
                toast.success(res, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              })
              .finally(() => getListProperties(payload));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const reLoad = () => {
    window.location.reload();
  };

  const handelEdit = (value) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    setisLoading(true);
    propertyService
      .updateProperty(value)
      .then((res) => {
        if (res) {
          toast.success("Property Updated Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getListProperties(payload);
          setisLoading(false);
          setEditPropertyModal(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      });
  };

  ////////For Search Properties/////////
  const handleSearch = (data) => {
    let request = {
      pageNo: 1,
      pageSize: pageSize,
      sorts: [],
    };

    if (data.countryId) {
      request.countryId = data.countryId;
    }

    if (data.productId) {
      request.productId = data.productId;
    }

    if (typeof data.purchaseDate !== "undefined") {
      request.purchaseDate = data.purchaseDate;
    }

    if (typeof data.purchasePrice !== "undefined") {
      request.purchasePrice = data.purchasePrice;
    }

    if (typeof data.totalValueSold !== "undefined") {
      request.totalValueSold = data.totalValueSold;
    }

    propertyService.searchProperty(request).then((res) => {
      setPreportyList(res);
    });
  };

  ////////Expenses Modal////////

  const handleClickOpen = () => {
    setExpensesModal(true);
  };

  const handleClickClose = () => {
    setExpensesModal(false);
  };

  ////////Edit Property Modal//////
  const handleClickOpenEdit = () => {
    setEditPropertyModal(true);
  };

  const handleClickCloseEdit = () => {
    setEditPropertyModal(false);
    setisLoading(false);
  };
  ///////////sell Property Modal////
  const handleClickOpenSell = () => {
    setSellPropertyModal(true);
  };
  const handleClickCloseSell = () => {
    setSellPropertyModal(false);
  };

  const onSort = (fieldName, value) => {
    setFieldName(fieldName);
    setOrder(value);
  };

  const onShowTransaction = (id) => {
    setRowId(id);
    setShowLedger(true);
  };

  const onCloseLedger = () => {
    setShowLedger(false);
  };

  return access ? (
    <div className="table-responsive">
      <ToastContainer />
      {/* {!preportyList.data && <PageLoader />} */}
      {!loader && <PageLoader />}
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h1>Property List</h1>
        <div className="d-flex align-items-center add-searchBtn">
          <Link to="/property-add">
            {(access.isWrite || access.isDelete) && (
              <button type="button" className="c-btn-1 ">
                <span>
                  <FiPlus />
                </span>
                <strong>Property</strong>
              </button>
            )}
          </Link>
          <button
            type="button"
            className="c-btn-1 ms-2"
            onClick={() => setSearchBar(!searchBar)}
          >
            <span>
              <BiSearchAlt />
            </span>
            <strong>Search</strong>
          </button>
        </div>
      </div>
      <div className="cn-bank-search-w">
        <Collapse in={searchBar}>
          <div className="cn-bank-search-form">
            <h1>Search</h1>
            <Formik
              initialValues={{
                countryId: "",
                productId: "",
                purchaseDate: "",
                purchasePrice: "",
                totalValueSold: "",
              }}
              onSubmit={(values) => {
                let reqData = { ...values };
                reqData.purchaseDate = values.purchaseDate
                  ? moment(values.purchaseDate).format("YYYY-MM-DD")
                  : null;
                handleSearch(reqData);
              }}
            >
              {({ handleChange, onSubmit, values, errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="countryId"
                          placeholder="Select Country"
                          component={CountryDropDown}
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <Field
                          name="productId"
                          className="form-control"
                          component={ProductDropDown}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        {/* <Field
                          name="purchaseDate"
                          className="form-control"
                          type="date"
                        /> */}
                        <Calendar
                          name="purchaseDate"
                          className="w-100 mb-2 form-group c-calender-w"
                          value={values.purchaseDate}
                          onChange={handleChange}
                          showIcon
                          dateFormat="dd/mm/yy"
                          placeholder="dd/mm/yyyy"
                          readOnlyInput
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <Field
                          name="purchasePrice"
                          className="form-control"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Purchase Price"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <Field
                          name="totalValueSold"
                          className="form-control"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Total Value"
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 m-auto">
                      <div className="form-group d-flex justify-content-end">
                        <button type="submit" className="c-btn-1 ms-2">
                          <strong>SEARCH</strong>{" "}
                        </button>

                        <button
                          type="reset"
                          className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                        >
                          <strong>Reset</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                        <button
                          // type="reset"
                          onClick={() => reLoad()}
                          className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                        >
                          <strong>Reload</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Collapse>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th onClick={() => onSort("Name", !order)}>
              Name <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("productId", !order)}>
              Product <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("countryId", !order)}>
              Country
              <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>

            <th onClick={() => onSort("purchaseDate", !order)}>
              Purchase Date
              <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("purchasePrice", !order)}>
              Purchase Price
              <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("totalValueSold", !order)}>
              Total Cost
              <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            {(access.isWrite || access.isDelete) && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {preportyList.data && preportyList.data.length > 0
            ? preportyList.data.map((item, index) => {
                return (
                  <tr key={`preportyList-${index}`}>
                    <td>{pageSize * pageNo - pageSize + 1 + index}</td>
                    <td>{item.name}</td>
                    <td>{item.productName}</td>
                    <td>{item.countryName}</td>

                    <td>{moment(item.purchaseDate).format("Do MMM, YYYY")}</td>
                    <td>{getCurrencyFormat(item.purchasePrice)}</td>
                    <td>{getCurrencyFormat(item.totalValueSold)}</td>
                    {(access.isWrite || access.isDelete) && (
                      <td>
                        {access.isWrite ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                            }
                          >
                            <button
                              onClick={() => getIdforUpdate(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <BiEdit onClick={handleClickOpenEdit} />{" "}
                            </button>
                          </OverlayTrigger>
                        ) : null}
                        {access.isDelete ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                            }
                          >
                            <button
                              onClick={() => deleteItem(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <BsTrash />{" "}
                            </button>
                          </OverlayTrigger>
                        ) : null}
                        {/* <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              Property Expenses
                            </Tooltip>
                          }
                        >
                          <button
                            onClick={() => rowIdExpenses(item.id)}
                            type="button"
                            className="c-edit-btn"
                          >
                            {" "}
                            <IoPricetagSharp onClick={handleClickOpen} />{" "}
                          </button>
                        </OverlayTrigger> */}

                        {/* <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              Sell Preperty
                            </Tooltip>
                          }
                        >
                          <button
                            onClick={() => getIdforUpdate(item.id)}
                            type="button"
                            className="c-edit-btn"
                          >
                            {" "}
                            <TiArrowMoveOutline
                              onClick={handleClickOpenSell}
                            />{" "}
                          </button>
                        </OverlayTrigger> */}

                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              Transaction View
                            </Tooltip>
                          }
                        >
                          <button
                            onClick={() => onShowTransaction(item.id)}
                            type="button"
                            className="c-edit-btn"
                          >
                            <TiThList />
                          </button>
                        </OverlayTrigger>
                      </td>
                    )}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>

      <Paginator
        pageSize={preportyList.pageSize}
        firstPage={preportyList.firstPage}
        lastPage={preportyList.lastPage}
        decrement={decrement}
        increment={increment}
        pagesizechange={pagesizechange}
        pageNo={preportyList.pageNo}
        totalPages={preportyList.totalPages}
      />

      {sellPropertyModal ? (
        <SellProperty
          propertyFilterData={editID}
          close={handleClickCloseSell}
        />
      ) : null}
      {expensesModal ? (
        <PropertyExpensesModal close={handleClickClose} />
      ) : null}
      {editPropertyModal ? (
        <EditProperty
          propertyFilterData={editID}
          close={handleClickCloseEdit}
          handelEdit={handelEdit}
          isLoading={isLoading}
        />
      ) : null}

      {showLedger && (
        <ProductLedger
          onClose={onCloseLedger}
          rowId={rowId}
          defaultValue={"PROPERTY"}
        />
      )}
    </div>
  ) : null;
};

export default ListProperty;
