import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class MutualFundsService {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }
  ////////@MutualFunds & MutualFunds Type Are Defferent Api Call/////////

  ////////////Create MutualFund ///////////

  createMutualfund = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/mutual-fund/create`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  //////MutualFunds Listing//////////

  getFundsList = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/mutual-fund/search`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////For Mutual Fund Search //////
  searchFund = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/mutual-fund/search`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////One Data Fatch With ID For MutualFunds////////

  getUpdateFundWithId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/mutual-fund/${id}`, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Update  For MutualFunds////////

  updateFund = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/mutual-fund/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////Create MeutualFunds Type/////////

  addFundsType = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/mutual-fund-type/create`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////One Data Fatch With ID For MutualFunds Type////////

  getUpdateWithId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/mutual-fund-type/${id}`, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Update Data For MutualFunds Type////////

  updateFundType = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/mutual-fund-type/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////All MutualFunds Type////////

  getAllFundList = async () => {
    try {
      const response = await axios.get(`${baseUrl}/mutual-fund-type/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////////Create MutualFund Value Update///////////

  addValueUpdate = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/mutual-fund-value/create`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Mutual Fund value Update For Row ID API//////////
  getValueUpdateFund = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/mutual-fund-value/all-fund-Value/${id}`,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  //////////Single Data Fatch For Mutual Fund Value/////////
  getFandValueDetails = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/mutual-fund-value/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Update MutualFund Value/////////

  updateFundValue = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/mutual-fund-value/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////MutualFund Value All/////

  getAllFund = async () => {
    try {
      const response = await axios.get(`${baseUrl}/mutual-fund/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Create MutualFund Redemption/////////

  createMutualRedemption = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/mutual-fund-redemption/create`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Mutual Fund Redemption For Row ID API//////////
  getfundRedemption = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/mutual-fund-redemption/all-fund-redemption/${id}`,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Single Data Fatch For Mutual Fund Redemption/////////
  getFandRedemptionDetails = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/mutual-fund-redemption/${id}`,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Update MutualFund Redemption/////////

  updateFundRedemption = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/mutual-fund-redemption/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Delete Fund////////
  deleteFund = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/mutual-fund/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  deleteFundValue = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/mutual-fund-value/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  deleteFundRede = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/mutual-fund-redemption/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  deleteFundType = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/mutual-fund-type/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
