import React, { useState } from "react";
import logoImg from "../../src/assets/images/login-bg-1.jpg";
import { Field, Form, Formik } from "formik";
import Password_icon from "../../src/assets/images/Password.png";
import { FiChevronRight, FiChevronsRight, FiRotateCw } from "react-icons/fi";
import AuthService from "../services/AuthService";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

const ChangePassword = () => {
  const authService = new AuthService();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const resetPasswordSchema = Yup.object().shape({
    // newPassword: Yup.string().required("New Password can't be empty."),
    newPassword: Yup.string()
      .required("No password provided,Field can't be empty.")
      .min(
        8,
        "Password should be minimum 8 chars with at least 1 upper, 1 lower, 1 number and 1 special character(@$!%*?)"
      )
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        "Password can only contain Latin letters"
      ),

    confirmPassword: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      "Passwords must be match"
    ),
  });

  const handelRestPassword = (values) => {
    setLoading(true);
    authService
      .resetPassword(values)
      .then((res) => {
        toast.success(res.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/login");
        }, 3000);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };
  return (
    <section className="c-login-w">
      <ToastContainer />
      <div className="c-leftBox-w">
        <img className="img-fluid" src={logoImg} alt="logoImg" />
      </div>
      <div className="c-rightBox-w">
        <div className="c-rightBox-in">
          <div className="c-rightBox-con">
            <h2>PIFS</h2>
            <p>Personal Investment & Financial System</p>
          </div>
          <div className="c-rightBox-form">
            <Formik
              initialValues={{
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={resetPasswordSchema}
              onSubmit={(values) => {
                handelRestPassword(values);
              }}>
              {({ handelRestPassword, errors, touched }) => (
                <Form>
                  <div className="form-group">
                    <span>
                      <img src={Password_icon} alt="" />
                    </span>
                    <Field
                      name="newPassword"
                      type="password"
                      className="form-control"
                      placeholder="New Password"
                    />
                    {errors.newPassword && touched.newPassword ? (
                      <div className="text-danger">{errors.newPassword}</div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <span>
                      <img src={Password_icon} alt="" />
                    </span>
                    <Field
                      name="confirmPassword"
                      type="password"
                      className="form-control"
                      placeholder="Confirm Password"
                    />
                    {errors.confirmPassword && touched.confirmPassword ? (
                      <div className="text-danger">
                        {errors.confirmPassword}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="d-flex ">
                      <button
                        type="submit"
                        className="c-submit-btn"
                        disabled={loading}>
                        <strong>Submit</strong>
                        {loading ? (
                          <span className="rotate-infinite">
                            <FiRotateCw />
                          </span>
                        ) : (
                          <span>
                            <FiChevronsRight />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>

                  {/* <button
                    type="submit"
                    className="c-submit-btn"
                    // loading={this.state.loading}
                  >
                    <strong>Submit</strong>
                    <span>
                      <FiChevronRight />
                    </span>
                  </button> */}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;
