import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { FormLabel } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { BiEdit, BiRevision } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import { FiChevronsRight, FiRotateCw } from "react-icons/fi";
import { toast } from "react-toastify";
import * as Yup from "yup";
import InsuranceServices from "../../../services/InsuranceService";
import PolicyNumberDropDown from "./PolicyNumberDropDown";

const WithDrawalTableModal = ({ close, valueWithDrawalData }) => {
  const insuranceService = new InsuranceServices(); //-----------For Service Call-------//
  const [editUpdateModal, setEditUpdateModal] = useState(false);
  const [updateID, setupdateID] = useState([]); //--------For Fatching One Data--------//
  const [editID, setEditID] = useState([]); //--------For edit id---------//

  const [initialValues, setInitialValues] = useState(null); //---------For edit--------//
  const [showForm, setShowForm] = useState(false); //---------For Edit Form----------//
  const [updateValue, SetUpdateValue] = useState([]); //---------For Update Value--------//
  const [loading, setLoading] = useState(false); //---------For Loading------//

  const withDrawalSchema = Yup.object().shape({
    insuranceId: Yup.string().required(
      "Insurance Police Number can't be empty"
    ),
    // sellPrice: Yup.string().required("Price can't be empty"),
    // unitRedeemed: Yup.string().required("Unit Redeemed can't be empty"),
    // valueRedeemed: Yup.string().required("Value Redeemed can'tbe empty"),
    // sellDate: Yup.string().required("Date can't be empty"),
  });

  //////////For Edit UPLI WithDrawal Update/////////

  const getIdforUpdate = async (id) => {
    const res = await insuranceService.getSingleWithDrawalID(id);
    res.sellDate = res.sellDate
      ? moment(res.sellDate).format("YYYY-MM-DD")
      : null;
    setInitialValues(res);
    setShowForm(true);
  };

  //For useing Update Endpoint/////

  const upliUpdate = (values) => {
    setLoading(true);
    insuranceService
      .upDateWithDrawal(values)
      .then((res) => {
        toast.success("WithDrawal updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        close();
        setLoading(false);
      })

      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setLoading(false);
      });
  };

  ////////Function For Edit ULIP WithDrawal Modal////////

  const handleClickOpen = () => {
    setEditUpdateModal(true);
  };

  const handleClickClose = () => {
    setEditUpdateModal(false);
  };

  //////////Delete Insurance Value////////

  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            insuranceService.deleteWithDrawl(id).then((res) => {
              toast.success(res, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            });
            close();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div className="c-listbank-w">
      <div className="d-flex justify-content-end">
        <Dialog
          draggable={false}
          visible={true}
          onHide={() => close()}
          header={showForm ? <h1>Edit WithDrawal</h1> : <h1> WithDrawal</h1>}
          style={{ width: "80%" }}
        >
          <div className="c-content-in">
            {showForm ? (
              <Formik
                initialValues={{
                  ...initialValues,
                }}
                validationSchema={withDrawalSchema}
                onSubmit={(values, { resetForm }) => {
                  upliUpdate(values);
                  resetForm();
                }}
                enableReinitialize
              >
                {({ handleChange, onSubmit, values, errors, touched }) => (
                  <Form>
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Insurance Police Number</FormLabel>

                          <Field
                            name="insuranceId"
                            className="form-control"
                            component={PolicyNumberDropDown}
                            placeholder="Insurance policy number"
                          />
                          {errors.insuranceId && touched.insuranceId ? (
                            <div className="text-danger">
                              {errors.insuranceId}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Price</FormLabel>
                          <Field
                            name="sellPrice"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Price"
                          />
                          {errors.sellPrice && touched.sellPrice ? (
                            <div className="text-danger">
                              {errors.sellPrice}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Value Redeemed</FormLabel>

                          <Field
                            name="valueRedeemed"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Value Redeemed"
                          />
                          {errors.valueRedeemed && touched.valueRedeemed ? (
                            <div className="text-danger">
                              {errors.valueRedeemed}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Units Redeemed</FormLabel>
                          <Field
                            name="unitRedeemed"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Units Redeemed"
                          />
                          {errors.unitRedeemed && touched.unitRedeemed ? (
                            <div className="text-danger">
                              {errors.unitRedeemed}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Date</FormLabel>
                          {/* <Field
                            name="sellDate"
                            className="form-control"
                            type="date"
                            placeholder="Date"
                          /> */}
                          <Calendar
                            name="sellDate"
                            className="w-100 mb-2 form-group c-calender-w"
                            value={values.sellDate}
                            onChange={handleChange}
                            showIcon
                            dateFormat="dd/mm/yy"
                            placeholder="dd/mm/yyyy"
                            readOnlyInput
                          />
                          {errors.sellDate && touched.sellDate ? (
                            <div className="text-danger">{errors.sellDate}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="d-flex c-btn-group">
                          <button
                            type="submit"
                            className="c-btn-1"
                            disabled={loading}
                          >
                            <strong>Update</strong>
                            {loading ? (
                              <span className="rotate-infinite">
                                <FiRotateCw />
                              </span>
                            ) : (
                              <span>
                                <FiChevronsRight />
                              </span>
                            )}
                          </button>

                          <button
                            type="button"
                            onClick={() => close()}
                            className="c-btn-1 c-del-btn"
                          >
                            <strong>Cancel</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : null}

            <div className="table-responsive p-3">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Insurance Policy Number</th>
                    <th>Price</th>
                    <th>Value Redeemed</th>
                    <th>Units Redeemed</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {valueWithDrawalData.length > 0
                    ? valueWithDrawalData.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.insuranceId}</td>
                            <td>{item.sellPrice}</td>
                            <td>{item.unitRedeemed}</td>
                            <td>{item.valueRedeemed}</td>
                            <td>{moment(item.valueDate).format("YY-MM-DD")}</td>

                            <td>
                              <button
                                onClick={() => getIdforUpdate(item.id)}
                                type="button"
                                className="c-edit-btn"
                              >
                                {" "}
                                <BiEdit />{" "}
                              </button>

                              <button
                                type="button"
                                className="c-edit-btn"
                                onClick={() => deleteItem(item.id)}
                              >
                                <BsTrash />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>

          {/* {editUpdateModal ? (
            <WithDrawalEdit withDrawalData={editID} close={handleClickClose} />
          ) : null} */}
        </Dialog>
      </div>
    </div>
  );
};

export default WithDrawalTableModal;
