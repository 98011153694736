import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class AuthService {
  constructor() {
    this.headers = {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${this.token}`,
    };
  }

  /* Login function
   * @method: POST
   */
  login = async ({ userId, password }) => {
    let payload = {
      userId: userId,
      password: password,
    };
    try {
      const response = await axios.post(
        `${baseUrl}/customer/authenticate`,
        payload,
        { headers: this.headers }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  //------FORGET PASSWORDS----//
  sendOtp = async (payload) => {
    try {
      const response = await axios.post(
        baseUrl + "/customer/send-otp",
        payload,
        { headers: this.headers }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  verifyOtp = async (payload) => {
    try {
      const response = await axios.post(
        baseUrl + "/customer/verify-otp",
        payload,
        { headers: this.headers }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //-------RESET PASSWORD------//

  resetPassword = async ({ newPassword, confirmPassword }) => {
    this.token = sessionStorage.getItem("token");

    let payload = {
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    try {
      const response = await axios.post(
        baseUrl + "/customer/password/update",
        payload,
        { headers: { Authorization: `Bearer ${this.token}` } }

        // { headers: `Bearer ${this.token}` }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
