import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class AccountantService {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  ////////////Create Accountant ///////////

  createAccountant = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/accountant/create`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  };

  /////For Search  and listing //////
  getSearchAccountant = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/accountant/search`,
        payload,

        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////Account For Single ID///////////

  getUpdateId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/accountant/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////For Update Account ////////

  updateAccountant = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/accountant/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////For all Accountant ///////

  allAccountant = async () => {
    try {
      const response = await axios.get(`${baseUrl}/accountant/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Deleete Ledger //////

  deleteAccountant = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/accountant/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  getAllLedgerView = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/bank-statement-voucher/ledger-view`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
