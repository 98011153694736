import React, { useState, useEffect, useRef, useReducer } from "react";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiEdit, BiRevision, BiSearchAlt } from "react-icons/bi";
import { BsArrowDown, BsArrowUp, BsTrash, BsUpload } from "react-icons/bs";
import { TiThList } from "react-icons/ti";
import { FiPlus } from "react-icons/fi";
import { Field, Formik, Form } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { produce } from "immer";
import moment from "moment/moment";
import { confirmAlert } from "react-confirm-alert";
import BondService from "../../services/BondService";
import ProductDropDown from "../components/ProductDropDown";
import { getCurrencyFormat } from "../../shared/UtilsFunction";
import CreateBond from "./CreateBond";
import EditBonds from "./EditBonds";
import BondType from "./BondType";
import Paginator from "../components/Paginator";
import PageLoader from "../components/PageLoader";
import { Dialog } from "primereact/dialog";
import DocumentUpload from "../components/DocumentUpload";
import ProductLedger from "../components/ProductLedger";

const BondsList = () => {
  const { pathname } = useLocation();
  const reducer = produce((state, action) => {
    switch (action.type) {
      case "dialog":
        state.dialog = action.payload;
        break;
      case "origin":
        state.origin.originId = action.payload.originId;
        state.origin.originItemId = action.payload.originItemId;
        break;
      default:
        return state;
    }
  });
  const [state, dispatch] = useReducer(reducer, {
    dialog: false,
    origin: { originId: null, originItemId: null },
  });
  const [bonds, setBonds] = useState([]);
  const [searchBar, setSearchBar] = useState(false); //--------For SearchBar---------//
  const [edit, SetEdit] = useState(false); //--------For Edit---------//
  const [add, setAdd] = useState(false); //--------For Add---------//
  const [addType, setAddType] = useState(false); //--------For Add---------//
  const [bondDetails, setbondDetails] = useState([]); //--------For edit id---------//
  const [isLoading, setisLoading] = useState(false);
  const [pageNo, setpageNo] = useState(1); //--------for pagenation-------//
  const [pageSize, setpageSize] = useState(10); //-----------for pagenation------//
  const [access, setAccess] = useState(null);
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const bondservice = new BondService();
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState(false);
  const [fieldNmae, setFieldName] = useState(undefined);
  const [showLedger, setShowLedger] = useState(false);
  const [rowId, setRowId] = useState(undefined);

  useEffect(() => {
    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
    }
  }, []);
  //////////Bond List/////////
  useEffect(() => {
    if (menuList !== null) {
      let payload = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (typeof fieldNmae !== "undefined") {
        payload.sorts = [`${fieldNmae},${order ? "asc" : "desc"}`];
      }
      listBond(payload);
    }
  }, [pageNo, pageSize, order]);

  /////////For Product Code List///////
  const listBond = (payload) => {
    setLoader(true);
    bondservice
      .getBondList(payload)
      .then((res) => {
        setBonds(res);
        setLoader(false);
      })

      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoader(false);
      });
  };

  /////////for pagenation////////////
  const increment = () => {
    setpageNo(pageNo + 1);
  };

  const decrement = () => {
    setpageNo(pageNo - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * pageNo) / e.target.value);
    setpageSize(e.target.value);
    setpageNo(temp);
  };
  /// For Edit Bonds ///

  const getBondDetails = (id) => {
    bondservice.getBondDetails(id).then((res) => {
      setbondDetails(res);
    });
  };

  ///////EDIT Modal/////
  const handleClickEdit = () => {
    SetEdit(true);
  };
  const handleCloseEdit = () => {
    SetEdit(false);
  };
  const handleCloseAdd = () => {
    setAdd(false);
  };
  const handleCloseAddType = () => {
    setAddType(false);
  };

  const handleSearch = (data) => {
    let request = {
      pageNo: 1,
      pageSize: pageSize,
      sorts: [],
    };
    if (data.productId) {
      request.productId = data.productId;
    }
    if (data.accountNo) {
      request.accountNo = data.accountNo;
    }
    if (data.issuingCompany) {
      request.issuingCompany = data.issuingCompany;
    }

    bondservice.getBondSearchList(request).then((res) => {
      setBonds(res);
    });
  };

  const handelAdd = (data) => {
    setisLoading(true);
    let payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };

    bondservice
      .createBond(data)
      .then((res) => {
        if (res) {
          toast.success("Bond Added Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setisLoading(false);
          setAdd(false);
          listBond(payload);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      });
  };

  const handelEdit = (data) => {
    bondservice
      .upDateBond(data)
      .then((res) => {
        if (res) {
          toast.success("Bond Updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setisLoading(false);

          SetEdit(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      })

      .finally(() => {
        let payload = {
          pageNo: pageNo,
          pageSize: pageSize,
        };
        listBond(payload);
        setisLoading(false);
      });
  };

  const deleteItem = (id) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            bondservice
              .deleteBond(id)
              .then((res) => {
                toast.success(res, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              })
              .finally(() => listBond(payload));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const reLoad = () => {
    window.location.reload();
  };

  const onSort = (fieldName, value) => {
    setFieldName(fieldName);
    setOrder(value);
  };

  const onShowTransaction = (id) => {
    setRowId(id);
    setShowLedger(true);
  };

  const onCloseLedger = () => {
    setShowLedger(false);
  };

  return access ? (
    <div className="table-responsive">
      <ToastContainer />
      {/* {!bonds.data && <PageLoader />} */}
      {loader && <PageLoader />}
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h1>Bond List</h1>
        <div className="d-flex align-items-center add-searchBtn">
          {(access.isWrite || access.isDelete) && (
            <button
              type="button"
              className="c-btn-1 me-1"
              onClick={() => setAdd(true)}
            >
              <span>
                <FiPlus />
              </span>
              <strong>Bond</strong>
            </button>
          )}

          <button
            type="button"
            className="c-btn-1 me-1"
            onClick={() => setAddType(true)}
          >
            <span>
              <FiPlus />
            </span>
            <strong>Bond Type</strong>
          </button>
          <button
            type="button"
            className="c-btn-1"
            onClick={() => setSearchBar(!searchBar)}
          >
            <span>
              <BiSearchAlt />
            </span>
            <strong>Search</strong>
          </button>
        </div>
      </div>
      <div className="cn-bank-search-w">
        <Collapse in={searchBar}>
          <div className="cn-bank-search-form">
            <h1>Search</h1>
            <Formik
              initialValues={{
                productId: "",
                accountNo: "",
                issuingCompany: "",
              }}
              onSubmit={(values) => {
                handleSearch(values);
              }}
            >
              {({ handleChange, onSubmit, values, errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="productId"
                          className="form-control"
                          component={ProductDropDown}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="accountNo"
                          type="text"
                          className="form-control"
                          placeholder="Search by Account No"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="issuingCompany"
                          type="text"
                          className="form-control"
                          placeholder="Search by Issuing Company"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group d-flex justify-content-end">
                        <button type="submit" className="c-btn-1">
                          <strong>SEARCH</strong>{" "}
                        </button>

                        <button
                          type="reset"
                          className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                        >
                          <strong>Reset</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                        <button
                          // type="reset"
                          onClick={() => reLoad()}
                          className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                        >
                          <strong>Reload</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Collapse>
      </div>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th onClick={() => onSort("productId", !order)}>
              Product Name <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th>Account No</th>
            <th onClick={() => onSort("issuingCompany", !order)}>
              Issuing Company
              <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>

            <th onClick={() => onSort("purchaseAmount", !order)}>
              Purchase Amount <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("purchaseDate", !order)}>
              Purchase Date <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("expiryDate", !order)}>
              Expiry Date <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            {(access.isWrite || access.isDelete) && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {bonds.data && bonds.data.length > 0
            ? bonds.data.map((item, index) => {
                return (
                  <tr key={`bonds-${index}`}>
                    <td>{pageSize * pageNo - pageSize + 1 + index}</td>
                    <td>{item.productName}</td>
                    <td>{item.accountNo}</td>
                    <td>{item.issuingCompany}</td>

                    <td>{getCurrencyFormat(item.purchaseAmount)}</td>
                    <td>{moment(item.purchaseDate).format("Do MMM, YYYY")}</td>
                    <td>{moment(item.expiryDate).format("Do MMM, YYYY")}</td>
                    {(access.isWrite || access.isDelete) && (
                      <td>
                        {access.isWrite ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                            }
                          >
                            <button
                              onClick={() => getBondDetails(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <BiEdit onClick={handleClickEdit} />{" "}
                            </button>
                          </OverlayTrigger>
                        ) : null}
                        {access.isDelete ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                            }
                          >
                            <button
                              type="button"
                              className="c-edit-btn"
                              onClick={() => deleteItem(item.id)}
                            >
                              {" "}
                              <BsTrash />{" "}
                            </button>
                          </OverlayTrigger>
                        ) : null}
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>Document</Tooltip>
                          }
                        >
                          <button
                            type="button"
                            className="c-edit-btn"
                            onClick={() => {
                              dispatch({ type: "dialog", payload: true });
                              dispatch({
                                type: "origin",
                                payload: {
                                  originId: item?.productId,
                                  originItemId: item?.id,
                                },
                              });
                            }}
                          >
                            <BsUpload />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              Transaction View
                            </Tooltip>
                          }
                        >
                          <button
                            onClick={() => onShowTransaction(item.id)}
                            type="button"
                            className="c-edit-btn"
                          >
                            <TiThList />
                          </button>
                        </OverlayTrigger>
                      </td>
                    )}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>

      <Paginator
        pageSize={bonds.pageSize}
        firstPage={bonds.firstPage}
        lastPage={bonds.lastPage}
        decrement={decrement}
        increment={increment}
        pagesizechange={pagesizechange}
        pageNo={bonds.pageNo}
        totalPages={bonds.totalPages}
      />

      {edit ? (
        <EditBonds
          bondDetails={bondDetails}
          handelEdit={handelEdit}
          close={handleCloseEdit}
          isLoading={isLoading}
        />
      ) : null}
      {add ? (
        <CreateBond
          handelAdd={handelAdd}
          close={handleCloseAdd}
          isLoading={isLoading}
        />
      ) : null}
      {addType ? <BondType close={handleCloseAddType} access={access} /> : null}

      <Dialog
        visible={state.dialog}
        style={{ width: "50%" }}
        onHide={() => {
          dispatch({ type: "dialog", payload: false });
        }}
        header={<h1>Document Upload</h1>}
      >
        <DocumentUpload name={"Insurance"} origin={state.origin} />
      </Dialog>
      {showLedger && (
        <ProductLedger
          onClose={onCloseLedger}
          rowId={rowId}
          defaultValue={"BONDS"}
        />
      )}
    </div>
  ) : null;
};

export default BondsList;
