import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { FormLabel } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import { FiChevronsRight, FiRotateCw } from "react-icons/fi";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AccountService from "../../services/AccountService";
import DropdownComponent from "./primereactComponent/DropdownComponent";
import { Calendar } from "primereact/calendar";

const ledgerSchema = Yup.object().shape({
  description: Yup.string().required("Description can't be empty"),
  // alias: Yup.string().required("Alias can't be empty"),
  openingBalance: Yup.number().required("Opening Balance can't be empty"),
  accountGroupId: Yup.number().required("Account Group can't be empty"),
  // id: Yup.number().required("Child Group can't be empty"),
  // openingDate: Yup.string().required("Opening Date can't be empty"),
});

const AddAccountLedger = ({
  type,
  onClose,
  // isShowAccountLedger,
  handelAdd,
  isLoading,
  // allChildGroup,
  // allParentGroup,
  // onChangeParentGroup,
  selectedGroup,
  selectedChildGroup,
}) => {
  const accountSevice = new AccountService();
  const [allParentGroup, setAllParentGroup] = useState([]);
  const [allChildGroup, setAllChildGroup] = useState([]);

  useEffect(() => {
    getAllParents();
    // if (selectedGroup) {
    //   getChildGroup();
    // }
  }, []);

  const getAllParents = () => {
    accountSevice
      .getAllChildGroup()
      .then((res) => {
        setAllParentGroup(res);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
    // accountSevice
    //   .getAccountGroupListAll()
    //   .then((response) => {
    //     setAllParentGroup(response);
    //   })
    //   .catch((error) => {
    //     toast.error(error.message, {
    //       position: "top-right",
    //       autoClose: 3000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "colored",
    //     });
    //   });
  };

  const getChildGroup = () => {
    accountSevice
      .getChildGroup(selectedGroup)
      .then((res) => {
        setAllChildGroup(res);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const onChangeParentGroup = (value, setFieldValue) => {
    setAllChildGroup([]);
    setFieldValue("accountGroupId", value);
    accountSevice
      .getChildGroup(value)
      .then((res) => {
        setAllChildGroup(res);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const onCloseModal = () => {
    setAllChildGroup([]);
    setAllParentGroup([]);
    onClose();
  };

  return (
    <Dialog
      draggable={false}
      header={<h1>Add Account Ledger</h1>}
      visible={true}
      onHide={onCloseModal}
      style={{ width: "40%" }}
    >
      <section className="c-content-in">
        <Formik
          initialValues={{
            description: "",
            alias: "",
            openingBalance: "",
            accountGroupId: "",
            // id: "",
            openingDate: "",
            ...(selectedGroup && { accountGroupId: selectedChildGroup }),
            // ...(selectedChildGroup && { id: selectedChildGroup }),
          }}
          enableReinitialize
          validationSchema={ledgerSchema}
          onSubmit={(values, { resetForm }) => {
            let reqData = { ...values };
            reqData.openingDate = values.openingDate
              ? moment(values.openingDate).format("YYYY-MM-DD")
              : null;
            handelAdd(reqData);
            resetForm();
          }}
        >
          {({
            handleChange,
            onSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => {
            return (
              <Form>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Description</FormLabel>

                      <Field
                        name="description"
                        className="form-control"
                        type="textarea"
                        placeholder="Description"
                      />
                      {errors.description && touched.description ? (
                        <div className="text-danger">{errors.description}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Alis</FormLabel>

                      <Field
                        name="alias"
                        className="form-control"
                        type="text"
                        placeholder="Alis"
                      />
                      {errors.alias && touched.alias ? (
                        <div className="text-danger">{errors.alias}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group c-MutualFund-text">
                      <DropdownComponent
                        filter
                        // disabled={type !== "statement"}
                        options={allParentGroup}
                        placeholder="Select Group"
                        optionLabel="name"
                        header={"Select Group"}
                        optionValue="id"
                        name="accountGroupId"
                        className="cr-autoSearch"
                        value={values.accountGroupId}
                        onChange={(e) =>
                          onChangeParentGroup(e.value, setFieldValue)
                        }
                      />
                      {errors.accountGroupId && touched.accountGroupId ? (
                        <div className="text-danger">
                          {errors.accountGroupId}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* <div className="col-lg-6 col-md-12">
                    <div className="form-group c-MutualFund-text">
                      <DropdownComponent
                        filter
                        // disabled={type !== "statement"}
                        options={allChildGroup}
                        placeholder="Account Child Group"
                        optionLabel="name"
                        header={"Account Child Group"}
                        optionValue="id"
                        name="id"
                        className="cr-autoSearch"
                        onChange={(e) => setFieldValue("id", e.value)}
                      />
                      {errors.id && touched.id ? (
                        <div className="text-danger">{errors.id}</div>
                      ) : null}
                    </div>
                  </div> */}

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Opening Balance</FormLabel>

                      <Field
                        name="openingBalance"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Opening Balance"
                      />
                      {errors.openingBalance && touched.openingBalance ? (
                        <div className="text-danger">
                          {errors.openingBalance}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Opening Date</FormLabel>
                      <Calendar
                        name="openingDate"
                        className="w-100 mb-2 form-group c-calender-w"
                        value={values.openingDate}
                        onChange={handleChange}
                        showIcon
                        dateFormat="dd/mm/yy"
                        placeholder="dd/mm/yyyy"
                        readOnlyInput
                      />
                      {/* <Field
                        name="openingDate"
                        className="form-control"
                        type="date"
                        placeholder="Opening Date"
                      /> */}
                      {errors.openingDate && touched.openingDate ? (
                        <div className="text-danger">{errors.openingDate}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="d-flex c-btn-group">
                      <button
                        type="submit"
                        className="c-btn-1"
                        disabled={isLoading}
                      >
                        <strong>Submit</strong>
                        {isLoading ? (
                          <span className="rotate-infinite">
                            <FiRotateCw />
                          </span>
                        ) : (
                          <span>
                            <FiChevronsRight />
                          </span>
                        )}
                      </button>

                      <button type="reset" className="c-btn-1 c-del-btn">
                        <strong>Reset</strong>
                        <span>
                          <BiRevision />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </section>
    </Dialog>
  );
};
export default AddAccountLedger;
