import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { FormLabel } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import { FiPlus, FiChevronsRight, FiRotateCw } from "react-icons/fi";
import { Dialog } from "primereact/dialog";
import * as Yup from "yup";
import LedgerService from "../../services/LedgerService";
import PageLoader from "./PageLoader";
import AddAccountGroup from "./AddAccountGroup";
import AddAccountLedger from "./AddAccountLeader";
import AccountService from "../../services/AccountService";
import { convertNullToString } from "../utils/utils";
import Config from "../../shared/Config";

const MappingProduct = ({
  onClose,
  handelAddMapping,
  isLoading,
  allProducts,
  productId,
}) => {
  const accountSevice = new AccountService();
  const ledgerService = new LedgerService();

  const [group, setGroup] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedChildGroup, setSelectedChildGroup] = useState("");
  const [allParentGroup, setAllParentGroup] = useState([]);
  const [allChildGroup, setAllChildGroup] = useState([]);
  const [incomeLedger, setIncomeLedger] = useState([]);
  const [expensesLedger, setExpensesLedger] = useState([]);
  const [loader, setLoader] = useState(false);
  const [addingLoader, setAddingLoader] = useState(false);
  const [isShowAccountGroup, setIsShowAccountGroup] = useState(false);
  const [isShowAccountLedger, setIsShowAccountLedger] = useState(false);

  const [initialValues, setInitialValues] = useState({
    productId: "",
    ledgerId: "",
    childGroupId: "",
    incomeLedgerId: "",
    expenseLedgerId: "",
    dividendId: "",
    rentPaid: "",
    rentReceived: "",
    propertyExpense: "",
    registrationFeeLedger: "",
    stampDuty: "",
    otherLedger: "",
    interest: "",
    bankCharges: "",
    loanFee: "",
  });

  const ledgerSchema = Yup.object().shape({
    productAlias: Yup.string().required("Product can't be empty"),
    // headerId: Yup.string().required("Header can't be empty"),
    ledgerId: Yup.object().required("Parent Group can't be empty"),
    childGroupId: Yup.number().required("Child Group can't be empty"),
    // ledgerId: Yup.string().required("Ledger can't be empty"),
    incomeLedgerId: Yup.string().when("productAlias", {
      is: (type) =>
        Number(type) === Config.BOND_ID ||
        Number(type) === Config.ULIP_ID ||
        Number(type) === Config.TRADITIONAL_ID ||
        Number(type) === Config.LIFE_INSURANCE_ID ||
        Number(type) === Config.TERM_LIFE_INSURANCE_ID ||
        Number(type) === Config.MEDICAL_INSURANCE_ID ||
        Number(type) === Config.MUTUAL_FUND_ID ||
        Number(type) === Config.STOCK_ID ||
        Number(type) === Config.PROPERTY_ID,
      then: () => Yup.string().required("Income Ledger can't be empty"),
    }),
    expenseLedgerId: Yup.string().when("productAlias", {
      is: (type) =>
        Number(type) === Config.BOND_ID ||
        Number(type) === Config.MUTUAL_FUND_ID ||
        Number(type) === Config.STOCK_ID ||
        Number(type) === Config.PROPERTY_ID,
      then: () => Yup.string().required("Expense Ledger can't be empty"),
    }),
    dividendId: Yup.string().when("productAlias", {
      is: (type) =>
        Number(type) === Config.BOND_ID ||
        Number(type) === Config.STOCK_ID ||
        Number(type) === Config.MUTUAL_FUND_ID,
      then: () => Yup.string().required("Dividend Ledger can't be empty"),
    }),
    rentPaid: Yup.string().when("productAlias", {
      is: (type) => Number(type) === Config.PROPERTY_ID,
      then: () => Yup.string().required("Rent Paid can't be empty"),
    }),
    rentReceived: Yup.string().when("productAlias", {
      is: (type) => Number(type) === Config.PROPERTY_ID,
      then: () => Yup.string().required("Rent Ledger can't be empty"),
    }),
    propertyExpense: Yup.string().when("productAlias", {
      is: (type) => Number(type) === Config.PROPERTY_ID,
      then: () => Yup.string().required("Property Ledger can't be empty"),
    }),
    registrationFeeLedger: Yup.string().when("productAlias", {
      is: (type) => Number(type) === Config.PROPERTY_ID,
      then: () =>
        Yup.string().required("Registration Fee Ledger can't be empty"),
    }),
    stampDuty: Yup.string().when("productAlias", {
      is: (type) => Number(type) === Config.PROPERTY_ID,
      then: () => Yup.string().required("Stampp Duty Ledger can't be empty"),
    }),
    otherLedger: Yup.string().when("productAlias", {
      is: (type) => Number(type) === Config.PROPERTY_ID,
      then: () => Yup.string().required("Other Ledger can't be empty"),
    }),
    interest: Yup.string().when("productAlias", {
      is: (type) =>
        Number(type) === Config.CURRENT_ACCOUNT_ID ||
        Number(type) === Config.SAVINGS_ACCOUNT_ID ||
        Number(type) === Config.DEPOSIT_ACCOUNT_ID ||
        Number(type) === Config.LOAN_ID,
      then: () => Yup.string().required("Interest Ledger can't be empty"),
    }),
    bankCharges: Yup.string().when("productAlias", {
      is: (type) =>
        Number(type) === Config.CURRENT_ACCOUNT_ID ||
        Number(type) === Config.SAVINGS_ACCOUNT_ID ||
        Number(type) === Config.DEPOSIT_ACCOUNT_ID,
      then: () => Yup.string().required("Bank Charges can't be empty"),
    }),
    loanFee: Yup.string().when("productAlias", {
      is: (type) => Number(type) === Config.LOAN_ID,
      then: () => Yup.string().required("Loan Fee can't be empty"),
    }),
  });

  useEffect(() => {
    if (typeof productId !== "undefined") {
      setLoader(true);
      Promise.all([
        accountSevice.getAccountGroupListAll(),
        accountSevice.getProductDetails(productId),
        ledgerService.getIncomeLedger(),
        ledgerService.getExpensesLedger(),
      ])
        .then((response) => {
          let allProductGroup = response[0];
          let productDetails = response[1];
          let incomeLedger = response[2];
          let expenseLedger = response[3];

          setIncomeLedger(incomeLedger);
          setExpensesLedger(expenseLedger);
          setAllParentGroup(allProductGroup);

          let filterParent = allProductGroup.filter(
            (it) => it.id === productDetails.ledgerId
          );
          accountSevice
            .getChildGroup(productDetails.ledgerId)
            .then((res) => {
              let convertedData = convertNullToString(productDetails);
              convertedData.ledgerId = filterParent[0];
              setInitialValues(convertedData);
              setAllChildGroup(res);
              setSelectedChildGroup(productDetails.childGroupId);
              setSelectedGroup(filterParent[0]);
              setGroup(filterParent[0]);
              setLoader(false);
            })
            .catch((e) => {
              toast.error(e.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setLoader(false);
            });
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoader(false);
        });
    } else {
      accountSevice
        .getAccountGroupListAll()
        .then((res) => {
          setAllParentGroup(res);
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  }, []);

  const onAddAccountLedger = (value) => {
    setAddingLoader(true);

    // if (productId === Config.BOND_ID || productId === Config.MUTUAL_FUND_ID) {
    ledgerService
      .createLedgerAccount(value)
      .then((res) => {
        Promise.all([
          ledgerService.getIncomeLedger(),
          ledgerService.getExpensesLedger(),
        ])
          .then((response) => {
            let incomeLedger = response[0];
            let expenseLedger = response[1];

            setIncomeLedger(incomeLedger);
            setExpensesLedger(expenseLedger);
            setAddingLoader(false);
            setIsShowAccountLedger(false);
          })
          .catch((e) => {
            toast.success("Account Added Successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setAddingLoader(false);
            setIsShowAccountLedger(false);
          });
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setAddingLoader(false);
      });
  };

  const onChangeProduct = (id, setFieldValue) => {
    setLoader(true);
    setFieldValue("ledgerId", "");
    setFieldValue("childGroupId", "");
    setFieldValue("incomeLedgerId", "");
    setFieldValue("expenseLedgerId", "");
    setFieldValue("dividendId", "");
    setFieldValue("rentPaid", "");
    setFieldValue("rentReceived", "");
    setFieldValue("propertyExpense", "");
    setFieldValue("registrationFeeLedger", "");
    setFieldValue("stampDuty", "");
    setFieldValue("otherLedger", "");
    setFieldValue("interest", "");
    setFieldValue("bankCharges", "");
    setFieldValue("loanFee", "");

    let reqData = {
      productType: id,
    };

    Promise.all([
      ledgerService.getIncomeLedger(),
      ledgerService.getExpensesLedger(),
      ledgerService.getCustomerProduct(reqData),
    ])
      .then((response) => {
        let incomeLedger = response[0];
        let expenseLedger = response[1];
        let customerProduct = response[2];

        setIncomeLedger(incomeLedger);
        setExpensesLedger(expenseLedger);
        setFieldValue("productAlias", id);

        let filterParent = allParentGroup.filter(
          (it) => it.id === customerProduct.ledgerId
        );

        if (customerProduct) {
          accountSevice
            .getChildGroup(customerProduct.ledgerId)
            .then((res) => {
              let convertedData = convertNullToString(customerProduct);
              convertedData.ledgerId = filterParent[0];
              setAllChildGroup(res);
              setGroup(filterParent[0]);
              setSelectedGroup(filterParent[0]);
              setSelectedChildGroup(customerProduct.childGroupId);
              setInitialValues(convertedData);
              setLoader(false);
            })
            .catch((e) => {
              toast.error(e.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setLoader(false);
            });
        } else {
          setLoader(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoader(false);
      });
  };

  const onChangeBankLedger = (value, setFieldValue) => {
    setFieldValue("bankCharges", value);
  };

  const onChangeInterestLedger = (value, setFieldValue) => {
    setFieldValue("interest", value);
  };

  const onChangeLoanFeeLedger = (value, setFieldValue) => {
    setFieldValue("loanFee", value);
  };

  const onChangeExpenseLedger = (value, setFieldValue) => {
    setFieldValue("expenseLedgerId", value);
  };

  const onChangeRentPaid = (value, setFieldValue) => {
    setFieldValue("rentPaid", value);
  };

  const onChangeIncomeLedger = (value, setFieldValue) => {
    setFieldValue("incomeLedgerId", value);
  };

  const onChangeRentReceived = (value, setFieldValue) => {
    setFieldValue("rentReceived", value);
  };

  const onChangePropertyExpenseLedger = (value, setFieldValue) => {
    setFieldValue("propertyExpense", value);
  };

  const onChangeRegistrationLedger = (value, setFieldValue) => {
    setFieldValue("registrationFeeLedger", value);
  };

  const onChangeStampDutyLedger = (value, setFieldValue) => {
    setFieldValue("stampDuty", value);
  };

  const onChangeOtherLedger = (value, setFieldValue) => {
    setFieldValue("otherLedger", value);
  };

  const onChangeGroup = (item, setFieldValue) => {
    setSelectedGroup(item);
    setLoader(true);
    accountSevice
      .getChildGroup(item.id)
      .then((res) => {
        setAllChildGroup(res);
        setFieldValue("ledgerId", item);

        setGroup(item);
        setLoader(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoader(false);
      });
  };

  const onChangeChildGroup = (id, setFieldValue) => {
    setFieldValue("childGroupId", id);
    setSelectedChildGroup(id);
  };

  const onAddAccountGroup = (value) => {
    setAddingLoader(true);
    accountSevice
      .createLedger(value)
      .then((res) => {
        const groups = [...allChildGroup, res];
        setAllChildGroup(groups);
        setIsShowAccountGroup(false);
        toast.success("Account Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setAddingLoader(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setAddingLoader(false);
      });
  };

  const onCloseAccountGroup = () => {
    setIsShowAccountGroup(false);
  };

  const onCloseAccountLeger = () => {
    // setSelectedGroup("");
    setIsShowAccountLedger(false);
  };

  const onChangeDividendLedger = (id, setFieldValue) => {
    setFieldValue("dividendId", id);
  };

  // const onChangeParentGroup = (value, setFieldValue) => {
  //   setSelectedGroup(value);
  //   accountSevice
  //     .getChildGroup(value)
  //     .then((res) => {
  //       setAllChildGroup(res);
  //     })
  //     .catch((e) => {
  //       toast.error(e.message, {
  //         position: "top-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     });
  // };

  return (
    <>
      <Dialog
        draggable={false}
        header={<h1>Mapping Product</h1>}
        visible={true}
        onHide={onClose}
        style={{ width: "60%" }}
      >
        <section className="c-content-in">
          <Formik
            initialValues={initialValues}
            validationSchema={ledgerSchema}
            enableReinitialize
            onSubmit={(values, { resetForm }) => {
              handelAddMapping(values);
              resetForm();
            }}
          >
            {({
              handleChange,
              onSubmit,
              values,
              errors,
              touched,
              setFieldValue,
            }) => {
              const isShowChildGroup = !values.ledgerId;
              const isShowLedger = !values.childGroupId;
              const productIdValue = values.productAlias;
              return (
                <Form>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group c-MutualFund-text">
                        <FormLabel>Product</FormLabel>

                        <Dropdown
                          filter
                          name="productAlias"
                          value={productIdValue}
                          options={allProducts}
                          optionLabel="name"
                          optionValue="alias"
                          placeholder="Select Product"
                          className="w-100 mb-2"
                          onChange={(e) =>
                            onChangeProduct(e.value, setFieldValue)
                          }
                        />
                        {errors.productAlias && touched.productAlias ? (
                          <div className="text-danger">
                            {errors.productAlias}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group c-MutualFund-text">
                        <FormLabel>Parent Group</FormLabel>

                        <Dropdown
                          filter
                          name="ledgerId"
                          value={values.ledgerId}
                          options={allParentGroup}
                          optionLabel="name"
                          // optionValue="id"
                          placeholder="Select Parent Group"
                          className="w-100 mb-2"
                          onChange={(e) =>
                            onChangeGroup(e.target.value, setFieldValue)
                          }
                        />
                        {errors.ledgerId && touched.ledgerId ? (
                          <div className="text-danger">{errors.ledgerId}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group c-MutualFund-text">
                        <FormLabel>Child Group</FormLabel>
                        <div className="p-inputgroup flex-1">
                          <div className="w-100 cr-groupBx">
                            <Dropdown
                              filter
                              disabled={isShowChildGroup}
                              name="childGroupId"
                              value={values.childGroupId}
                              options={allChildGroup}
                              optionLabel="name"
                              optionValue="id"
                              placeholder="Select Child Group"
                              className="w-100 mb-2"
                              onChange={(e) =>
                                onChangeChildGroup(e.value, setFieldValue)
                              }
                            />
                            {errors.childGroupId && touched.childGroupId ? (
                              <div className="text-danger">
                                {errors.childGroupId}
                              </div>
                            ) : null}
                          </div>
                          <span
                            className="p-inputgroup-addon"
                            style={{
                              minHeight: "41px",
                              maxHeight: "41px",
                              background: "#253d98",
                              color: " #fff",
                              border: "1px solid #253d98",
                              cursor: "pointer",
                              fontSize: "22px",
                            }}
                            onClick={
                              isShowChildGroup
                                ? undefined
                                : () => setIsShowAccountGroup(true)
                            }
                          >
                            <FiPlus />
                          </span>
                        </div>
                      </div>
                    </div>

                    {productIdValue === Config.ULIP_ID ||
                    productIdValue === Config.TRADITIONAL_ID ||
                    productIdValue === Config.LIFE_INSURANCE_ID ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group c-MutualFund-text">
                          <FormLabel>{"Premium Ledger"}</FormLabel>

                          <div className="p-inputgroup flex-1">
                            <div className="w-100 cr-groupBx">
                              <Dropdown
                                filter
                                name="expenseLedgerId"
                                value={values.expenseLedgerId}
                                options={expensesLedger}
                                optionLabel="description"
                                optionValue="id"
                                placeholder={"Select Expense Ledger"}
                                className="w-100 mb-2"
                                onChange={(e) =>
                                  onChangeExpenseLedger(e.value, setFieldValue)
                                }
                              />
                              {errors.expenseLedgerId &&
                              touched.expenseLedgerId ? (
                                <div className="text-danger">
                                  {errors.expenseLedgerId}
                                </div>
                              ) : null}
                            </div>

                            <span
                              className="p-inputgroup-addon"
                              style={{
                                minHeight: "41px",
                                maxHeight: "41px",
                                background: "#253d98",
                                color: " #fff",
                                border: "1px solid #253d98",
                                cursor: "pointer",
                                fontSize: "22px",
                              }}
                              onClick={
                                isShowChildGroup
                                  ? undefined
                                  : () => setIsShowAccountLedger(true)
                              }
                            >
                              <FiPlus />
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {productIdValue === Config.BOND_ID ||
                    productIdValue === Config.TERM_LIFE_INSURANCE_ID ||
                    productIdValue === Config.MEDICAL_INSURANCE_ID ||
                    productIdValue === Config.MUTUAL_FUND_ID ||
                    productIdValue === Config.STOCK_ID ||
                    productIdValue === Config.PROPERTY_ID ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group c-MutualFund-text">
                          <FormLabel>
                            {productIdValue === Config.BOND_ID ||
                            productIdValue === Config.PROPERTY_ID ||
                            productIdValue === Config.MUTUAL_FUND_ID ||
                            productIdValue === Config.STOCK_ID
                              ? "Income Ledger"
                              : "Mature Ledger"}
                          </FormLabel>

                          <div className="p-inputgroup flex-1">
                            <div className="w-100 cr-groupBx">
                              <Dropdown
                                filter
                                name="incomeLedgerId"
                                value={values.incomeLedgerId}
                                options={incomeLedger}
                                optionLabel="description"
                                optionValue="id"
                                placeholder={
                                  productIdValue === Config.BOND_ID ||
                                  productIdValue === Config.PROPERTY_ID ||
                                  productIdValue === Config.MUTUAL_FUND_ID ||
                                  productIdValue === Config.STOCK_ID
                                    ? "Select Income Ledger"
                                    : "Select Mature Ledger"
                                }
                                className="w-100 mb-2"
                                onChange={(e) =>
                                  onChangeIncomeLedger(e.value, setFieldValue)
                                }
                              />
                              {errors.incomeLedgerId &&
                              touched.incomeLedgerId ? (
                                <div className="text-danger">
                                  {errors.incomeLedgerId}
                                </div>
                              ) : null}
                            </div>

                            <span
                              className="p-inputgroup-addon"
                              style={{
                                minHeight: "41px",
                                maxHeight: "41px",
                                background: "#253d98",
                                color: " #fff",
                                border: "1px solid #253d98",
                                cursor: "pointer",
                                fontSize: "22px",
                              }}
                              onClick={
                                isShowChildGroup
                                  ? undefined
                                  : () => setIsShowAccountLedger(true)
                              }
                            >
                              <FiPlus />
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {productIdValue === Config.BOND_ID ||
                    productIdValue === Config.MUTUAL_FUND_ID ||
                    productIdValue === Config.STOCK_ID ||
                    productIdValue === Config.PROPERTY_ID ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group c-MutualFund-text">
                          <FormLabel>{"Expense Ledger"}</FormLabel>

                          <div className="p-inputgroup flex-1">
                            <div className="w-100 cr-groupBx">
                              <Dropdown
                                filter
                                name="expenseLedgerId"
                                value={values.expenseLedgerId}
                                options={expensesLedger}
                                optionLabel="description"
                                optionValue="id"
                                placeholder={"Select Expense Ledger"}
                                className="w-100 mb-2"
                                onChange={(e) =>
                                  onChangeExpenseLedger(e.value, setFieldValue)
                                }
                              />
                              {errors.expenseLedgerId &&
                              touched.expenseLedgerId ? (
                                <div className="text-danger">
                                  {errors.expenseLedgerId}
                                </div>
                              ) : null}
                            </div>

                            <span
                              className="p-inputgroup-addon"
                              style={{
                                minHeight: "41px",
                                maxHeight: "41px",
                                background: "#253d98",
                                color: " #fff",
                                border: "1px solid #253d98",
                                cursor: "pointer",
                                fontSize: "22px",
                              }}
                              onClick={
                                isShowChildGroup
                                  ? undefined
                                  : () => setIsShowAccountLedger(true)
                              }
                            >
                              <FiPlus />
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {productIdValue === Config.PROPERTY_ID ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group c-MutualFund-text">
                          <FormLabel>Rent Paid</FormLabel>

                          <div className="p-inputgroup flex-1">
                            <div className="w-100 cr-groupBx">
                              <Dropdown
                                filter
                                name="rentPaid"
                                value={values.rentPaid}
                                options={expensesLedger}
                                optionLabel="description"
                                optionValue="id"
                                placeholder={"Select Rent Paid"}
                                className="w-100 mb-2"
                                onChange={(e) =>
                                  onChangeRentPaid(e.value, setFieldValue)
                                }
                              />
                              {errors.rentPaid && touched.rentPaid ? (
                                <div className="text-danger">
                                  {errors.rentPaid}
                                </div>
                              ) : null}
                            </div>

                            <span
                              className="p-inputgroup-addon"
                              style={{
                                minHeight: "41px",
                                maxHeight: "41px",
                                background: "#253d98",
                                color: " #fff",
                                border: "1px solid #253d98",
                                cursor: "pointer",
                                fontSize: "22px",
                              }}
                              onClick={
                                isShowChildGroup
                                  ? undefined
                                  : () => setIsShowAccountLedger(true)
                              }
                            >
                              <FiPlus />
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {productIdValue === Config.PROPERTY_ID ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group c-MutualFund-text">
                          <FormLabel>Rent Received</FormLabel>

                          <div className="p-inputgroup flex-1">
                            <div className="w-100 cr-groupBx">
                              <Dropdown
                                filter
                                name="rentReceived"
                                value={values.rentReceived}
                                options={incomeLedger}
                                optionLabel="description"
                                optionValue="id"
                                placeholder={"Select Rent Received"}
                                className="w-100 mb-2"
                                onChange={(e) =>
                                  onChangeRentReceived(e.value, setFieldValue)
                                }
                              />
                              {errors.rentReceived && touched.rentReceived ? (
                                <div className="text-danger">
                                  {errors.rentReceived}
                                </div>
                              ) : null}
                            </div>

                            <span
                              className="p-inputgroup-addon"
                              style={{
                                minHeight: "41px",
                                maxHeight: "41px",
                                background: "#253d98",
                                color: " #fff",
                                border: "1px solid #253d98",
                                cursor: "pointer",
                                fontSize: "22px",
                              }}
                              onClick={
                                isShowChildGroup
                                  ? undefined
                                  : () => setIsShowAccountLedger(true)
                              }
                            >
                              <FiPlus />
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {productIdValue === Config.PROPERTY_ID ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group c-MutualFund-text">
                          <FormLabel>Property Expense Ledger</FormLabel>

                          <div className="p-inputgroup flex-1">
                            <div className="w-100 cr-groupBx">
                              <Dropdown
                                filter
                                name="propertyExpense"
                                value={values.propertyExpense}
                                options={expensesLedger}
                                optionLabel="description"
                                optionValue="id"
                                placeholder={"Select Property Expense Ledger"}
                                className="w-100 mb-2"
                                onChange={(e) =>
                                  onChangePropertyExpenseLedger(
                                    e.value,
                                    setFieldValue
                                  )
                                }
                              />
                              {errors.propertyExpense &&
                              touched.propertyExpense ? (
                                <div className="text-danger">
                                  {errors.propertyExpense}
                                </div>
                              ) : null}
                            </div>
                            <span
                              className="p-inputgroup-addon"
                              style={{
                                minHeight: "41px",
                                maxHeight: "41px",
                                background: "#253d98",
                                color: " #fff",
                                border: "1px solid #253d98",
                                cursor: "pointer",
                                fontSize: "22px",
                              }}
                              onClick={
                                isShowChildGroup
                                  ? undefined
                                  : () => setIsShowAccountLedger(true)
                              }
                            >
                              <FiPlus />
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {productIdValue === Config.PROPERTY_ID ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group c-MutualFund-text">
                          <FormLabel>Registration Fee Ledger</FormLabel>

                          <div className="p-inputgroup flex-1">
                            <div className="w-100 cr-groupBx">
                              <Dropdown
                                filter
                                name="registrationFeeLedger"
                                value={values.registrationFeeLedger}
                                options={expensesLedger}
                                optionLabel="description"
                                optionValue="id"
                                placeholder={"Select Registration Fee Ledger"}
                                className="w-100 mb-2"
                                onChange={(e) =>
                                  onChangeRegistrationLedger(
                                    e.value,
                                    setFieldValue
                                  )
                                }
                              />
                              {errors.registrationFeeLedger &&
                              touched.registrationFeeLedger ? (
                                <div className="text-danger">
                                  {errors.registrationFeeLedger}
                                </div>
                              ) : null}
                            </div>
                            <span
                              className="p-inputgroup-addon"
                              style={{
                                minHeight: "41px",
                                maxHeight: "41px",
                                background: "#253d98",
                                color: " #fff",
                                border: "1px solid #253d98",
                                cursor: "pointer",
                                fontSize: "22px",
                              }}
                              onClick={
                                isShowChildGroup
                                  ? undefined
                                  : () => setIsShowAccountLedger(true)
                              }
                            >
                              <FiPlus />
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {productIdValue === Config.PROPERTY_ID ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group c-MutualFund-text">
                          <FormLabel>Stamp Duty Ledger</FormLabel>

                          <div className="p-inputgroup flex-1">
                            <div className="w-100 cr-groupBx">
                              <Dropdown
                                filter
                                name="stampDuty"
                                value={values.stampDuty}
                                options={expensesLedger}
                                optionLabel="description"
                                optionValue="id"
                                placeholder={"Select Stamp Duty Ledger"}
                                className="w-100 mb-2"
                                onChange={(e) =>
                                  onChangeStampDutyLedger(
                                    e.value,
                                    setFieldValue
                                  )
                                }
                              />
                              {errors.stampDuty && touched.stampDuty ? (
                                <div className="text-danger">
                                  {errors.stampDuty}
                                </div>
                              ) : null}
                            </div>
                            <span
                              className="p-inputgroup-addon"
                              style={{
                                minHeight: "41px",
                                maxHeight: "41px",
                                background: "#253d98",
                                color: " #fff",
                                border: "1px solid #253d98",
                                cursor: "pointer",
                                fontSize: "22px",
                              }}
                              onClick={
                                isShowChildGroup
                                  ? undefined
                                  : () => setIsShowAccountLedger(true)
                              }
                            >
                              <FiPlus />
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {productIdValue === Config.PROPERTY_ID ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group c-MutualFund-text">
                          <FormLabel>Other Expense</FormLabel>

                          <div className="p-inputgroup flex-1">
                            <div className="w-100 cr-groupBx">
                              <Dropdown
                                filter
                                name="otherLedger"
                                value={values.otherLedger}
                                options={expensesLedger}
                                optionLabel="description"
                                optionValue="id"
                                placeholder={"Select Other Expense"}
                                className="w-100 mb-2"
                                onChange={(e) =>
                                  onChangeOtherLedger(e.value, setFieldValue)
                                }
                              />
                              {errors.otherLedger && touched.otherLedger ? (
                                <div className="text-danger">
                                  {errors.otherLedger}
                                </div>
                              ) : null}
                            </div>
                            <span
                              className="p-inputgroup-addon"
                              style={{
                                minHeight: "41px",
                                maxHeight: "41px",
                                background: "#253d98",
                                color: " #fff",
                                border: "1px solid #253d98",
                                cursor: "pointer",
                                fontSize: "22px",
                              }}
                              onClick={
                                isShowChildGroup
                                  ? undefined
                                  : () => setIsShowAccountLedger(true)
                              }
                            >
                              <FiPlus />
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {productIdValue === Config.BOND_ID ||
                    productIdValue === Config.STOCK_ID ||
                    productIdValue === Config.MUTUAL_FUND_ID ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group c-MutualFund-text">
                          <FormLabel>Dividend Ledger</FormLabel>

                          <div className="p-inputgroup flex-1">
                            <div className="w-100 cr-groupBx">
                              <Dropdown
                                filter
                                name="dividendId"
                                value={values.dividendId}
                                options={incomeLedger}
                                optionLabel="description"
                                optionValue="id"
                                placeholder="Select Dividend Ledger"
                                className="w-100 mb-2"
                                onChange={(e) =>
                                  onChangeDividendLedger(e.value, setFieldValue)
                                }
                              />
                              {errors.dividendId && touched.dividendId ? (
                                <div className="text-danger">
                                  {errors.dividendId}
                                </div>
                              ) : null}
                            </div>
                            <span
                              className="p-inputgroup-addon"
                              style={{
                                minHeight: "41px",
                                maxHeight: "41px",
                                background: "#253d98",
                                color: " #fff",
                                border: "1px solid #253d98",
                                cursor: "pointer",
                                fontSize: "22px",
                              }}
                              onClick={
                                isShowChildGroup
                                  ? undefined
                                  : () => setIsShowAccountLedger(true)
                              }
                            >
                              <FiPlus />
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {productIdValue === Config.CURRENT_ACCOUNT_ID ||
                    productIdValue === Config.SAVINGS_ACCOUNT_ID ||
                    productIdValue === Config.DEPOSIT_ACCOUNT_ID ||
                    productIdValue === Config.LOAN_ID ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group c-MutualFund-text">
                          <FormLabel>Interest Ledger</FormLabel>

                          <div className="p-inputgroup flex-1">
                            <div className="w-100 cr-groupBx">
                              <Dropdown
                                filter
                                name="interest"
                                value={values.interest}
                                options={
                                  productIdValue === Config.LOAN_ID
                                    ? expensesLedger
                                    : incomeLedger
                                }
                                optionLabel="description"
                                optionValue="id"
                                placeholder="Select Interest Ledger"
                                className="w-100 mb-2"
                                onChange={(e) =>
                                  onChangeInterestLedger(e.value, setFieldValue)
                                }
                              />
                              {errors.interest && touched.interest ? (
                                <div className="text-danger">
                                  {errors.interest}
                                </div>
                              ) : null}
                            </div>
                            <span
                              className="p-inputgroup-addon"
                              style={{
                                minHeight: "41px",
                                maxHeight: "41px",
                                background: "#253d98",
                                color: " #fff",
                                border: "1px solid #253d98",
                                cursor: "pointer",
                                fontSize: "22px",
                              }}
                              onClick={
                                isShowChildGroup
                                  ? undefined
                                  : () => setIsShowAccountLedger(true)
                              }
                            >
                              <FiPlus />
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {productIdValue === Config.CURRENT_ACCOUNT_ID ||
                    productIdValue === Config.SAVINGS_ACCOUNT_ID ||
                    productIdValue === Config.DEPOSIT_ACCOUNT_ID ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group c-MutualFund-text">
                          <FormLabel>Bank Charges Ledger</FormLabel>

                          <div className="p-inputgroup flex-1">
                            <div className="w-100 cr-groupBx">
                              <Dropdown
                                filter
                                name="bankCharges"
                                value={values.bankCharges}
                                options={expensesLedger}
                                optionLabel="description"
                                optionValue="id"
                                placeholder="Select Bank Charges Ledger"
                                className="w-100 mb-2"
                                onChange={(e) =>
                                  onChangeBankLedger(e.value, setFieldValue)
                                }
                              />
                              {errors.bankCharges && touched.bankCharges ? (
                                <div className="text-danger">
                                  {errors.bankCharges}
                                </div>
                              ) : null}
                            </div>
                            <span
                              className="p-inputgroup-addon"
                              style={{
                                minHeight: "41px",
                                maxHeight: "41px",
                                background: "#253d98",
                                color: " #fff",
                                border: "1px solid #253d98",
                                cursor: "pointer",
                                fontSize: "22px",
                              }}
                              onClick={
                                isShowChildGroup
                                  ? undefined
                                  : () => setIsShowAccountLedger(true)
                              }
                            >
                              <FiPlus />
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {productIdValue === Config.LOAN_ID ? (
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group c-MutualFund-text">
                          <FormLabel>Loan Fees Ledger</FormLabel>

                          <div className="p-inputgroup flex-1">
                            <div className="w-100 cr-groupBx">
                              <Dropdown
                                filter
                                name="loanFee"
                                value={values.loanFee}
                                options={expensesLedger}
                                optionLabel="description"
                                optionValue="id"
                                placeholder="Select Loan Fees Ledger"
                                className="w-100 mb-2"
                                onChange={(e) =>
                                  onChangeLoanFeeLedger(e.value, setFieldValue)
                                }
                              />
                              {errors.loanFee && touched.loanFee ? (
                                <div className="text-danger">
                                  {errors.loanFee}
                                </div>
                              ) : null}
                            </div>
                            <span
                              className="p-inputgroup-addon"
                              style={{
                                minHeight: "41px",
                                maxHeight: "41px",
                                background: "#253d98",
                                color: " #fff",
                                border: "1px solid #253d98",
                                cursor: "pointer",
                                fontSize: "22px",
                              }}
                              onClick={
                                isShowChildGroup
                                  ? undefined
                                  : () => setIsShowAccountLedger(true)
                              }
                            >
                              <FiPlus />
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="col-lg-12 col-md-12">
                      <div className="d-flex c-btn-group">
                        <button
                          type="submit"
                          className="c-btn-1"
                          disabled={isLoading}
                        >
                          <strong>
                            {typeof productId !== "undefined"
                              ? "Update"
                              : "Submit"}
                          </strong>
                          {isLoading ? (
                            <span className="rotate-infinite">
                              <FiRotateCw />
                            </span>
                          ) : (
                            <span>
                              <FiChevronsRight />
                            </span>
                          )}
                        </button>

                        <button type="reset" className="c-btn-1 c-del-btn">
                          <strong>Reset</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </section>
        {loader && <PageLoader />}
        {/* </Modal> */}
      </Dialog>

      {isShowAccountGroup ? (
        <AddAccountGroup
          onClose={onCloseAccountGroup}
          handelAdd={onAddAccountGroup}
          isLoading={addingLoader}
          selectedGroup={group}
          allParentGroup={allParentGroup}
        />
      ) : null}

      {isShowAccountLedger && (
        <AddAccountLedger
          // isShowAccountLedger={isShowAccountLedger}
          onClose={onCloseAccountLeger}
          handelAdd={onAddAccountLedger}
          isLoading={addingLoader}
          selectedGroup={selectedGroup.id}
          // allChildGroup={allChildGroup}
          selectedChildGroup={selectedChildGroup}
          // allParentGroup={allParentGroup}
          // onChangeParentGroup={onChangeParentGroup}
        />
      )}
    </>
  );
};
export default MappingProduct;
