import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { RelationshipService } from "../../services/RelationshipService";

const IndividualRelationShipDropDown = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const [relation, setrelation] = useState([]);
  const relationshipService = new RelationshipService();

  useEffect(() => {
    props.bankId && bankList();
  }, [props.bankId]);

  const bankList = () => {
    relationshipService.getIndividualRelationShip(props.bankId).then((res) => {
      setrelation(res);
    });
  };

  return (
    <div>
      <Form.Select
        className="form-control"
        {...field}
        {...props}
        disabled={!props.bankId}
      >
        <option value="null">Select Relationship No.</option>
        {relation.map((item) => (
          <option key={item.id} value={item.id}>
            {item.relationshipNo}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export default IndividualRelationShipDropDown;
