import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class MenuServices {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  ////////////Create Menu ///////////

  createMenu = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/menu/create`, payload, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////Menu Listing//////////

  getNewMenuList = async () => {
    try {
      const response = await axios.get(`${baseUrl}/menu/all-menu`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getMenuList = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/menu/search`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  ///////One Data Fatch With ID For Menu////////

  getUpdateMenuWithId = async (ID) => {
    try {
      const response = await axios.get(`${baseUrl}/menu/${ID}`, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Update  For Menu////////

  updateMenu = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/menu/update`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////  GET Menu list //////////
  getAllMenu = async () => {
    try {
      const response = await axios.get(`${baseUrl}/menu/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Delete////////
  deleteMenu = async (ID) => {
    try {
      const response = await axios.post(
        `${baseUrl}/menu/delete/${ID}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
