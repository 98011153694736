import React from "react";
import { Form } from "react-bootstrap";

const LoanDropDown = () => {
	return (
		<select className="form-control" aria-label="Default select example">
			<option>Select Loan</option>
			<option value="parsonal">Personal Loan</option>
			<option value="home">Home Loan</option>
			<option value="car">Car Loan</option>
		</select>
	);
};

export default LoanDropDown;
