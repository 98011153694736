import React, { useEffect, useReducer, useState } from "react";
import CreditCardService from "../../services/CreditCardService";
import { BiEdit, BiRevision, BiSearchAlt } from "react-icons/bi";
import { BsArrowDown, BsArrowUp, BsTrash, BsUpload } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { TiThList } from "react-icons/ti";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Form, Formik, Field } from "formik";
import { ToastContainer, toast } from "react-toastify";
import AddCreditCard from "./AddCreditCard";
import EditCreditCard from "./EditCreditCard";
import BankDropDown from "../components/BankDropDown";
import ProductDropDown from "../components/ProductDropDown";
import { confirmAlert } from "react-confirm-alert";
import Paginator from "../components/Paginator";
import PageLoader from "../components/PageLoader";
import { useLocation } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import DocumentUpload from "../components/DocumentUpload";
import { produce } from "immer";
import ProductLedger from "../components/ProductLedger";
import moment from "moment";

const CreditCardListing = () => {
  const creditcardService = new CreditCardService();

  const [creditCardList, setCreditCardList] = useState([]);
  const [add, setAdd] = useState(false); //--------For Add---------//
  const [edit, setEdit] = useState(false);
  const [searchBar, setSearchBar] = useState(false);

  const [editID, setEditID] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [pageNo, setpageNo] = useState(1); //--------for pagenation-------//
  const [pageSize, setpageSize] = useState(10); //-----------for pagenation------//
  const { pathname } = useLocation();
  const [access, setAccess] = useState(null); //--------
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState(false);
  const [fieldNmae, setFieldName] = useState(undefined);
  const [showLedger, setShowLedger] = useState(false);
  const [rowId, setRowId] = useState(undefined);

  const reducer = produce((state, action) => {
    switch (action.type) {
      case "dialog":
        state.dialog = action.payload;
        break;
      case "origin":
        state.origin.originId = action.payload.originId;
        state.origin.originItemId = action.payload.originItemId;
        break;
      default:
        return state;
    }
  });
  const [state, dispatch] = useReducer(reducer, {
    dialog: false,
    origin: { originId: null, originItemId: null },
  });
  useEffect(() => {
    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (menuList !== null) {
      let payload = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (typeof fieldNmae !== "undefined") {
        payload.sorts = [`${fieldNmae},${order ? "asc" : "desc"}`];
      }
      creditData(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, pageSize, order]);

  const creditData = (payload) => {
    setLoader(true);
    creditcardService
      .getCreditCardList(payload)
      .then((res) => {
        setCreditCardList(res);
        setLoader(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoader(false);
      });
  };

  /////////for pagenation////////////
  const increment = () => {
    setpageNo(pageNo + 1);
  };

  const decrement = () => {
    setpageNo(pageNo - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * pageNo) / e.target.value);
    setpageSize(e.target.value);
    setpageNo(temp);
  };
  // For Edit Modal:
  const handleClickEdit = () => {
    setEdit(true);
  };

  const handleCloseEdit = () => {
    setEdit(false);
  };
  const handleCloseAdd = () => {
    setAdd(false);
  };

  const getIdforUpdate = (id) => {
    // console.log("id==>", id);
    creditcardService.getUpdateId(id).then((res) => {
      setEditID(res);
    });

    // let filterID = creditCardList.filter((item) => item.id === id);
    // setEditID(filterID[0]);
    // console.log("filterID==>", filterID);
  };
  // For Search
  const handleSearch = (value) => {
    let request = {
      pageNo: 1,
      pageSize: pageSize,

      sorts: [],
    };
    if (value.bankId) {
      request.bankId = value.bankId;
    }
    if (value.cardNo) {
      request.cardNo = value.cardNo;
    }
    if (value.productId) {
      request.productId = value.productId;
    }
    creditcardService.searchCreditCard(request).then((res) => {
      setCreditCardList(res);
    });
  };
  const handelAdd = (value) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    setisLoading(true);
    creditcardService
      .createCreditCard(value)
      .then((res) => {
        if (res) {
          toast.success("Credit Card Added Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          creditData(payload);
          setisLoading(false);
          setAdd(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      });
  };
  const handelEdit = (value) => {
    setisLoading(true);
    creditcardService
      .updateCreditCard(value)
      .then((res) => {
        if (res) {
          toast.success("Credit Card updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setisLoading(false);
          setEdit(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      })

      .finally(() => {
        let payload = {
          pageNo: pageNo,
          pageSize: pageSize,
        };
        creditData(payload);
        setisLoading(false);
      });
  };

  ///////////Delete Card/////////
  const deleteItem = (id) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            creditcardService
              .delete(id)
              .then((res) => {
                toast.success(res, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              })
              .finally(() => creditData(payload));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const reLoad = () => {
    window.location.reload();
  };

  const onSort = (fieldName, value) => {
    setFieldName(fieldName);
    setOrder(value);
  };

  const onShowTransaction = (id) => {
    setRowId(id);
    setShowLedger(true);
  };

  const onCloseLedger = () => {
    setShowLedger(false);
  };

  return access ? (
    <div className="table-responsive">
      <ToastContainer />
      {/* {!creditCardList.data && <PageLoader />} */}
      {loader && <PageLoader />}
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h1>Credit Card List</h1>
        <div className="d-flex align-items-center add-searchBtn">
          {(access.isWrite || access.isDelete) && (
            <button
              type="button"
              className="c-btn-1 me-3"
              onClick={() => setAdd(true)}
            >
              <span>
                <FiPlus />
              </span>
              <strong>Credit Card</strong>
            </button>
          )}
          <button
            type="button"
            className="c-btn-1"
            onClick={() => setSearchBar(!searchBar)}
          >
            <span>
              <BiSearchAlt />
            </span>
            <strong>Search</strong>
          </button>
        </div>
      </div>
      <div className="cn-bank-search-w">
        <Collapse in={searchBar}>
          <div className="cn-bank-search-form">
            <h1>CreditCard Search</h1>
            <Formik
              initialValues={{
                bankName: "",
                cardNo: "",
                productId: "",
              }}
              onSubmit={(values) => {
                handleSearch(values);
              }}
            >
              {({ handleSubmit, values, handleChange, errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="bankId"
                          className="form-control"
                          component={BankDropDown}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="cardNo"
                          type="text"
                          className="form-control"
                          placeholder="Search By Card No"
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="productId"
                          className="form-control"
                          component={ProductDropDown}
                        />
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-6 m-auto">
                      <div className="form-group d-flex justify-content-end">
                        <button type="submit" className="c-btn-1">
                          <strong>SEARCH</strong>{" "}
                        </button>

                        <button
                          type="reset"
                          className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                        >
                          <strong>Reset</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                        <button
                          // type="reset"
                          onClick={() => reLoad()}
                          className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                        >
                          <strong>Reload</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Collapse>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th onClick={() => onSort("bankId", !order)}>
              Bank Name <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th>Card No</th>
            <th onClick={() => onSort("expiryMonth", !order)}>
              Expiry(MM/YY) <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("productId", !order)}>
              Product <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("openingBalance", !order)}>
              Opening Balance <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("issuedDate", !order)}>
              Issued Date <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            {(access.isWrite || access.isDelete) && <th>Action</th>}
          </tr>
        </thead>

        <tbody>
          {creditCardList.data && creditCardList.data.length > 0
            ? creditCardList.data.map((item, index) => {
                return (
                  <tr key={`card-${index}`}>
                    <td>{pageSize * pageNo - pageSize + 1 + index}</td>
                    <td>{item.bankName}</td>
                    <td>{item.cardNo}</td>
                    <td>
                      {item.expiryMonth}/{item.expiryYear}
                    </td>
                    <td>{item.productName}</td>
                    <td>{item.openingBalance}</td>
                    <td>
                      {item.issuedDate
                        ? moment(item.issuedDate).format("Do MMM, YYYY")
                        : ""}
                    </td>

                    {(access.isWrite || access.isDelete) && (
                      <td>
                        {access.isWrite ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                            }
                          >
                            <button
                              onClick={() => getIdforUpdate(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <BiEdit onClick={handleClickEdit} />{" "}
                            </button>
                          </OverlayTrigger>
                        ) : null}
                        {access.isDelete ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                            }
                          >
                            <button
                              type="button"
                              className="c-edit-btn"
                              onClick={() => deleteItem(item.id)}
                            >
                              {" "}
                              <BsTrash />{" "}
                            </button>
                          </OverlayTrigger>
                        ) : null}
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>Document</Tooltip>
                          }
                        >
                          <button
                            type="button"
                            className="c-edit-btn"
                            onClick={() => {
                              dispatch({ type: "dialog", payload: true });
                              dispatch({
                                type: "origin",
                                payload: {
                                  originId: item?.productId,
                                  originItemId: item?.id,
                                },
                              });
                            }}
                          >
                            <BsUpload />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              Transaction View
                            </Tooltip>
                          }
                        >
                          <button
                            onClick={() => onShowTransaction(item.id)}
                            type="button"
                            className="c-edit-btn"
                          >
                            <TiThList />
                          </button>
                        </OverlayTrigger>
                      </td>
                    )}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>

      <Paginator
        pageSize={creditCardList.pageSize}
        firstPage={creditCardList.firstPage}
        lastPage={creditCardList.lastPage}
        decrement={decrement}
        increment={increment}
        pagesizechange={pagesizechange}
        pageNo={creditCardList.pageNo}
        totalPages={creditCardList.totalPages}
      />

      {edit ? (
        <EditCreditCard
          creditCardFilterData={editID}
          handelEdit={handelEdit}
          close={handleCloseEdit}
          isLoading={isLoading}
        />
      ) : null}
      {add ? (
        <AddCreditCard
          handelAdd={handelAdd}
          close={handleCloseAdd}
          isLoading={isLoading}
        />
      ) : null}
      <Dialog
        visible={state.dialog}
        style={{ width: "50%" }}
        onHide={() => {
          dispatch({ type: "dialog", payload: false });
        }}
        header={<h1>Document Upload</h1>}
      >
        <DocumentUpload name={"Credit_Card"} origin={state.origin} />
      </Dialog>
      {showLedger && (
        <ProductLedger
          onClose={onCloseLedger}
          rowId={rowId}
          defaultValue={"CREDIT_CARD"}
        />
      )}
    </div>
  ) : null;
};

export default CreditCardListing;
