import React from "react";
import { Form } from "react-bootstrap";

const DepositDropDown = () => {
	return (
		<div>
			<select className="form-control" aria-label="Default select example">
				<option>Select</option>
				<option value="monthly">Monthly</option>
				<option value="quarterly">Quarterly</option>
				<option value="six monthly">Six Monthly</option>
				<option value="yearly">Yearly</option>
				<option value="cumulatively">Cumulative</option>
			</select>
		</div>
	);
};

export default DepositDropDown;
