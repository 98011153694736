import { FileUpload } from "primereact/fileupload";
import { S3 } from "aws-sdk";
import Config from "../../shared/Config";
import DocumentService from "../../services/DocumentService";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import PageLoader from "./PageLoader";
import pdfImg from "../../assets/images/pdf.png";
import xlsImg from "../../assets/images/xls.png";

const excelExt = ["xlsm", "xlsx", "xls"];

const DocumentUpload = (props) => {
  const documentUpload = new DocumentService();
  const [document, setDocument] = useState([]);
  const [loader, setLoader] = useState(false);
  const s3Clint = new S3({
    accessKeyId: Config.S3_CONFIG.S3_ACCESSKEYID,
    secretAccessKey: Config.S3_CONFIG.S3_SECRETACCESSKEY,
    region: Config.S3_CONFIG.S3_REGION,
    signatureVersion: "v4",
  });
  function getFileExtension(filename) {
    const extension = filename.split(".")[1];
    return extension;
  }
  const getData = (id) => {
    documentUpload.getDocumentByOriginId(id).then((res) => setDocument(res));
  };
  const deleteData = (id, fileName) => {
    setLoader(true);
    const params = {
      Bucket: Config.S3_CONFIG.S3_BUCKET + "/documents/",
      Key: fileName,
    };
    s3Clint.deleteObject(params, (err, data) => {
      if (err) {
        setLoader(false);
      } else {
        documentUpload
          .deleteDocumentById(id)
          .then(() => {
            toast.success("Document deleted successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            props?.origin?.originItemId && getData(props?.origin?.originItemId);
            props?.getData && props?.getData();
          })
          .finally(() => {
            setLoader(false);
          });
      }
    });
  };
  const handelFileUpload = (e) => {
    setLoader(true);
    const file = e?.files[0];
    const fileName = file?.name;
    const fileNameArray = fileName.split(".");
    const fileExtension = fileNameArray[fileNameArray.length - 1];
    const newFileName = `${new Date().getTime()}.${fileExtension}`;
    const params = {
      Body: file,
      Bucket: Config.S3_CONFIG.S3_BUCKET + "/documents",
      Key: newFileName,
      ContentType: file.type,
    };

    s3Clint.upload(params, (err, resp) => {
      if (err) {
        toast.error(e?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoader(false);
      } else {
        documentUpload
          .uploadDocument({
            originId: props.origin?.originId,
            originItemId: props?.origin?.originItemId,
            documentFileName: fileName,
            generatedFile: newFileName,
            parentId: props.parentId ? props.parentId : null,
          })
          .then(() => {
            e.options.clear();
            toast.success("Document uploaded successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            props?.origin?.originItemId && getData(props?.origin?.originItemId);
            props?.getData && props?.getData();
          })
          .finally(() => setLoader(false));
      }
    });
  };
  useEffect(() => {
    props?.origin?.originItemId && getData(props.origin.originItemId);
  }, []);
  return (
    <>
      {loader && <PageLoader />}
      {/* <Carousel
        value={document}
        numVisible={6}
        numScroll={3}
        itemTemplate={(doc) => {
          console.log(doc);
          return (
            <div className="border-1 surface-border border-round m-2 text-center py-5 px-3">
              <div>
                <img
                  style={{ width: "100%" }}
                  src={
                    Config.S3_CONFIG.S3_ACCESS_URL +
                    "documents/" +
                    props?.name +
                    "/" +
                    doc?.documentFileName
                  }
                  alt={doc?.documentFileName}
                />
              </div>
              <button></button>
            </div>
          );
        }}
      /> */}
      <div
        className="d-flex justify-content-start align-items-center flex-wrap"
        style={{ gap: "15px" }}
      >
        {document?.map((item, index) => (
          <div key={index.toString()} className="cr-uploadImgbx">
            <a
              href={
                Config.S3_CONFIG.S3_ACCESS_URL +
                "documents/" +
                item?.generatedFile
              }
              target="_blank"
              rel="noreferrer"
            >
              {getFileExtension(item?.generatedFile) === "pdf" ? (
                <img src={pdfImg} />
              ) : excelExt.includes(getFileExtension(item?.generatedFile)) ? (
                <img src={xlsImg} />
              ) : (
                <img
                  style={{ width: "100%" }}
                  src={
                    Config.S3_CONFIG.S3_ACCESS_URL +
                    "documents/" +
                    item?.generatedFile
                  }
                  alt={item?.generatedFile}
                />
              )}
            </a>
            <button
              className="cr-deleteIcn"
              onClick={() => deleteData(item?.id, item?.generatedFile)}
            >
              <BsTrash />
            </button>
          </div>
        ))}
      </div>
      <FileUpload
        multiple
        accept="*"
        maxFileSize={1000000}
        emptyTemplate={<p>Drag and drop files to here to upload.</p>}
        uploadHandler={handelFileUpload}
        customUpload
        chooseLabel="Browse"
        style={{ marginTop: "15px" }}
      />
    </>
  );
};

export default DocumentUpload;
