import { Field, Form, Formik } from "formik";
import { produce } from "immer";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { BiEdit, BiRevision, BiSearchAlt } from "react-icons/bi";
import { BsArrowDown, BsArrowUp, BsTrash, BsUpload } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { RiExchangeDollarLine, RiGlobeFill } from "react-icons/ri";
import { TiThList } from "react-icons/ti";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import StockService from "../../services/StockService";
import DocumentUpload from "../components/DocumentUpload";
import LedgerView from "../components/LedgerView";
import PageLoader from "../components/PageLoader";
import Paginator from "../components/Paginator";
import ProductCodeDropDown from "../components/ProductCodeDropDown";
import ProductDropDown from "../components/ProductDropDown";
import ProductLedger from "../components/ProductLedger";
import EditStock from "./EditStock";
import StockSellTable from "./component/StockSellTable";
import StockValueTable from "./component/StockValueTable";

const ListStock = () => {
  const stockService = new StockService(); //---------For service------------//
  const reducer = produce((state, action) => {
    switch (action.type) {
      case "dialog":
        state.dialog = action.payload;
        break;
      case "origin":
        state.origin.originId = action.payload.originId;
        state.origin.originItemId = action.payload.originItemId;
        break;
      default:
        return state;
    }
  });
  const [state, dispatch] = useReducer(reducer, {
    dialog: false,
    origin: { originId: null, originItemId: null },
  });
  const toastTL = useRef(null); //-----------For Toster Msg----------------//
  const [stockList, setStockList] = useState([]); //----------For Stock List-----//
  const [editStockModal, setEditStockModal] = useState(false); //----------For Stock Edit Modal--------//
  const [updateID, setupdateID] = useState([]); //--------For Fatching One Data--------//
  const [editID, setEditID] = useState([]); //--------For edit id---------//
  const [stockValueId, setValueId] = useState([]); //--------For Get Stock value Update ID--------//
  const [stockValueModal, setStockValueModal] = useState(false); //--------For Stock Value Update Modal--------//
  const [stockSellId, setStockSell] = useState([]); //--------For get data Stock sell id---------//
  const [stockSellModal, setStockSellModal] = useState(false); //---------------For Stock Sell Modal--------//
  const [pageNo, setpageNo] = useState(1); //--------for pagenation-------//
  const [pageSize, setpageSize] = useState(10); //-----------for pagenation------//
  const [searchBar, setSearchBar] = useState(false); //--------For SearchBar---------//
  const { pathname } = useLocation();
  const [access, setAccess] = useState(null); //--------
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState(false);
  const [fieldNmae, setFieldName] = useState(undefined);
  const [showLedger, setShowLedger] = useState(false);
  const [rowId, setRowId] = useState(undefined);
  const [isLedgerView, setIsLedgerView] = useState(false);
  const [selectedLedger, setSelectedLedger] = useState(undefined);

  useEffect(() => {
    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
    }
  }, []);

  /////////For Listing///////

  useEffect(() => {
    if (menuList !== null) {
      let payload = {
        pageNo: pageNo,
        pageSize: pageSize,
      };

      if (typeof fieldNmae !== "undefined") {
        payload.sorts = [`${fieldNmae},${order ? "asc" : "desc"}`];
      }
      getListStock(payload);
    }
  }, [pageNo, pageSize, order]);

  /////////For Listing///////
  const getListStock = (payload) => {
    stockService
      .getStockList(payload)
      .then((res) => {
        setStockList(res);
        setLoader(true);
      })
      .catch((e) => {
        setLoader(false);
        toastTL.current.show({
          severity: "error",
          summary: e.name,
          detail: e.message,
          style: { color: "#000000" },
          sticky: true,
        });
      });
  };

  /////////for pagenation////////////
  const increment = () => {
    setpageNo(pageNo + 1);
  };

  const decrement = () => {
    setpageNo(pageNo - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * pageNo) / e.target.value);
    setpageSize(e.target.value);
    setpageNo(temp);
  };

  ///////////Stock Value Update List////////////
  const stockValueID = (id) => {
    stockService.getStockValuelID(id).then((res) => {
      setValueId(res);
    });
  };

  ////////////Stock Value Sell List////////
  const stockSell = (id) => {
    stockService.getStockSellID(id).then((res) => {
      setStockSell(res);
    });
  };

  //////////For Edit Stock/////////

  const getIdforUpdate = (id) => {
    stockService.getUpdateWithId(id).then((res) => {
      // console.log("res===", res);
      setupdateID(res);
      setEditID(res);
    });
    // console.log("filterID===", stockList);
    // let filterID = stockList.data.filter((item) => item.id === id);
    // console.log("filterID------------------", filterID);
    // setEditID(filterID[0]);
  };

  ///////////Delete /////////
  const deleteItem = (id) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            stockService
              .deleteStock(id)
              .then((res) => {
                toast.success(res, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              })
              .finally(() => getListStock(payload));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  ////////Edit Stock Modal//////

  const handleClickOpenEdit = () => {
    setEditStockModal(true);
  };

  const handleClickCloseEdit = () => {
    setEditStockModal(false);
  };

  //////////Stock Value Modal///////

  const handleClickValueOpen = () => {
    setStockValueModal(true);
  };

  const handleClickValueClose = () => {
    setStockValueModal(false);
  };

  /////////Stock Sell Modal/////
  const handelClickSellOpen = () => {
    setStockSellModal(true);
  };

  const handleClickClose = () => {
    setStockSellModal(false);
  };
  /////////Search Functionality//////
  const handleSearch = (data) => {
    let request = {
      pageNo: 1,
      pageSize: pageSize,

      sorts: [],
    };
    if (data.productId) {
      request.productId = data.productId;
    }

    if (data.productCodeId) {
      request.productCodeId = data.productCodeId;
    }
    if (data.stockName) {
      request.stockName = data.stockName;
    }

    if (data.stockType) {
      request.stockType = data.stockType;
    }

    if (data.accountNumber) {
      request.accountNumber = data.accountNumber;
    }

    stockService.searchStock(request).then((res) => {
      setStockList(res);
    });
  };

  const getUpdateStockData = (values) => {
    setLoading(true);
    stockService
      .updateStock(values)
      .then((res) => {
        setUpdateData(res);

        toast.success("Stock Updated successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        handleClickCloseEdit();
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      })
      .finally(() => {
        let payload = {
          pageNo: pageNo,
          pageSize: pageSize,
        };
        getListStock(payload);
        setLoading(false);
      });
  };
  ///////For Page Reloading//////
  const reLoad = () => {
    window.location.reload();
  };

  const onSort = (fieldName, value) => {
    setFieldName(fieldName);
    setOrder(value);
  };

  const onShowTransaction = (id) => {
    setRowId(id);
    setShowLedger(true);
  };

  const onShowLedger = (item) => {
    setSelectedLedger(item);
    setIsLedgerView(true);
  };

  const onCloseLedger = () => {
    setShowLedger(false);
  };

  const onCloseLedgerView = () => {
    setSelectedLedger(undefined);
    setIsLedgerView(false);
  };

  return access ? (
    <div className="table-responsive">
      <ToastContainer />
      {/* {!stockList.data && <PageLoader />} */}
      {!loader && <PageLoader />}
      <div className="d-flex align-items-center justify-content-between w-100 mb-2">
        <h1>Stock List</h1>
        {pathname === "/stock" && (
          <div className="d-flex align-items-center add-searchBtn">
            <Link to="/stock-add">
              {(access.isWrite || access.isDelete) && (
                <button type="button" className="c-btn-1">
                  <span>
                    <FiPlus />
                  </span>
                  <strong>Stock</strong>
                </button>
              )}
            </Link>
            {/* <Link to="/stock-value">
              {(access.isWrite || access.isDelete) && (
                <button type="button" className="c-btn-1 ms-2">
                  <span>
                    <FiPlus />
                  </span>
                  <strong>Stock Value Update</strong>
                </button>
              )}
            </Link>
            <Link to="/stock-sell">
              {(access.isWrite || access.isDelete) && (
                <button type="button" className="c-btn-1 ms-2">
                  <span>
                    <FiPlus />
                  </span>
                  <strong>Stock Sell</strong>
                </button>
              )}
            </Link> */}
            <button
              type="button"
              className="c-btn-1 ms-2"
              onClick={() => setSearchBar(!searchBar)}
            >
              <span>
                <BiSearchAlt />
              </span>
              <strong>Search</strong>
            </button>
          </div>
        )}
      </div>
      <div className="cn-bank-search-w">
        <Collapse in={searchBar}>
          <div className="cn-bank-search-form">
            <h1>Search</h1>
            <Formik
              initialValues={{
                productCodeId: "",
                productId: "",

                stockName: "",
                stockType: "",
                accountNumber: "",
              }}
              onSubmit={(values) => {
                handleSearch(values);
              }}
            >
              {({ handleChange, onSubmit, values, errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="productCodeId"
                          className="form-control"
                          component={ProductCodeDropDown}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="accountNumber"
                          className="form-control"
                          type="text"
                          placeholder="Account Number"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="stockName"
                          className="form-control"
                          type="text"
                          placeholder="Stock Name"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <select
                          value={values.stockType}
                          onChange={handleChange}
                          name="stockType"
                          className="form-control"
                          aria-label="Default select example"
                        >
                          <option>Select Stock Type</option>
                          <option value="SIP">SIP</option>
                          <option value="ONE_TIME">One Time</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="productId"
                          className="form-control"
                          component={ProductDropDown}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group d-flex justify-content-end">
                        <button type="submit" className="c-btn-1">
                          <strong>SEARCH</strong>{" "}
                        </button>

                        <button
                          type="reset"
                          className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                        >
                          <strong>Reset</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>

                        <button
                          // type="reset"
                          onClick={() => reLoad()}
                          className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                        >
                          <strong>Reload</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Collapse>
      </div>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th onClick={() => onSort("productId", !order)}>
              Product <BsArrowUp className="colorChange" />
              <BsArrowDown className="colorChange" />
            </th>
            <th>Product Code</th>
            <th onClick={() => onSort("stockName", !order)}>
              Stock Name
              <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("stockType", !order)}>
              Stock Type
              <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th>Account Number</th>
            <th>Closing Stock</th>
            {/* <th onClick={() => onSort("unitPrice", !order)}>
              Unit Price
              <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th> */}
            {(access.isWrite || access.isDelete) && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {stockList.data && stockList.data.length > 0
            ? stockList.data.map((item, index) => {
                return (
                  <tr key={`stock-${index}`}>
                    <td>{pageSize * pageNo - pageSize + 1 + index}</td>
                    <td>{item.productName}</td>
                    <td>{item.productCode}</td>
                    <td>{item.stockName}</td>
                    <td>{item.stockType.replaceAll("_", " ")}</td>
                    <td>{item.accountNumber}</td>
                    <td>{item.stockQty}</td>
                    {/* <td>{item.unitPrice}</td> */}
                    {(access.isWrite || access.isDelete) && (
                      <td>
                        {access.isWrite ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                            }
                          >
                            <button
                              onClick={() => getIdforUpdate(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              <BiEdit onClick={handleClickOpenEdit} />
                            </button>
                          </OverlayTrigger>
                        ) : null}
                        {access.isDelete ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                            }
                          >
                            <button
                              type="button"
                              className="c-edit-btn"
                              onClick={() => deleteItem(item.id)}
                            >
                              <BsTrash />
                            </button>
                          </OverlayTrigger>
                        ) : null}

                        {/* <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              Stock Value Update
                            </Tooltip>
                          }
                        >
                          <button
                            onClick={() => stockValueID(item.id)}
                            type="button"
                            className="c-edit-btn"
                          >
                            <RiExchangeDollarLine
                              onClick={handleClickValueOpen}
                            />
                          </button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>Stock Sell</Tooltip>
                          }
                        >
                          <button
                            onClick={() => stockSell(item.id)}
                            type="button"
                            className="c-edit-btn"
                          >
                            <RiGlobeFill onClick={handelClickSellOpen} />
                          </button>
                        </OverlayTrigger> */}

                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>Document</Tooltip>
                          }
                        >
                          <button
                            type="button"
                            className="c-edit-btn"
                            onClick={() => {
                              dispatch({ type: "dialog", payload: true });
                              dispatch({
                                type: "origin",
                                payload: {
                                  originId: item?.productId,
                                  originItemId: item?.id,
                                },
                              });
                            }}
                          >
                            <BsUpload />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              Transaction View
                            </Tooltip>
                          }
                        >
                          <button
                            onClick={() => onShowTransaction(item.id)}
                            type="button"
                            className="c-edit-btn"
                          >
                            <TiThList />
                          </button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>Ledger View</Tooltip>
                          }
                        >
                          <button
                            type="button"
                            className="c-edit-btn"
                            onClick={() => onShowLedger(item)}
                          >
                            <TiThList />
                          </button>
                        </OverlayTrigger>
                      </td>
                    )}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>

      <Paginator
        pageSize={stockList.pageSize}
        firstPage={stockList.firstPage}
        lastPage={stockList.lastPage}
        decrement={decrement}
        increment={increment}
        pagesizechange={pagesizechange}
        pageNo={stockList.pageNo}
        totalPages={stockList.totalPages}
      />

      {editStockModal ? (
        <EditStock
          stockFilterData={editID}
          close={handleClickCloseEdit}
          getUpdateStockData={getUpdateStockData}
          loading={loading}
        />
      ) : null}

      {stockValueModal ? (
        <StockValueTable
          valueStockData={stockValueId}
          close={handleClickValueClose}
        />
      ) : null}

      {stockSellModal ? (
        <StockSellTable sellStockData={stockSellId} close={handleClickClose} />
      ) : null}
      <Dialog
        visible={state.dialog}
        style={{ width: "50%" }}
        onHide={() => {
          dispatch({ type: "dialog", payload: false });
        }}
        header={<h1>Document Upload</h1>}
      >
        <DocumentUpload name={"Credit_Card"} origin={state.origin} />
      </Dialog>

      {showLedger && (
        <ProductLedger
          onClose={onCloseLedger}
          rowId={rowId}
          defaultValue={"STOCKS"}
        />
      )}

      {isLedgerView && (
        <LedgerView
          onClose={onCloseLedgerView}
          selectedLedger={selectedLedger}
          defaultValue={"STOCKS"}
        />
      )}
    </div>
  ) : null;
};

export default ListStock;
