import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class InsuranceServices {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  ///////////@Insurance & @Insurance Type Is Defferent Api Call ///////

  ///////////// Create Insurance //////////

  addInsurance = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/insurance/create`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  };

  ////////Insurance Listing////////////

  getInsuranceList = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/insurance/search`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////For Single ID///////////

  getUpdateId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/insurance/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////For Update Insurance ////////

  upDatInsurance = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/insurance/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////All Data For Insurance////////

  getAllInsurance = async () => {
    try {
      const response = await axios.get(`${baseUrl}/insurance/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////Insurance Type For All/////////

  getInsurance = async () => {
    try {
      const response = await axios.get(`${baseUrl}/insurance-Type/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////Create Insurance Type///////////

  addInsuranceType = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/insurance-Type/create`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////Listing Insurance Type/////////

  getInsuranceTypeList = async () => {
    try {
      const response = await axios.get(`${baseUrl}/insurance-Type/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////For Single ID///////////

  getUpdateIdType = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/insurance-Type/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////For Update Insurance-Type ////////

  upDatInsuranceType = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/insurance-Type/update
`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////// Create ULIP Insurance value ///////////

  addValueUpdate = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/insurance-value/create`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////ULIP Value Update All//////
  getValueUpdateAll = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/insurance-value/all-insurance-Value/${id}`,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////UPLI Value Update Edit Single Data////////////
  getupliUpdateId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/insurance-value/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////For Update UPLI Value/////////
  upDateUpli = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/insurance-value/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////Create For Upli WithDrawal//////////////

  addwithDrawal = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/insurance-withdrawl/create`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////Listing For Upli Withdrawal////////////

  getlistiWithDrawal = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/insurance-withdrawl/all-withdrawl-value/${id}`,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Single Data Fatch For UPLI WithDrawal///////
  getSingleWithDrawalID = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/insurance-withdrawl/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////////Update For UPLI With Drawal///////

  upDateWithDrawal = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/insurance-withdrawl/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////////Delete////////
  delete = async (id, type) => {
    try {
      const response = await axios.post(
        `${baseUrl}/insurance/delete/${id}/${type}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  deleteType = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/insurance-Type/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  deleteValue = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/insurance-value/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  deleteWithDrawl = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/insurance-withdrawl/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////For Insurance Search //////

  searchInsurance = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/insurance/search`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
