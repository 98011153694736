import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";

export const inputField = ({ field, form: { touched, errors }, ...props }) => (
  <div className="form-group">
    <Form.Label>{props.label}</Form.Label>
    <Form.Control {...field} {...props} />
    {touched[field.name] && errors[field.name] && (
      <div className="text-danger">{errors[field.name]}</div>
    )}
  </div>
);

export const successToaster = ({ label }) =>
  toast.success(label, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });

export const errorToaster = ({ label }) =>
  toast.error(label, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
