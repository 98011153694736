import React, { useEffect, useReducer, useRef, useState } from "react";
import { BsArrowDown, BsArrowUp, BsTrash, BsUpload } from "react-icons/bs";
import InsuranceServices from "../../services/InsuranceService";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiEdit, BiRevision, BiSearchAlt } from "react-icons/bi";
import { TiThList } from "react-icons/ti";
import { FiFilter, FiPlus } from "react-icons/fi";
import { EditInsurance } from "./EditInsurance";
import { ImFileText2 } from "react-icons/im";
import ValueUpdateTableModal from "./component/ValueUpdateTableModal";
import { BiEraser } from "react-icons/bi";
import WithDrawalTableModal from "./component/WithDrawalTableModal";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Paginator from "../components/Paginator";
import ProductCodeDropDown from "../components/ProductCodeDropDown";
import { InsuranceTypeDropDown } from "./component/InsuranceTypeDropDown";
import { Field, Form, Formik } from "formik";
import PageLoader from "../components/PageLoader";
import { Dialog } from "primereact/dialog";
import DocumentUpload from "../components/DocumentUpload";
import { produce } from "immer";
import ProductLedger from "../components/ProductLedger";

const ListInsurance = () => {
  const insuranceService = new InsuranceServices(); //-----------For Service Call-------//
  const navigate = useNavigate(); //------FOR NAVIGATION-------//
  const [insuranceData, setInsuranceData] = useState([]); //----------For Listing----------//
  const toastTL = useRef(null); //-----------For Toster Msg----------------//
  const [edit, SetEdit] = useState(false); //--------For Edit Modal---------//
  const [updateID, setupdateID] = useState([]); //--------For Fatching One Data--------//
  const [editID, setEditID] = useState([]); //--------For edit id---------//
  const reducer = produce((state, action) => {
    switch (action.type) {
      case "dialog":
        state.dialog = action.payload;
        break;
      case "origin":
        state.origin.originId = action.payload.originId;
        state.origin.originItemId = action.payload.originItemId;
        break;
      default:
        return state;
    }
  });
  const [state, dispatch] = useReducer(reducer, {
    dialog: false,
    origin: { originId: null, originItemId: null },
  });
  const [valueUpdateUpliList, setValueUpdateUpliList] = useState([]); //--------For Value Update Listing--------//
  const [valueUpdate, setValueUpdate] = useState([]); //--------For Value Update --------//

  const [upliValueModal, setUpliValueModal] = useState(false); //-------for Upli Value Modal------//
  const [upliListID, setUpliListID] = useState([]); //--------for Upli row list ID---------//
  const [withDrawalmodal, setWithDrawalmodal] = useState(false); //-------------withDrawalmodal-------//
  const [withDrawalData, setWithDrawalData] = useState([]); //-------------withDrawaldata-------------]
  const [pageNo, setpageNo] = useState(1); //--------for pagenation-------//
  const [pageSize, setpageSize] = useState(10); //-----------for pagenation------//
  const [searchBar, setSearchBar] = useState(false); //--------For SearchBar---------//
  const [access, setAccess] = useState(null);
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState(false);
  const [fieldNmae, setFieldName] = useState(undefined);
  const [showLedger, setShowLedger] = useState(false);
  const [rowId, setRowId] = useState(undefined);

  useEffect(() => {
    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
    }
  }, []);

  /////For Insurance Listing//////

  useEffect(() => {
    if (menuList !== null) {
      let payload = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (typeof fieldNmae !== "undefined") {
        payload.sorts = [`${fieldNmae},${order ? "asc" : "desc"}`];
      }
      insuranceList(payload);
    }
  }, [pageNo, pageSize, order]);

  /////For Insurance Listing service call//////

  const insuranceList = (payload) => {
    insuranceService
      .getInsuranceList(payload)
      .then((res) => {
        setInsuranceData(res);
        setLoader(true);
      })

      .catch((e) => {
        toastTL.current.show({
          severity: "error",
          summary: e.name,
          detail: e.message,
          style: { color: "#000000" },
          sticky: true,
        });
        setLoader(false);
      });
  };

  /////////for pagenation////////////
  const increment = () => {
    setpageNo(pageNo + 1);
  };

  const decrement = () => {
    setpageNo(pageNo - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * pageNo) / e.target.value);
    setpageSize(e.target.value);
    setpageNo(temp);
  };

  ///////////Delete Insurance/////////
  const deleteItem = (id, type) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            insuranceService
              .delete(id, type)
              .then((res) => {
                toast.success(res, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              })
              .finally(() => insuranceList(payload));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  //////////For Edit Insurance/////////

  const getIdforUpdate = (id) => {
    insuranceService.getUpdateId(id).then((res) => {
      setupdateID(res);
    });

    let filterID = insuranceData.data.filter((item) => item.id === id);
    setEditID(filterID[0]);
  };

  ////////Ulip Value Update Listing With ID/////

  const ulipIdValueUpdate = (id) => {
    insuranceService.getValueUpdateAll(id).then((res) => {
      setValueUpdate(res);
    });
  };

  /////////ULIP WithDrawal Listing With ID/////////
  const withDrawalID = (id) => {
    insuranceService.getlistiWithDrawal(id).then((res) => {
      setWithDrawalData(res);
    });
  };

  ///////EDIT Modal/////

  const handleClickEdit = () => {
    SetEdit(true);
  };

  const handleCloseEdit = () => {
    SetEdit(false);
  };

  ////////Open Modal for Insurance Type////////

  //////Open modal for Ulip  Listing/////

  const handleClickUlipOpen = () => {
    setUpliValueModal(true);
  };
  const handleClickUlipClose = () => {
    setUpliValueModal(false);
  };

  /////////For WithDrawal Modal/////////
  const handleClickWithDrawal = () => {
    setWithDrawalmodal(true);
  };
  const handleClickWithDrawalClose = () => {
    setWithDrawalmodal(false);
  };

  ////Search//////

  const handleSearch = (data) => {
    let request = {
      pageNo: 1,
      pageSize: pageSize,
      sorts: [],
    };

    if (data.insuranceTypeId.length > 0) {
      request.insuranceTypeId = data.insuranceTypeId;
    }
    if (data.policyNo.length > 0) {
      request.policyNo = data.policyNo;
    }
    if (data.bankAccountNo.length > 0) {
      request.bankAccountNo = data.bankAccountNo;
    }

    if (data.companyName.length > 0) {
      request.companyName = data.companyName;
    }

    insuranceService.searchInsurance(request).then((res) => {
      setInsuranceData(res);
    });
  };

  const getUpdateInsuranceData = (values) => {
    setLoading(true);
    insuranceService
      .upDatInsurance(values)
      .then((res) => {
        setUpdateData(res);
        toast.success("Insurance Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        handleCloseEdit();
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      })
      .finally(() => {
        let payload = {
          pageNo: pageNo,
          pageSize: pageSize,
        };
        insuranceList(payload);
        setLoading(false);
      });
  };

  const reLoad = () => {
    window.location.reload();
  };

  const onSort = (fieldName, value) => {
    setFieldName(fieldName);
    setOrder(value);
  };

  const onShowTransaction = (id) => {
    setRowId(id);
    setShowLedger(true);
  };

  const onCloseLedger = () => {
    setShowLedger(false);
  };

  return access ? (
    <div className="c-listbank-w">
      <ToastContainer />
      {/* {!insuranceData.data && <PageLoader />} */}
      {!loader && <PageLoader />}

      <div className="table-responsive">
        <div className="d-flex align-items-center justify-content-between w-100 mb-2">
          <h1>Insurance List</h1>
          <div className="d-flex align-items-center add-searchBtn">
            <Link to="/insurance-add">
              {(access.isWrite || access.isDelete) && (
                <button type="button" className="c-btn-1 ms-2">
                  <span>
                    <FiPlus />
                  </span>
                  <strong>Insurance</strong>
                </button>
              )}
            </Link>
            {/* <Link to="/ulip-value-update">
              {(access.isWrite || access.isDelete) && (
                <button type="button" className="c-btn-1 ms-2">
                  <span>
                    <FiPlus />
                  </span>
                  <strong>ULIP Value Update</strong>
                </button>
              )}
            </Link>
            <Link to="/ulip-withdrawal">
              {(access.isWrite || access.isDelete) && (
                <button type="button" className="c-btn-1 ms-2">
                  <span>
                    <FiPlus />
                  </span>
                  <strong>ULIP Withdrawal</strong>
                </button>
              )}
            </Link> */}
            <button
              type="button"
              className="c-btn-1 ms-2"
              onClick={() => setSearchBar(!searchBar)}
            >
              <span>
                <BiSearchAlt />
              </span>
              <strong>Search</strong>
            </button>
          </div>
        </div>

        <div className="cn-bank-search-w">
          <Collapse in={searchBar}>
            <div className="cn-bank-search-form">
              <h1>Search</h1>
              <Formik
                initialValues={{
                  insuranceTypeId: "",
                  policyNo: "",
                  bankAccountNo: "",
                  companyName: "",
                }}
                onSubmit={(values) => {
                  handleSearch(values);
                }}
              >
                {({ handleChange, onSubmit, values, errors, touched }) => (
                  <Form>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <Field
                            name="insuranceTypeId"
                            className="form-control"
                            component={InsuranceTypeDropDown}
                            placeholder="Insurance Type"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <Field
                            name="policyNo"
                            className="form-control"
                            type="text"
                            placeholder="Policy Number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <Field
                            name="bankAccountNo"
                            className="form-control"
                            type="text"
                            placeholder="Bank Account Number"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <Field
                            name="companyName"
                            className="form-control"
                            type="text"
                            placeholder="Company Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group d-flex justify-content-end">
                          <button type="submit" className="c-btn-1">
                            <strong>SEARCH</strong>{" "}
                          </button>
                          <button
                            type="reset"
                            className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                          >
                            <strong>Reset</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                          <button
                            // type="reset"
                            onClick={() => reLoad()}
                            className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                          >
                            <strong>Reload</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Collapse>
        </div>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th onClick={() => onSort("insuranceTypeId", !order)}>
                Insurance Code
                <BsArrowUp className="colorChange" />{" "}
                <BsArrowDown className="colorChange" />
              </th>
              <th>Policy No</th>
              <th>Account Number</th>
              <th onClick={() => onSort("companyName", !order)}>
                company Name
                <BsArrowUp className="colorChange" />{" "}
                <BsArrowDown className="colorChange" />
              </th>
              {(access.isWrite || access.isDelete) && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {insuranceData.data && insuranceData.data.length > 0
              ? insuranceData.data.map((item, index) => {
                  return (
                    <tr key={`insurance-${index}`}>
                      <td>{pageSize * pageNo - pageSize + 1 + index}</td>
                      <td>{item.insuranceTypeId.split("_").join(" ")}</td>
                      <td>{item.policyNo}</td>
                      <td>{item.bankAccountNo}</td>
                      <td>{item.companyName}</td>
                      {(access.isWrite || access.isDelete) && (
                        <td>
                          {access.isWrite ? (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                              }
                            >
                              <button
                                onClick={() => getIdforUpdate(item.id)}
                                type="button"
                                className="c-edit-btn"
                              >
                                {" "}
                                <BiEdit onClick={handleClickEdit} />{" "}
                              </button>
                            </OverlayTrigger>
                          ) : null}
                          {access.isDelete ? (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                              }
                            >
                              <button
                                onClick={() =>
                                  deleteItem(item.id, item.insuranceTypeId)
                                }
                                type="button"
                                className="c-edit-btn"
                              >
                                {" "}
                                <BsTrash />{" "}
                              </button>
                            </OverlayTrigger>
                          ) : null}

                          {/* <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                Value Update List
                              </Tooltip>
                            }
                          >
                            <button
                              onClick={() => ulipIdValueUpdate(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <ImFileText2 onClick={handleClickUlipOpen} />{" "}
                            </button>
                          </OverlayTrigger> */}

                          {/* <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                WithDrawal List
                              </Tooltip>
                            }
                          >
                            <button
                              onClick={() => withDrawalID(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <BiEraser onClick={handleClickWithDrawal} />{" "}
                            </button>
                          </OverlayTrigger> */}
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Document</Tooltip>
                            }
                          >
                            <button
                              type="button"
                              className="c-edit-btn"
                              onClick={() => {
                                dispatch({ type: "dialog", payload: true });
                                dispatch({
                                  type: "origin",
                                  payload: {
                                    originId: item?.productId,
                                    originItemId: item?.id,
                                  },
                                });
                              }}
                            >
                              <BsUpload />
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                Transaction View
                              </Tooltip>
                            }
                          >
                            <button
                              onClick={() => onShowTransaction(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              <TiThList />
                            </button>
                          </OverlayTrigger>
                        </td>
                      )}
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        <Paginator
          pageSize={insuranceData.pageSize}
          firstPage={insuranceData.firstPage}
          lastPage={insuranceData.lastPage}
          decrement={decrement}
          increment={increment}
          pagesizechange={pagesizechange}
          pageNo={insuranceData.pageNo}
          totalPages={insuranceData.totalPages}
        />
      </div>

      {withDrawalmodal ? (
        <WithDrawalTableModal
          valueWithDrawalData={withDrawalData}
          close={handleClickWithDrawalClose}
        />
      ) : null}

      {upliValueModal ? (
        <ValueUpdateTableModal
          valueUpdateData={valueUpdate}
          close={handleClickUlipClose}
        />
      ) : null}

      {/* {openInsuranceModal ? (
        <AddInsuranceType close={handleClickClose} />
      ) : null} */}
      {edit ? (
        <EditInsurance
          insuranceFilterData={editID}
          close={handleCloseEdit}
          getUpdateInsuranceData={getUpdateInsuranceData}
          loading={loading}
        />
      ) : null}
      <Dialog
        visible={state.dialog}
        style={{ width: "50%" }}
        onHide={() => {
          dispatch({ type: "dialog", payload: false });
        }}
        header={<h1>Document Upload</h1>}
      >
        <DocumentUpload name={"Insurance"} origin={state.origin} />
      </Dialog>
      {showLedger && (
        <ProductLedger
          onClose={onCloseLedger}
          rowId={rowId}
          defaultValue={"LIFE_INSURANCE"}
        />
      )}
    </div>
  ) : null;
};

export default ListInsurance;
