import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import React, { useState } from "react";
import { FormLabel } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import StockService from "../../services/StockService";
import StockName from "../components/StockName";

const StockSale = () => {
  const stockService = new StockService(); //---------For service------------//
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const stockSellSchema = Yup.object().shape({
    stockId: Yup.string().required("Stock Name can't be empty"),
    // sellPrice: Yup.string().required("sell Price can't be empty"),
    // stockSold: Yup.string().required("Stock Sold can't be empty"),
    // totalValueSold: Yup.string().required("Total Value Sold can't be empty"),
    // sellDate: Yup.string().required("Sell Date can't be empty"),
  });

  const createStockSellSubmit = (values) => {
    setLoading(true);
    stockService
      .createStockSell(values)
      .then((res) => {
        toast.success("Stock Sell Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/stock");
        }, 1000);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  return (
    <div className="row">
      <ToastContainer />
      <div className="d-flex align-items-center justify-content-between w-100">
        <h1>Add Stock Sell</h1>
        <Link to="/stock">
          <button type="button" className="c-btn-1">
            <span>
              <FiChevronRight />
            </span>
            <strong>Back</strong>
          </button>
        </Link>
      </div>
      <Formik
        initialValues={{
          stockId: "",
          sellPrice: "",
          stockSold: "",
          totalValueSold: "",
          sellDate: "",
        }}
        validationSchema={stockSellSchema}
        onSubmit={(values) => {
          let reqData = { ...values };
          reqData.sellDate = values.sellDate
            ? moment(values.sellDate).format("YYYY-MM-DD")
            : null;
          createStockSellSubmit(reqData);
        }}
      >
        {({ handleChange, values, errors, touched }) => (
          <Form>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Stock Name</FormLabel>
                  <Field
                    name="stockId"
                    className="form-control"
                    component={StockName}
                  />

                  {errors.stockId && touched.stockId ? (
                    <div className="text-danger">{errors.stockId}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Sell Price</FormLabel>
                  <Field
                    name="sellPrice"
                    className="form-control"
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    placeholder="Sell Price"
                  />
                  {errors.sellPrice && touched.sellPrice ? (
                    <div className="text-danger">{errors.sellPrice}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Stock Sold</FormLabel>
                  <Field
                    name="stockSold"
                    className="form-control"
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    placeholder="Stock Sold"
                  />
                  {errors.stockSold && touched.stockSold ? (
                    <div className="text-danger">{errors.stockSold}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Total Value Sold</FormLabel>
                  <Field
                    name="totalValueSold"
                    className="form-control"
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    placeholder="Total Value Price"
                  />
                  {errors.totalValueSold && touched.totalValueSold ? (
                    <div className="text-danger">{errors.totalValueSold}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Sell Date</FormLabel>
                  {/* <Field
                    name="sellDate"
                    className="form-control"
                    type="date"
                    placeholder="Sell Date"
                  /> */}
                  <Calendar
                    name="sellDate"
                    className="w-100 mb-2 form-group c-calender-w"
                    value={values.sellDate}
                    onChange={handleChange}
                    showIcon
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                    readOnlyInput
                  />
                  {errors.sellDate && touched.sellDate ? (
                    <div className="text-danger">{errors.sellDate}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="d-flex c-btn-group">
                  <button type="submit" className="c-btn-1" disabled={loading}>
                    <strong>Submit</strong>
                    {loading ? (
                      <span className="rotate-infinite">
                        <FiRotateCw />
                      </span>
                    ) : (
                      <span>
                        <FiChevronRight />
                      </span>
                    )}
                  </button>

                  <button type="reset" className="c-btn-1 c-del-btn">
                    <strong>Reset</strong>
                    <span>
                      <BiRevision />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StockSale;
