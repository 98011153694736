import React from "react";
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";

const Paginator = (props) => {
  return (
    <div className="w-full mt-3 paginator d-flex justify-content-between align-items-center">
      <div className="mr-3">
        Rows per page:{" "}
        <select onChange={props.pagesizechange} value={props.pageSize}>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
        </select>
      </div>
      <div className="arrowBtn">
        <button
          className="mr-2 pagination-btn"
          disabled={props.firstPage}
          onClick={props.decrement}
        >
          <BsChevronLeft />
        </button>
        <span className="labelText">
          {props.pageNo}/{props.totalPages}
        </span>
        <button
          className="ml-2 pagination-btn"
          disabled={props.lastPage}
          onClick={props.increment}
        >
          <BsChevronRight />
        </button>
      </div>
    </div>
  );
};

export default Paginator;
