import React, { useEffect } from "react";
import SideNav from "./SideNav";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet, useNavigate } from "react-router-dom";

const Layout = () => {
	const navigate = useNavigate();
	const authToken = sessionStorage.getItem("token") || null;

	useEffect(() => {
		if (authToken === null) {
			navigate("/login");
		}
	}, []);

	return (
		<section className="c-inner-page-2 ">
			<SideNav />
			<Header />
			<section className="c-content-in">
				<Outlet />
			</section>
			<Footer />
		</section>
	);
};

export default Layout;
