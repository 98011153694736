import React, { useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { Modal, FormLabel } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import { FiRotateCw, FiChevronRight, FiPlus } from "react-icons/fi";
import BankDropDown from "../components/BankDropDown";
import { toast } from "react-toastify";
import BankingServices from "../../services/BankingServices";
import BankAdd from "../Bank/bankmanagement/BankAdd";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { Calendar } from "primereact/calendar";

const EditCreditCard = ({
  creditCardFilterData,
  handelEdit,
  close,
  isLoading,
}) => {
  const creditCardEditSchema = Yup.object().shape({
    bankId: Yup.string().required("Bank Name can't be empty"),
    // cardNo: Yup.string().required("Card No can't be empty"),
    // expiryMonth: Yup.string()
    //   .max(12, "Month should't be greater than 12")
    //   .required("Expiry Month can't be empty"),
    // expiryYear: Yup.string().required("Expiry Year can't be empty"),
    // productId: Yup.string().required("Product Name can't be empty"),
  });

  const bankingService = new BankingServices();

  const [add, setAdd] = useState(false);
  const [isBankLoading, setisBankLoading] = useState(false);

  const handelBankAdd = (value) => {
    setisBankLoading(true);
    bankingService
      .addBank(value)
      .then((res) => {
        if (res) {
          toast.success("Bank Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setisBankLoading(false);
          setAdd(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisBankLoading(false);
      });
  };

  const handleCloseAdd = () => {
    setAdd(false);
    setisBankLoading(false);
  };

  return (
    <Dialog
      draggable={false}
      style={{ width: "60%" }}
      header={<h1>Edit Credit Card details</h1>}
      visible={true}
      onHide={() => close()}
    >
      <section className="c-content-in">
        <Formik
          initialValues={{
            id: creditCardFilterData.id,
            bankId: creditCardFilterData.bankId,
            cardNo: creditCardFilterData.cardNo,
            expiryMonth: creditCardFilterData.expiryMonth,
            expiryYear: creditCardFilterData.expiryYear,
            // productId: creditCardFilterData.productId,
            openingBalance: creditCardFilterData.openingBalance
              ? creditCardFilterData.openingBalance
              : null,
            issuedDate: creditCardFilterData.issuedDate
              ? new Date(creditCardFilterData.issuedDate)
              : null,
          }}
          validationSchema={creditCardEditSchema}
          onSubmit={(values, resetForm) => {
            let reqData = { ...values };
            reqData.issuedDate = values.issuedDate
              ? moment(values.issuedDate).format("YYYY-MM-DD")
              : null;

            handelEdit(reqData);
            resetForm();
          }}
          enableReinitialize
        >
          {({ handleChange, onSubmit, values, errors, touched }) => (
            <Form>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <div style={{ width: "85%" }}>
                      <FormLabel>Bank</FormLabel>
                      {!add && (
                        <Field
                          name="bankId"
                          className="form-control"
                          component={BankDropDown}
                        />
                      )}
                      {errors.bankId && touched.bankId ? (
                        <div className="text-danger">{errors.bankId}</div>
                      ) : null}
                    </div>

                    <button
                      type="button"
                      className="btn btn-primary cr-BtnCus"
                      style={{ borderRadius: "50%", marginTop: 30 }}
                      onClick={() => setAdd(true)}
                    >
                      <span>
                        <FiPlus />
                      </span>
                    </button>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Card No</FormLabel>

                    <Field
                      name="cardNo"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control"
                      placeholder="Card No"
                    />

                    {errors.cardNo && touched.cardNo ? (
                      <div className="text-danger">{errors.cardNo}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Expiry Month</FormLabel>

                    <Field
                      name="expiryMonth"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control"
                      placeholder="Expiry Month"
                    />

                    {errors.expiryMonth && touched.expiryMonth ? (
                      <div className="text-danger">{errors.expiryMonth}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Expiry Year</FormLabel>

                    <Field
                      name="expiryYear"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control"
                      placeholder="Expiry Year"
                    />

                    {errors.expiryYear && touched.expiryYear ? (
                      <div className="text-danger">{errors.expiryYear}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Opening Balance</FormLabel>
                    <Field
                      name="openingBalance"
                      type={"number"}
                      className="form-control"
                      placeholder="Opening Balance"
                      value={values.openingBalance}
                      onChange={handleChange}
                    />

                    {errors.openingBalance && touched.openingBalance ? (
                      <div className="text-danger">{errors.openingBalance}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Issued Date</FormLabel>
                    {/* <Field
                      name="issuedDate"
                      type={"date"}
                      className="form-control"
                      placeholder="Issued Date"
                      value={values.issuedDate}
                      onChange={handleChange}
                    /> */}
                    <Calendar
                      name="issuedDate"
                      className="w-100 mb-2 form-group c-calender-w"
                      value={values.issuedDate}
                      onChange={handleChange}
                      showIcon
                      dateFormat="dd/mm/yy"
                      placeholder="dd/mm/yyyy"
                      readOnlyInput
                    />

                    {errors.issuedDate && touched.issuedDate ? (
                      <div className="text-danger">{errors.issuedDate}</div>
                    ) : null}
                  </div>
                </div>
                {/* 
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Product</FormLabel>

                    <Field
                      name="productId"
                      type="text"
                      className="form-control"
                      component={ProductDropDown}
                    />

                    {errors.productId && touched.productId ? (
                      <div className="text-danger">{errors.productId}</div>
                    ) : null}
                  </div>
                </div> */}
                <div className="col-lg-12 col-md-12">
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="d-flex c-btn-group">
                        <button
                          type="submit"
                          className="c-btn-1"
                          disabled={isLoading}
                        >
                          <strong>Update</strong>
                          {isLoading ? (
                            <span className="rotate-infinite">
                              <FiRotateCw />
                            </span>
                          ) : (
                            <span>
                              <FiChevronRight />
                            </span>
                          )}
                        </button>

                        <button
                          onClick={() => close()}
                          type="close"
                          className="c-btn-1 c-del-btn"
                        >
                          <strong>Cancel</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>

      {add ? (
        <BankAdd
          handelAdd={handelBankAdd}
          close={handleCloseAdd}
          isLoading={isBankLoading}
        />
      ) : null}
    </Dialog>
  );
};

export default EditCreditCard;
