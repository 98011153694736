import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ProductService from "../../services/ProductService";
import DropdownComponent from "./primereactComponent/DropdownComponent";

export const ProductDropDown = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const [product, setProduct] = useState([]);
  const productService = new ProductService();

  useEffect(() => {
    getProduct();
  }, []);

  const getProduct = () => {
    productService.getCustomerProduct().then((res) => {
      setProduct(res);
      // console.log("res==", res);
    });
  };

  return (
    <div>
      <DropdownComponent
        options={product}
        placeholder={props.placeholder ?? "Product"}
        name={field.name}
        className={"p-0 "}
        filter
        optionLabel={"productName"}
        optionValue={"productId"}
      />
      {/* <Form.Select className="form-control" {...field} {...props}>
        <option value="null">Select Product</option>
        {product.map((item) => (
          <option key={item.productId} value={item.productId}>
            
            {item.productName}
          </option>
        ))}
      </Form.Select> */}
    </div>
  );
};

export default ProductDropDown;
