import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import BankingServices from "../../services/BankingServices";

const AccountDropDown = ({ field, form: { touched, errors }, ...props }) => {
  const [account, setaccount] = useState([]);
  const bankingService = new BankingServices();

  useEffect(() => {
    bankList();
  }, []);

  const bankList = () => {
    bankingService.getAllaccount().then((res) => {
      setaccount(res);
    });
  };

  return (
    <div>
      <Form.Select className="form-control" {...field} {...props}>
        <option value="null">Select Parent Account No.</option>
        {account.map((item) => (
          <option key={item.id} value={item.id}>
            {item.accountNo}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export default AccountDropDown;
