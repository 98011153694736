import React, { useEffect, useReducer, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Field, Formik, Form } from "formik";
import { BiEdit, BiRevision, BiSearchAlt } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { TiThList } from "react-icons/ti";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsArrowDown, BsArrowUp, BsTrash, BsUpload } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert";
import BankingServices from "../../../services/BankingServices";
import BankDropDown from "../../components/BankDropDown";
import AddBankAccount from "./AddBankAccount";
import EditBankAccount from "./EditBankAccount";
import Paginator from "../../components/Paginator";
import PageLoader from "../../components/PageLoader";
import { useLocation } from "react-router-dom";
import { produce } from "immer";
import { Dialog } from "primereact/dialog";
import DocumentUpload from "../../components/DocumentUpload";
import ProductLedger from "../../components/ProductLedger";

const ListBankAccount = () => {
  const bankservice = new BankingServices();
  const reducer = produce((state, action) => {
    switch (action.type) {
      case "dialog":
        state.dialog = action.payload;
        break;
      case "origin":
        state.origin.originId = action.payload.originId;
        state.origin.originItemId = action.payload.originItemId;
        break;
      default:
        return state;
    }
  });
  const [state, dispatch] = useReducer(reducer, {
    dialog: false,
    origin: { originId: null, originItemId: null },
  });
  const [accountList, setAccountList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [searchBar, setSearchBar] = useState(false); //--------For SearchBar---------//
  const [edit, SetEdit] = useState(false); //--------For Edit---------//
  const [add, setAdd] = useState(false); //--------For Add---------//
  const [editID, setEditID] = useState([]); //--------For edit id---------//
  const [pageNo, setpageNo] = useState(1); //--------for pagenation-------//
  const [pageSize, setpageSize] = useState(10); //-----------for pagenation------//
  const { pathname } = useLocation();
  const [access, setAccess] = useState(null); //--------
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState(false);
  const [fieldNmae, setFieldName] = useState(undefined);
  const [showLedger, setShowLedger] = useState(false);
  const [rowId, setRowId] = useState(undefined);

  useEffect(() => {
    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
    }
  }, []);

  useEffect(() => {
    if (menuList !== null) {
      let payload = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (typeof fieldNmae !== "undefined") {
        payload.sorts = [`${fieldNmae},${order ? "asc" : "desc"}`];
      }
      listAccount(payload);
    }
  }, [pageNo, pageSize, order]);

  /////////For account List///////
  const listAccount = (payload) => {
    bankservice
      .listingBankAccount(payload)
      .then((res) => {
        setAccountList(res);
        setLoader(true);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoader(false);
      });
  };

  /////////for pagenation////////////
  const increment = () => {
    setpageNo(pageNo + 1);
  };

  const decrement = () => {
    setpageNo(pageNo - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * pageNo) / e.target.value);
    setpageSize(e.target.value);
    setpageNo(temp);
  };
  //////////For Edit  /////////
  const getIdforUpdate = (id) => {
    bankservice.getAccountDetails(id).then((res) => {
      setEditID(res);
    });

    // let filterID = accountList.filter((item) => item.id === id);
    // setEditID(filterID[0]);
    // console.log("filterID==>", filterID);
  };

  const handleClickEdit = () => {
    SetEdit(true);
  };

  const handleCloseEdit = () => {
    SetEdit(false);
  };
  const handleCloseAdd = () => {
    setAdd(false);
  };

  const handleSearch = (data) => {
    let request = {
      pageNo: 1,
      pageSize: pageSize,
      sorts: [],
    };
    if (data.bankId) {
      request.bankId = data.bankId;
    }
    if (data.accountNo) {
      request.accountNo = data.accountNo;
    }
    if (data.type) {
      request.type = data.type;
    }
    bankservice.listingBankAccount(request).then((res) => {
      setAccountList(res);
    });
  };

  const handelAdd = (value) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    setisLoading(true);

    bankservice
      .createBankAccount(value)
      .then((res) => {
        if (res) {
          toast.success("Account Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          listAccount(payload);
          setisLoading(false);
          setAdd(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      });
  };
  const handelEdit = (value) => {
    setisLoading(true);
    bankservice
      .updateBankAccount(value)
      .then((res) => {
        if (res) {
          toast.success("Account updated Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setisLoading(false);
          SetEdit(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      })

      .finally(() => {
        let payload = {
          pageNo: pageNo,
          pageSize: pageSize,
        };
        listAccount(payload);
        setisLoading(false);
      });
  };
  const deleteItem = (id, type) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            bankservice
              .deleteAccount(id, type)
              .then((res) => {
                toast.success(res, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              })

              .finally(() => listAccount(payload));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const reLoad = () => {
    window.location.reload();
  };

  const onSort = (fieldName, value) => {
    setFieldName(fieldName);
    setOrder(value);
  };

  const onShowTransaction = (id) => {
    setRowId(id);
    setShowLedger(true);
  };

  const onCloseLedger = () => {
    setShowLedger(false);
  };

  return access ? (
    <div className="table-responsive">
      <ToastContainer />
      {/* {!accountList.data && <PageLoader />} */}
      {!loader && <PageLoader />}
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h1>Account Management</h1>
        <div className="d-flex align-items-center add-searchBtn">
          {(access.isWrite || access.isDelete) && (
            <button
              type="button"
              className="c-btn-1 me-1"
              onClick={() => setAdd(true)}
            >
              <span>
                <FiPlus />
              </span>
              <strong>Account</strong>
            </button>
          )}
          <button
            type="button"
            className="c-btn-1"
            onClick={() => setSearchBar(!searchBar)}
          >
            <span>
              <BiSearchAlt />
            </span>
            <strong>Search</strong>
          </button>
        </div>
      </div>
      <div className="cn-bank-search-w">
        <Collapse in={searchBar}>
          <div className="cn-bank-search-form">
            <h1>Search</h1>
            <Formik
              initialValues={{
                accountNo: "",
                bankId: "",
                type: "",
              }}
              onSubmit={(values) => {
                handleSearch(values);
              }}
            >
              {({ handleChange, onSubmit, values, errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <select
                          value={values.type}
                          onChange={handleChange}
                          name="type"
                          className="form-control"
                          aria-label="Default select example"
                        >
                          <option>Select Account Type</option>
                          <option value="CHECKING_CURRENT_ACCOUNT">
                            Current
                          </option>
                          <option value="SAVINGS_ACCOUNT">Savings</option>
                          <option value="DEPOSIT_ACCOUNT">Deposit</option>
                          <option value="INVESTMENT">Cumulative</option>
                          <option value="LOAN">Loan</option>
                          <option value="CREDIT_CARD">Credit Card</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="accountNo"
                          type="text"
                          className="form-control"
                          placeholder="Search by Account No"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="bankId"
                          className="form-control"
                          component={BankDropDown}
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 m-auto">
                      <div className="form-group d-flex justify-content-end">
                        <button type="submit" className="c-btn-1">
                          <strong>SEARCH</strong>{" "}
                        </button>

                        <button
                          type="reset"
                          className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                        >
                          <strong>Reset</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                        <button
                          // type="reset"
                          onClick={() => reLoad()}
                          className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                        >
                          <strong>Reload</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Collapse>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th onClick={() => onSort("type", !order)}>
              Acc. Type <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th>Acc.No</th>
            <th onClick={() => onSort("bankId", !order)}>
              Bank Name <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("accountDesc", !order)}>
              Description <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            {(access.isWrite || access.isDelete) && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {accountList.data && accountList.data.length > 0
            ? accountList.data.map((item, index) => (
                <tr key={`account-${index}`}>
                  <td>{pageSize * pageNo - pageSize + 1 + index}</td>
                  <td>
                    {item.type === "LOAN"
                      ? "Loan"
                      : item.type === "DEPOSIT_ACCOUNT"
                      ? "Diposit"
                      : item.type === "CREDIT_CARD"
                      ? "Credit Card"
                      : item.type === "SAVINGS_ACCOUNT"
                      ? "Savings"
                      : item.type === "INVESTMENT"
                      ? "Investment"
                      : item.type === "CHECKING_CURRENT_ACCOUNT"
                      ? "Current"
                      : ""}
                  </td>
                  <td>{item.accountNo}</td>
                  <td>{item.bankName}</td>
                  <td>{item.accountDesc}</td>
                  {(access.isWrite || access.isDelete) && (
                    <td>
                      {access.isWrite ? (
                        <OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                          }
                        >
                          <button
                            onClick={() => getIdforUpdate(item.id)}
                            type="button"
                            className="c-edit-btn"
                          >
                            <BiEdit onClick={handleClickEdit} />
                          </button>
                        </OverlayTrigger>
                      ) : null}
                      {access.isDelete ? (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                          }
                        >
                          <button
                            type="button"
                            className="c-edit-btn"
                            onClick={() => deleteItem(item.id, item.type)}
                          >
                            <BsTrash />
                          </button>
                        </OverlayTrigger>
                      ) : null}

                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>Document</Tooltip>
                        }
                      >
                        <button
                          type="button"
                          className="c-edit-btn"
                          onClick={() => {
                            dispatch({ type: "dialog", payload: true });
                            dispatch({
                              type: "origin",
                              payload: {
                                originId: item?.productId,
                                originItemId: item?.id,
                              },
                            });
                          }}
                        >
                          <BsUpload />
                        </button>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            Transaction View
                          </Tooltip>
                        }
                      >
                        <button
                          onClick={() => onShowTransaction(item.id)}
                          type="button"
                          className="c-edit-btn"
                        >
                          <TiThList />
                        </button>
                      </OverlayTrigger>
                    </td>
                  )}
                </tr>
              ))
            : null}
        </tbody>
      </table>

      <Paginator
        pageSize={accountList.pageSize}
        firstPage={accountList.firstPage}
        lastPage={accountList.lastPage}
        decrement={decrement}
        increment={increment}
        pagesizechange={pagesizechange}
        pageNo={accountList.pageNo}
        totalPages={accountList.totalPages}
      />

      {add ? (
        <AddBankAccount
          close={handleCloseAdd}
          handelAdd={handelAdd}
          isLoading={isLoading}
        />
      ) : null}
      {edit ? (
        <EditBankAccount
          data={editID}
          close={handleCloseEdit}
          handelEdit={handelEdit}
          isLoading={isLoading}
        />
      ) : null}
      <Dialog
        visible={state.dialog}
        style={{ width: "50%" }}
        onHide={() => {
          dispatch({ type: "dialog", payload: false });
        }}
        header={<h1>Document Upload</h1>}
      >
        <DocumentUpload name={"Credit_Card"} origin={state.origin} />
      </Dialog>
      {showLedger && (
        <ProductLedger
          onClose={onCloseLedger}
          rowId={rowId}
          defaultValue={"SAVINGS_ACCOUNT"}
        />
      )}
    </div>
  ) : null;
};

export default ListBankAccount;
