import React, { useEffect, useRef, useState } from "react";
import { BiEdit, BiRevision, BiSearchAlt } from "react-icons/bi";
import { BsArrowDown, BsArrowUp, BsTrash } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import AccountService from "../../services/AccountService";
import Paginator from "../components/Paginator";
import { Collapse, FormLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { Field, Form, Formik } from "formik";
import AccountGroupEdit from "./AccountGroupEdit";
import PageLoader from "../components/PageLoader";
import AccountParentIDDropDown from "../components/AccountParentIDDropDown";

const AccountGroupList = () => {
  const accountSevice = new AccountService(); //-----------For Service Call-------//
  const [groupLedgerData, setGroupLedgerData] = useState([]); //---------For Liting--------//
  const toastTL = useRef(null); //-----------For Toster Msg----------------//

  const [edit, SetEdit] = useState(false); //--------For Edit Modal---------//
  const [updateID, setupdateID] = useState([]); //--------For Fatching One Data--------//
  const [editID, setEditID] = useState([]); //--------For edit id---------//

  const [pageNo, setpageNo] = useState(1); //--------for pagenation-------//
  const [pageSize, setpageSize] = useState(10); //-----------for pagenation------//
  const [searchBar, setSearchBar] = useState(false); //--------For SearchBar---------//
  const [loading, setLoading] = useState(false);

  const { pathname } = useLocation();
  const [access, setAccess] = useState(null); //--------
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState(false);
  const [fieldNmae, setFieldName] = useState(undefined);

  useEffect(() => {
    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
    }
  }, []);

  useEffect(() => {
    if (menuList !== null) {
      let payload = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (typeof fieldNmae !== "undefined") {
        payload.sorts = [`${fieldNmae},${order ? "asc" : "desc"}`];
      }
      ledgerGroupList(payload);
    }
  }, [pageNo, pageSize, order]);

  /////////for pagenation////////////
  const increment = () => {
    setpageNo(pageNo + 1);
  };

  const decrement = () => {
    setpageNo(pageNo - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * pageNo) / e.target.value);
    setpageSize(e.target.value);
    setpageNo(temp);
  };
  /////////For Listing///////

  const ledgerGroupList = (payload) => {
    accountSevice
      .getSearchLedger(payload)
      .then((res) => {
        setGroupLedgerData(res);
        setLoader(true);
      })

      .catch((e) => {
        toastTL.current.show({
          severity: "error",
          summary: e.name,
          detail: e.message,
          style: { color: "#000000" },
          sticky: true,
        });
        setLoader(false);
      });
  };

  ///////////Delete /////////
  const deleteItem = (id) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            accountSevice
              .deleteLedger(id)
              .then((res) => {
                toast.success(res, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                ledgerGroupList(payload);
              })
              .catch((e) => {
                toastTL.current.show({
                  severity: "error",
                  summary: e.name,
                  detail: e.message,
                  style: { color: "#000000" },
                  sticky: true,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  ///////Search//////////

  const handleSearch = (data) => {
    let request = {
      pageNo: 1,
      pageSize: pageSize,
      sorts: [],
    };

    if (data.name.length > 0) {
      request.name = data.name;
    }

    if (data.accounting.length > 0) {
      request.accounting = data.accounting;
    }

    if (data.ledgerName.length > 0) {
      request.ledgerName = data.ledgerName;
    }

    if (data.position) {
      request.position = data.position;
    }

    accountSevice.getSearchLedger(request).then((res) => {
      setGroupLedgerData(res);
    });
  };

  //////////For Edit /////////

  const getIdforUpdate = (id) => {
    accountSevice.getUpdateId(id).then((res) => {
      setupdateID(res);
    });

    let filterID = groupLedgerData.data.filter((item) => item.id === id);

    setEditID(filterID[0]);
    // console.log("filterId", filterID[0].id);
  };

  ///////EDIT Modal/////

  const handleClickEdit = () => {
    SetEdit(true);
  };

  const handleCloseEdit = () => {
    SetEdit(false);
  };

  ///Accountent Update////
  const accountUpdate = (values) => {
    setLoading(true);
    accountSevice
      .updateLedger(values)
      .then((res) => {
        toast.success("Account Updated Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        handleCloseEdit();
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      })
      .finally(() => {
        let payload = {
          pageNo: pageNo,
          pageSize: pageSize,
        };
        ledgerGroupList(payload);
        setLoading(false);
      });
  };

  const reLoad = () => {
    setLoader(true);
    window.location.reload();
  };

  const onSort = (fieldName, value) => {
    setFieldName(fieldName);
    setOrder(value);
  };

  return access ? (
    <div className="table-responsive">
      <ToastContainer />

      {/* {!groupLedgerData.data && <PageLoader />} */}
      {!loader && <PageLoader />}
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h1>Account Group</h1>

        <div className="d-flex align-items-center add-searchBtn">
          <Link to="/account-group-add">
            {(access.isWrite || access.isDelete) && (
              <button type="button" className="c-btn-1 me-1">
                <span>
                  <FiPlus />
                </span>
                <strong>Account Group</strong>
              </button>
            )}
          </Link>
          <button
            type="button"
            className="c-btn-1 ms-2"
            onClick={() => setSearchBar(!searchBar)}
          >
            <span>
              <BiSearchAlt />
            </span>
            <strong>Search</strong>
          </button>
        </div>
      </div>

      <div className="cn-bank-search-w">
        <Collapse in={searchBar}>
          <div className="cn-bank1-search-form">
            <h1>Search</h1>
            <Formik
              initialValues={{
                name: "",
                position: "",
                parentId: "",
                accounting: "",
                ledgerName: "",
              }}
              onSubmit={(values) => {
                handleSearch(values);
              }}
            >
              {({ handleChange, values, errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Name</FormLabel>
                        <div className="d-flex">
                          <Field
                            name="name"
                            className="form-control"
                            type="text"
                            placeholder="Name"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Accounting Type</FormLabel>
                        <select
                          className="form-control"
                          name="accounting"
                          value={values.accounting}
                          onChange={handleChange}
                          aria-label="Default select example"
                        >
                          <option>Select Accounting Type</option>
                          <option value="ASSET">ASSET</option>
                          <option value="LIABILITY">LIABILITY</option>
                          <option value="INCOME">INCOME</option>
                          <option value="EXPENSES">EXPENSE</option>
                        </select>
                      </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-12">
                      <div className="form-group c-MutualFund-text">
                        <FormLabel>Parent Group</FormLabel>
                        <div className="d-flex">
                          <Field
                            name="parentId"
                            className="form-control"
                            type="text"
                            component={AccountParentIDDropDown}
                            placeholder="Parent Group"
                          />
                        </div>
                      </div>
                    </div> */}

                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Position</FormLabel>
                        <div className="d-flex">
                          <Field
                            name="position"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Position"
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>LedgerName</FormLabel>
                        <div className="d-flex">
                          <Field
                            name="ledgerName"
                            className="form-control"
                            type="text"
                            placeholder="LedgerName"
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="row justify-content-center">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group d-flex justify-content-end">
                          <button type="submit" className="c-btn-1">
                            <strong>SEARCH</strong>{" "}
                          </button>
                          <button
                            type="reset"
                            className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                          >
                            <strong>Reset</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                          <button
                            // type="reset"
                            onClick={() => reLoad()}
                            className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                          >
                            <strong>Reload</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Collapse>
      </div>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th onClick={() => onSort("name", !order)}>
              Group Name <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th
            // onClick={() => onSort("name", !order)}
            >
              Parent Group Name
              {/* <BsArrowUp className="colorChange" /> */}
              {/* <BsArrowDown className="colorChange" /> */}
            </th>
            <th onClick={() => onSort("accounting", !order)}>
              Accounting <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            {/* <th>User Id</th> */}
            <th>Position</th>
            {/* <th>Ledger Name</th> */}

            {(access.isWrite || access.isDelete) && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {groupLedgerData.data && groupLedgerData.data.length > 0
            ? groupLedgerData.data.map((item, index) => {
                return (
                  <tr key={`LedgerData-${index}`}>
                    <td>{pageSize * pageNo - pageSize + 1 + index}</td>
                    <td>{item.name}</td>
                    <td>{item.parentName}</td>
                    <td>{item.accounting}</td>
                    {/* <td>{item.parentId}</td> */}
                    <td>{item.position}</td>
                    {/* <td>{item.ledgerName}</td> */}
                    {(access.isWrite || access.isDelete) && (
                      <td>
                        {access.isWrite ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                            }
                          >
                            <button
                              onClick={() => getIdforUpdate(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <BiEdit onClick={handleClickEdit} />{" "}
                            </button>
                          </OverlayTrigger>
                        ) : null}
                        {access.isDelete ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                            }
                          >
                            <button
                              onClick={() => deleteItem(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <BsTrash />{" "}
                            </button>
                          </OverlayTrigger>
                        ) : null}
                      </td>
                    )}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>

      <Paginator
        pageSize={groupLedgerData.pageSize}
        firstPage={groupLedgerData.firstPage}
        lastPage={groupLedgerData.lastPage}
        decrement={decrement}
        increment={increment}
        pagesizechange={pagesizechange}
        pageNo={groupLedgerData.pageNo}
        totalPages={groupLedgerData.totalPages}
      />

      {edit ? (
        <AccountGroupEdit
          AccountGroupEditFilterData={editID}
          accountentUpdate={accountUpdate}
          loading={loading}
          close={handleCloseEdit}
        />
      ) : null}
    </div>
  ) : null;
};

export default AccountGroupList;
