import React, { useState } from "react";
import { FormLabel, Modal } from "react-bootstrap";
import AccountService from "../../services/AccountService";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { FiChevronsRight, FiRotateCw } from "react-icons/fi";
import { BiRevision } from "react-icons/bi";
import { Dialog } from "primereact/dialog";
import AccountParentIDDropDown from "../components/AccountParentIDDropDown";
// import AccountParentIDDropDown from "../components/AccountParentIDDropDown";

const AccountGroupEdit = ({
  close,
  AccountGroupEditFilterData,
  accountentUpdate,
  loading,
}) => {
  const accountGroupchema = Yup.object().shape({
    name: Yup.string().required("Name can't be empty"),
    // position: Yup.string().required("position can't be empty"),
    // parentId: Yup.string().required("User Id can't be empty"),
    accounting: Yup.string().required("Accounting Id can't be empty"),
    // ledgerName: Yup.string().required("LedgerName Id can't be empty"),
  });

  return (
    <div>
      <Dialog
        draggable={false}
        visible={true}
        onHide={() => close()}
        header={<h1>Edit Account Group!!</h1>}
        style={{ width: "80%" }}
      >
        <section className="c-content-in">
          <Formik
            initialValues={{
              id: AccountGroupEditFilterData.id,
              name: AccountGroupEditFilterData.name,
              position: AccountGroupEditFilterData.position,
              parentId: AccountGroupEditFilterData.parentId,
              accounting: AccountGroupEditFilterData.accounting,
              // ledgerName: AccountGroupEditFilterData.ledgerName,
            }}
            validationSchema={accountGroupchema}
            onSubmit={(values) => {
              accountentUpdate(values);
            }}
          >
            {({ handleChange, values, errors, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Name</FormLabel>

                      <Field
                        name="name"
                        className="form-control"
                        type="text"
                        placeholder="Name"
                      />
                      {errors.name && touched.name ? (
                        <div className="text-danger">{errors.name}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group ">
                      <FormLabel>Accounting Type</FormLabel>

                      <select
                        className="form-control"
                        name="accounting"
                        value={values.accounting}
                        onChange={handleChange}
                        aria-label="Default select example"
                      >
                        <option>Select Accounting Type</option>
                        <option value="ASSET">ASSET</option>
                        <option value="LIABILITY">LIABILITY</option>
                        <option value="INCOME">INCOME</option>
                        <option value="EXPENSES">EXPENSE</option>
                      </select>
                      {errors.accounting && touched.accounting ? (
                        <div className="text-danger">{errors.accounting}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group c-MutualFund-text">
                      <FormLabel>Parent Group</FormLabel>

                      <Field
                        name="parentId"
                        className="form-control"
                        type="text"
                        component={AccountParentIDDropDown}
                        placeholder="Parent Group"
                      />
                      {errors.parentId && touched.parentId ? (
                        <div className="text-danger">{errors.parentId}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Position</FormLabel>

                      <Field
                        name="position"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Position"
                      />
                      {errors.position && touched.position ? (
                        <div className="text-danger">{errors.position}</div>
                      ) : null}
                    </div>
                  </div>

                  {/* <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Ledger Name</FormLabel>

                      <Field
                        name="ledgerName"
                        className="form-control"
                        type="text"
                        placeholder="Ledger Name"
                      />
                      {errors.ledgerName && touched.ledgerName ? (
                        <div className="text-danger">{errors.ledgerName}</div>
                      ) : null}
                    </div>
                  </div> */}

                  <div className="col-lg-12 col-md-12">
                    <div className="d-flex c-btn-group">
                      <button
                        type="submit"
                        className="c-btn-1"
                        disabled={loading}
                      >
                        <strong>Update</strong>
                        {loading ? (
                          <span className="rotate-infinite">
                            <FiRotateCw />
                          </span>
                        ) : (
                          <span>
                            <FiChevronsRight />
                          </span>
                        )}
                      </button>

                      <button
                        onClick={() => close()}
                        type="reset"
                        className="c-btn-1 c-del-btn"
                      >
                        <strong>Cancel</strong>
                        <span>
                          <BiRevision />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </Dialog>
    </div>
  );
};

export default AccountGroupEdit;
