import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FormLabel } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import { FiChevronRight, FiChevronsRight, FiRotateCw } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import LedgerService from "../../services/LedgerService";
import * as Yup from "yup";
import AccountGroupDropDown from "../components/AccountGroupDropDown";
import AccountService from "../../services/AccountService";
import DropdownComponent from "../components/primereactComponent/DropdownComponent";
import { Calendar } from "primereact/calendar";

const AddLedgers = () => {
  const ledgerService = new LedgerService(); //-----------For Service Call-------//
  const accountSevice = new AccountService();
  const [allChildGroup, setAllChildGroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accountGroup, setAccountGroup] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // accountSevice.getAccountGroupListAll().then((res) => {
    //   setAccountGroup(res);
    // });
    accountSevice
      .getAllChildGroup()
      .then((res) => {
        setAllChildGroup(res);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }, []);

  const ledgerSchema = Yup.object().shape({
    description: Yup.string().required("Description can't be empty"),
    // alias: Yup.string().required("Alias can't be empty"),
    openingBalance: Yup.number().required("Opening Balance can't be empty"),
    // id: Yup.number().required("Accountant Group can't be empty"),
    accountGroupId: Yup.number().required("Account Group can't be empty"),
    // openingDate: Yup.string().required("Opening Date can't be empty"),
  });

  const createLedger = (values) => {
    const number = Number(values.accountantGroupId);
    values.accountantGroupId = number;
    setLoading(true);
    ledgerService
      .createLedgerAccount(values)
      .then((res) => {
        setLoading(true);
        toast.success("Ledger Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        setTimeout(() => {
          navigate("/ledger");
        }, 1000);

        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  // const onChangeGroup = (item, setFieldValue) => {
  //   setFieldValue("id", item.value);
  //   // setLoader(true);
  //   accountSevice
  //     .getChildGroup(item.value)
  //     .then((res) => {
  //       setAllChildGroup(res);

  //       // setGroup(item);
  //       // setLoader(false);
  //     })
  //     .catch((e) => {
  //       toast.error(e.message, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       // setLoader(false);
  //     });
  // };

  const onChangeId = (item, setFieldValue) => {
    setFieldValue("accountGroupId", item.value);
  };

  return (
    <div className="row">
      <ToastContainer />
      <div className="d-flex align-items-center justify-content-between w-100">
        <h1>Add Account Ledger</h1>
        <Link to="/ledger">
          <button type="button" className="c-btn-1">
            <span>
              <FiChevronRight />
            </span>
            <strong>Back</strong>
          </button>
        </Link>
      </div>
      <Formik
        initialValues={{
          description: "",
          alias: "",
          openingBalance: "",
          accountGroupId: "",
          // id: "",
          openingDate: "",
        }}
        validationSchema={ledgerSchema}
        onSubmit={(values, { resetForm }) => {
          createLedger(values);
          resetForm();
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Description</FormLabel>

                  <Field
                    name="description"
                    className="form-control"
                    type="textarea"
                    placeholder="Description"
                    value={values.description}
                    onChange={(e) =>
                      setFieldValue(
                        "description",
                        e.target.value?.toUpperCase()
                      )
                    }
                  />
                  {errors.description && touched.description ? (
                    <div className="text-danger">{errors.description}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Alias</FormLabel>

                  <Field
                    name="alias"
                    className="form-control"
                    type="text"
                    placeholder="Alias"
                  />
                  {errors.alias && touched.alias ? (
                    <div className="text-danger">{errors.alias}</div>
                  ) : null}
                </div>
              </div>

              {/* <div className="col-lg-6 col-md-12">
                <div className="form-group c-MutualFund-text">
                  <DropdownComponent
                    filter
                    options={accountGroup}
                    placeholder="Select Account Group"
                    optionLabel="name"
                    header={"Select Account Group"}
                    optionValue="id"
                    name="id"
                    className="cr-autoSearch"
                    onChange={(e) => onChangeGroup(e, setFieldValue)}
                  />
                  {errors.id && touched.id ? (
                    <div className="text-danger">{errors.id}</div>
                  ) : null}
                </div>
              </div> */}

              <div className="col-lg-6 col-md-12">
                {/* <div className="form-group c-MutualFund-text"> */}
                <DropdownComponent
                  filter
                  options={allChildGroup}
                  placeholder="Account Child Group"
                  optionLabel="name"
                  header={"Account Child Group"}
                  optionValue="id"
                  name="accountGroupId"
                  className="cr-autoSearch"
                  onChange={(e) => onChangeId(e, setFieldValue)}
                />
                {errors.accountGroupId && touched.accountGroupId ? (
                  <div className="text-danger">{errors.accountGroupId}</div>
                ) : null}
                {/* </div> */}
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Opening Balance</FormLabel>

                  <Field
                    name="openingBalance"
                    className="form-control"
                    type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    placeholder="Opening Balance"
                  />
                  {errors.openingBalance && touched.openingBalance ? (
                    <div className="text-danger">{errors.openingBalance}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Opening Date </FormLabel>

                  {/* <Field
                    name="openingDate"
                    className="form-control"
                    type="date"
                    placeholder="Opening Date"
                  /> */}

                  <Calendar
                    name="openingDate"
                    className="w-100 mb-2 form-group c-calender-w"
                    value={values.openingDate}
                    onChange={handleChange}
                    showIcon
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                    readOnlyInput
                  />

                  {errors.openingDate && touched.openingDate ? (
                    <div className="text-danger">{errors.openingDate}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="d-flex c-btn-group">
                  <button type="submit" className="c-btn-1" disabled={loading}>
                    <strong>Submit</strong>
                    {loading ? (
                      <span className="rotate-infinite">
                        <FiRotateCw />
                      </span>
                    ) : (
                      <span>
                        <FiChevronsRight />
                      </span>
                    )}
                  </button>

                  <button type="reset" className="c-btn-1 c-del-btn">
                    <strong>Reset</strong>
                    <span>
                      <BiRevision />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddLedgers;
