import { useField } from "formik";
import { InputText } from "primereact/inputtext";
import { FormLabel } from "react-bootstrap";

const InputComponent = (props) => {
  const [field, meta] = useField(props.name);
  return (
    <div className="form-group d-flex flex-column">
      <FormLabel>{props?.header ? props?.header : ""}</FormLabel>
      <InputText {...field} {...props} />
      {meta.touched && Boolean(meta.error) && (
        <small style={{ color: "red" }}>{meta.error}</small>
      )}
    </div>
  );
};

export default InputComponent;
