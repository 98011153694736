import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import PageLoader from "./PageLoader";
import BankingServices from "../../services/BankingServices";
import { Dialog } from "primereact/dialog";

const LedgerView = ({ onClose, selectedLedger, defaultValue }) => {
  const bankService = new BankingServices();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    bankService
      .getLedgerView(selectedLedger.id, defaultValue)
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  }, []);

  return loading ? (
    <PageLoader />
  ) : (
    <div className="table-responsive">
      <Dialog
        draggable={false}
        visible={true}
        onHide={() => onClose()}
        header={
          <h1>
            Ledger Name:
            {selectedLedger.productName ||
              selectedLedger.description ||
              selectedLedger.ledgerName}
          </h1>
        }
        style={{ width: "80%" }}
      >
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Transaction Date</th>
              <th>Description</th>
              <th>Buy Quantity</th>
              <th>Sell Quantity</th>
              <th>Opening Balance</th>
              <th>Colsing Balance</th>
              <th>Buy Amount</th>
              <th>Sell Amount</th>
              <th>Buy Unit Price</th>
              <th>Sell Unit Price</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr key={index.toString()}>
                  <td>{index + 1}</td>
                  <td>{moment(item.txnDate).format("Do MMM, YYYY")}</td>
                  <td>{item.narration}</td>
                  <td>{item.buyQty}</td>
                  <td>{item.sellQty}</td>
                  <td>{item.openingBalance} </td>
                  <td>{item.closingBalance} </td>
                  <td>{item.buyTotals} </td>
                  <td>{item.sellAmount} </td>
                  <td>{item.buyUnitPrice} </td>
                  <td>{item.sellUnitPrice} </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Dialog>
    </div>
  );
};

export default LedgerView;
