import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Field, Formik, Form } from "formik";
import { BiEdit, BiRevision, BiSearchAlt } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsArrowDown, BsArrowUp, BsTrash } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert";
import BankAdd from "./BankAdd";
import BankEdit from "./BankEdit";
import CountryDropDown from "../../components/CountryDropDown";
import CurrencyDropDown from "../../components/CurrencyDropDown";
import BankingServices from "../../../services/BankingServices";
import Paginator from "../../components/Paginator";
import PageLoader from "../../components/PageLoader";
import { useLocation } from "react-router-dom";

const BankListing = () => {
  const bankingService = new BankingServices(); //---------for service---------//
  const [bankLists, setBanklist] = useState([]); //------Banking Listing-------//
  const [isLoading, setisLoading] = useState(false);
  const [searchBar, setSearchBar] = useState(false); //--------For SearchBar---------//
  const [edit, SetEdit] = useState(false); //--------For Edit---------//
  const [add, setAdd] = useState(false); //--------For Add---------//
  const [editID, setEditID] = useState([]); //--------For edit id---------//
  const [pageNo, setpageNo] = useState(1); //--------for pagenation-------//
  const [pageSize, setpageSize] = useState(10); //-----------for pagenation------//
  const { pathname } = useLocation();
  const [access, setAccess] = useState(null); //--------
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState(false);
  const [fieldNmae, setFieldName] = useState(undefined);

  useEffect(() => {
    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
    }
  }, []);

  useEffect(() => {
    if (menuList !== null) {
      let payload = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (typeof fieldNmae !== "undefined") {
        payload.sorts = [`${fieldNmae},${order ? "asc" : "desc"}`];
      }
      bankList(payload);
    }
  }, [pageNo, pageSize, order]);

  /////For useing Bank Listing EndPoint//////
  const bankList = (payload) => {
    bankingService
      .getBankList(payload)
      .then((res) => {
        setBanklist(res);
        setLoader(true);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoader(false);
      });
  };

  //////////For Edit  /////////
  const getIdforUpdate = (id) => {
    bankingService
      .getUpdateId(id)
      .then((res) => {
        setEditID(res);
      })

      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      });
  };

  ///////EDIT Modal/////

  const handleClickEdit = () => {
    SetEdit(true);
  };

  const handleCloseEdit = () => {
    SetEdit(false);
    setisLoading(false);
  };

  const handleCloseAdd = () => {
    setAdd(false);
    setisLoading(false);
  };

  /////For Search Bank///////
  const handleSearch = (data) => {
    let request = {
      pageNo: 1,
      pageSize: pageSize,
      sorts: [],
    };

    if (data.name.length > 0) {
      request.name = data.name;
    }
    if (data.countryId.length > 0) {
      request.countryId = data.countryId;
    }
    if (data.city.length > 0) {
      request.city = data.city;
    }

    if (data.branchName.length > 0) {
      request.branchName = data.branchName;
    }

    if (data.ifscCode.length > 0) {
      request.ifscCode = data.ifscCode;
    }

    if (data.micrNo.length > 0) {
      request.micrNo = data.micrNo;
    }

    if (data.swiftCode.length > 0) {
      request.swiftCode = data.swiftCode;
    }

    if (data.currencyId.length > 0) {
      request.currencyId = data.currencyId;
    }

    bankingService
      .searchBank(request)
      .then((res) => {
        setBanklist(res);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      });
  };
  const handelAdd = (value) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    setisLoading(true);
    bankingService
      .addBank(value)
      .then((res) => {
        if (res) {
          toast.success("Bank Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          bankList(payload);
          setisLoading(false);
          setAdd(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      });
  };

  const handelEdit = (value) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    setisLoading(true);
    bankingService
      .updateBank(value)
      .then((res) => {
        if (res) {
          toast.success("Bank updated Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          bankList(payload);
          setisLoading(false);
          SetEdit(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      });
  };

  const deleteItem = (id) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            bankingService
              .deleteBank(id)
              .then((res) => {
                toast.success(res, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              })

              .finally(() => bankList(payload));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  /////////for pagenation////////////
  const increment = () => {
    setpageNo(pageNo + 1);
  };

  const decrement = () => {
    setpageNo(pageNo - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * pageNo) / e.target.value);
    setpageSize(e.target.value);
    setpageNo(temp);
  };

  const reLoad = () => {
    window.location.reload();
  };

  const onSort = (fieldName, value) => {
    setFieldName(fieldName);
    setOrder(value);
  };

  return access ? (
    <>
      <div className="table-responsive">
        <ToastContainer />
        {/* {!bankLists.data && <PageLoader />} */}
        {!loader && <PageLoader />}
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h1>Bank List</h1>
          <div className="d-flex align-items-center add-searchBtn">
            {(access.isWrite || access.isDelete) && (
              <button
                type="button"
                className="c-btn-1 me-1"
                onClick={() => setAdd(true)}
              >
                <span>
                  <FiPlus />
                </span>
                <strong>Bank</strong>
              </button>
            )}
            <button
              type="button"
              className="c-btn-1"
              onClick={() => setSearchBar(!searchBar)}
            >
              <span>
                <BiSearchAlt />
              </span>
              <strong>Search</strong>
            </button>
          </div>
        </div>
        <div className="cn-bank-search-w">
          <Collapse in={searchBar}>
            <div className="cn-bank-search-form">
              <h1>Search</h1>
              <Formik
                initialValues={{
                  name: "",
                  countryId: "",
                  city: "",
                  branchName: "",
                  ifscCode: "",
                  swiftCode: "",
                  micrNo: "",
                  currencyId: "",
                }}
                onSubmit={(values) => {
                  handleSearch(values);
                }}
              >
                {({ handleChange, onSubmit, values, errors, touched }) => (
                  <Form>
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <Field
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Search by Bank Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <Field
                            name="countryId"
                            className="form-control"
                            component={CountryDropDown}
                            placeholder="Search by Country"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <Field
                            name="city"
                            type="text"
                            className="form-control"
                            placeholder="Search by Location"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <Field
                            name="branchName"
                            type="text"
                            className="form-control"
                            placeholder="Search by Branch"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <Field
                            name="ifscCode"
                            type="text"
                            className="form-control"
                            placeholder="Search by IFSC Code"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <Field
                            name="micrNo"
                            type="text"
                            className="form-control"
                            placeholder="Search by MICR No"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <Field
                            name="swiftCode"
                            type="text"
                            className="form-control"
                            placeholder="Search by Swift Code"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <Field
                            name="currencyId"
                            className="form-control"
                            component={CurrencyDropDown}
                            placeholder="Search by Currency"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group d-flex justify-content-end">
                          <button type="submit" className="c-btn-1">
                            <strong>SEARCH</strong>{" "}
                          </button>

                          <button
                            type="reset"
                            className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                          >
                            <strong>Reset</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                          <button
                            // type="reset"
                            onClick={() => reLoad()}
                            className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                          >
                            <strong>Reload</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Collapse>
        </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th onClick={() => onSort("name", !order)}>
                Name <BsArrowUp className="colorChange" />{" "}
                <BsArrowDown className="colorChange" />{" "}
              </th>
              <th onClick={() => onSort("city", !order)}>
                City <BsArrowUp className="colorChange" />{" "}
                <BsArrowDown className="colorChange" />
              </th>
              <th onClick={() => onSort("countryId", !order)}>
                Country <BsArrowUp className="colorChange" />{" "}
                <BsArrowDown className="colorChange" />
              </th>
              <th onClick={() => onSort("id", !order)}>
                Currency <BsArrowUp className="colorChange" />{" "}
                <BsArrowDown className="colorChange" />
              </th>
              <th onClick={() => onSort("branchName", !order)}>
                Branch <BsArrowUp className="colorChange" />{" "}
                <BsArrowDown className="colorChange" />
              </th>
              <th onClick={() => onSort("ifscCode", !order)}>
                IFSC Code <BsArrowUp className="colorChange" />{" "}
                <BsArrowDown className="colorChange" />
              </th>
              <th>MICR No</th>
              <th onClick={() => onSort("swiftCode", !order)}>
                Swift Code <BsArrowUp className="colorChange" />{" "}
                <BsArrowDown className="colorChange" />
              </th>
              {(access.isWrite || access.isDelete) && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {bankLists.data && bankLists.data.length > 0
              ? bankLists.data.map((item, index) => {
                  return (
                    <tr key={`bank-${index}`}>
                      <td>{pageSize * pageNo - pageSize + 1 + index}</td>
                      <td>{item.name}</td>
                      <td>{item.city}</td>
                      <td>{item.countryName}</td>
                      <td>{item.currencyName}</td>
                      <td>{item.branchName}</td>
                      <td>{item.ifscCode}</td>
                      <td>{item.micrNo}</td>
                      <td>{item.swiftCode}</td>
                      {(access.isWrite || access.isDelete) && (
                        <td>
                          {access.isWrite ? (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                              }
                            >
                              <button
                                onClick={() => getIdforUpdate(item.id)}
                                type="button"
                                className="c-edit-btn"
                              >
                                {" "}
                                <BiEdit onClick={handleClickEdit} />{" "}
                              </button>
                            </OverlayTrigger>
                          ) : null}
                          {access.isDelete ? (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                              }
                            >
                              <button
                                type="button"
                                className="c-edit-btn"
                                onClick={() => deleteItem(item.id)}
                              >
                                {" "}
                                <BsTrash />{" "}
                              </button>
                            </OverlayTrigger>
                          ) : null}
                        </td>
                      )}
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        <Paginator
          pageSize={bankLists.pageSize}
          firstPage={bankLists.firstPage}
          lastPage={bankLists.lastPage}
          decrement={decrement}
          increment={increment}
          pagesizechange={pagesizechange}
          pageNo={bankLists.pageNo}
          totalPages={bankLists.totalPages}
        />

        {add ? (
          <BankAdd
            handelAdd={handelAdd}
            close={handleCloseAdd}
            isLoading={isLoading}
          />
        ) : null}
        {edit ? (
          <BankEdit
            bankFilterData={editID}
            close={handleCloseEdit}
            handelEdit={handelEdit}
            isLoading={isLoading}
          />
        ) : null}
      </div>
    </>
  ) : null;
};

export default BankListing;
