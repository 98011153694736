import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React from "react";
import { FormLabel } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import { FiChevronsRight, FiRotateCw } from "react-icons/fi";
import * as Yup from "yup";
import { InsuranceTypeDropDown } from "../../insuranceManagement/component/InsuranceTypeDropDown";
import ProductDropDown from "../ProductDropDown";

const InsuranceForm = ({ alias, onClose, onAdd, isLoading }) => {
  const insuranceSchema = Yup.object().shape({
    // productId: Yup.number().required("Product Name can't be empty"),
    insuranceTypeId: Yup.string().required("Insurance Type can't be empty"),
    policyNo: Yup.string().required("PolicyNo can't be empty"),
    // companyName: Yup.string().required("Company Name can't be empty"),
    startDate: Yup.string().required("Start Date can't be empty"),
    // endDate: Yup.string().required("End Date can't be empty"),
    premiumAmount: Yup.number().required("Premium Amount can't be empty"),
    // coverageAmount: Yup.number().required("Coverage Amount can't be empty"),
    // paymentFrequency: Yup.string().required("Payment Frequency can't be empty"),
    maturityAmount: Yup.number().required("Maturity Amount can't be empty"),
    // bonusAmount: Yup.number().required("Bonus Amount can be empty"),
    // maturityDate: Yup.string().required("Maturity Date can be empty"),
    // loyaltyAmount: Yup.number().required("Loyalty Amount can be empty"),
    // closeDate: Yup.string().required("Close Date can be empty"),
    // closeUnitRedeemed: Yup.number().required(
    //   "Close Unit Redeemed can be empty"
    // ),
    // closeValueRedeemed: Yup.number().required(
    //   "Close Unit Redeemed can be empty"
    // ),
    // closePrice: Yup.number().required("Close Price can be empty"),
    bankAccountNo: Yup.string().required("Bank AccountNo can be empty"),
    ledgerName: Yup.string().required("Ledger name can't be empty"),
  });

  return (
    <Dialog
      draggable={false}
      visible={true}
      onHide={() => onClose()}
      header={<h1>Add Insurance</h1>}
      style={{ width: "80%" }}
    >
      <section className="c-content-in">
        <Formik
          initialValues={{
            insuranceTypeId: alias,
            // productId: "",
            policyNo: "",
            bankAccountNo: "",
            companyName: "",
            startDate: "",
            endDate: "",
            premiumAmount: "",
            coverageAmount: "",
            maturityAmount: "",
            bonusAmount: "",
            maturityDate: "",
            loyaltyAmount: "",
            paymentFrequency: "",
            closeDate: "",
            closePrice: "",
            closeUnitRedeemed: "",
            closeValueRedeemed: "",
            isSurrendered: false,
            isClosed: false,
            ledgerName: "",
          }}
          validationSchema={insuranceSchema}
          onSubmit={(values, { resetForm }) => {
            let reqData = { ...values };
            reqData.maturityDate = values.maturityDate
              ? moment(values.maturityDate).format("YYYY-MM-DD")
              : null;
            reqData.endDate = values.endDate
              ? moment(values.endDate).format("YYYY-MM-DD")
              : null;
            reqData.startDate = values.startDate
              ? moment(values.startDate).format("YYYY-MM-DD")
              : null;
            reqData.closeDate = values.closeDate
              ? moment(values.closeDate).format("YYYY-MM-DD")
              : null;

            onAdd(reqData);
            resetForm();
          }}
        >
          {({
            handleChange,
            onSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Insurance Type</FormLabel>
                    <div className="d-flex">
                      <Field
                        name="insuranceTypeId"
                        className="form-control"
                        component={InsuranceTypeDropDown}
                        placeholder="Insurance Type"
                        alias={alias}
                      />
                    </div>
                    {/* {errors.insuranceTypeId && touched.insuranceTypeId ? (
                      <div className="text-danger">
                        {errors.insuranceTypeId}
                      </div>
                    ) : null} */}
                  </div>
                </div>
                {/* <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Product</FormLabel>

                    <Field
                      name="productId"
                      className="form-control"
                      component={ProductDropDown}
                    />

                    {errors.productId && touched.productId ? (
                      <div className="text-danger">{errors.productId}</div>
                    ) : null}
                  </div>
                </div> */}
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Policy Number</FormLabel>
                    <Field
                      name="policyNo"
                      className="form-control"
                      type="text"
                      placeholder="Policy Number"
                    />
                    {errors.policyNo && touched.policyNo ? (
                      <div className="text-danger">{errors.policyNo}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Bank Account</FormLabel>
                    <Field
                      name="bankAccountNo"
                      className="form-control"
                      type="text"
                      placeholder="Bank Account"
                    />
                    {errors.bankAccountNo && touched.bankAccountNo ? (
                      <div className="text-danger">{errors.bankAccountNo}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Company Name</FormLabel>

                    <Field
                      name="companyName"
                      className="form-control"
                      type="text"
                      placeholder="Company Name"
                    />
                    {errors.companyName && touched.companyName ? (
                      <div className="text-danger">{errors.companyName}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Start Date</FormLabel>
                    {/* <Field
                      name="startDate"
                      className="form-control"
                      type="date"
                      placeholder="Start Date"
                    /> */}
                    <Calendar
                      name="startDate"
                      className="w-100 mb-2 form-group c-calender-w"
                      value={values.startDate}
                      onChange={handleChange}
                      showIcon
                      dateFormat="dd/mm/yy"
                      placeholder="dd/mm/yyyy"
                      readOnlyInput
                    />
                    {errors.startDate && touched.startDate ? (
                      <div className="text-danger">{errors.startDate}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>End Date</FormLabel>

                    {/* <Field
                      name="endDate"
                      className="form-control"
                      type="date"
                      placeholder="End Date"
                    /> */}
                    <Calendar
                      name="endDate"
                      className="w-100 mb-2 form-group c-calender-w"
                      value={values.endDate}
                      onChange={handleChange}
                      showIcon
                      dateFormat="dd/mm/yy"
                      placeholder="dd/mm/yyyy"
                      readOnlyInput
                    />
                    {errors.endDate && touched.endDate ? (
                      <div className="text-danger">{errors.endDate}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Premium Amount</FormLabel>
                    <Field
                      name="premiumAmount"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Premium Amount"
                    />
                    {errors.premiumAmount && touched.premiumAmount ? (
                      <div className="text-danger">{errors.premiumAmount}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Coverage Amount</FormLabel>
                    <Field
                      name="coverageAmount"
                      className="form-control"
                      placeholder="Coverage Amount"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    {errors.coverageAmount && touched.coverageAmount ? (
                      <div className="text-danger">{errors.coverageAmount}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Maturity Amount</FormLabel>

                    <Field
                      name="maturityAmount"
                      className="form-control"
                      placeholder="Maturity Amount"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                    {errors.maturityAmount && touched.maturityAmount ? (
                      <div className="text-danger">{errors.maturityAmount}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Payment Frequency</FormLabel>
                    <select
                      className="form-control"
                      name="paymentFrequency"
                      value={values.paymentFrequency}
                      onChange={handleChange}
                      aria-label="Default select example"
                    >
                      <option>Select Payment</option>
                      <option value="monthly">Monthly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="halfyearly">Half Yearly</option>
                      <option value="annually">Annually</option>
                    </select>
                    {errors.paymentFrequency && touched.paymentFrequency ? (
                      <div className="text-danger">
                        {errors.paymentFrequency}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Bonus Amount</FormLabel>
                    <Field
                      name="bonusAmount"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Bonus Amount"
                    />
                    {errors.bonusAmount && touched.bonusAmount ? (
                      <div className="text-danger">{errors.bonusAmount}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Maturity Date</FormLabel>
                    {/* <Field
                      name="maturityDate"
                      className="form-control"
                      type="date"
                      placeholder="Maturity Date"
                    /> */}

                    <Calendar
                      name="maturityDate"
                      className="w-100 mb-2 form-group c-calender-w"
                      value={values.maturityDate}
                      onChange={handleChange}
                      showIcon
                      dateFormat="dd/mm/yy"
                      placeholder="dd/mm/yyyy"
                      readOnlyInput
                    />
                    {errors.maturityDate && touched.maturityDate ? (
                      <div className="text-danger">{errors.maturityDate}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Loyalty Amount</FormLabel>
                    <Field
                      name="loyaltyAmount"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Loyalty Amount"
                    />
                    {errors.loyaltyAmount && touched.loyaltyAmount ? (
                      <div className="text-danger">{errors.loyaltyAmount}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Close Date</FormLabel>
                    {/* <Field
                      name="closeDate"
                      className="form-control"
                      type="date"
                      placeholder="Close Date"
                    /> */}

                    <Calendar
                      name="closeDate"
                      className="w-100 mb-2 form-group c-calender-w"
                      value={values.closeDate}
                      onChange={handleChange}
                      showIcon
                      dateFormat="dd/mm/yy"
                      placeholder="dd/mm/yyyy"
                      readOnlyInput
                    />

                    {errors.closeDate && touched.closeDate ? (
                      <div className="text-danger">{errors.closeDate}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Close Price</FormLabel>
                    <Field
                      name="closePrice"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Close Price"
                    />
                    {errors.closePrice && touched.closePrice ? (
                      <div className="text-danger">{errors.closePrice}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Close Unit Redeemed</FormLabel>
                    <Field
                      name="closeUnitRedeemed"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Close Unit Redeemed"
                    />
                    {errors.closeUnitRedeemed && touched.closeUnitRedeemed ? (
                      <div className="text-danger">
                        {errors.closeUnitRedeemed}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Close Value Redeemed</FormLabel>
                    <Field
                      name="closeValueRedeemed"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Close Value Redeemed"
                    />
                    {errors.closeValueRedeemed && touched.closeValueRedeemed ? (
                      <div className="text-danger">
                        {errors.closeValueRedeemed}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Ledger Name</FormLabel>
                    <Field
                      name="ledgerName"
                      className="form-control"
                      placeholder="Ledger Name"
                      value={values.ledgerName}
                      onChange={(e) =>
                        setFieldValue(
                          "ledgerName",
                          e.target.value?.toUpperCase()
                        )
                      }
                    />
                    {errors.ledgerName && touched.ledgerName ? (
                      <div className="text-danger">{errors.ledgerName}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="d-flex c-btn-group">
                    {/* <button type="submit" className="c-btn-1" disabled={loading}>
                    <strong>Submit</strong>
                    {loading ? (
                      <span className="rotate-infinite">
                        <FiRotateCw />
                      </span>
                    ) : (
                      <span>
                        <FiChevronRight />
                      </span>
                    )}
                  </button> */}
                    <button
                      type="submit"
                      className="c-btn-1"
                      disabled={isLoading}
                    >
                      <strong>Submit</strong>
                      {isLoading ? (
                        <span className="rotate-infinite">
                          <FiRotateCw />
                        </span>
                      ) : (
                        <span>
                          <FiChevronsRight />
                        </span>
                      )}
                    </button>

                    <button type="reset" className="c-btn-1 c-del-btn">
                      <strong>Reset</strong>
                      <span>
                        <BiRevision />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>
    </Dialog>
  );
};

export default InsuranceForm;
