import React, { useEffect, useState } from "react";
// import InsuranceServices from "../../../services/InsuranceService";
import { Form } from "react-bootstrap";

export const InsuranceTypeDropDown = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const [insuranceType, setInsuranceType] = useState([
    { label: "LIFE_INSURANCE", value: "LIFE_INSURANCE" },
    { label: "TERM_LIFE_INSURANCE", value: "TERM_LIFE_INSURANCE" },
    { label: "MEDICAL_INSURANCE", value: "MEDICAL_INSURANCE" },
    { label: "ULIP", value: "ULIP" },
    { label: "TRADITIONAL", value: "TRADITIONAL" },
  ]);
  // const insuranceService = new InsuranceServices();

  // useEffect(() => {
  //   insuranceData();
  // }, [props.addedInsurance]);

  // const insuranceData = () => {
  //   insuranceService.getInsurance().then((res) => {
  //     setInsuranceType(res);
  //   });
  // };

  return (
    <Form.Select
      className="form-control"
      {...field}
      {...props}
      // value={props.alias}
      disabled={props.alias}
    >
      <option>Select insurance type</option>
      {insuranceType.map((item, index) => {
        return (
          <option key={index.toString()} value={item.value}>
            {item.label.replaceAll("_", " ")}
          </option>
        );
      })}
    </Form.Select>
  );
};
