import React, { useEffect, useState } from "react";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiEdit, BiRevision, BiSearchAlt } from "react-icons/bi";
import { BsArrowDown, BsArrowUp, BsTrash } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { Field, Formik, Form } from "formik";
import { ToastContainer, toast } from "react-toastify";
import ProductService from "../../../services/ProductService";
import EditProduct from "./EditProduct";
import BankDropDown from "../../components/BankDropDown";
import ProductDropDown from "../../components/ProductDropDown";
import AddProduct from "./AddProduct";
import Paginator from "../../components/Paginator";
import { confirmAlert } from "react-confirm-alert";
import { useLocation } from "react-router-dom";
import PageLoader from "../../components/PageLoader";

const ProductCode = () => {
  const [productList, setProductList] = useState([]); //---------For ProductList--------//
  const productService = new ProductService(); //---------For api Service -------//
  const { pathname } = useLocation();
  const [searchBar, setSearchBar] = useState(false); //--------For SearchBar---------//
  const [edit, SetEdit] = useState(false); //--------For Edit---------//
  const [add, setAdd] = useState(false); //--------For Add---------//
  const [editID, setEditID] = useState([]); //--------For edit id---------//
  const [isLoading, setisLoading] = useState(false);
  const [pageNo, setpageNo] = useState(1); //--------for pagenation-------//
  const [pageSize, setpageSize] = useState(10); //-----------for pagenation------//
  const [access, setAccess] = useState(null); //--------
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState(false);
  const [fieldNmae, setFieldName] = useState(undefined);

  // const coustomardata = JSON.parse(sessionStorage.getItem("customer"));

  useEffect(() => {
    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);

      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
    }
  }, []);

  useEffect(() => {
    if (menuList !== null) {
      let payload = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (typeof fieldNmae !== "undefined") {
        payload.sorts = [`${fieldNmae},${order ? "asc" : "desc"}`];
      }
      ListProduct(payload);
    }
  }, [pageNo, pageSize, order]);

  /////////For Product Code List///////
  const ListProduct = (payload) => {
    productService
      .getProductList(payload)
      .then((res) => {
        setProductList(res);
        setLoader(true);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoader(false);
      });
  };

  ///////EDIT Modal/////
  const handleClickEdit = () => {
    SetEdit(true);
  };
  const handleCloseEdit = () => {
    SetEdit(false);
  };
  ///////ADD Modal/////
  const handleClickAdd = () => {
    setAdd(true);
  };
  const handleCloseAdd = () => {
    setAdd(false);
  };

  ///////For Search//////
  const handleSearch = (data) => {
    let request = {
      pageNo: 1,
      pageSize: pageSize,
      sorts: [],
    };

    if (data.bankId) {
      request.bankId = data.bankId;
    }

    if (data.productId) {
      request.productId = data.productId;
    }

    if (data.code) {
      request.code = data.code;
    }

    productService.searchProduct(request).then((res) => {
      setProductList(res);
    });
  };

  //////////For Edit single ID /////////
  const getIdforUpdate = (id) => {
    productService.getUpdateId(id).then((res) => {
      setEditID(res);
    });
  };
  const handelEdit = (value) => {
    setisLoading(true);
    productService
      .updateProduct(value)
      .then((res) => {
        if (res) {
          toast.success("Product code updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setisLoading(false);
          SetEdit(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      })
      .finally(() => {
        let payload = {
          pageNo: pageNo,
          pageSize: pageSize,
        };
        ListProduct(payload);
        setisLoading(false);
      });
  };
  const handelAdd = (value) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    setisLoading(true);
    productService
      .createProduct(value)
      .then((res) => {
        if (res) {
          toast.success("Product code Added Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          ListProduct(payload);
          setisLoading(false);
          setAdd(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      });
  };

  /////////for pagenation////////////
  const increment = () => {
    setpageNo(pageNo + 1);
  };

  const decrement = () => {
    setpageNo(pageNo - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * pageNo) / e.target.value);
    setpageSize(e.target.value);
    setpageNo(temp);
  };
  ///////////Delete /////////
  const deleteItem = (id) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            productService
              .delete(id)
              .then((res) => {
                toast.success(res, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              })
              .finally(() => ListProduct(payload));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const reLoad = () => {
    window.location.reload();
  };

  const onSort = (fieldName, value) => {
    setFieldName(fieldName);
    setOrder(value);
  };
  return access ? (
    <div className="table-responsive">
      <ToastContainer />
      {/* {!productList.data && <PageLoader />} */}
      {!loader && <PageLoader />}
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h1>Product Code List</h1>
        <div className="d-flex align-items-center add-searchBtn">
          {(access.isWrite || access.isDelete) && (
            <button
              type="button"
              className="c-btn-1"
              onClick={() => setAdd(true)}
            >
              <span>
                <FiPlus />
              </span>
              <strong>Product Code</strong>
            </button>
          )}

          <button
            type="button"
            className="c-btn-1"
            onClick={() => setSearchBar(!searchBar)}
          >
            <span>
              <BiSearchAlt />
            </span>
            <strong>Search</strong>
          </button>
        </div>
      </div>
      <div className="cn-bank-search-w">
        <Collapse in={searchBar}>
          <div className="cn-bank-search-form">
            <h1>Product Code Search</h1>
            <Formik
              initialValues={{
                bankId: "",
                productId: "",
                code: "",
              }}
              onSubmit={(values) => {
                handleSearch(values);
              }}
            >
              {({ handleChange, onSubmit, values, errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="bankId"
                          className="form-control"
                          component={BankDropDown}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="productId"
                          className="form-control"
                          component={ProductDropDown}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group">
                        <Field
                          name="code"
                          type="text"
                          className="form-control"
                          placeholder="Search by Product Code"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">
                      <div className="form-group d-flex justify-content-end">
                        <button type="submit" className="c-btn-1">
                          <strong>SEARCH</strong>{" "}
                        </button>

                        <button
                          type="reset"
                          className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                        >
                          <strong>Reset</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                        <button
                          // type="reset"
                          onClick={() => reLoad()}
                          className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                        >
                          <strong>Reload</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Collapse>
      </div>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th onClick={() => onSort("bankId", !order)}>
              Bank Name <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("productId", !order)}>
              Product Name <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("description", !order)}>
              Product Description <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th>Product Code</th>

            {(access.isWrite || access.isDelete) && <th>Action</th>}
          </tr>
        </thead>

        <tbody>
          {productList.data && productList.data.length > 0
            ? productList.data.map((item, index) => {
                return (
                  <tr key={`productCode-${index}`}>
                    <td>{pageSize * pageNo - pageSize + 1 + index}</td>
                    <td>{item.bankName}</td>
                    <td>{item.productName}</td>
                    <td>{item.description}</td>
                    <td>{item.code}</td>
                    {(access.isWrite || access.isDelete) && (
                      <td>
                        {access.isWrite ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                            }
                          >
                            <button
                              onClick={() => getIdforUpdate(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <BiEdit onClick={handleClickEdit} />{" "}
                            </button>
                          </OverlayTrigger>
                        ) : null}
                        {access.isDelete ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                            }
                          >
                            <button
                              type="button"
                              className="c-edit-btn"
                              onClick={() => deleteItem(item.id)}
                            >
                              <BsTrash />{" "}
                            </button>
                          </OverlayTrigger>
                        ) : null}
                      </td>
                    )}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>

      <Paginator
        pageSize={productList.pageSize}
        firstPage={productList.firstPage}
        lastPage={productList.lastPage}
        decrement={decrement}
        increment={increment}
        pagesizechange={pagesizechange}
        pageNo={productList.pageNo}
        totalPages={productList.totalPages}
      />

      {edit ? (
        <EditProduct
          productFilterData={editID}
          handelEdit={handelEdit}
          close={handleCloseEdit}
          isLoading={isLoading}
        />
      ) : null}
      {add ? (
        <AddProduct
          handelAdd={handelAdd}
          close={handleCloseAdd}
          isLoading={isLoading}
        />
      ) : null}
    </div>
  ) : null;
};

export default ProductCode;
