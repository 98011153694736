import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { FormLabel } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";
import PropertyService from "../../services/PropertyService";
import CountryDropDown from "../components/CountryDropDown";

const EditProperty = ({ close, propertyFilterData, handelEdit, isLoading }) => {
  const propertyService = new PropertyService(); //----------For Suevice---------------//
  const [loading, setLoading] = useState(false);

  const [updateData, setUpdateData] = useState([]);

  const propertyEditSchema = Yup.object().shape({
    countryId: Yup.number().required("Country Name can't be empty"),
    // productId: Yup.number().required("Product Name can't be empty"),
    // address: Yup.string().required("Address can't be empty"),
    fundType: Yup.string().required("Fund Type can't be empty"),
    // funded: Yup.number().required("Funded can't be empty"),
    // totalValueFunded: Yup.number().required("Total Value  can't be empty"),
    // loanAccountNumber: Yup.string().required("Account Number can't be empty"),
    purchaseDate: Yup.string().required("Purchase Date  can't be empty"),
    purchasePrice: Yup.number().required("Purchase Price  can't be empty"),
    // stampDutyCost: Yup.number().required("Stamp Duty Cost  can't be empty"),
    // registrationCost: Yup.number().required("Stamp Duty Cost  can't be empty"),
    // otherCost: Yup.number().required("other Cost  can't be empty"),
    // sellDate: Yup.string().required("Sell Date  can't be empty"),
    // sellPrice: Yup.number().required("Sell Price  can't be empty"),
    // totalValueSold: Yup.number().required("Total Value Sold can't be empty"),
  });

  // const getUpdatePropertyData = (values) => {

  //   setLoading(true);
  //   propertyService
  //     .updateProperty(values)
  //     .then((res) => {
  //       setUpdateData(res);
  //       toast.success("Property Edited", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       close();
  //       getListProperties();
  //     })
  //     .catch((e) => {
  //       toast.error(e.message, {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //       setLoading(false);
  //     });
  // };

  return (
    <div>
      <Dialog
        draggable={false}
        visible={true}
        onHide={() => close()}
        header={<h1>Edit property</h1>}
        style={{ width: "80%" }}
      >
        <ToastContainer />

        <section className="c-content-in">
          <Formik
            initialValues={{
              id: propertyFilterData.id,
              countryId: propertyFilterData.countryId,
              // productId: propertyFilterData.productId,
              address: propertyFilterData.address,
              fundType: propertyFilterData.fundType,
              funded: propertyFilterData.funded,
              totalValueFunded: propertyFilterData.totalValueFunded,
              loanAccountNumber: propertyFilterData.loanAccountNumber,
              purchaseDate: propertyFilterData.purchaseDate
                ? new Date(propertyFilterData.purchaseDate)
                : null,
              purchasePrice: propertyFilterData.purchasePrice,
              stampDutyCost: propertyFilterData.stampDutyCost,
              registrationCost: propertyFilterData.registrationCost,
              otherCost: propertyFilterData.otherCost,
              sellDate: propertyFilterData.sellDate
                ? new Date(propertyFilterData.sellDate)
                : null,
              sellPrice: propertyFilterData.sellPrice,
              totalValueSold: propertyFilterData.totalValueSold,
            }}
            validationSchema={propertyEditSchema}
            onSubmit={(values, { resetForm }) => {
              let reqData = { ...values };
              reqData.sellDate = values.sellDate
                ? moment(values.sellDate).format("YYYY-MM-DD")
                : null;
              reqData.purchaseDate = values.purchaseDate
                ? moment(values.purchaseDate).format("YYYY-MM-DD")
                : null;
              handelEdit(reqData);
              resetForm();
            }}
          >
            {({ handleChange, values, errors, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <FormLabel>Select Country</FormLabel>
                      <Field
                        name="countryId"
                        placeholder="Select Country"
                        component={CountryDropDown}
                      />
                      {errors.countryId && touched.countryId ? (
                        <div className="text-danger">{errors.countryId}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Select Product</FormLabel>
                      <Field
                        name="productId"
                        className="form-control"
                        component={ProductDropDown}
                      />

                      {errors.productId && touched.productId ? (
                        <div className="text-danger">{errors.productId}</div>
                      ) : null}
                    </div>
                  </div> */}

                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <FormLabel>Adress</FormLabel>
                      <Field
                        name="address"
                        className="form-control"
                        type="textarea"
                        rows={5}
                        placeholder="Address"
                      />
                      {errors.address && touched.address ? (
                        <div className="text-danger">{errors.address}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <FormLabel> Select Fund Type</FormLabel>
                      <select
                        value={values.fundType}
                        onChange={handleChange}
                        name="fundType"
                        className="form-control"
                        aria-label="Default select example"
                      >
                        <option>Select Fund Type</option>
                        <option value="LOAN">Loan</option>
                        <option value="SELF_FUNDED">Self Funded</option>
                      </select>
                    </div>
                  </div>

                  {values.fundType === "LOAN" && (
                    <>
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <FormLabel>Funded</FormLabel>
                          <Field
                            name="funded"
                            placeholder="Funded"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <FormLabel>Total Value Funded</FormLabel>
                          <Field
                            name="totalValueFunded"
                            placeholder="Total Value Funded"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <FormLabel>Loan Account Number</FormLabel>
                          <Field
                            name="loanAccountNumber"
                            placeholder="Total Value Funded"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {values.fundType === "SELF_FUNDED" && (
                    <>
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <FormLabel>Funded</FormLabel>
                          <Field
                            name="funded"
                            placeholder="Funded"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <FormLabel>Total Value Funded</FormLabel>
                          <Field
                            name="totalValueFunded"
                            placeholder="Total Value Funded"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group">
                          <FormLabel>Loan Account Number</FormLabel>
                          <Field
                            name="loanAccountNumber"
                            placeholder="Total Value Funded"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <FormLabel>Purchase Date</FormLabel>
                      {/* <Field
                        name="purchaseDate"
                        className="form-control"
                        type="date"
                        placeholder="Purchase Date"
                      /> */}
                      <Calendar
                        name="purchaseDate"
                        className="w-100 mb-2 form-group c-calender-w"
                        value={values.purchaseDate}
                        onChange={handleChange}
                        showIcon
                        dateFormat="dd/mm/yy"
                        placeholder="dd/mm/yyyy"
                        readOnlyInput
                      />
                      {errors.purchaseDate && touched.purchaseDate ? (
                        <div className="text-danger">{errors.purchaseDate}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <FormLabel>Purchase Price</FormLabel>
                      <Field
                        name="purchasePrice"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Purchase Price"
                      />
                      {errors.purchasePrice && touched.purchasePrice ? (
                        <div className="text-danger">
                          {errors.purchasePrice}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <FormLabel>Stamp Duty Cost</FormLabel>
                      <Field
                        name="stampDutyCost"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Purchase Price"
                      />
                      {errors.stampDutyCost && touched.stampDutyCost ? (
                        <div className="text-danger">
                          {errors.stampDutyCost}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <FormLabel>Registration Cost</FormLabel>
                      <Field
                        name="registrationCost"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Registration Cost"
                      />
                      {errors.registrationCost && touched.registrationCost ? (
                        <div className="text-danger">
                          {errors.registrationCost}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <FormLabel>Any Other Cost</FormLabel>
                      <Field
                        name="otherCost"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Any Other Cost"
                      />
                      {errors.otherCost && touched.otherCost ? (
                        <div className="text-danger">{errors.otherCost}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <FormLabel>Total Initial Cost</FormLabel>
                      <Field
                        name="totalValueSold"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Total Initial Cost"
                      />
                      {errors.totalValueSold && touched.totalValueSold ? (
                        <div className="text-danger">
                          {errors.totalValueSold}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <FormLabel>Sell Date</FormLabel>
                      {/* <Field
                        name="sellDate"
                        className="form-control"
                        type="date"
                        placeholder="Sell Date"
                      /> */}
                      <Calendar
                        name="sellDate"
                        className="w-100 mb-2 form-group c-calender-w"
                        value={values.sellDate}
                        onChange={handleChange}
                        showIcon
                        dateFormat="dd/mm/yy"
                        placeholder="dd/mm/yyyy"
                        readOnlyInput
                      />
                      {errors.sellDate && touched.sellDate ? (
                        <div className="text-danger">{errors.sellDate}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <FormLabel>Sell Price</FormLabel>
                      <Field
                        name="sellPrice"
                        className="form-control"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        placeholder="Sell Price"
                      />
                      {errors.sellPrice && touched.sellPrice ? (
                        <div className="text-danger">{errors.sellPrice}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="d-flex c-btn-group">
                      <button
                        type="submit"
                        className="c-btn-1"
                        disabled={isLoading}
                      >
                        <strong>Update</strong>
                        {isLoading ? (
                          <span className="rotate-infinite">
                            <FiRotateCw />
                          </span>
                        ) : (
                          <span>
                            <FiChevronRight />
                          </span>
                        )}
                      </button>

                      <button
                        onClick={() => close()}
                        type="close"
                        className="c-btn-1 c-del-btn"
                      >
                        <strong>Cancel</strong>
                        <span>
                          <BiRevision />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </Dialog>
    </div>
  );
};

export default EditProperty;
