import React, { useState } from "react";
import { FormLabel, Modal } from "react-bootstrap";
import InsuranceServices from "../../services/InsuranceService";
import { BiRevision } from "react-icons/bi";
import { Field, Form, Formik } from "formik";
import { InsuranceTypeDropDown } from "./component/InsuranceTypeDropDown";
import * as Yup from "yup";
import { FiChevronsRight, FiRotateCw } from "react-icons/fi";
import ProductDropDown from "../components/ProductDropDown";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";

export const EditInsurance = ({
  close,
  insuranceFilterData,
  getUpdateInsuranceData,
  loading,
}) => {
  const insuranceService = new InsuranceServices();

  const insuranceEditSchema = Yup.object().shape({
    // productId: Yup.number().required("Product Name can't be empty"),
    insuranceTypeId: Yup.string().required("Insurance Type can't be empty"),
    policyNo: Yup.string().required("PolicyNo can't be empty"),
    // companyName: Yup.string().required("Company Name can't be empty"),
    startDate: Yup.string().required("Start Date can't be empty"),
    // endDate: Yup.string().required("End Date can't be empty"),
    premiumAmount: Yup.number().required("Premium Amount can't be empty"),
    // coverageAmount: Yup.number().required("Coverage Amount can't be empty"),
    // paymentFrequency: Yup.string().required("Payment Frequency can't be empty"),
    maturityAmount: Yup.number().required("Maturity Amount can't be empty"),
    // bonusAmount: Yup.number().required("Bonus Amount can be empty"),
    // maturityDate: Yup.string().required("Maturity Date can be empty"),
    // loyaltyAmount: Yup.number().required("Loyalty Amount can be empty"),
    // closeDate: Yup.string().required("Close Date can be empty"),
    // closeUnitRedeemed: Yup.number().required(
    //   "Close Unit Redeemed can be empty"
    // ),
    // closeValueRedeemed: Yup.number().required(
    //   "Close Unit Redeemed can be empty"
    // ),
    // closePrice: Yup.number().required("Close Price can be empty"),
    // bankAccountNo: Yup.string().required("Bank AccountNo can be empty"),
  });

  //For using Update Endpoint/////

  // const getUpdateInsuranceData1 = (values) => {
  //   console.log("values==>", values);
  //   insuranceService.upDatInsurance(values).then((res) => {
  //     setUpdateData(res);
  //     close();
  //   });
  // };

  return (
    <div>
      <section className="c-inner-page-2 ">
        <Dialog
          draggable={false}
          visible={true}
          onHide={() => close()}
          header={<h1>Edit Insurance</h1>}
          style={{ width: "80%" }}
        >
          <section className="c-content-in">
            <Formik
              initialValues={{
                id: insuranceFilterData.id,
                insuranceTypeId: insuranceFilterData.insuranceTypeId,
                // productId: insuranceFilterData.productId,
                policyNo: insuranceFilterData.policyNo,
                bankAccountNo: insuranceFilterData.bankAccountNo,
                companyName: insuranceFilterData.companyName,
                startDate: insuranceFilterData.startDate
                  ? new Date(insuranceFilterData.startDate)
                  : null,
                endDate: insuranceFilterData.endDate
                  ? new Date(insuranceFilterData.endDate)
                  : null,
                premiumAmount: insuranceFilterData.premiumAmount,
                coverageAmount: insuranceFilterData.coverageAmount,
                maturityAmount: insuranceFilterData.maturityAmount,
                bonusAmount: insuranceFilterData.bonusAmount,
                maturityDate: insuranceFilterData.maturityDate
                  ? new Date(insuranceFilterData.maturityDate)
                  : null,
                loyaltyAmount: insuranceFilterData.loyaltyAmount,
                paymentFrequency: insuranceFilterData.paymentFrequency,
                closeDate: insuranceFilterData.closeDate
                  ? new Date(insuranceFilterData.closeDate)
                  : null,
                closePrice: insuranceFilterData.closePrice,
                closeUnitRedeemed: insuranceFilterData.closeUnitRedeemed,
                closeValueRedeemed: insuranceFilterData.closeValueRedeemed,
                isSurrendered: false,
                isClosed: false,
              }}
              validationSchema={insuranceEditSchema}
              onSubmit={(values, { resetForm }) => {
                let reqData = { ...values };
                reqData.startDate = values.startDate
                  ? moment(values.startDate).format("YYYY-MM-DD")
                  : null;
                reqData.endDate = values.endDate
                  ? moment(values.endDate).format("YYYY-MM-DD")
                  : null;
                reqData.maturityDate = values.maturityDate
                  ? moment(values.maturityDate).format("YYYY-MM-DD")
                  : null;
                reqData.closeDate = values.closeDate
                  ? moment(values.closeDate).format("YYYY-MM-DD")
                  : null;

                getUpdateInsuranceData(reqData);
                resetForm();
              }}
            >
              {({ handleChange, onSubmit, values, errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Insurance Type</FormLabel>
                        <Field
                          name="insuranceTypeId"
                          className="form-control"
                          component={InsuranceTypeDropDown}
                          placeholder="Insurance Type"
                        />
                        {errors.insuranceTypeId && touched.insuranceTypeId ? (
                          <div className="text-danger">
                            {errors.insuranceTypeId}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Product </FormLabel>
                        <Field
                          name="productId"
                          className="form-control"
                          component={ProductDropDown}
                        />

                        {errors.productId && touched.productId ? (
                          <div className="text-danger">{errors.productId}</div>
                        ) : null}
                      </div>
                    </div> */}
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Policy Number</FormLabel>
                        <Field
                          name="policyNo"
                          className="form-control"
                          type="text"
                          placeholder="Policy Number"
                        />
                        {errors.policyNo && touched.policyNo ? (
                          <div className="text-danger">{errors.policyNo}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Bank Account</FormLabel>

                        <Field
                          name="bankAccountNo"
                          className="form-control"
                          type="text"
                          placeholder="Insurance Company"
                        />
                        {errors.bankAccountNo && touched.bankAccountNo ? (
                          <div className="text-danger">
                            {errors.bankAccountNo}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Company Name</FormLabel>

                        <Field
                          name="companyName"
                          className="form-control"
                          type="text"
                          placeholder="Company Name"
                        />
                        {errors.companyName && touched.companyName ? (
                          <div className="text-danger">
                            {errors.companyName}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Start Date</FormLabel>

                        {/* <Field
                          name="startDate"
                          className="form-control"
                          type="date"
                          placeholder="Start Date"
                        /> */}
                        <Calendar
                          name="startDate"
                          className="w-100 mb-2 form-group c-calender-w"
                          value={values.startDate}
                          onChange={handleChange}
                          showIcon
                          dateFormat="dd/mm/yy"
                          placeholder="dd/mm/yyyy"
                          readOnlyInput
                        />
                        {errors.startDate && touched.startDate ? (
                          <div className="text-danger">{errors.startDate}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>End Date</FormLabel>

                        {/* <Field
                          name="endDate"
                          className="form-control"
                          type="date"
                          placeholder="Preminum Account"
                        /> */}
                        <Calendar
                          name="endDate"
                          className="w-100 mb-2 form-group c-calender-w"
                          value={values.endDate}
                          onChange={handleChange}
                          showIcon
                          dateFormat="dd/mm/yy"
                          placeholder="dd/mm/yyyy"
                          readOnlyInput
                        />
                        {errors.endDate && touched.endDate ? (
                          <div className="text-danger">{errors.endDate}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Premium Amount</FormLabel>

                        <Field
                          name="premiumAmount"
                          className="form-control"
                          placeholder="Payment Frequency"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                        />
                        {errors.premiumAmount && touched.premiumAmount ? (
                          <div className="text-danger">
                            {errors.premiumAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Coverage Amount</FormLabel>

                        <Field
                          name="coverageAmount"
                          className="form-control"
                          placeholder="Coverage Amount"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                        />
                        {errors.coverageAmount && touched.coverageAmount ? (
                          <div className="text-danger">
                            {errors.coverageAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Maturity Amount</FormLabel>

                        <Field
                          name="maturityAmount"
                          className="form-control"
                          placeholder="Maturity Amount"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                        />
                        {errors.maturityAmount && touched.maturityAmount ? (
                          <div className="text-danger">
                            {errors.maturityAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Payment Frequency</FormLabel>
                        <select
                          className="form-control"
                          name="paymentFrequency"
                          value={values.paymentFrequency}
                          onChange={handleChange}
                          aria-label="Default select example"
                        >
                          <option>Select Payment</option>
                          <option value="monthly">Monthly</option>
                          <option value="quarterly">Quarterly</option>
                          <option value="halfyearly">Half Yearly</option>
                          <option value="annually">Annually</option>
                        </select>
                        {errors.paymentFrequency && touched.paymentFrequency ? (
                          <div className="text-danger">
                            {errors.paymentFrequency}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Bonus Amount</FormLabel>
                        <Field
                          name="bonusAmount"
                          className="form-control"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Bonus Amount"
                        />
                        {errors.bonusAmount && touched.bonusAmount ? (
                          <div className="text-danger">
                            {errors.bonusAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Close Date</FormLabel>
                        {/* <Field
                          name="closeDate"
                          className="form-control"
                          type="date"
                          placeholder="Close Date"
                        /> */}
                        <Calendar
                          name="closeDate"
                          className="w-100 mb-2 form-group c-calender-w"
                          value={values.closeDate}
                          onChange={handleChange}
                          showIcon
                          dateFormat="dd/mm/yy"
                          placeholder="dd/mm/yyyy"
                          readOnlyInput
                        />
                        {errors.closeDate && touched.closeDate ? (
                          <div className="text-danger">{errors.closeDate}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Close Price</FormLabel>
                        <Field
                          name="closePrice"
                          className="form-control"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Close Price"
                        />
                        {errors.closePrice && touched.closePrice ? (
                          <div className="text-danger">{errors.closePrice}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Close Unit Redeemed</FormLabel>
                        <Field
                          name="closeUnitRedeemed"
                          className="form-control"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Close Unit Redeemed"
                        />
                        {errors.closeUnitRedeemed &&
                        touched.closeUnitRedeemed ? (
                          <div className="text-danger">
                            {errors.closeUnitRedeemed}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Close Value Redeemed</FormLabel>
                        <Field
                          name="closeValueRedeemed"
                          className="form-control"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          placeholder="Close Value Redeemed"
                        />
                        {errors.closeValueRedeemed &&
                        touched.closeValueRedeemed ? (
                          <div className="text-danger">
                            {errors.closeValueRedeemed}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="d-flex c-btn-group">
                        <button
                          type="submit"
                          className="c-btn-1"
                          disabled={loading}
                        >
                          <strong>Update</strong>
                          {loading ? (
                            <span className="rotate-infinite">
                              <FiRotateCw />
                            </span>
                          ) : (
                            <span>
                              <FiChevronsRight />
                            </span>
                          )}
                        </button>

                        <button
                          onClick={() => close()}
                          type="reset"
                          className="c-btn-1 c-del-btn"
                        >
                          <strong>Cancel</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </section>
        </Dialog>
      </section>
    </div>
  );
};
