import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import AccountantService from "../../services/AccountantService";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { FiChevronRight, FiChevronsRight, FiRotateCw } from "react-icons/fi";
import { BiRevision } from "react-icons/bi";
import {
	errorToaster,
	inputField,
	successToaster,
} from "../../Global Component/FormField";

const AddAccountant = () => {
	const accountantService = new AccountantService(); //-----------For Service Call-------//
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const accountantSchema = Yup.object().shape({
		fullName: Yup.string()
			.matches(/^[a-zA-Z ]*$/, "Use only alphabetical characters")
			.required("FullName can't be empty"),

		mobileNo: Yup.string()
			.matches(/^[0]?[6789]\d{9}$/, "Invalid Mobile No.")
			.required("MobileNo can't be empty"),
		email: Yup.string()
			.email("Invalied Email")
			.required("Email can't be empty"),
		userName: Yup.string().required("User Name can't be empty"),
		password: Yup.string().required("Password can't be empty"),
	});

	const createAccountant = (values) => {
		setLoading(true);
		accountantService
			.createAccountant(values)
			.then((res) => {
				successToaster({ label: "Accountant Created Successfully" });
				setTimeout(() => {
					navigate("/accountant");
				}, 1000);

				setLoading(false);
			})
			.catch((e) => {
				errorToaster({ label: e.message });
				setLoading(false);
			});
	};

	return (
		<div className="table-responsive">
			<ToastContainer />
			<div className="row">
				<div className="d-flex align-items-center justify-content-between w-100">
					<h1>Add Accountant</h1>
					<Link to="/accountant">
						<button type="button" className="c-btn-1">
							<span>
								<FiChevronRight />
							</span>
							<strong>Back</strong>
						</button>
					</Link>
				</div>

				<Formik
					initialValues={{
						fullName: "",
						mobileNo: "",
						email: "",
						userName: "",
						password: "",
					}}
					validationSchema={accountantSchema}
					onSubmit={(values, { resetForm }) => {
						createAccountant(values);
						resetForm();
					}}
				>
					{({ handleChange, values, errors, touched }) => (
						<Form>
							<div className="row">
								<div className="col-lg-6 col-md-12">
									<Field
										name="fullName"
										className="form-control"
										type="text"
										placeholder="Full Name"
										label="Full Name"
										component={inputField}
									/>
								</div>

								<div className="col-lg-6 col-md-12">
									<Field
										name="mobileNo"
										className="form-control"
										type="number"
										placeholder="Mobile No"
										label="Mobile No"
										component={inputField}
									/>
								</div>
								<div className="col-lg-6 col-md-12">
									<Field
										name="email"
										className="form-control"
										type="text"
										placeholder="Email"
										label="Email"
										component={inputField}
									/>
								</div>

								<div className="col-lg-6 col-md-12">
									<Field
										name="userName"
										className="form-control"
										type="text"
										placeholder="User Name"
										label="User Name"
										component={inputField}
									/>
								</div>

								<div className="col-lg-6 col-md-12">
									<Field
										name="password"
										className="form-control"
										type="password"
										placeholder="Password"
										label="Password"
										component={inputField}
									/>
								</div>

								<div className="col-lg-12 col-md-12">
									<div className="d-flex c-btn-group">
										<button
											type="submit"
											className="c-btn-1"
											disabled={loading}
										>
											<strong>Submit</strong>
											{loading ? (
												<span className="rotate-infinite">
													<FiRotateCw />
												</span>
											) : (
												<span>
													<FiChevronsRight />
												</span>
											)}
										</button>

										<button
											type="reset"
											className="c-btn-1 c-del-btn"
										>
											<strong>Reset</strong>
											<span>
												<BiRevision />
											</span>
										</button>
									</div>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default AddAccountant;
