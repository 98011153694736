import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Field, Formik, Form } from "formik";
import { BiEdit, BiRevision, BiSearchAlt } from "react-icons/bi";
import { FiPlus } from "react-icons/fi";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsArrowDown, BsArrowUp, BsTrash } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert";
import { RelationshipService } from "../../../services/RelationshipService";
import BankDropDown from "../../components/BankDropDown";
import RelationshipEdit from "./RelationshipEdit";
import RelationshipAdd from "./RelationshipAdd";
import Paginator from "../../components/Paginator";
import PageLoader from "../../components/PageLoader";
import { useLocation } from "react-router-dom";

const RelationshipList = () => {
  const [relationshipLists, setRelationshiplist] = useState([]); //-----Relationship Listing-----//
  const [isLoading, setisLoading] = useState(false);
  const toastTL = useRef(null); //-----------For Toster Msg----------------//
  const [searchBar, setSearchBar] = useState(false); //--------For SearchBar---------//
  const [edit, SetEdit] = useState(false); //--------For Edit---------//
  const [add, setAdd] = useState(false); //--------For Add---------//
  const [editID, setEditID] = useState([]); //--------For edit id---------//
  const [pageNo, setpageNo] = useState(1); //--------for pagenation-------//
  const [pageSize, setpageSize] = useState(10); //-----------for pagenation------//
  const { pathname } = useLocation();
  const [access, setAccess] = useState(null); //--------
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState(false);
  const [fieldNmae, setFieldName] = useState(undefined);

  useEffect(() => {
    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
    }
  }, []);

  const relationshipService = new RelationshipService();

  useEffect(() => {
    if (menuList !== null) {
      let payload = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (typeof fieldNmae !== "undefined") {
        payload.sorts = [`${fieldNmae},${order ? "asc" : "desc"}`];
      }
      relationshipList(payload);
    }
  }, [pageNo, pageSize, order]);

  const handleClickEdit = () => {
    SetEdit(true);
  };

  const handleCloseEdit = () => {
    SetEdit(false);
    setisLoading(false);
  };
  const handleCloseAdd = () => {
    setAdd(false);
    setisLoading(false);
  };

  const relationshipList = (payload) => {
    relationshipService
      .getRelationshipList(payload)
      .then((res) => {
        setRelationshiplist(res);
        setLoader(true);
      })
      .catch((e) => {
        toastTL.current.show({
          severity: "error",
          summary: e.name,
          detail: e.message,
          style: { color: "#000000" },
          sticky: true,
        });
        setLoader(false);
      });
  };

  /////////for pagenation////////////
  const increment = () => {
    setpageNo(pageNo + 1);
  };

  const decrement = () => {
    setpageNo(pageNo - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * pageNo) / e.target.value);
    setpageSize(e.target.value);
    setpageNo(temp);
  };
  ///Edit ID For Relationship////
  const getIdforUpdate = (id) => {
    relationshipService.getRelationshipDetails(id).then((res) => {
      setEditID(res);
    });
  };

  const handleSearch = (data) => {
    let request = {
      pageNo: 1,
      pageSize: pageSize,
      sorts: [],
    };
    if (data.bankId) {
      request.bankId = data.bankId;
    }
    if (data.relationshipNo.length > 0) {
      request.relationshipNo = data.relationshipNo;
    }
    relationshipService
      .searchRelationship(request)
      .then((res) => {
        setRelationshiplist(res);
      })

      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      });
  };
  const handelAdd = (value) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    setisLoading(true);
    relationshipService
      .addRelation(value)
      .then((res) => {
        if (res) {
          toast.success("Relationship Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          relationshipList(payload);
          setisLoading(false);
          setAdd(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      });
  };
  const handelEdit = (value) => {
    setisLoading(true);
    relationshipService
      .updateRelation(value)
      .then((res) => {
        if (res) {
          toast.success("Relationship updated Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          setisLoading(false);
          SetEdit(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setisLoading(false);
      })

      .finally(() => {
        let payload = {
          pageNo: pageNo,
          pageSize: pageSize,
        };
        relationshipList(payload);
        setisLoading(false);
      });
  };

  const deleteItem = (id) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            relationshipService
              .deleteRelation(id)
              .then((res) => {
                toast.success(res, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              })
              .finally(() => relationshipList(payload));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const reLoad = () => {
    window.location.reload();
  };

  const onSort = (fieldName, value) => {
    setFieldName(fieldName);
    setOrder(value);
  };

  return access ? (
    <>
      <div className="table-responsive">
        <ToastContainer />
        {/* {!relationshipLists.data && <PageLoader />} */}
        {!loader && <PageLoader />}
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h1>Bank Relationship</h1>
          <div className="d-flex align-items-center add-searchBtn">
            {(access.isWrite || access.isDelete) && (
              <button
                type="button"
                className="c-btn-1 me-1"
                onClick={() => setAdd(true)}
              >
                <span>
                  <FiPlus />
                </span>
                <strong>Relation</strong>
              </button>
            )}
            <button
              type="button"
              className="c-btn-1"
              onClick={() => setSearchBar(!searchBar)}
            >
              <span>
                <BiSearchAlt />
              </span>
              <strong>Search</strong>
            </button>
          </div>
        </div>
        <div className="cn-bank-search-w">
          <Collapse in={searchBar}>
            <div className="cn-bank-search-form">
              <h1>Search</h1>
              <Formik
                initialValues={{
                  relationshipNo: "",
                  bankId: "",
                }}
                onSubmit={(values) => {
                  handleSearch(values);
                }}
              >
                {({ handleChange, onSubmit, values, errors, touched }) => (
                  <Form>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <Field
                            name="relationshipNo"
                            type="text"
                            className="form-control"
                            placeholder="Search by Relationship No"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <Field
                            name="bankId"
                            className="form-control"
                            component={BankDropDown}
                          />
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-6 m-auto">
                        <div className="form-group d-flex justify-content-end">
                          <button type="submit" className="c-btn-1">
                            <strong>SEARCH</strong>{" "}
                          </button>
                          <button
                            type="reset"
                            className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                          >
                            <strong>Reset</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                          <button
                            // type="reset"
                            onClick={() => reLoad()}
                            className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                          >
                            <strong>Reload</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Collapse>
        </div>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th onClick={() => onSort("bankId", !order)}>
                Bank Name <BsArrowUp className="colorChange" />{" "}
                <BsArrowDown className="colorChange" />
              </th>
              <th>Relationship</th>
              {(access.isWrite || access.isDelete) && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {relationshipLists.data && relationshipLists.data.length > 0
              ? relationshipLists.data.map((item, index) => {
                  return (
                    <tr key={`relationShip-${index}`}>
                      <td>{pageSize * pageNo - pageSize + 1 + index}</td>
                      <td>{item.bankName}</td>
                      <td>{item.relationshipNo}</td>
                      {(access.isWrite || access.isDelete) && (
                        <td>
                          {access.isWrite ? (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                              }
                            >
                              <button
                                onClick={() => getIdforUpdate(item.id)}
                                type="button"
                                className="c-edit-btn"
                              >
                                {" "}
                                <BiEdit onClick={handleClickEdit} />{" "}
                              </button>
                            </OverlayTrigger>
                          ) : null}
                          {access.isDelete ? (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                              }
                            >
                              <button
                                type="button"
                                className="c-edit-btn"
                                onClick={() => deleteItem(item.id)}
                              >
                                {" "}
                                <BsTrash />{" "}
                              </button>
                            </OverlayTrigger>
                          ) : null}
                        </td>
                      )}
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        <Paginator
          pageSize={relationshipLists.pageSize}
          firstPage={relationshipLists.firstPage}
          lastPage={relationshipLists.lastPage}
          decrement={decrement}
          increment={increment}
          pagesizechange={pagesizechange}
          pageNo={relationshipLists.pageNo}
          totalPages={relationshipLists.totalPages}
        />

        {edit ? (
          <RelationshipEdit
            bankFilterData={editID}
            close={handleCloseEdit}
            handelEdit={handelEdit}
            isLoading={isLoading}
          />
        ) : null}
        {add ? (
          <RelationshipAdd
            handelAdd={handelAdd}
            selectedBankId={""}
            close={handleCloseAdd}
            isLoading={isLoading}
          />
        ) : null}
      </div>
    </>
  ) : null;
};

export default RelationshipList;
