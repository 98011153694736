import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import { BiRevision, BiSearchAlt } from "react-icons/bi";
import * as Yup from "yup";
import Config from "../../shared/Config";
import { Calendar } from "primereact/calendar";

const SearchByYear = ({
  onSearch,
  heading,
  initialDate = "CURRENT_FINANCIAL_YEAR",
}) => {
  const [toggle, setToggle] = useState(true);

  const searchSchema = Yup.object().shape({
    date: Yup.string().required("Date can't be empty."),
    startYear: Yup.date().when("date", {
      is: (type) => type === "CUSTOM_DATE_RANGE",
      then: () => Yup.string().required("Start year can't be empty"),
    }),
    endYear: Yup.date().when("date", {
      is: (type) => type === "CUSTOM_DATE_RANGE",
      then: () => Yup.string().required("End year can't be empty"),
    }),
    // endYear: Yup.string().required("End year can't be empty."),
  });

  return (
    <div className="row">
      {heading && (
        <div className="d-flex align-items-center justify-content-between">
          <h3>{heading ?? ""}</h3>
          <button
            type="button"
            className="c-btn-1"
            onClick={() => setToggle((prev) => !prev)}
          >
            <span>
              <BiSearchAlt />
            </span>
            <strong>Search</strong>
          </button>
        </div>
      )}

      <div className="cn-bank-search-w col-12">
        <Collapse in={toggle}>
          <div className="cn-bank-search-form">
            <h1>Search</h1>
            <Formik
              initialValues={{
                date: initialDate,
                startYear: "",
                endYear: "",
              }}
              validationSchema={searchSchema}
              onSubmit={(values) => {
                onSearch(values);
              }}
            >
              {({
                handleSubmit,
                values,
                errors,
                touched,
                resetForm,
                setFieldValue,
                handleChange,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <Field
                          value={values.date}
                          onChange={(e) => {
                            if (e.target.value !== "CUSTOM_DATE_RANGE") {
                              setFieldValue("startYear", "", true);
                              setFieldValue("endYear", "", true);
                            }
                            setFieldValue("date", e.target.value);
                          }}
                          name="date"
                          className="form-control"
                          placeholder="Select "
                          component="select"
                        >
                          <option value={""}>Select</option>
                          {Config?.BalanceSheetSearhDateEnum?.map((elm, i) => (
                            <option key={i} value={elm?.value}>
                              {elm?.label}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                    {values?.date === "CUSTOM_DATE_RANGE" && (
                      <>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <Calendar
                              name="startYear"
                              className="w-100 mb-2 form-group c-calender-w"
                              value={values.startYear}
                              onChange={handleChange}
                              showIcon
                              dateFormat="dd/mm/yy"
                              placeholder="dd/mm/yyyy"
                              readOnlyInput
                            />

                            {errors.startYear && touched.startYear ? (
                              <div className="text-danger">
                                {errors.startYear}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group c-">
                            <Calendar
                              name="endYear"
                              className="w-100 mb-2 form-group c-calender-w"
                              value={values.endYear}
                              onChange={handleChange}
                              showIcon
                              dateFormat="dd/mm/yy"
                              placeholder="dd/mm/yyyy"
                              readOnlyInput
                            />

                            {errors.endYear && touched.endYear ? (
                              <div className="text-danger">
                                {errors.endYear}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-lg-5 col-md-6 m-auto">
                      <div className="form-group d-flex justify-content-end gap-1">
                        <button type="submit" className="c-btn-1">
                          <strong>SEARCH</strong>
                        </button>
                        <button
                          type="button"
                          onClick={() => resetForm()}
                          className="c-btn-1 c-del-btn c-cancel-btn "
                        >
                          <span>
                            <BiRevision />
                          </span>
                          <strong>Reset</strong>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default SearchByYear;
