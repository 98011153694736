import React, { useEffect, useState } from "react";
import AccountService from "../../services/AccountService";
import { Form } from "react-bootstrap";

const AccountGroupDropDown = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const accountSevice = new AccountService(); //-----------For Service Call-------//
  const [accountName, setAccountName] = useState([]);

  useEffect(() => {
    accountGroupName();
  }, []);

  const accountGroupName = () => {
    accountSevice.getAccountGroupListAll().then((res) => {
      setAccountName(res);
    });
  };

  return (
    <div>
      <Form.Select className="form-control" {...field} {...props}>
        <option value="null">Select Group</option>
        {accountName.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export default AccountGroupDropDown;
