import { useField } from "formik";
import { Dropdown } from "primereact/dropdown";
import { FormLabel } from "react-bootstrap";

const DropdownComponent = (props) => {
  const [field, meta] = useField(props.name);
  return (
    <div className="form-group d-flex flex-column mb-0">
      {props?.header && <FormLabel>{props?.header}</FormLabel>}
      <Dropdown
        className="p-2"
        {...field}
        {...props}

        // onChange={props.onChange}
      />
    </div>
  );
};

export default DropdownComponent;
