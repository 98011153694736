import React, { useEffect, useState } from "react";
import Profile_img from "../assets/images/Profile.png";
import Bank_img from "../assets/images/Bank.png";
import Product_img from "../assets/images/Product-code.png";
import Account_img from "../assets/images/Accountant-management.png";
import Credit_Card from "../assets/images/credit-card.png";
import Bond_img from "../assets/images/bonds.png";
import Insurance_img from "../assets/images/insurance.png";
import Mapping_img from "../assets/images/mapping.png";
import Relation_img from "../assets/images/relation.png";
import Property_img from "../assets/images/property.png";
import Stock_img from "../assets/images/stock.png";
import Mutual_img from "../assets/images/mutua-funds.png";
import Menu_img from "../assets/images/menu.png";
import Control_img from "../assets/images/control.png";
import group_img from "../assets/images/account-group.png";
import Ledger_img from "../assets/images/account-ledger.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import Config from "../shared/Config";

function Dashboard() {
  const { pathname } = useLocation();
  const [access, setAccess] = useState(null); //--------
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList")) || [];

  return (
    <section className="c-dashboard-list">
      <div className="container">
        <div className="row">
          {menuList.length > 0 &&
            menuList.map((item, index) => {
              return (
                <div
                  key={`menu-${index}`}
                  className="col-lg-3 col-md-4 col-xs-6">
                  <div className="c-dashboard-box">
                    <NavLink to={item.url}>&nbsp</NavLink>
                    <span>
                      <img
                        src={
                          item.darkIcon
                            ? `${Config.S3_CONFIG.S3_ACCESS_URL}menuIcon/${item.darkIcon}`
                            : Profile_img
                        }
                        alt=""
                        width="30"
                      />
                    </span>

                    <h3>{item.name}</h3>
                  </div>
                </div>
              );
            })}
          {/* <div className="col-lg-3 col-md-4 col-xs-6"> */}

          {/* {menuList.length > 0 &&
              menuList.map((item, index) => {
                return (
                  <div className="c-dashboard-box">
                    <div key={`menu-${index}`}>
                      <NavLink to={item.url}>
                        <span>
                          <img
                            src={
                              item.darkIcon
                                ? `${Config.S3_CONFIG.S3_ACCESS_URL}menuIcon/${item.darkIcon}`
                                : Profile_img
                            }
                            alt=""
                            width="30"
                          />
                        </span>
                      </NavLink>
                    </div>
                  </div>
                );
              })} */}
          {/* </div> */}

          {/* <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={Mapping_img} alt="" />
              </span>
              <h3>Product Mapping</h3>
              <Link to="/product-mapping"></Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={Product_img} alt="" />
              </span>
              <h3>Product Code</h3>
              <Link to="/product-code"></Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={Bank_img} alt="" />
              </span>
              <h3>Bank</h3>
              <Link to="/bank"></Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={Relation_img} alt="" />
              </span>
              <h3>Relation</h3>
              <Link to="/bank-relationship"></Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={Account_img} alt="" />
              </span>
              <h3>Account</h3>
              <Link to="/bank-account"></Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={Credit_Card} alt="" />
              </span>
              <h3>Credit Card</h3>
              <Link to="/credit-card"></Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={Insurance_img} alt="" />
              </span>
              <h3>Insurance</h3>
              <Link to="/insurance"></Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={Bond_img} alt="" />
              </span>
              <h3>Bonds</h3>
              <Link to="/bonds"></Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={Stock_img} alt="" />
              </span>
              <h3>Stock</h3>
              <Link to="/stock"></Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={Mutual_img} alt="" />
              </span>
              <h3>Mutual Fund</h3>
              <Link to="/mutualfund"></Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={Property_img} alt="" />
              </span>
              <h3>Property</h3>
              <Link to="/property"></Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={Menu_img} alt="" />
              </span>
              <h3>Menu</h3>
              <Link to="/menu"></Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={Control_img} alt="" />
              </span>
              <h3>Access Control</h3>
              <Link to="/access-control"></Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={group_img} alt="" />
              </span>
              <h3>Account Group</h3>
              <Link to="/account-group"></Link>
            </div>
          </div>

          <div className="col-lg-3 col-md-4 col-xs-6">
            <div className="c-dashboard-box">
              <span>
                <img src={Ledger_img} alt="" />
              </span>
              <h3>Account Ledger</h3>
              <Link to="/ledger"></Link>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
