import { Field, Form, Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { BiRevision } from "react-icons/bi";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import { FormLabel } from "react-bootstrap";
import CountryDropDown from "../../components/CountryDropDown";
import CurrencyDropDown from "../../components/CurrencyDropDown";
import axios from "axios";
import { Dialog } from "primereact/dialog";

const BankAdd = ({ close, isLoading, handelAdd }) => {
  const bankAddSchema = Yup.object().shape({
    name: Yup.string().required("Bank Name can't be empty"),
    countryId: Yup.string().required("Cuntry Name can't be empty"),
    // city: Yup.string().required("Bank City can't be empty"),
    // branchName: Yup.string().required("Branch Name can't be empty"),
    // swiftCode: Yup.string().required("Swift Code can't be empty"),
    // micrNo: Yup.string().required("Micr No can't be empty"),
    ifscCode: Yup.string()
      .length(11, "Please enter valid IFSC")
      .required("Bank  IFSC code can't be empty"),
    currencyId: Yup.string().required("Base Currency can't be empty"),
  });
  const ifscCheck = (e, setFieldValue) => {
    setFieldValue("ifscCode", e, true);
    if (e.length === 11) {
      axios.get(`https://ifsc.razorpay.com/${e}`).then((res) => {
        setFieldValue("name", res.data.BANK, true);
        setFieldValue("micrNo", res.data.MICR, true);
        setFieldValue("ifscCode", res.data.IFSC, true);
        setFieldValue("city", res.data.CITY, true);
        setFieldValue("branchName", res.data.BRANCH, true);
        // setFieldValue("swiftCode", res.data.SWIFT, true);
      });
    }
  };

  return (
    <>
      <Dialog
        draggable={false}
        header={<h1>Add Bank</h1>}
        visible={true}
        onHide={() => close()}
        style={{ width: "80%" }}
      >
        <section className="c-content-in">
          <Formik
            initialValues={{
              name: "",
              countryId: "",
              city: "",
              branchName: "",
              ifscCode: "",
              swiftCode: "",
              micrNo: "",
              currencyId: "",
            }}
            validationSchema={bankAddSchema}
            onSubmit={(values, { resetForm }) => {
              handelAdd(values);
              resetForm();
            }}
          >
            {({
              handleChange,
              onSubmit,
              values,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Bank Name</FormLabel>

                      <Field
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Bank Name"
                      />

                      {errors.name && touched.name ? (
                        <div className="text-danger">{errors.name}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Select Country</FormLabel>
                      <Field
                        name="countryId"
                        placeholder="Select Country"
                        component={CountryDropDown}
                      />

                      {errors.countryId && touched.countryId ? (
                        <div className="text-danger">{errors.countryId}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>City</FormLabel>
                      <Field
                        name="city"
                        type="text"
                        className="form-control"
                        placeholder="City"
                      />
                      {errors.city && touched.city ? (
                        <div className="text-danger">{errors.city}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Bank IFSC code</FormLabel>
                      <Field
                        name="ifscCode"
                        type="text"
                        className="form-control"
                        placeholder="Bank IFSC code"
                        onChange={(e) =>
                          ifscCheck(e.target.value, setFieldValue)
                        }
                      />
                      {errors.ifscCode && touched.ifscCode ? (
                        <div className="text-danger">{errors.ifscCode}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Swift Code</FormLabel>

                      <Field
                        name="swiftCode"
                        type="text"
                        className="form-control"
                        placeholder="Swift Code"
                      />
                      {errors.swiftCode && touched.swiftCode ? (
                        <div className="text-danger">{errors.swiftCode}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>MICR Code</FormLabel>

                      <Field
                        name="micrNo"
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        className="form-control"
                        placeholder="MICR Code"
                      />
                      {errors.micrNo && touched.micrNo ? (
                        <div className="text-danger">{errors.micrNo}</div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Select Base Currency</FormLabel>

                      <Field
                        name="currencyId"
                        className="form-control"
                        component={CurrencyDropDown}
                      />

                      {errors.currencyId && touched.currencyId ? (
                        <div className="text-danger">{errors.currencyId}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12">
                    <div className="form-group">
                      <FormLabel>Branch Name</FormLabel>

                      <Field
                        name="branchName"
                        type="text"
                        className="form-control"
                        placeholder="Branch Name"
                      />
                      {errors.branchName && touched.branchName ? (
                        <div className="text-danger">{errors.branchName}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="d-flex c-btn-group">
                          <button
                            type="submit"
                            className="c-btn-1"
                            // loading={this.state.loading}
                          >
                            <strong>Submit</strong>
                            {isLoading ? (
                              <span className="rotate-infinite">
                                <FiRotateCw />
                              </span>
                            ) : (
                              <span>
                                <FiChevronRight />
                              </span>
                            )}
                          </button>

                          <button
                            type="button"
                            onClick={() => close()}
                            className="c-btn-1 c-del-btn"
                          >
                            <strong>Cancel</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </Dialog>
      {/* <Modal size="lg"  backdrop="static">
        <Modal.Header closeButton>
          <h1>Add Bank</h1>
        </Modal.Header>
      </Modal> */}
    </>
  );
};

export default BankAdd;
