import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { FormLabel } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { BiEdit, BiRevision } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import StockService from "../../../services/StockService";
import { getCurrencyFormat } from "../../../shared/UtilsFunction";
import StockName from "../../components/StockName";

const StockSellTable = ({ close, sellStockData }) => {
  const stockService = new StockService(); //---------For service------------//
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null); //---------For edit--------//
  const [showForm, setShowForm] = useState(false); //---------For Edit Form----------//
  const [updateValue, SetUpdateValue] = useState([]); //---------For Update Value--------//

  const stockSellSchema = Yup.object().shape({
    stockId: Yup.string().required("Stock Name can't be empty"),
    // sellPrice: Yup.string().required("sell Price can't be empty"),
    // stockSold: Yup.string().required("Stock Sold can't be empty"),
    // totalValueSold: Yup.string().required("Total Value Sold can't be empty"),
    // sellDate: Yup.string().required("Sell Date can't be empty"),
  });

  ////////////Sell Edit//////////

  const getStockSellEdit = async (id) => {
    const res = await stockService.getSellDataWithId(id);
    res.sellDate = res.sellDate ? new Date(res.sellDate) : null;
    setInitialValues(res);
    setShowForm(true);
  };

  //For useing Update Endpoint/////

  // const UpdateSell = (values) => {
  //   stockService.updateStockSell(values).then((res) => {
  //     SetUpdateValue(res);

  //     close();
  //   });
  // };

  const UpdateSell = (values) => {
    let reqData = { ...values };
    reqData.sellDate = values.sellDate
      ? moment(values.sellDate).format("YYYY-MM-DD")
      : null;
    setLoading(true);
    stockService
      .updateStockSell(reqData)
      .then((res) => {
        SetUpdateValue(res);
        toast.success("StockSell Updated Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        close();
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  ///////////Delete /////////
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            stockService.deleteSell(id).then((res) => {
              toast.success(res, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            });
            close();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div className="c-listbank-w">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="d-flex justify-content-end">
        <Dialog
          draggable={false}
          visible={true}
          onHide={() => close()}
          header={
            showForm ? <h1>Edit Stock Sell</h1> : <h1>Stock Sell List </h1>
          }
          style={{ width: "80%" }}
        >
          <div className="c-content-in">
            {showForm ? (
              <Formik
                initialValues={{
                  ...initialValues,
                }}
                validationSchema={stockSellSchema}
                onSubmit={(values, { resetForm }) => {
                  UpdateSell(values);
                  resetForm();
                }}
                enableReinitialize
              >
                {({ handleChange, values, errors, touched }) => (
                  <Form>
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Stock Name</FormLabel>
                          <Field
                            name="stockId"
                            className="form-control"
                            component={StockName}
                          />

                          {errors.stockId && touched.stockId ? (
                            <div className="text-danger">{errors.stockId}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Sell Price</FormLabel>
                          <Field
                            name="sellPrice"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Sell Price"
                          />
                          {errors.sellPrice && touched.sellPrice ? (
                            <div className="text-danger">
                              {errors.sellPrice}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Stock Sold</FormLabel>
                          <Field
                            name="stockSold"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Stock Sold"
                          />
                          {errors.stockSold && touched.stockSold ? (
                            <div className="text-danger">
                              {errors.stockSold}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Total Value Sold</FormLabel>
                          <Field
                            name="totalValueSold"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Total Value Price"
                          />
                          {errors.totalValueSold && touched.totalValueSold ? (
                            <div className="text-danger">
                              {errors.totalValueSold}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <FormLabel>Sell Date</FormLabel>
                          {/* <Field
                            name="sellDate"
                            className="form-control"
                            type="date"
                            placeholder="Sell Date"
                          /> */}
                          <Calendar
                            name="sellDate"
                            className="w-100 mb-2 form-group c-calender-w"
                            value={values.sellDate}
                            onChange={handleChange}
                            showIcon
                            dateFormat="dd/mm/yy"
                            placeholder="dd/mm/yyyy"
                            readOnlyInput
                          />
                          {errors.sellDate && touched.sellDate ? (
                            <div className="text-danger">{errors.sellDate}</div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="d-flex c-btn-group">
                          <button
                            type="submit"
                            className="c-btn-1"
                            disabled={loading}
                          >
                            <strong>Update</strong>
                            {loading ? (
                              <span className="rotate-infinite">
                                <FiRotateCw />
                              </span>
                            ) : (
                              <span>
                                <FiChevronRight />
                              </span>
                            )}
                          </button>

                          <button
                            type="button"
                            onClick={() => close()}
                            className="c-btn-1 c-del-btn"
                          >
                            <strong>Cancel</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : null}

            <div className="table-responsive p-3">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Stock Name</th>
                    <th> SellPrice</th>
                    <th>Stock Sold</th>
                    <th>Total Value Sold</th>
                    <th>Sell Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {sellStockData.length > 0
                    ? sellStockData.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.stockId}</td>

                            <td>{getCurrencyFormat(item.sellPrice)}</td>

                            <td>{item.stockSold}</td>

                            <td>{getCurrencyFormat(item.totalValueSold)}</td>
                            <td>{moment(item.sellDate).format("YY-MM-DD")}</td>

                            <td>
                              <button
                                onClick={() => getStockSellEdit(item.id)}
                                type="button"
                                className="c-edit-btn"
                              >
                                <BiEdit />
                              </button>

                              <button
                                type="button"
                                className="c-edit-btn"
                                onClick={() => deleteItem(item.id)}
                              >
                                <BsTrash />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default StockSellTable;
