import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { FormLabel, Modal } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import { FiRotateCw, FiChevronRight, FiPlus } from "react-icons/fi";
import BondTypeDropDown from "../components/BondTypeDropDown";
import PaymentFreqDropDown from "../components/PaymentFreqDropDown";
import ProductService from "../../services/ProductService";
import BondType from "./BondType";
import AddProduct from "../Products/ProductCodeManagement/AddProduct";
import { toast } from "react-toastify";
import DropdownComponent from "../components/primereactComponent/DropdownComponent";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import moment from "moment";

const CreateBond = ({ handelAdd, close, isLoading }) => {
  const { pathname } = useLocation();
  // const [product, setProduct] = useState(null);
  const [productCode, setProductCode] = useState([]);
  const [addType, setAddType] = useState(false);
  const [access, setAccess] = useState(null);
  const [add, setAdd] = useState(false);
  const [isOverlayLoading, setIsOverlayLoadingAdd] = useState(false);
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));

  const productService = new ProductService();
  const bondSchema = Yup.object().shape({
    // productId: Yup.string().required("Product can't be empty"),
    bondTypeId: Yup.string().required("Bond Type can't be empty"),
    // productCodeId: Yup.string().required("Product Code can't be empty"),
    accountNo: Yup.string().required("Account No can't be empty"),
    // issuingCompany: Yup.string().required("Issuing Company can't be empty"),
    purchaseDate: Yup.string().required("Purchase Date can't be empty"),
    // couponAmount: Yup.string().required("Coupon Amount can't be empty"),
    // couponRate: Yup.string().required("Coupon Rate can't be empty"),
    purchaseAmount: Yup.string().required("Purchase Amount can't be empty"),
    // paymentFreq: Yup.string().required("Payment Frequency can't be empty"),
    // expiryDate: Yup.string().required("Expiry Date can't be empty"),
    ledgerName: Yup.string().required("Ledger Name can't be empty"),
  });

  useEffect(() => {
    productService.getProductCodeByAliasID("BONDS").then((res) => {
      setProductCode(res);
    });

    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
    }
  }, [add]);

  const handleCloseAddType = () => {
    setAddType(false);
  };

  const handleCloseAdd = () => {
    setAdd(false);
  };

  const handelAddProduct = (value) => {
    setIsOverlayLoadingAdd(true);
    productService
      .createProduct(value)
      .then((res) => {
        if (res) {
          toast.success("Product code Added Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsOverlayLoadingAdd(false);
          setAdd(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsOverlayLoadingAdd(false);
      });
  };

  return (
    <Dialog
      style={{ width: "60%" }}
      header={<h1>Add Bonds</h1>}
      draggable={false}
      visible={true}
      onHide={() => close()}
    >
      <section className="c-content-in">
        <Formik
          initialValues={{
            // productId: "",
            bondTypeId: "",
            productCodeId: "",
            accountNo: "",
            issuingCompany: "",
            purchaseDate: "",
            couponAmount: "",
            couponRate: "",
            purchaseAmount: "",
            expiryDate: "",
            paymentFreq: "",
            ledgerName: "",
          }}
          validationSchema={bondSchema}
          onSubmit={(values, { resetForm }) => {
            let reqData = { ...values };
            reqData.expiryDate = values.expiryDate
              ? moment(values.expiryDate).format("YYYY-MM-DD")
              : null;
            reqData.purchaseDate = values.purchaseDate
              ? moment(values.purchaseDate).format("YYYY-MM-DD")
              : null;

            handelAdd(reqData);
            resetForm();
          }}
          enableReinitialize
        >
          {({ values, errors, touched, setFieldValue, handleChange }) => (
            <Form>
              <div className="row">
                {/* <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Select Product</FormLabel>
                    <Field
                      name="productId"
                      className="form-control"
                      component={ProductDropDown}
                      onChange={(e) => {
                        setProduct(e.target.value);
                        setFieldValue("productId", e.target.value);
                      }}
                    />

                    {errors.productId && touched.productId ? (
                      <div className="text-danger">{errors.productId}</div>
                    ) : null}
                  </div>
                </div> */}
                <div className="col-lg-6 col-md-12">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <div style={{ width: "85%" }}>
                      <FormLabel>Bond Type</FormLabel>
                      <Field
                        name="bondTypeId"
                        className="form-control"
                        component={!addType && BondTypeDropDown}
                      />

                      {errors.bondTypeId && touched.bondTypeId ? (
                        <div className="text-danger">{errors.bondTypeId}</div>
                      ) : null}
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary cr-BtnCus"
                      style={{ borderRadius: "50%", marginTop: 20 }}
                      onClick={() => setAddType(true)}
                    >
                      <span>
                        <FiPlus />
                      </span>
                    </button>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <div style={{ width: "85%" }}>
                      <FormLabel>Product Code</FormLabel>

                      {/* <Field
                        as="select"
                        name="productCodeId"
                        className="form-control"
                      >
                        <option value="">Select Product Code</option>
                        {productCode.map((item, index) => (
                          <option value={item.id} key={`product-code-${index}`}>
                            {item.code}
                          </option>
                        ))}
                      </Field> */}
                      <Dropdown
                        options={productCode}
                        name="productCodeId"
                        className={"p-0 w-100"}
                        optionValue={"id"}
                        filter
                        optionLabel={"code"}
                        value={values.productCodeId}
                        onChange={handleChange}
                      />

                      {errors.productCodeId && touched.productCodeId ? (
                        <div className="text-danger">
                          {errors.productCodeId}
                        </div>
                      ) : null}
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary cr-BtnCus"
                      style={{ borderRadius: "50%", marginTop: 20 }}
                      onClick={() => setAdd(true)}
                    >
                      <span>
                        <FiPlus />
                      </span>
                    </button>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Account No</FormLabel>
                    <Field
                      name="accountNo"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control"
                      placeholder="Account No"
                    />

                    {errors.accountNo && touched.accountNo ? (
                      <div className="text-danger">{errors.accountNo}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Issuing Company</FormLabel>
                    <Field
                      name="issuingCompany"
                      type="text"
                      className="form-control"
                      placeholder="Issuing Company"
                    />

                    {errors.issuingCompany && touched.issuingCompany ? (
                      <div className="text-danger">{errors.issuingCompany}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Purchase Date</FormLabel>
                    {/* <Field
                      name="purchaseDate"
                      type="date"
                      className="form-control"
                      placeholder="Purchase Date"
                    /> */}

                    <Calendar
                      name="purchaseDate"
                      className="w-100 mb-2 form-group c-calender-w"
                      value={values.purchaseDate}
                      onChange={handleChange}
                      showIcon
                      dateFormat="dd/mm/yy"
                      placeholder="dd/mm/yyyy"
                      readOnlyInput
                    />

                    {errors.purchaseDate && touched.purchaseDate ? (
                      <div className="text-danger">{errors.purchaseDate}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Coupon Amount</FormLabel>
                    <Field
                      name="couponAmount"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control"
                      placeholder="Coupon Amount"
                    />

                    {errors.couponAmount && touched.couponAmount ? (
                      <div className="text-danger">{errors.couponAmount}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Coupon Rate</FormLabel>
                    <Field
                      name="couponRate"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control"
                      placeholder="Coupon Rate"
                    />

                    {errors.couponRate && touched.couponRate ? (
                      <div className="text-danger">{errors.couponRate}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Purchase Amount</FormLabel>
                    <Field
                      name="purchaseAmount"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control"
                      placeholder="Purchase Amount"
                    />

                    {errors.purchaseAmount && touched.purchaseAmount ? (
                      <div className="text-danger">{errors.purchaseAmount}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Expiry Date</FormLabel>
                    {/* <Field
                      name="expiryDate"
                      type="date"
                      className="form-control"
                      placeholder="Expiry Date"
                    /> */}
                    <Calendar
                      name="expiryDate"
                      className="w-100 mb-2 form-group c-calender-w"
                      value={values.expiryDate}
                      onChange={handleChange}
                      showIcon
                      dateFormat="dd/mm/yy"
                      placeholder="dd/mm/yyyy"
                      readOnlyInput
                    />

                    {errors.expiryDate && touched.expiryDate ? (
                      <div className="text-danger">{errors.expiryDate}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <FormLabel>Payment Freq</FormLabel>
                    <Field name="paymentFreq" component={PaymentFreqDropDown} />

                    {errors.paymentFreq && touched.paymentFreq ? (
                      <div className="text-danger">{errors.paymentFreq}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Ledger Name</FormLabel>
                  <Field
                    name="ledgerName"
                    type="text"
                    className="form-control"
                    placeholder="Ledger Name"
                    value={values.ledgerName}
                    onChange={(e) =>
                      setFieldValue("ledgerName", e.target.value?.toUpperCase())
                    }
                  />

                  {errors.ledgerName && touched.ledgerName ? (
                    <div className="text-danger">{errors.ledgerName}</div>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="d-flex c-btn-group">
                    <button
                      type="submit"
                      className="c-btn-1"
                      disabled={isLoading}
                    >
                      <strong>Submit</strong>
                      {isLoading ? (
                        <span className="rotate-infinite">
                          <FiRotateCw />
                        </span>
                      ) : (
                        <span>
                          <FiChevronRight />
                        </span>
                      )}
                    </button>

                    <button
                      type="button"
                      onClick={() => close()}
                      className="c-btn-1 c-del-btn"
                    >
                      <strong>Cancel</strong>
                      <span>
                        <BiRevision />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>

      {addType ? <BondType close={handleCloseAddType} access={access} /> : null}

      {add ? (
        <AddProduct
          handelAdd={handelAddProduct}
          close={handleCloseAdd}
          isLoading={isOverlayLoading}
        />
      ) : null}
    </Dialog>
  );
};

export default CreateBond;
