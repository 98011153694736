import { Field, Form, Formik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useState } from "react";
import { FormLabel } from "react-bootstrap";
import { BiRevision } from "react-icons/bi";
import { FiChevronRight, FiPlus, FiRotateCw } from "react-icons/fi";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ProductService from "../../../services/ProductService";
import AddProduct from "../../Products/ProductCodeManagement/AddProduct";

const StockForm = ({ onClose, onAdd, isLoading }) => {
  const productService = new ProductService();
  const [productCode, setProductCode] = useState([]);
  const [add, setAdd] = useState(false);
  const [isOverlayLoading, setIsOverlayLoadingAdd] = useState(false);

  const stockSchema = Yup.object().shape({
    // productId: Yup.number().required("Product Name can't be empty"),
    productCodeId: Yup.number().required("Product Code can't be empty"),
    // accountNumber: Yup.string().required("Account No can't be empty"),
    stockName: Yup.string().required("Stock Name can't be empty"),
    stockType: Yup.string().required("Stock Type can't be empty"),
    ledgerName: Yup.string().required("Ledger name can't be empty"),
    stockQty: Yup.number().required("Stock Quantity can't be empty"),
    unitPrice: Yup.number().required("Unit Price can't be empty"),
    // startDate: Yup.string().when("stockType", {
    //   is: (type) => type === "SIP",
    //   then: () => Yup.string().required("Start date is required"),
    // }),
    // endDate: Yup.string().when("stockType", {
    //   is: (type) => type === "SIP",
    //   then: () => Yup.string().required("End date is required"),
    // }),
    stockFrequency: Yup.string().when("stockType", {
      is: (type) => type === "SIP",
      then: () => Yup.string().required("Stock Frequency can't be empty"),
    }),
    // periodNumber: Yup.number().when("stockType", {
    //   is: (type) => type === "SIP",
    //   then: () => Yup.number().required("Period Number can't be empty"),
    // }),
    // amount: Yup.number().when("stockType", {
    //   is: (type) => type === "SIP",
    //   then: () => Yup.number().required("Amount can't be empty"),
    // }),
    // purchaseDate: Yup.string().when("stockType", {
    //   is: (type) => type === "ONE_TIME",
    //   then: () => Yup.string().required("Purchase date can't be empty"),
    // }),
    // purchasedAmount: Yup.number().when("stockType", {
    //   is: (type) => type === "ONE_TIME",
    //   then: () => Yup.number().required("Purchased amount can't be empty"),
    // }),
  });

  useEffect(() => {
    productService.getProductCodeByAliasID("STOCKS").then((res) => {
      setProductCode(res);
    });
  }, [add]);

  const handleCloseAdd = () => {
    setAdd(false);
  };

  const handelAddProduct = (value) => {
    setIsOverlayLoadingAdd(true);
    productService
      .createProduct(value)
      .then((res) => {
        if (res) {
          toast.success("Product code Added Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsOverlayLoadingAdd(false);
          setAdd(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsOverlayLoadingAdd(false);
      });
  };

  return (
    <Dialog
      draggable={false}
      visible={true}
      onHide={() => onClose()}
      header={<h1>Add Stock</h1>}
      style={{ width: "80%" }}
    >
      <section className="c-content-in">
        <Formik
          initialValues={{
            // productId: "",
            productCodeId: "",
            accountNumber: "",
            stockName: "",
            stockType: "ONE_TIME",
            startDate: "",
            endDate: "",
            periodNumber: "",
            stockFrequency: "",
            amount: "",
            purchaseDate: "",
            purchasedAmount: "",
            stockQty: "",
            unitPrice: "",
            ledgerName: "",
            totalAmount: "",
          }}
          validationSchema={stockSchema}
          onSubmit={(values, formmikHandeler) => {
            let reqData = { ...values };
            reqData.startDate = values.startDate
              ? moment(values.startDate).format("YYYY-MM-DD")
              : null;
            reqData.endDate = values.endDate
              ? moment(values.endDate).format("YYYY-MM-DD")
              : null;
            onAdd(values);
            formmikHandeler.resetForm();
          }}
          enableReinitialize
        >
          {({ handleChange, values, errors, touched, setFieldValue }) => (
            <Form>
              <div className="row">
                {/* <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Product</FormLabel>

                  <Field
                    name="productId"
                    className="form-control"
                    component={ProductDropDown}
                  />

                  {errors.productId && touched.productId ? (
                    <div className="text-danger">{errors.productId}</div>
                  ) : null}
                </div>
              </div> */}

                <div className="col-lg-3 col-md-12">
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <div style={{ width: "85%" }}>
                      <FormLabel>Product Code</FormLabel>
                      <Field
                        as="select"
                        name="productCodeId"
                        className="form-control"
                      >
                        <option value="">Select Product Code</option>
                        {productCode.map((item, index) => (
                          <option value={item.id} key={`product-code-${index}`}>
                            {item.code}
                          </option>
                        ))}
                      </Field>
                      {errors.productCodeId && touched.productCodeId ? (
                        <div className="text-danger">
                          {errors.productCodeId}
                        </div>
                      ) : null}
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary cr-BtnCus"
                      style={{ borderRadius: "50%", marginTop: 20 }}
                      onClick={() => setAdd(true)}
                    >
                      <span>
                        <FiPlus />
                      </span>
                    </button>
                  </div>
                </div>

                <div className="col-lg-3 col-md-12">
                  <div className="form-group">
                    <FormLabel>Account Number</FormLabel>
                    <Field
                      name="accountNumber"
                      className="form-control"
                      type="text"
                      placeholder="Account Number"
                    />
                    {errors.accountNumber && touched.accountNumber ? (
                      <div className="text-danger">{errors.accountNumber}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-3 col-md-12">
                  <div className="form-group">
                    <FormLabel>Stock Name</FormLabel>
                    <Field
                      name="stockName"
                      className="form-control"
                      type="text"
                      placeholder="Stock Name"
                    />
                    {errors.stockName && touched.stockName ? (
                      <div className="text-danger">{errors.stockName}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-3 col-md-12">
                  <div className="form-group">
                    <FormLabel>Stock Type</FormLabel>
                    <select
                      disabled
                      value={values.stockType}
                      onChange={handleChange}
                      name="stockType"
                      className="form-control"
                      aria-label="Default select example"
                    >
                      <option>Select Stock Type</option>
                      <option value="SIP">SIP</option>
                      <option value="ONE_TIME">One Time</option>
                    </select>
                    {errors.stockType && touched.stockType ? (
                      <div className="text-danger">{errors.stockType}</div>
                    ) : null}
                  </div>
                </div>

                {values.stockType === "SIP" && (
                  <>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Start Date</FormLabel>
                        {/* <Field
                          name="startDate"
                          placeholder="Date"
                          type="date"
                          className="form-control"
                        /> */}
                        <Calendar
                          name="startDate"
                          className="w-100 mb-2 form-group c-calender-w"
                          value={values.startDate}
                          onChange={handleChange}
                          showIcon
                          dateFormat="dd/mm/yy"
                          placeholder="dd/mm/yyyy"
                          readOnlyInput
                        />
                        {errors.startDate && touched.startDate ? (
                          <div className="text-danger">{errors.startDate}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>End Date</FormLabel>
                        {/* <Field
                          name="endDate"
                          placeholder="Date"
                          type="date"
                          className="form-control"
                        /> */}
                        <Calendar
                          name="endDate"
                          className="w-100 mb-2 form-group c-calender-w"
                          value={values.endDate}
                          onChange={handleChange}
                          showIcon
                          dateFormat="dd/mm/yy"
                          placeholder="dd/mm/yyyy"
                          readOnlyInput
                        />
                        {errors.endDate && touched.endDate ? (
                          <div className="text-danger">{errors.endDate}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Stock Frequency</FormLabel>

                        <select
                          className="form-control"
                          name="stockFrequency"
                          value={values.stockFrequency}
                          onChange={handleChange}
                          aria-label="Default select example"
                        >
                          <option>Select Payment</option>
                          <option value="monthly">Monthly</option>
                          <option value="daily">Daily</option>
                          <option value="fortnightly">Fornightly</option>
                        </select>
                        {errors.stockFrequency && touched.stockFrequency ? (
                          <div className="text-danger">
                            {errors.stockFrequency}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>SIP Amount</FormLabel>
                        <Field
                          name="amount"
                          placeholder="SIP Amount"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          className="form-control"
                        />
                        {errors.amount && touched.amount ? (
                          <div className="text-danger">{errors.amount}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Period Number</FormLabel>
                        <Field
                          name="periodNumber"
                          placeholder="Period Number"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          className="form-control"
                        />
                        {errors.periodNumber && touched.periodNumber ? (
                          <div className="text-danger">
                            {errors.periodNumber}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}

                {values.stockType === "ONE_TIME" && (
                  <div className="row">
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Purchase Date</FormLabel>
                        {/* <Field
                          name="purchaseDate"
                          placeholder="Date Of Purchase"
                          type="date"
                          className="form-control"
                        /> */}
                        <Calendar
                          name="purchaseDate"
                          className="w-100 mb-2 form-group c-calender-w"
                          value={values.purchaseDate}
                          onChange={handleChange}
                          showIcon
                          dateFormat="dd/mm/yy"
                          placeholder="dd/mm/yyyy"
                          readOnlyInput
                        />
                        {errors.purchaseDate && touched.purchaseDate ? (
                          <div className="text-danger">
                            {errors.purchaseDate}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Opening Amount</FormLabel>
                        <Field
                          name="purchasedAmount"
                          placeholder="Purchase Amount"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          className="form-control"
                          onChange={(e) => {
                            setFieldValue("purchasedAmount", e.target.value);
                            setFieldValue(
                              "unitPrice",
                              e.target.value / values.stockQty
                            );
                          }}
                        />
                        {errors.purchasedAmount && touched.purchasedAmount ? (
                          <div className="text-danger">
                            {errors.purchasedAmount}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-lg-3 col-md-12">
                  <div className="form-group">
                    <FormLabel>Opening QTY</FormLabel>
                    <Field
                      name="stockQty"
                      placeholder="Stock QTY"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control"
                      onChange={(e) => {
                        setFieldValue("stockQty", e.target.value);
                        setFieldValue(
                          "unitPrice",
                          values.purchasedAmount / e.target.value
                        );
                      }}
                    />
                    {errors.stockQty && touched.stockQty ? (
                      <div className="text-danger">{errors.stockQty}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-3 col-md-12">
                  <div className="form-group">
                    <FormLabel>Unit Price</FormLabel>
                    <Field
                      disabled
                      name="unitPrice"
                      placeholder="Unit Price"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      className="form-control"
                      // onChange={(e) => {
                      //   setFieldValue("unitPrice", e.target.value);
                      //   setFieldValue(
                      //     "totalAmount",
                      //     e.target.value * values.stockQty
                      //   );
                      // }}
                    />
                    {errors.unitPrice && touched.unitPrice ? (
                      <div className="text-danger">{errors.unitPrice}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <FormLabel>Ledger Name</FormLabel>
                    <Field
                      name="ledgerName"
                      placeholder="Ledger Name"
                      className="form-control"
                      onChange={(e) =>
                        setFieldValue(
                          "ledgerName",
                          e.target.value?.toUpperCase()
                        )
                      }
                    />
                    {errors.ledgerName && touched.ledgerName ? (
                      <div className="text-danger">{errors.ledgerName}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="d-flex c-btn-group">
                    <button
                      type="submit"
                      className="c-btn-1"
                      disabled={isLoading}
                    >
                      <strong>Submit</strong>
                      {isLoading ? (
                        <span className="rotate-infinite">
                          <FiRotateCw />
                        </span>
                      ) : (
                        <span>
                          <FiChevronRight />
                        </span>
                      )}
                    </button>

                    <button type="reset" className="c-btn-1 c-del-btn">
                      <strong>Reset</strong>
                      <span>
                        <BiRevision />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </section>

      {add ? (
        <AddProduct
          handelAdd={handelAddProduct}
          close={handleCloseAdd}
          isLoading={isOverlayLoading}
        />
      ) : null}
    </Dialog>
  );
};

export default StockForm;
