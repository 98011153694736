import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class AccountService {
  constructor() {
    this.token = sessionStorage.getItem("token");

    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  ////////////Create Ledger ///////////

  createLedger = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/account-group/create`,
        payload,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////For all Account Group Name ///////

  getAccountGroupListAll = async () => {
    try {
      const response = await axios.get(`${baseUrl}/account-group/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////For Update Ledger ////////

  updateLedger = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/account-group/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////Ledger For Single ID///////////

  getUpdateId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/account-group/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////For Search Ledger and listing //////
  getSearchLedger = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/account-group/search`,
        payload,

        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  /////////Deleete Ledger //////

  deleteLedger = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/account-group/delete/${id}`,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getChildGroup = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/account-group/child-group/${id}`,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getProductDetails = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/customer-product/mapping/${id}`,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getAllChildGroup = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/account-group/subGroup-all`,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  addJournal = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/journal/save`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getAllJournal = async (payload) => {
    try {
      const response = await axios.post(`${baseUrl}/journal/search`, payload, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getJournal = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/journal/${id}`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getNetWorth = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/journal/networth-all/search`,
        payload,
        { headers: this.headers }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
