import React, { Component } from "react";
import { Field, Form, Formik } from "formik";
import { Link, Navigate } from "react-router-dom";
import * as Yup from "yup";
import { FiRotateCw, FiChevronRight, FiLock } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import Logoimg from "../assets/images/login-bg-1.jpg";
import UserIcon from "../assets/images/User.png";
import PasswordIcon from "../assets/images/Password.png";
import AuthService from "../services/AuthService";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      authToken: null,
    };
    this.loginSchema = Yup.object().shape({
      userId: Yup.string().required("User ID can't be empty."),
      password: Yup.string().required("Password can't be empty."),
    });
    this.authService = new AuthService();
  }

  handelLogin = (values) => {
    this.setState({ loading: true });
    this.authService
      .login(values)
      .then((res) => {
        sessionStorage.setItem("token", res.jwtToken);
        sessionStorage.setItem("customer", JSON.stringify(res.customer));

        if (typeof res.accountant !== null) {
          sessionStorage.setItem("userData", JSON.stringify(res.accountant));
        } else {
          sessionStorage.setItem("userData", JSON.stringify(res.customer));
        }
        if (res.userMenuWithChild) {
          sessionStorage.setItem(
            "userNewMenuList",
            JSON.stringify(res.userMenuWithChild)
          );
        }
        if (typeof res.userMenuList !== null) {
          sessionStorage.setItem(
            "userMenuList",
            JSON.stringify(res.userMenuList)
          );
        }
        this.setState({ authToken: res.jwtToken });
        this.setState({ loading: false });
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        this.setState({ loading: false });
      });
  };

  render = () => {
    return (
      <>
        {this.state.authToken && <Navigate to="/" replace={true} />}
        <ToastContainer />
        <section className="c-login-w">
          <div className="c-leftBox-w">
            <img className="img-fluid" src={Logoimg} alt="Logoimg" />
          </div>
          <div className="c-rightBox-w">
            <div className="c-rightBox-in">
              <div className="c-rightBox-con">
                <h2>PIFS</h2>
                <p>Personal Investment & Financial System</p>
              </div>
              <div className="c-rightBox-form">
                <Formik
                  initialValues={{
                    userId: "",
                    password: "",
                  }}
                  validationSchema={this.loginSchema}
                  // onSubmit={this.handelLogin}
                  onSubmit={(values) => {
                    this.handelLogin(values);
                  }}
                >
                  {({ onSubmit, handelLogin, errors, touched }) => (
                    <Form autoComplete="off">
                      <div className="form-group">
                        <span>
                          <img src={UserIcon} alt="" />
                        </span>
                        <Field
                          name="userId"
                          type="text"
                          className="form-control"
                          placeholder="User ID"
                        />
                        {errors.userId && touched.userId ? (
                          <div className="text-danger">{errors.userId}</div>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <span>
                          <img src={PasswordIcon} alt="" />
                        </span>
                        <Field
                          name="password"
                          type="password"
                          className="form-control"
                          placeholder="Password"
                        />
                        {errors.password && touched.password ? (
                          <div className="text-danger">{errors.password}</div>
                        ) : null}
                      </div>

                      <button
                        type="submit"
                        className="c-submit-btn"
                        disabled={this.state.loading}
                      >
                        <strong>Submit</strong>
                        {this.state.loading ? (
                          <span className="rotate-infinite">
                            <FiRotateCw />
                          </span>
                        ) : (
                          <span>
                            <FiChevronRight />
                          </span>
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>

                <div className="c-forget-btn">
                  <Link to="/forgotpassword" className="c-forget-btn">
                    <FiLock />
                    {" Forgot Password"}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
}

export default Login;
