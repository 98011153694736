import React, { useEffect, useReducer, useState } from "react";
import AccountantService from "../../services/AccountantService";
import { confirmAlert } from "react-confirm-alert";
import { ToastContainer } from "react-toastify";
import { BiEdit, BiRevision, BiSearchAlt } from "react-icons/bi";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsArrowDown, BsArrowUp, BsTrash } from "react-icons/bs";
import Paginator from "../components/Paginator";
import EditAccountant from "./EditAccountant";
import { Field, Form, Formik } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { ImFileText2 } from "react-icons/im";
import PageLoader from "../components/PageLoader";
import {
  errorToaster,
  inputField,
  successToaster,
} from "../../Global Component/FormField";
// import { produce } from "immer";

const ListAccountant = () => {
  const accountantService = new AccountantService(); //-----------For Service Call-------//
  const [accountantListData, setAccountantListData] = useState([]); //-----------For Listing--------//
  const [pageNo, setpageNo] = useState(1); //--------for pagenation-------//
  const [pageSize, setpageSize] = useState(10); //-----------for pagenation------//
  const [searchBar, setSearchBar] = useState(false); //--------For SearchBar---------//
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [access, setAccess] = useState(null); //--------
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState(false);
  const [fieldNmae, setFieldName] = useState(undefined);

  useEffect(() => {
    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, []);

  useEffect(() => {
    if (menuList !== null) {
      accountantList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, pageSize, order]);

  /////////For Listing///////////
  const accountantList = () => {
    setLoader(true);
    let payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    if (typeof fieldNmae !== "undefined") {
      payload.sorts = [`${fieldNmae},${order ? "asc" : "desc"}`];
    }
    accountantService
      .getSearchAccountant(payload)
      .then((res) => {
        setAccountantListData(res);
        setLoader(false);
      })

      .catch((e) => {
        errorToaster({ label: e.message });
        setLoader(false);
      });
  };

  /////////for pagenation////////////
  const increment = () => {
    setpageNo(pageNo + 1);
  };

  const decrement = () => {
    setpageNo(pageNo - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * pageNo) / e.target.value);
    setpageSize(e.target.value);
    setpageNo(temp);
  };

  ///////////Delete /////////
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are You Sure To Delete This Item",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            accountantService
              .deleteAccountant(id)
              .then((res) => {
                successToaster({ label: res });
              })

              .finally(() => accountantList());
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  //////////For Search//////////

  const handleSearch = (data) => {
    let request = {
      ...data,
      pageNo: 1,
      pageSize: pageSize,
      sorts: [],
    };
    accountantService.getSearchAccountant(request).then((res) => {
      setAccountantListData(res);
    });
  };

  //////////For Edit /////////

  const initialState = {
    value: {},
    dailoge: false,
  };

  // const reducer = produce((state, action) => {
  //   switch (action.type) {
  //     case "value":
  //       state.value = action.payload;
  //       break;
  //     case "dialoge":
  //       state.dailoge = action.payload;
  //       break;
  //     default:
  //       return state;
  //   }
  // });

  const reducer = (state, action) => {
    switch (action.type) {
      case "value": {
        return {
          ...state,
          value: action.payload,
        };
      }
      case "dialoge": {
        return {
          ...state,
          dailoge: action.payload,
        };
      }
      default:
        break;
    }
  };

  const [editData, setEditData] = useReducer(reducer, initialState);

  const getIdforUpdate = (item) => {
    setEditData({ type: "value", payload: item });
    setEditData({ type: "dialoge", payload: true });
  };

  ///////EDIT Modal/////

  const handleCloseEdit = () => {
    setEditData({ type: "dialoge", payload: false });
  };

  const handleClickNavigate = (id) => {
    navigate(`/access-control/${id}`);
  };

  ///Accountent Update////
  const accountentUpdate = (values) => {
    setLoading(true);
    accountantService
      .updateAccountant(values)
      .then((res) => {
        successToaster({ label: "Accountant Updated Successfully" });
        setLoading(false);
        handleCloseEdit();
        accountantList();
      })
      .catch((e) => {
        errorToaster({ label: e.message });
        setLoading(false);
      });
  };

  const onSort = (fieldName, value) => {
    setFieldName(fieldName);
    setOrder(value);
  };

  return access !== null && access !== undefined ? (
    <div className="table-responsive">
      <ToastContainer />

      {/* {!accountantListData.data && <PageLoader />}
       */}
      {loader && <PageLoader />}
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h1>Accountant</h1>
        <div className="d-flex align-items-center add-searchBtn">
          <Link to="/add-accountant">
            {(access.isWrite || access.isDelete) && (
              <button type="button" className="c-btn-1 me-1">
                <span>
                  <FiPlus />
                </span>
                <strong>Accountant</strong>
              </button>
            )}
          </Link>
          <button
            type="button"
            className="c-btn-1 ms-2"
            onClick={() => setSearchBar(!searchBar)}
          >
            <span>
              <BiSearchAlt />
            </span>
            <strong>Search</strong>
          </button>
        </div>
      </div>
      <div className="cn-bank-search-w">
        <Collapse in={searchBar}>
          <div className="cn-bank-search-form">
            <h1>Search</h1>
            <Formik
              initialValues={{
                fullName: "",
                mobileNo: "",
                email: "",
                userName: "",
              }}
              onSubmit={(values) => {
                handleSearch(values);
              }}
            >
              {({ handleChange, values, errors, touched }) => (
                <Form>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <Field
                        name="fullName"
                        className="form-control"
                        type="text"
                        placeholder="Full Name"
                        label="Full Name"
                        component={inputField}
                      />
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <Field
                        name="mobileNo"
                        className="form-control"
                        type="text"
                        placeholder="Mobile No"
                        label="Mobile No"
                        component={inputField}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <Field
                        name="email"
                        className="form-control"
                        type="text"
                        placeholder="Email"
                        label="Email"
                        component={inputField}
                      />
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <Field
                        name="userName"
                        className="form-control"
                        type="text"
                        placeholder="User Name"
                        label="User Name"
                        component={inputField}
                      />
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group d-flex justify-content-end">
                          <button type="submit" className="c-btn-1">
                            <strong>SEARCH</strong>{" "}
                          </button>

                          <button
                            type="reset"
                            className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                          >
                            <strong>Reset</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                          <button
                            // type="reset"
                            onClick={() => accountantList()}
                            className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                          >
                            <strong>Reload</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Collapse>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th onClick={() => onSort("fullName", !order)}>
              FullName
              <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th>Mobile No</th>
            <th onClick={() => onSort("email", !order)}>
              Email
              <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            <th onClick={() => onSort("userName", !order)}>
              UserName
              <BsArrowUp className="colorChange" />{" "}
              <BsArrowDown className="colorChange" />
            </th>
            {(access.isWrite || access.isDelete) && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {accountantListData.data && accountantListData.data.length > 0
            ? accountantListData.data.map((item, index) => {
                return (
                  <tr key={`accountantListData-${index}`}>
                    <td>{pageSize * pageNo - pageSize + 1 + index}</td>
                    <td>{item.fullName}</td>
                    <td>{item.mobileNo}</td>
                    <td>{item.email}</td>
                    <td>{item.userName}</td>
                    {(access.isWrite || access.isDelete) && (
                      <td>
                        {access.isWrite && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                            }
                          >
                            <button
                              onClick={() => getIdforUpdate(item)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <BiEdit />{" "}
                            </button>
                          </OverlayTrigger>
                        )}
                        {access.isDelete && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                            }
                          >
                            <button
                              onClick={() => deleteItem(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <BsTrash />{" "}
                            </button>
                          </OverlayTrigger>
                        )}

                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              Acces Control
                            </Tooltip>
                          }
                        >
                          <button
                            onClick={() => handleClickNavigate(item.id)}
                            type="button"
                            className="c-edit-btn"
                          >
                            {" "}
                            <ImFileText2 />{" "}
                          </button>
                        </OverlayTrigger>
                      </td>
                    )}
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>

      <Paginator
        pageSize={accountantListData.pageSize}
        firstPage={accountantListData.firstPage}
        lastPage={accountantListData.lastPage}
        decrement={decrement}
        increment={increment}
        pagesizechange={pagesizechange}
        pageNo={accountantListData.pageNo}
        totalPages={accountantListData.totalPages}
      />

      {editData.dailoge ? (
        <EditAccountant
          AccountantEditFilterData={editData.value}
          close={handleCloseEdit}
          accountentUpdate={accountentUpdate}
          loading={loading}
        />
      ) : null}
    </div>
  ) : null;
};

export default ListAccountant;
