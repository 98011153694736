import React, { Component } from "react";
import { Field, Form, Formik } from "formik";
import { FiChevronRight, FiRotateCw } from "react-icons/fi";
import { Link, Navigate } from "react-router-dom";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import logoImg from "../../src/assets/images/login-bg-1.jpg";
import userIcon from "../../src/assets/images/User.png";
import AuthService from "../services/AuthService";

class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			isOtpSend: false,
			mobileNumber: null,
		};
		this.forgotSchema = Yup.object().shape({
			mobileNumber: Yup.string().required(
				"Mobile Number can't be empty."
			),
		});
		this.authService = new AuthService();
	}

	handelForgot = (values) => {
		const payload = {
			...values,
			requestType: "UPDATE",
		};
		this.setState({ loading: true });
		this.authService
			.sendOtp(payload)
			.then((res) => {
				if (res.httpStatusCode === 200) {
					this.setState({
						loading: false,
						isOtpSend: true,
						mobileNumber: values.mobileNumber,
					});
				}
			})
			.catch((e) => {
				toast.error(e.message, {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "colored",
				});
				this.setState({ loading: false });
			});
	};

	render = () => {
		return (
			<>
				{this.state.isOtpSend && (
					<Navigate
						to={`/verify/otp?mobile=${this.state.mobileNumber}`}
						replace={true}
					/>
				)}
				<ToastContainer />
				<section className="c-login-w">
					<div className="c-leftBox-w">
						<img
							className="img-fluid"
							src={logoImg}
							alt="logoImg"
						/>
					</div>
					<div className="c-rightBox-w">
						<div className="c-rightBox-in">
							<div className="c-rightBox-con">
								<h2>PIFS</h2>
								<p>Personal Investment & Financial System</p>
							</div>
							<div className="c-rightBox-form">
								<Formik
									initialValues={{
										mobileNumber: "",
									}}
									validationSchema={this.forgotSchema}
									onSubmit={(values) => {
										this.handelForgot(values);
									}}
								>
									{({ handelForgot, errors, touched }) => (
										<Form>
											<div className="form-group">
												<span>
													<img
														src={userIcon}
														alt=""
													/>
												</span>
												<Field
													name="mobileNumber"
													type="number"
													className="form-control"
													placeholder="User Mobile Number"
												/>
												{errors.mobileNumber &&
												touched.mobileNumber ? (
													<div className="text-danger">
														{errors.mobileNumber}
													</div>
												) : null}
											</div>

											<button
												type="submit"
												className="c-submit-btn"
												disabled={this.state.loading}
											>
												<strong>Send OTP</strong>
												{this.state.loading ? (
													<span className="rotate-infinite">
														<FiRotateCw />
													</span>
												) : (
													<span>
														<FiChevronRight />
													</span>
												)}
											</button>
										</Form>
									)}
								</Formik>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	};
}

export default ForgotPassword;
