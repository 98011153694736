import React, { useEffect, useState } from "react";
import MutualFundsService from "../../../services/MutualFundsService";
import { ToastContainer, toast } from "react-toastify";
import { FormLabel, Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { BiEdit, BiRevision } from "react-icons/bi";
import { FiRotateCw, FiChevronRight } from "react-icons/fi";

import * as Yup from "yup";
import { BsTrash } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert";
import PageLoader from "../../components/PageLoader";

const AddMutualFundType = ({ close, onAdd, onUpdateMf, isLoading }) => {
  const mutualFundService = new MutualFundsService(); //-----------For Service Call-------//
  const [loading, setLoading] = useState(true);
  const [initialValue, setinitialValue] = useState({ name: "" });
  const [fundTypeList, setFundTypeList] = useState([]);

  const fundTypeSchema = Yup.object().shape({
    name: Yup.string().required("Mutual Fund Type Name can't be empty"),
  });

  ///////Mutualfund Type listing /////////

  useEffect(() => {
    listFundType();
  }, []);

  ////////////Create & Edit MutualFund Type////////////

  const handelForm = (value) => {
    if (value.hasOwnProperty("id")) {
      onUpdateMf(value);
    } else {
      onAdd(value);
    }
  };

  const getFundType = (item) => {
    setinitialValue({
      id: item.id,
      name: item.name,
    });
  };

  ///////MutualFund Type listing /////////

  const listFundType = () => {
    mutualFundService
      .getAllFundList()
      .then((res) => {
        setFundTypeList(res);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  ///////////Delete /////////
  const deleteItem = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete this Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            mutualFundService.deleteFundType(id).then((res) => {
              toast.success(res, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            });
            close();
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <>
      {/* <ToastContainer /> */}
      {loading ? (
        <PageLoader />
      ) : (
        <>
          <section className="c-content-in">
            <Formik
              initialValues={{
                ...initialValue,
              }}
              validationSchema={fundTypeSchema}
              onSubmit={(values, formmikHandeler) => {
                handelForm(values);
                formmikHandeler.resetForm();
              }}
              enableReinitialize
            >
              {({ handleChange, values, errors, touched }) => (
                <Form>
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-group">
                        <FormLabel>Mutual Fund Type</FormLabel>
                        <Field
                          name="name"
                          className="form-control"
                          type="text"
                          placeholder="Type Mutual Fund Name"
                        />
                        {errors.name && touched.name ? (
                          <div className="text-danger">{errors.name}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="d-flex c-btn-group">
                        <button
                          type="submit"
                          className="c-btn-1"
                          disabled={isLoading}
                        >
                          {values.id ? (
                            <strong>Update</strong>
                          ) : (
                            <strong>Submit</strong>
                          )}
                          {isLoading ? (
                            <span className="rotate-infinite">
                              <FiRotateCw />
                            </span>
                          ) : (
                            <span>
                              <FiChevronRight />
                            </span>
                          )}
                        </button>

                        <button
                          onClick={() => close()}
                          type="button"
                          className="c-btn-1 c-del-btn"
                        >
                          <strong>Cancel</strong>
                          <span>
                            <BiRevision />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </section>

          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <h1>Mutual Fund Type List</h1>

                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>MutualFund Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fundTypeList.length > 0
                      ? fundTypeList.map((item, index) => {
                          return (
                            <tr key={index.toString()}>
                              <td>{index + 1}</td>
                              <td>{item.name}</td>

                              <td>
                                <button
                                  onClick={() => getFundType(item)}
                                  type="button"
                                  className="c-edit-btn"
                                >
                                  {" "}
                                  <BiEdit />{" "}
                                </button>

                                <button
                                  type="button"
                                  className="c-edit-btn"
                                  onClick={() => deleteItem(item.id)}
                                >
                                  <BsTrash />
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddMutualFundType;
