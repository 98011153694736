import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import BondService from "../../services/BondService";
import DropdownComponent from "./primereactComponent/DropdownComponent";

const BondTypeDropDown = ({ field, form: { touched, errors }, ...props }) => {
  const [bondType, setbondType] = useState([]);
  const bondService = new BondService();

  useEffect(() => {
    bondService.getBondType().then((res) => {
      setbondType(res);
    });
  }, []);

  return (
    // <Form.Select className="form-control" {...field} {...props}>
    // 		<option value="null">Select Bond Type</option>
    // 			{bondType.map((item) => (
    // 				<option key={item.id} value={item.id}>
    // 					{item.name}
    // 				</option>
    // 			))}
    // 	</Form.Select>
    <DropdownComponent
      options={bondType}
      placeholder={props.placeholder ?? "Bond Type"}
      name={field.name}
      className={"p-0 "}
      filter
      optionLabel={"name"}
      optionValue={"id"}
    />
  );
};

export default BondTypeDropDown;
