import axios from "axios";
import { baseUrl } from "../shared/Config";

export default class ProductService {
  constructor() {
    this.token = sessionStorage.getItem("token");
    this.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    };
  }

  //////////Create Product////////

  createProduct = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/product-code/create`,
        payload,
        { headers: this.headers }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////  GET Product Mapping //////////
  getAllProduct = async () => {
    try {
      const response = await axios.get(`${baseUrl}/product/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getAllProductCode = async () => {
    try {
      const response = await axios.get(`${baseUrl}/product-code/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
  getCustomerProduct = async () => {
    try {
      const response = await axios.get(`${baseUrl}/customer-product/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////Get All Banks//////

  getBankList = async () => {
    try {
      const response = await axios.get(`${baseUrl}/bank/all`, {
        headers: this.headers,
      });

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////For Product List///////
  getProductList = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/product-code/search`,
        payload,

        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////////For Search Products ///////////

  searchProduct = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/product-code/search`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  //////For Update Bank ////////

  updateProduct = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/product-code/update`,
        payload,
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ////////For Single ID///////////

  getUpdateId = async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/product-code/${id}`, {
        headers: this.headers,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  ///////////For Product Active Mapping api///////////
  submitActiveProducts = async (payload) => {
    try {
      const response = await axios.post(
        `${baseUrl}/customer-product/save-all-customer-products`,
        payload,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getProductCodeByAliasID = async (alias) => {
    try {
      const response = await axios.get(
        `${baseUrl}/product-code/product-by-alias/${alias}`,
        {
          headers: this.headers,
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  // getProductCodeByProductID = async (id) => {
  // 	try {
  // 		const response = await axios.get(
  // 			`${baseUrl}/product-code/product/${id}`,
  // 			{
  // 				headers: this.headers,
  // 			}
  // 		);
  // 		return response.data;
  // 	} catch (error) {
  // 		throw new Error(error.message);
  // 	}
  // };

  //////////Delete////////
  delete = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/product-code/delete/${id}`,
        {},
        {
          headers: this.headers,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getGroupsByHeader = async (value) => {
    try {
      const response = await axios.get(
        `${baseUrl}/account-group/by-accounting/${value}`,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getLedgersByGroup = async (id) => {
    try {
      const response = await axios.get(
        `${baseUrl}/ledger/by-ledger-group/${id}`,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  productMapping = async (value) => {
    try {
      const response = await axios.post(
        `${baseUrl}/customer-product/save-all-customer-products`,
        value,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  productMappingUpdate = async (value) => {
    try {
      const response = await axios.post(
        `${baseUrl}/customer-product/update`,
        value,
        { headers: this.headers }
      );

      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };
}
