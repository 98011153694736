import React, { useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { FiRotateCw, FiChevronRight, FiPlus } from "react-icons/fi";
import { BiRevision } from "react-icons/bi";
import { FormLabel } from "react-bootstrap";
import PropertyService from "../../services/PropertyService";
import { Link, useNavigate } from "react-router-dom";
import CountryDropDown from "../components/CountryDropDown";
import { Calendar } from "primereact/calendar";
import moment from "moment";

const AddProperty = () => {
  const propertyService = new PropertyService(); //----------For Suevice---------------//
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const propertySchema = Yup.object().shape({
    name: Yup.string().required("Name can't be empty"),
    countryId: Yup.number().required("Country Name can't be empty"),
    // productId: Yup.number().required("Product Name can't be empty"),
    // address: Yup.string().required("Address can't be empty"),
    fundType: Yup.string().required("Fund Type can't be empty"),
    // funded: Yup.number().required("Funded can't be empty"),
    // totalValueFunded: Yup.number().required("Total Value  can't be empty"),
    // loanAccountNumber: Yup.string().required("Account Number can't be empty"),
    purchaseDate: Yup.string().required("Purchase Date  can't be empty"),
    purchasePrice: Yup.number().required("Purchase Price  can't be empty"),
    // stampDutyCost: Yup.number().required("Stamp Duty Cost  can't be empty"),
    // registrationCost: Yup.number().required("Stamp Duty Cost  can't be empty"),
    // otherCost: Yup.number().required("other Cost  can't be empty"),
    ledgerName: Yup.string().required("Ledger Name  can't be empty"),
    // totalValueSold: Yup.number().required("Total Value Sold can't be empty"),
  });

  const propertyCreate = (values) => {
    const countryId = Number(values.countryId);
    // const productID = Number(values.productId);
    const requestBody = {
      ...values,
      countryId: countryId,
      // productId: productID,
    };
    // console.log(requestBody);
    setLoading(true);
    propertyService
      .createProperty(requestBody)
      .then((res) => {
        toast.success(" Property Added Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/property");
        }, 1000);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  return (
    <div className="row">
      <ToastContainer />
      <div className="d-flex align-items-center justify-content-between w-100">
        <h1>Add Property</h1>

        <Link to="/property">
          <button type="button" className="c-btn-1">
            <span>
              <FiChevronRight />
            </span>
            <strong>Back</strong>
          </button>
        </Link>
      </div>
      <Formik
        initialValues={{
          name: "",
          countryId: "",
          // productId: "",
          address: "",
          fundType: "",
          funded: "",
          totalValueFunded: "",
          loanAccountNumber: "",
          purchaseDate: "",
          purchasePrice: "",
          stampDutyCost: "",
          registrationCost: "",
          otherCost: "",
          sellDate: "",
          sellPrice: "",
          totalValueSold: "",
          ledgerName: "",
        }}
        validationSchema={propertySchema}
        onSubmit={(values, { resetForm }) => {
          let reqData = { ...values };
          reqData.purchaseDate = values.purchaseDate
            ? moment(values.purchaseDate).format("YYYY-MM-DD")
            : null;
          propertyCreate(reqData);
          resetForm();
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue }) => {
          return (
            <Form>
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <FormLabel>Name</FormLabel>
                    <Field
                      name="name"
                      className="form-control"
                      type="textarea"
                      rows={5}
                      placeholder="Name"
                    />
                    {errors.name && touched.name ? (
                      <div className="text-danger">{errors.name}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <FormLabel>Select Country</FormLabel>
                    <Field
                      name="countryId"
                      placeholder="Select Country"
                      component={CountryDropDown}
                    />
                    {errors.countryId && touched.countryId ? (
                      <div className="text-danger">{errors.countryId}</div>
                    ) : null}
                  </div>
                </div>
                {/* <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Select Product</FormLabel>
                  <Field
                    name="productId"
                    className="form-control"
                    component={ProductDropDown}
                  />

                  {errors.productId && touched.productId ? (
                    <div className="text-danger">{errors.productId}</div>
                  ) : null}
                </div>
              </div> */}

                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <FormLabel>Adress</FormLabel>
                    <Field
                      name="address"
                      className="form-control"
                      type="textarea"
                      rows={5}
                      placeholder="Address"
                    />
                    {errors.address && touched.address ? (
                      <div className="text-danger">{errors.address}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <FormLabel> Select Fund Type</FormLabel>
                    <select
                      value={values.fundType}
                      onChange={handleChange}
                      name="fundType"
                      className="form-control"
                      aria-label="Default select example"
                    >
                      <option>Select Fund Type</option>
                      <option value="LOAN">Loan</option>
                      <option value="SELF_FUNDED">Self Funded</option>
                    </select>
                  </div>
                  {errors.fundType && touched.fundType ? (
                    <div className="text-danger">{errors.fundType}</div>
                  ) : null}
                </div>

                {values.fundType === "LOAN" && (
                  <>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Funded</FormLabel>
                        <Field
                          name="funded"
                          placeholder="Funded"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          className="form-control"
                        />
                        {errors.funded && touched.funded ? (
                          <div className="text-danger">{errors.funded}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Total Value Funded</FormLabel>
                        <Field
                          name="totalValueFunded"
                          placeholder="Total Value Funded"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          className="form-control"
                        />
                        {errors.totalValueFunded && touched.totalValueFunded ? (
                          <div className="text-danger">
                            {errors.totalValueFunded}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Loan Account Number</FormLabel>
                        <Field
                          name="loanAccountNumber"
                          placeholder="Loan Account Number"
                          type="text"
                          className="form-control"
                        />
                        {errors.loanAccountNumber &&
                        touched.loanAccountNumber ? (
                          <div className="text-danger">
                            {errors.loanAccountNumber}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}

                {values.fundType === "SELF_FUNDED" && (
                  <>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Funded</FormLabel>
                        <Field
                          name="funded"
                          placeholder="Funded"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          className="form-control"
                        />
                      </div>
                      {errors.funded && touched.funded ? (
                        <div className="text-danger">{errors.funded}</div>
                      ) : null}
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Total Value Funded</FormLabel>
                        <Field
                          name="totalValueFunded"
                          placeholder="Total Value Funded"
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          className="form-control"
                        />
                      </div>
                      {errors.totalValueFunded && touched.totalValueFunded ? (
                        <div className="text-danger">
                          {errors.totalValueFunded}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="form-group">
                        <FormLabel>Loan Account Number</FormLabel>
                        <Field
                          name="loanAccountNumber"
                          placeholder="Total Value Funded"
                          type="text"
                          className="form-control"
                        />
                      </div>
                      {errors.loanAccountNumber && touched.loanAccountNumber ? (
                        <div className="text-danger">
                          {errors.loanAccountNumber}
                        </div>
                      ) : null}
                    </div>
                  </>
                )}

                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <FormLabel>Purchase Date</FormLabel>
                    {/* <Field
                      name="purchaseDate"
                      className="form-control"
                      type="date"
                      placeholder="Purchase Date"
                    /> */}
                    <Calendar
                      name="purchaseDate"
                      className="w-100 mb-2 form-group c-calender-w"
                      value={values.purchaseDate}
                      onChange={handleChange}
                      showIcon
                      dateFormat="dd/mm/yy"
                      placeholder="dd/mm/yyyy"
                      readOnlyInput
                    />
                    {errors.purchaseDate && touched.purchaseDate ? (
                      <div className="text-danger">{errors.purchaseDate}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <FormLabel>Purchase Price</FormLabel>
                    <Field
                      name="purchasePrice"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Purchase Price"
                    />
                    {errors.purchasePrice && touched.purchasePrice ? (
                      <div className="text-danger">{errors.purchasePrice}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <FormLabel>Stamp Duty Cost</FormLabel>
                    <Field
                      name="stampDutyCost"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Stamp Duty Cost"
                    />
                    {errors.stampDutyCost && touched.stampDutyCost ? (
                      <div className="text-danger">{errors.stampDutyCost}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <FormLabel>Registration Cost</FormLabel>
                    <Field
                      name="registrationCost"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Registration Cost"
                    />
                    {errors.registrationCost && touched.registrationCost ? (
                      <div className="text-danger">
                        {errors.registrationCost}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <FormLabel>Any Other Cost</FormLabel>
                    <Field
                      name="otherCost"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Any Other Cost"
                    />
                    {errors.otherCost && touched.otherCost ? (
                      <div className="text-danger">{errors.otherCost}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <FormLabel>Total Value Sold</FormLabel>
                    <Field
                      name="totalValueSold"
                      className="form-control"
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Total Value Sold"
                    />
                    {errors.totalValueSold && touched.totalValueSold ? (
                      <div className="text-danger">{errors.totalValueSold}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <FormLabel>Ledger Name</FormLabel>
                    <Field
                      name="ledgerName"
                      className="form-control"
                      type="textarea"
                      rows={5}
                      placeholder="Ledger Name"
                      value={values.ledgerName}
                      onChange={(e) =>
                        setFieldValue(
                          "ledgerName",
                          e.target.value?.toUpperCase()
                        )
                      }
                    />
                    {errors.ledgerName && touched.ledgerName ? (
                      <div className="text-danger">{errors.ledgerName}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              {/* <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Sell Date</FormLabel>
                  <Field
                    name="sellDate"
                    className="form-control"
                    type="date"
                    placeholder="Sell Date"
                  />
                  {errors.sellDate && touched.sellDate ? (
                    <div className="text-danger">{errors.sellDate}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <FormLabel>Sell Price</FormLabel>
                  <Field
                    name="sellPrice"
                    className="form-control"
                     type="number"onWheel={(event) => event.currentTarget.blur()}
                    placeholder="Sell Price"
                  />
                  {errors.sellPrice && touched.sellPrice ? (
                    <div className="text-danger">{errors.sellPrice}</div>
                  ) : null}
                </div>
              </div>
            </div> */}

              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="d-flex c-btn-group">
                    <button
                      type="submit"
                      className="c-btn-1"
                      disabled={loading}
                    >
                      <strong>Submit</strong>
                      {loading ? (
                        <span className="rotate-infinite">
                          <FiRotateCw />
                        </span>
                      ) : (
                        <span>
                          <FiChevronRight />
                        </span>
                      )}
                    </button>

                    <button type="reset" className="c-btn-1 c-del-btn">
                      <strong>Reset</strong>
                      <span>
                        <BiRevision />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddProperty;
