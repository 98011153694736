import { Field, Form, Formik } from "formik";
import { produce } from "immer";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { BiEdit, BiEraser, BiRevision, BiSearchAlt } from "react-icons/bi";
import { BsArrowDown, BsArrowUp, BsTrash, BsUpload } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { ImFileText2 } from "react-icons/im";
import { TiThList } from "react-icons/ti";
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import MutualFundsService from "../../services/MutualFundsService";
import { getCurrencyFormat } from "../../shared/UtilsFunction";
import DocumentUpload from "../components/DocumentUpload";
import LedgerView from "../components/LedgerView";
import PageLoader from "../components/PageLoader";
import Paginator from "../components/Paginator";
import ProductCodeDropDown from "../components/ProductCodeDropDown";
import ProductLedger from "../components/ProductLedger";
import EditMutualFund from "./EditMutualFund";
import MutualRedemptionTableModal from "./component/MutualRedemptionTableModal";
import MutualValueUpdateTable from "./component/MutualValueUpdateTable";

const ListMutualFunds = () => {
  const mutualFundService = new MutualFundsService(); //-----------For Service Call-------//
  const [mutualFundsListData, setMutualFundsListData] = useState([]);
  const toastTL = useRef(null); //-----------For Toster Msg----------------//
  const [edit, SetEdit] = useState(false); //--------For Edit Modal---------//
  const [updateID, setupdateID] = useState([]); //--------For Fatching One Data--------//
  const [editID, setEditID] = useState([]); //--------For edit id---------//
  const reducer = produce((state, action) => {
    switch (action.type) {
      case "dialog":
        state.dialog = action.payload;
        break;
      case "origin":
        state.origin.originId = action.payload.originId;
        state.origin.originItemId = action.payload.originItemId;
        break;
      default:
        return state;
    }
  });
  const [state, dispatch] = useReducer(reducer, {
    dialog: false,
    origin: { originId: null, originItemId: null },
  });
  const [mutualValueModal, setMutualValueModal] = useState(false); //-------for MutualFund Value Update Modal------//
  const [valueUpdate, setValueUpdate] = useState([]); //--------For MutualFund Value Update --------//
  const [mutualRedemptionModal, setMutualRedemptionModal] = useState(false); //-------for Mutual Redemption Modal----//
  const [fundRed, setRed] = useState([]);
  const [pageNo, setpageNo] = useState(1); //--------for pagenation-------//
  const [pageSize, setpageSize] = useState(10); //-----------for pagenation------//
  const [searchBar, setSearchBar] = useState(false); //--------For SearchBar---------//
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const { pathname } = useLocation();
  const [access, setAccess] = useState(null); //--------
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const [loader, setLoader] = useState(false);
  const [order, setOrder] = useState(false);
  const [fieldNmae, setFieldName] = useState(undefined);
  const [showLedger, setShowLedger] = useState(false);
  const [rowId, setRowId] = useState(undefined);
  const [isLedgerView, setIsLedgerView] = useState(false);
  const [selectedLedger, setSelectedLedger] = useState(undefined);

  useEffect(() => {
    if (menuList !== null) {
      const menu = menuList.find((item) => item.url === pathname);
      const access = (({ isDelete, isRead, isWrite }) => ({
        isDelete,
        isRead,
        isWrite,
      }))(menu);
      setAccess(access);
    }
  }, []);

  /////////For Listing///////

  useEffect(() => {
    if (menuList !== null) {
      let payload = {
        pageNo: pageNo,
        pageSize: pageSize,
      };
      if (typeof fieldNmae !== "undefined") {
        payload.sorts = [`${fieldNmae},${order ? "asc" : "desc"}`];
      }
      mutualFundsList(payload);
    }
  }, [pageNo, pageSize, order]);

  /////////For Listing///////

  const mutualFundsList = (payload) => {
    mutualFundService
      .getFundsList(payload)
      .then((res) => {
        setMutualFundsListData(res);
        setLoader(true);
      })

      .catch((e) => {
        toastTL.current.show({
          severity: "error",
          summary: e.name,
          detail: e.message,
          style: { color: "#000000" },
          sticky: true,
        });
        setLoader(false);
      });
  };
  /////////for pagenation////////////
  const increment = () => {
    setpageNo(pageNo + 1);
  };

  const decrement = () => {
    setpageNo(pageNo - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * pageNo) / e.target.value);
    setpageSize(e.target.value);
    setpageNo(temp);
  };

  //////////For Edit MutualFund/////////

  const getIdforUpdate = (id) => {
    mutualFundService.getUpdateFundWithId(id).then((res) => {
      setupdateID(res);
      setEditID(res);
    });

    // let filterID = mutualFundsListData.data.filter((item) => item.id === id);

    // setEditID(filterID[0]);
    // console.log("filterId", filterID[0].id);
  };

  ///////MutualFunds Value update//////
  const metualFundValueUpdate = (id) => {
    mutualFundService.getValueUpdateFund(id).then((res) => {
      setValueUpdate(res);
    });
  };

  //////MutualFunds Redemption//////
  const fundRedemption = (id) => {
    mutualFundService.getfundRedemption(id).then((res) => {
      setRed(res);
    });
  };

  ///////////Delete /////////
  const deleteItem = (id) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are You Sure To Delete This Item.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            mutualFundService
              .deleteFund(id)
              .then((res) => {
                toast.success(res, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              })
              .finally(() => mutualFundsList(payload));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  ///////EDIT Modal/////

  const handleClickEdit = () => {
    SetEdit(true);
  };

  const handleCloseEdit = () => {
    SetEdit(false);
  };

  //////Open modal for MutualFund Value Update  Listing/////

  const handleClickUFundOpen = () => {
    setMutualValueModal(true);
  };
  const handleClickUFundClose = () => {
    setMutualValueModal(false);
  };

  //////Redemption Modal/////
  const handleClickRedemptionOpen = () => {
    setMutualRedemptionModal(true);
  };

  const handleClickRedemptionClose = () => {
    setMutualRedemptionModal(false);
  };

  const handleSearch = (data) => {
    let request = {
      pageNo: 1,
      pageSize: pageSize,
      sorts: [],
    };

    if (data.productCodeId) {
      request.productCodeId = data.productCodeId;
    }
    if (data.companyName) {
      request.companyName = data.companyName;
    }
    if (data.paymentMode) {
      request.paymentMode = data.paymentMode;
    }

    if (data.amount) {
      request.amount = data.amount;
    }

    if (data.purchaseDate) {
      request.purchaseDate = data.purchaseDate;
    }

    mutualFundService.searchFund(request).then((res) => {
      setMutualFundsListData(res);
    });
  };

  const getUpdateFundData = (values) => {
    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
    };
    setLoading(true);
    mutualFundService
      .updateFund(values)
      .then((res) => {
        setUpdateData(res);
        toast.success("MutualFund Updated ", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        mutualFundsList(payload);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  const reLoad = () => {
    window.location.reload();
  };

  const onSort = (fieldName, value) => {
    setFieldName(fieldName);
    setOrder(value);
  };

  const onShowTransaction = (id) => {
    setRowId(id);
    setShowLedger(true);
  };

  const onShowLedger = (item) => {
    setSelectedLedger(item);
    setIsLedgerView(true);
  };

  const onCloseLedger = () => {
    setShowLedger(false);
  };

  const onCloseLedgerView = () => {
    setSelectedLedger(undefined);
    setIsLedgerView(false);
  };

  return access ? (
    <div className="c-listbank-w">
      <div className="table-responsive">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* {!mutualFundsListData.data && <PageLoader />} */}
        {!loader && <PageLoader />}

        <div className="d-flex align-items-center justify-content-between w-100 mb-2 ">
          <h1>MutualFunds List</h1>
          {pathname === "/mutualfund" && (
            <div className="d-flex align-items-center add-searchBtn">
              <Link to="/mutualfund-add">
                {(access.isWrite || access.isDelete) && (
                  <button type="button" className="c-btn-1">
                    <span>
                      <FiPlus />
                    </span>
                    <strong>MutualFund</strong>
                  </button>
                )}
              </Link>
              {/* <Link to="/mutual-value-update">
                {(access.isWrite || access.isDelete) && (
                  <button type="button" className="c-btn-1 ms-2">
                    <span>
                      <FiPlus />
                    </span>
                    <strong>MutualFund Value Update</strong>
                  </button>
                )}
              </Link>
              <Link to="/mutual-redemption">
                {(access.isWrite || access.isDelete) && (
                  <button type="button" className="c-btn-1 ms-2">
                    <span>
                      <FiPlus />
                    </span>
                    <strong>MutualFund Redemption</strong>
                  </button>
                )}
              </Link> */}
              <button
                type="button"
                className="c-btn-1 ms-2"
                onClick={() => setSearchBar(!searchBar)}
              >
                <span>
                  <BiSearchAlt />
                </span>
                <strong>Search</strong>
              </button>
            </div>
          )}
        </div>

        <div className="cn-bank-search-w">
          <Collapse in={searchBar}>
            <div className="cn-bank-search-form">
              <h1>Search</h1>
              <Formik
                initialValues={{
                  productCodeId: "",
                  companyName: "",
                  paymentMode: "",
                  amount: "",
                  purchaseDate: "",
                }}
                onSubmit={(values) => {
                  let reqData = { ...values };
                  reqData.purchaseDate = values.purchaseDate
                    ? moment(values.purchaseDate).format("YYYY-MM-DD")
                    : null;
                  handleSearch(reqData);
                }}
              >
                {({ handleChange, onSubmit, values, errors, touched }) => (
                  <Form>
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <Field
                            name="productId"
                            className="form-control"
                            component={ProductCodeDropDown}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <Field
                            name="companyName"
                            className="form-control"
                            type="text"
                            placeholder="Company Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <Field
                            name="paymentMode"
                            className="form-control"
                            type="text"
                            placeholder="Payment Mode"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          <Field
                            name="amount"
                            className="form-control"
                            type="number"
                            onWheel={(event) => event.currentTarget.blur()}
                            placeholder="Amount"
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group">
                          {/* <Field
                            name="purchaseDate"
                            className="form-control"
                            type="date"
                            placeholder="Purchase Date"
                          /> */}

                          <Calendar
                            name="purchaseDate"
                            className="w-100 mb-2 form-group c-calender-w"
                            value={values.purchaseDate}
                            onChange={handleChange}
                            showIcon
                            dateFormat="dd/mm/yy"
                            placeholder="dd/mm/yyyy"
                            readOnlyInput
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-group d-flex justify-content-end">
                          <button type="submit" className="c-btn-1">
                            <strong>SEARCH</strong>{" "}
                          </button>

                          <button
                            type="reset"
                            className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                          >
                            <strong>Reset</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                          <button
                            // type="reset"
                            onClick={() => reLoad()}
                            className="c-btn-1 c-del-btn c-cancel-btn ms-2"
                          >
                            <strong>Reload</strong>
                            <span>
                              <BiRevision />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Collapse>
        </div>

        <table className="table table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th onClick={() => onSort("productId", !order)}>
                Product
                <BsArrowUp className="colorChange" />{" "}
                <BsArrowDown className="colorChange" />
              </th>

              <th onClick={() => onSort("companyName", !order)}>
                company Name
                <BsArrowUp className="colorChange" />{" "}
                <BsArrowDown className="colorChange" />
              </th>
              <th onClick={() => onSort("paymentMode", !order)}>
                Payment Mode
                <BsArrowUp className="colorChange" />{" "}
                <BsArrowDown className="colorChange" />
              </th>
              <th>Amount</th>
              <th>Current Nav</th>
              {/* <th onClick={() => onSort("purchaseDate", !order)}>
                Purchase Date
                <BsArrowUp className="colorChange" />{" "}
                <BsArrowDown className="colorChange" />
              </th> */}
              {(access.isWrite || access.isDelete) && <th>Action</th>}
            </tr>
          </thead>

          <tbody>
            {mutualFundsListData.data && mutualFundsListData.data.length > 0
              ? mutualFundsListData.data.map((item, index) => {
                  return (
                    <tr key={`mutualfund-${index}`}>
                      <td>{pageSize * pageNo - pageSize + 1 + index}</td>
                      <td>{item.productId}</td>
                      <td>{item.companyName}</td>
                      <td>{item.paymentMode}</td>
                      <td>{getCurrencyFormat(item.amount)}</td>
                      <td>{item.nav}</td>
                      {/* <td>
                        {moment(item.purchaseDate).format("Do MMM, YYYY")}
                      </td> */}
                      {(access.isWrite || access.isDelete) && (
                        <td>
                          {access.isWrite ? (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-bottom`}>Edit</Tooltip>
                              }
                            >
                              <button
                                onClick={() => getIdforUpdate(item.id)}
                                type="button"
                                className="c-edit-btn"
                              >
                                {" "}
                                <BiEdit onClick={handleClickEdit} />{" "}
                              </button>
                            </OverlayTrigger>
                          ) : null}
                          {access.isDelete ? (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id={`tooltip-bottom`}>Delete</Tooltip>
                              }
                            >
                              <button
                                onClick={() => deleteItem(item.id)}
                                type="button"
                                className="c-edit-btn"
                              >
                                {" "}
                                <BsTrash />{" "}
                              </button>
                            </OverlayTrigger>
                          ) : null}
                          {/* <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                Value Update List
                              </Tooltip>
                            }
                          >
                            <button
                              onClick={() => metualFundValueUpdate(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <ImFileText2
                                onClick={handleClickUFundOpen}
                              />{" "}
                            </button>
                          </OverlayTrigger> */}

                          {/* <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                Redemption List
                              </Tooltip>
                            }
                          >
                            <button
                              onClick={() => fundRedemption(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              {" "}
                              <BiEraser
                                onClick={handleClickRedemptionOpen}
                              />{" "}
                            </button>
                          </OverlayTrigger> */}
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>Document</Tooltip>
                            }
                          >
                            <button
                              type="button"
                              className="c-edit-btn"
                              onClick={() => {
                                dispatch({ type: "dialog", payload: true });
                                dispatch({
                                  type: "origin",
                                  payload: {
                                    originId: item?.productId,
                                    originItemId: item?.id,
                                  },
                                });
                              }}
                            >
                              <BsUpload />
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                Transaction View
                              </Tooltip>
                            }
                          >
                            <button
                              onClick={() => onShowTransaction(item.id)}
                              type="button"
                              className="c-edit-btn"
                            >
                              <TiThList />
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                Ledger View
                              </Tooltip>
                            }
                          >
                            <button
                              type="button"
                              className="c-edit-btn"
                              onClick={() => onShowLedger(item)}
                            >
                              <TiThList />
                            </button>
                          </OverlayTrigger>
                        </td>
                      )}
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        <Paginator
          pageSize={mutualFundsListData.pageSize}
          firstPage={mutualFundsListData.firstPage}
          lastPage={mutualFundsListData.lastPage}
          decrement={decrement}
          increment={increment}
          pagesizechange={pagesizechange}
          pageNo={mutualFundsListData.pageNo}
          totalPages={mutualFundsListData.totalPages}
        />
      </div>

      {edit ? (
        <EditMutualFund
          mutualFundFilterData={editID}
          close={handleCloseEdit}
          getUpdateFundData={getUpdateFundData}
          loading={loading}
        />
      ) : null}

      {mutualRedemptionModal ? (
        <MutualRedemptionTableModal
          valueUpdateData={fundRed}
          close={handleClickRedemptionClose}
        />
      ) : null}
      {mutualValueModal ? (
        <MutualValueUpdateTable
          valueUpdateData={valueUpdate}
          close={handleClickUFundClose}
        />
      ) : null}
      <Dialog
        visible={state.dialog}
        style={{ width: "50%" }}
        onHide={() => {
          dispatch({ type: "dialog", payload: false });
        }}
        header={<h1>Document Upload</h1>}
      >
        <DocumentUpload name={"Mutual_Fund"} origin={state.origin} />
      </Dialog>
      {showLedger && (
        <ProductLedger
          onClose={onCloseLedger}
          rowId={rowId}
          defaultValue={"MUTUAL_FUNDS"}
        />
      )}

      {isLedgerView && (
        <LedgerView
          onClose={onCloseLedgerView}
          selectedLedger={selectedLedger}
          defaultValue={"MUTUAL_FUNDS"}
        />
      )}
    </div>
  ) : null;
};

export default ListMutualFunds;
