import { QueryClient } from "react-query";

export function isNull(str) {
  if (str === null) {
    return "";
  } else {
    return str;
  }
}

export function convertNullToString(obj) {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] === null) {
      obj[key] = "";
    }
  });
  return obj;
}

export const queryClient = new QueryClient();
