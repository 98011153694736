import React, { Fragment, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import PageLoader from "../components/PageLoader";
import { convertToRoman } from "../../shared/UtilsFunction";
import LedgerModel from "./LedgerModel";
import SearchByYear from "../components/SearchByYear";
import LedgerService from "../../services/LedgerService";
import moment from "moment";
import { SplitButton } from "primereact/splitbutton";
import jsPDF from "jspdf";
import { utils, writeFile } from "xlsx";

const ProfitLoss = () => {
  const ledgerService = new LedgerService();
  const tableRef = useRef(null);
  const incomeRef = useRef();
  const expenseRef = useRef();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [ledgerDetails, setLedgerDetails] = useState(null);
  const [isShowLedgerModel, setIsShowLedgerModal] = useState(false);
  const [searchReqData, setSearchReqData] = useState(null);
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));
  const [selectedName, setSelectedName] = useState("");
  const genaratePdf = () => {
    const label =
      searchReqData.date === "CUSTOM_DATE_RANGE"
        ? searchReqData.startYear + "_" + searchReqData.endYear
        : searchReqData.date;
    const pdf = new jsPDF("landscape", "px", [1320, 1200]);
    const data = tableRef.current;
    pdf.html(data, { margin: 40 }).then(() => {
      pdf.save(`Profit_and_loss_${label}.pdf`);
    });
  };
  const generateXlsx = () => {
    const label =
      searchReqData.date === "CUSTOM_DATE_RANGE"
        ? searchReqData.startYear + "_" + searchReqData.endYear
        : searchReqData.date;
    var wb = utils.book_new();
    const book1 = incomeRef.current;
    const book2 = expenseRef.current;
    const ws1 = utils.table_to_sheet(book1);
    const ws2 = utils.table_to_sheet(book2);
    utils.book_append_sheet(wb, ws1, "Income");
    utils.book_append_sheet(wb, ws2, "Expanse");
    writeFile(wb, `Profit_and_loss_${label}.xlsx`);
  };
  useEffect(() => {
    if (menuList !== null) {
      let reqData = {
        date: "CURRENT_FINANCIAL_YEAR",
      };
      getData(reqData);
    }
  }, []);
  const items = [
    {
      label: "CSV",
      icon: "pi pi-download",
      command: () => {
        generateXlsx();
      },
    },
    {
      label: "PDF",
      icon: "pi pi-download",
      command: () => {
        genaratePdf();
      },
    },
  ];
  const getData = (value) => {
    let reqData = value
      ? {
          date: Boolean(value.date) ? value.date : null,
          startYear: value?.startYear
            ? moment(value?.startYear).format("YYYY-MM-DD")
            : null,
          endYear: value?.endYear
            ? moment(value?.endYear).format("YYYY-MM-DD")
            : null,
        }
      : null;
    ledgerService
      .getProfitLoss(reqData)
      .then((response) => {
        let balanceSheetResponse = response;

        let income = 0;
        let expense = 0;

        balanceSheetResponse?.incomeModel?.groupList?.forEach((element) => {
          element?.subGroupDtoList?.forEach((item) => {
            income += Math.abs(item.amount.toFixed(2));
          });
        });

        balanceSheetResponse?.expensesModel?.groupList?.forEach((element) => {
          element?.subGroupDtoList?.forEach((item) => {
            expense += Math.abs(item.amount.toFixed(2));
          });
        });
        setData(balanceSheetResponse);
        setTotalIncome(income);
        setTotalExpense(expense);
        setSearchReqData(reqData);
        setLoading(false);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  const onShowLegerDetails = (item) => {
    let reqData = {
      subGroupId: item.subgroupId,
      timeRange: searchReqData,
    };

    setLoading(true);
    ledgerService
      .getLedgerDetails(reqData)
      .then((res) => {
        setLedgerDetails(res);
        setSelectedName(item.subGroupName);
        setLoading(false);
        setIsShowLedgerModal(true);
      })
      .catch((e) => {
        toast.error(e.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      });
  };

  const onCloseLedgerModel = () => {
    setIsShowLedgerModal(false);
  };

  const onSearch = (value) => {
    setLoading(true);
    getData(value);
  };

  return (
    <>
      {loading && <PageLoader />}

      <div className="c-balansheet-table">
        <div className="row align-team-center">
          <SearchByYear
            heading={"FOR PROFIT & LOSS ITEMS"}
            onSearch={onSearch}
          />
        </div>
        <div className="d-flex justify-content-end pb-2">
          <SplitButton className="cr-split" label="Export" model={items} />
        </div>
        <div ref={tableRef} className="row g-2 cr-tableBx">
          <div className="col-6">
            <table ref={incomeRef} style={{ width: "100%" }}>
              <tbody>
                <tr style={{ backgroundColor: "#253d98", color: "white" }}>
                  <th width="15%">SL. NO.</th>
                  <th width="60%">INCOME</th>
                  <th width="25%" style={{ textAlign: "right" }}>
                    AMOUNT
                  </th>
                </tr>
                {data?.incomeModel?.groupList.map((item, index) => (
                  <Fragment key={item}>
                    <tr key={index}>
                      <td
                        width="15%"
                        style={{ textAlign: "center !important" }}
                      >
                        {(index + 1 + 9).toString(36).toUpperCase()}.
                      </td>
                      <td width="60%">
                        <strong>{item.ledgerGroupName}</strong>
                      </td>
                      <td
                        width="25%"
                        style={{ textAlign: "right !important" }}
                      ></td>
                    </tr>
                    {item?.subGroupDtoList.map((elm, ind) => (
                      <tr key={ind} width="15%">
                        <td style={{ textAlign: "right !important" }}>
                          {convertToRoman(ind + 1)}.
                        </td>
                        <td
                          width="60%"
                          onClick={() => onShowLegerDetails(elm)}
                          className="cursor-pointer"
                        >
                          {elm.subGroupName}
                        </td>
                        <td
                          width="25%"
                          style={{ textAlign: "center !important" }}
                        >
                          {Math.abs(elm?.amount)}
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
                {/* <tr>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    <strong>Total Liability</strong>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <strong> {totalLiability}</strong>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
          <div className="col-6">
            <table ref={expenseRef} style={{ width: "100%" }}>
              <tbody>
                <tr style={{ backgroundColor: "#253d98", color: "white" }}>
                  <th width="15%">SL. NO.</th>
                  <th width="60%">EXPENSE</th>
                  <th width="25%" style={{ textAlign: "right" }}>
                    AMOUNT
                  </th>
                </tr>
                {data?.expensesModel?.groupList.map((item, index) => (
                  <Fragment key={item}>
                    <tr key={index}>
                      <td width="15%" style={{ textAlign: "center" }}>
                        {(index + 1 + 9).toString(36).toUpperCase()}.
                      </td>
                      <td width="60%">
                        <strong>{item.ledgerGroupName}</strong>
                      </td>
                      <td width="25%" style={{ textAlign: "right" }}></td>
                    </tr>
                    {item?.subGroupDtoList.map((elm, ind) => (
                      <tr key={ind} width="15%">
                        <td style={{ textAlign: "right" }}>
                          {convertToRoman(ind + 1)}.
                        </td>
                        <td
                          width="60%"
                          onClick={() => onShowLegerDetails(elm)}
                          className="cursor-pointer"
                        >
                          {elm.subGroupName}
                        </td>
                        <td width="25%" style={{ textAlign: "left" }}>
                          {Math.abs(elm?.amount)}
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
                {/* {totalExpense - totalIncome > 0 ? (
                  <tr>
                    <td colSpan={2} style={{ textAlign: "right" }}>
                      <strong>Net Loss</strong>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <strong>
                        {" "}
                        {Math.abs(totalExpense - totalIncome).toFixed(2)}
                      </strong>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={2} style={{ textAlign: "right" }}>
                      <strong>Net Profit </strong>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <strong>
                        {Math.abs(totalExpense - totalIncome).toFixed(2)}
                      </strong>
                    </td>
                  </tr>
                )} */}
              </tbody>
            </table>
            {totalExpense - totalIncome > 0 ? (
              <p style={{ margin: 20, textAlign: "right" }}>
                Net Loss {Math.abs(totalExpense - totalIncome).toFixed(2)}
              </p>
            ) : (
              <p style={{ margin: 20, textAlign: "right" }}>
                Net Profit {Math.abs(totalExpense - totalIncome).toFixed(2)}
              </p>
            )}
          </div>
        </div>
        {/* <div ref={tableRef} className="table-responsive">
          <table
            className="table c-balansheet-table-main"
            style={{ border: 0 }}
          >
            <tr>
              <td>
                <table className="table table-bordered cr-nonBorderTbl">
                  <tr>
                    <th width="10%">SL. NO.</th>
                    <th width="55%">INCOME</th>
                    <th width="15%">AMOUNT</th>
                  </tr>

                  {data?.incomeModel?.groupList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td colSpan={3} className="no-padding">
                          <table className="table table-bordered cr-nonBorderTbl">
                            <tr className="c-balansheet-table-parent">
                              <td width="15%">
                                {(index + 1 + 9).toString(36).toUpperCase()}.
                              </td>
                              <td width="60%">
                                <strong>{item.ledgerGroupName}</strong>
                              </td>
                              <td width="25%"></td>
                            </tr>
                            {item?.subGroupDtoList.map((elm, ind) => {
                              return (
                                <tr key={ind} width="15%">
                                  <td>{convertToRoman(ind + 1)}.</td>
                                  <td
                                    width="60%"
                                    onClick={() => onShowLegerDetails(elm)}
                                    className="cursor-pointer"
                                  >
                                    {elm.subGroupName}
                                  </td>
                                  <td width="25%">
                                    {Math.abs(elm?.amount).toFixed(2)}
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </td>
              <td>
                <table className="table table-bordered cr-nonBorderTbl">
                  <tr>
                    <th width="10%">SL. NO.</th>
                    <th width="55%">EXPENSE</th>
                    <th width="15%">AMOUNT</th>
                  </tr>
                  <tbody>
                    {data?.expensesModel?.groupList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td colSpan={3} className="no-padding">
                            <table className="table table-bordered cr-nonBorderTbl">
                              <tr className="c-balansheet-table-parent">
                                <td width={"15%"}>
                                  {(index + 1 + 9).toString(36).toUpperCase()}.
                                </td>
                                <td width={"60%"}>
                                  <strong>{item.ledgerGroupName}</strong>
                                </td>
                                <td width={"25%"}></td>
                              </tr>

                              {item?.subGroupDtoList.map((elm, ind) => (
                                <tr
                                  key={ind}
                                  onClick={() => onShowLegerDetails(elm)}
                                >
                                  <td width={"15%"}>
                                    {convertToRoman(ind + 1)}.
                                  </td>
                                  <td
                                    width={"60%"}
                                    onClick={() => onShowLegerDetails(elm)}
                                    className="cursor-pointer"
                                  >
                                    {elm.subGroupName}
                                  </td>
                                  <td width={"25%"}>
                                    {Math.abs(elm?.amount).toFixed(2)}
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </div> */}
      </div>

      {totalExpense - totalIncome > 0 ? (
        <h3 style={{ margin: 20, textAlign: "right" }}>
          Net Loss {Math.abs(totalExpense - totalIncome).toFixed(2)}
        </h3>
      ) : (
        <h3 style={{ margin: 20, textAlign: "right" }}>
          Net Profit {Math.abs(totalExpense - totalIncome).toFixed(2)}
        </h3>
      )}

      {isShowLedgerModel && (
        <LedgerModel
          onClose={onCloseLedgerModel}
          ledgerDetails={ledgerDetails}
          heading={selectedName}
        />
      )}
    </>
  );
};

export default ProfitLoss;
