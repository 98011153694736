import React, { useReducer, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { BsUpload } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import moment from "moment/moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Paginator from "../components/Paginator";
import AccountService from "../../services/AccountService";
import PageLoader from "../components/PageLoader";
import { Dialog } from "primereact/dialog";
import { produce } from "immer";

const ListJournal = () => {
  const accountService = new AccountService();
  const navigate = useNavigate();
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const menuList = JSON.parse(sessionStorage.getItem("userMenuList"));

  const reducer = produce((state, action) => {
    switch (action.type) {
      case "dialog":
        state.dialog = action.payload;
        break;
      case "origin":
        state.origin.originId = action.payload.originId;
        state.origin.originItemId = action.payload.originItemId;
        break;
      default:
        return state;
    }
  });
  const [state, dispatch] = useReducer(reducer, {
    dialog: false,
    origin: { originId: null, originItemId: null },
  });

  useEffect(() => {
    if (menuList !== null) {
      setIsLoading(true);
      let reqData = {
        pageNo: page,
        pageSize: pageSize,
      };
      accountService
        .getAllJournal(reqData)
        .then((res) => {
          setAllData(res);
          setIsLoading(false);
        })
        .catch((e) => {
          toast.error(e.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        });
    }
  }, [page, pageSize]);

  const increment = () => {
    setPage(page + 1);
  };

  const decrement = () => {
    setPage(page - 1);
  };

  const pagesizechange = (e) => {
    let temp = Math.ceil((pageSize * page) / e.target.value);
    setpageSize(e.target.value);
    setPage(temp);
  };

  const onEdit = (id) => {
    navigate(`/addjournal`, { state: id });
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <div className="c-listbank-w">
        <div className="d-flex justify-content-end">
          <Link to="/addjournal">
            <button type="button" className="c-btn-1">
              <span>
                <FiPlus />
              </span>
              <strong>Journal</strong>
            </button>
          </Link>
        </div>
        <div className="table-responsive">
          <h1>Journal List</h1>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>#</th>
                <th>Journal No</th>
                <th>Transaction Date</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {allData?.data?.map((item, index) => {
                return (
                  <tr key={index.toString()}>
                    <td>{index + 1}</td>
                    <td>{item.number}</td>
                    <td>{moment(item.txnDate).format("Do MMM YYYY")}</td>
                    <td>{item.debit}</td>
                    <td>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>Preview</Tooltip>
                        }
                      >
                        <button
                          onClick={() => onEdit(item.id)}
                          type="button"
                          className="c-edit-btn"
                        >
                          <BiEdit />
                        </button>
                      </OverlayTrigger>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <Paginator
            pageSize={allData.pageSize}
            firstPage={allData.firstPage}
            lastPage={allData.lastPage}
            decrement={decrement}
            increment={increment}
            pagesizechange={pagesizechange}
            pageNo={allData.pageNo}
            totalPages={allData.totalPages}
          />
        </div>
      </div>
    </>
  );
};

export default ListJournal;
