import React, { useEffect, useState } from "react";
import PropertyService from "../../services/PropertyService";
import { Form } from "react-bootstrap";

const PropertyDropDown = ({ field, form: { touched, errors }, ...props }) => {
  const propertyService = new PropertyService(); //----------For Suevice---------------//
  const [property, setProperty] = useState([]);

  useEffect(() => {
    getProperty();
  }, []);

  const getProperty = () => {
    propertyService.getAllProperty().then((res) => {
      setProperty(res);
    });
  };

  return (
    <div>
      <Form.Select className="form-control" {...field} {...props}>
        <option value="null">Select Property</option>
        {property.map((item) => (
          <option key={item.id} value={item.id}>
            {item.id}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

export default PropertyDropDown;
